import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import { Chip, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AGENTIC_BASE_MODELS } from "../../Configs/AgenticConfigs";
import { PAGE_ROUTES } from "../../Configs/Routes";
import { color } from "../../Styles/Color";
import ModelList from "./ModelsList";


export const Models = () => {

  const nav = useNavigate()
  const myRef = useRef(null);

  const [searched, setSearched] = useState("")

  const handleScroll = () => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      myRef.current.scrollLeft += 25;
      scrollAmount += Math.abs(25);
      if (scrollAmount >= 600) {
        clearInterval(slideTimer);
      }
    }, 10)
  };

  return <Stack spacing={5} py={2}>
    <Stack spacing={2}>
      <Typography variant="h1">Deploy TheAgentic Base Models</Typography>
      <Stack direction="row" spacing={2}>
        <Stack
          ref={myRef}
          direction="row"
          gap={2}
          overflow="auto"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
              scrollBehavior: "smooth"
            }
          }}
          width="100%"
        >
          {
            AGENTIC_BASE_MODELS.map((temp, idx) => (
              <Stack
                key={idx}
                minWidth="32%"
                borderRadius="12px"
                border={`1px solid ${color.borders}`}
                boxShadow="0px 1px 4px 0px #0000000A"
                overflow="hidden"
                height="15vh"
                position='relative'
                sx={{
                  "&:hover": {
                    cursor: 'pointer'
                  },
                }}
                onClick={() =>
                  nav(PAGE_ROUTES.agenticLaunch, { state: { model: "TheAgenticAI" } })
                }
              >
                <Stack
                  position="absolute"
                  top={0} left={0}
                  height="100%"
                  width="100%"
                  justifyContent="center"
                  alignItems="end"
                >
                  {temp.icon}
                </Stack>
                <Stack
                  position="absolute"
                  top={0} left={0}
                  zIndex="5"
                  spacing={1}
                  width="100%"
                  p={2}
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant='h3'>DEPLOY</Typography>
                    <Chip
                      sx={{
                        background: "rgba(255, 255, 255, 0.55)",
                        textTransform: "uppercase",
                        px: 1,
                      }}
                      label={<Typography variant='h3' fontSize="12px">{temp.inf_type}</Typography>}
                    />
                  </Stack>
                  <Typography variant='body1' color="#4660C8">{temp.model}</Typography>
                  <Typography variant='subtitle1' color={color.secondaryText}>
                    by {temp.provider}
                  </Typography>
                </Stack>
              </Stack>
            ))
          }
        </Stack>
        <Stack
          width="5%"
          borderRadius="8px"
          bgcolor={color.secondaryBackground}
          border={`1px solid ${color.borders}`}
          justifyContent="center"
          alignItems="center"
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={handleScroll}
        >
          <ArrowForwardIosIcon />
        </Stack>
      </Stack>
    </Stack>
    <Stack spacing={2}>
      <OutlinedInput
        value={searched}
        size='small'
        border={`1px solid ${color.borders}`}
        onChange={e => setSearched(e.target.value)}
        sx={{
          borderRadius: "8px",
          boxShadow: "0px 0px 4px 0px #00000029",
        }}
        placeholder='Search by model name or project id'
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize='small' />
          </InputAdornment>
        }
      />
      <ModelList />
    </Stack>
  </Stack >
}
