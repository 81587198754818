export const color = {
  primary: process.env.REACT_APP_PRIMARY_COLOR || "#0077E6",
  // selectedPrimary: "#0C66B9",
  selectedPrimary: "rgba(0, 0, 0, 0.2)",
  secondaryBackground: "#FAFAFA",
  white: "#FFFFFF",
  borders: "#E4E4E4",
  error: "#D32F2F",
  lightBlue: "#D8ECFF",
  secondaryText: "#ABABAB",
  successText: "#2AC27D",
  deleteText: "#FF4949",
  yellow: "#FCC120",
  blueBorders: "#3D98EC"
}