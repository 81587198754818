import { Switch, styled } from '@mui/material';
import React from 'react';
import { color } from '../../Styles/Color';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 31,
  height: 18,
  padding: 0,
  marginRight: 10,
  display: 'flex',

  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(13px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: color.primary,
      },
    },
    '&.Mui-disabled': {
      color: color.borders,
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    '&.Mui-checked.Mui-disabled': {
      color: color.lightBlue,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: color.primary,
      },
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: "6px",
    transition: theme.transitions.create(['width'], {
      duration: 200,
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: "8px",
    opacity: 1,
    backgroundColor: color.borders,
    boxSizing: 'border-box',
  },

}));

const BoxSwitch = ({ value, onChange, disabled }) => {
  return (
    <AntSwitch
      disabled={disabled}
      checked={value}
      onChange={onChange}
    />
  )
}

export default BoxSwitch