import { Stack, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import SubscriptionModal from '../Components/BillingComponents/SubscriptionModal';
import TopUpModal from '../Components/BillingComponents/TopUpModal';
import TransactionList from '../Components/BillingComponents/TransactionList';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { useGetBalanceQuery, useGetTransactionsQuery } from '../Services/billingApi';
import { color } from '../Styles/Color';

const {
  REACT_APP_STRIPE_PK
} = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PK)


export const BillingView = () => {

  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false)
  const [isSubsModalOpen, setIsSubsModalOpen] = useState(false)

  const { data, isLoading: loadingBalance, refetch: refetchBalance } = useGetBalanceQuery()
  const { data: transactions, isLoading: loadingTransactions, refetch: refetchTransaction } = useGetTransactionsQuery()

  if (loadingBalance || loadingTransactions) {
    return <Stack height="80%">
      <Loader />
    </Stack>
  }

  return (
    <Stack
      spacing={5}
      overflow="auto"
      // sx={{
      //   '&::-webkit-scrollbar': {
      //     display: 'none',
      //   }
      // }}
      p={1}
    >
      <Stack spacing={3}>
        <Typography variant='h1'>Billing</Typography>
        <Stack direction="row" gap={3} width="90%">
          <Stack
            borderColor={color.borders}
            borderRadius="12px"
            border={`1px solid ${color.borders}`}
            boxShadow="0px 1px 4px 0px #0000000A"
            width="45%"
            px={3}
            py={2}
            spacing={1}
          >
            <Stack spacing={1}>
              <Typography variant='h2'> Current Balance </Typography>
              <Typography variant='h1'>
                $ {data && Math.round(data.regular_balance * 100) / 100}
              </Typography>
            </Stack>
            <Stack alignItems="end">
              <CustomButton onClick={() => setIsTopUpModalOpen(true)} >
                Top Up Balance
              </CustomButton>
            </Stack>
          </Stack>
          <Stack
            borderColor={color.borders}
            borderRadius="12px"
            border={`1px solid ${color.borders}`}
            boxShadow="0px 1px 4px 0px #0000000A"
            width="45%"
            px={3}
            py={2}
            spacing={1}
          >
            <Stack spacing={1}>
              <Typography variant='h2'>
                Subscription Balance {`( ${data.months_left || 0} months left )`}
              </Typography>
              <Typography
                variant='h1'
                display="flex" flexDirection="row"
                alignItems="center"
                gap={3}
              >
                $ {data && Math.round(data.discount_balance * 100) / 100}
                <Typography variant='h2'>
                  ( $ {`${data && Math.round(data.refill_per_month * 100) / 100} / month `})
                </Typography>
              </Typography>
            </Stack>
            <Stack alignItems="end">
              <CustomButton onClick={() => setIsSubsModalOpen(true)}>
                Add Subscription
              </CustomButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant='h1' fontSize="20px">Transaction History</Typography>
        <TransactionList data={transactions} handleRedirect={() => setIsTopUpModalOpen(true)} />
      </Stack>
      <Elements stripe={stripePromise} key="Top-up">
        <TopUpModal
          isOpen={isTopUpModalOpen} setIsOpen={setIsTopUpModalOpen}
          refetchBalance={refetchBalance} refetchTransaction={refetchTransaction}
          key="Top-up"
        />
      </Elements>
      <Elements stripe={stripePromise} key="Subs">
        <SubscriptionModal
          isOpen={isSubsModalOpen} setIsOpen={setIsSubsModalOpen}
          refetchBalance={refetchBalance} refetchTransaction={refetchTransaction}
          key="Subs"
        />
      </Elements>
    </Stack>
  )
}
