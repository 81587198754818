import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIsSuccess } from "../../DataStore/successSlice";

export default function SuccessAlert() {
  const { isSuccess, successMessage } = useSelector(
    (state) => state.successState
  );
  const dispatch = useDispatch();

  return (
    <Snackbar
      open={isSuccess}
      autoHideDuration={3000}
      onClose={() => dispatch(setIsSuccess(false))}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ width: "30%" }}
    >
      <Alert severity="success" sx={{ width: "100%" }}>
        {successMessage}
      </Alert>
    </Snackbar>
  );
}
