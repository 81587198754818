import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { ModelConfiguration } from '../Inference/ModelConfiguration'
import { DetailFinetuneForm } from './DetailFinetuneForm'
import { FinetuneTemplateForm } from './FinetuneTemplateForm'

export const CustomFinetuneForm = ({
  watch, setValue, errors, setError, clearErrors, handleSubmit, reset
}) => {
  const nav = useNavigate()

  const [isModelConfigured, setIsModelConfigured] = useState(watch('model')?.length > 0 ? true : false)
  const [isFineTuned, setIsFineTuned] = useState(false)


  const handleBack = () => {
    nav(-1)
  }

  const handleNext = () => {
    if (watch("model")?.length === 0) {
      setError("model", { type: 'custom', message: 'This field is required' })
    } else if (isFineTuned && (!watch("base_model") || watch("base_model")?.length === 0)) {
      setError("base_model", { type: 'custom', message: 'This field is required' })
    } else {
      clearErrors()
      setIsModelConfigured(true)
    }
  }

  return !isModelConfigured ?
    <ModelConfiguration
      handleBack={handleBack} handleNext={handleNext}
      isFineTuned={isFineTuned} setIsFineTuned={setIsFineTuned}
      watch={watch} setValue={setValue} errors={errors}
      reset={reset} clearErrors={clearErrors}
    /> :
    isFineTuned ?
      <FinetuneTemplateForm
        handleSubmit={handleSubmit}
        handleBack={() => setIsModelConfigured(false)}
        backTitle="Go back to model configuration"
        watch={watch}
        setValue={setValue}
        errors={errors}
      /> :
      <DetailFinetuneForm
        handleSubmit={handleSubmit}
        handleBack={() => setIsModelConfigured(false)}
        watch={watch} setValue={setValue} errors={errors}
      />
}
