import { isRejectedWithValue } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/react";
import { setErrorMessage, setIsError } from '../DataStore/errorSlice';

const getErrorMessage = (error) => {

  if (Array.isArray(error?.data?.detail)) {
    return error?.data?.detail
      .map((obj) => {
        if (obj?.loc) {
          return `${obj?.loc?.join(".")} ${obj?.msg}`;
        }

        return obj?.msg ?? "";
      })
      .join("\n");
  } else if (typeof error?.data?.detail === "string") {
    return error?.data?.detail
  } else if (error?.message || error?.data?.message || error?.error?.message) {
    return error?.message || error?.data?.message || error?.error?.message;
  } else if (typeof error === "string") {
    return error;
  }

  return "An unknown error occured"
}


export const ErrorMiddleware = api => next => action => {

  if (isRejectedWithValue(action) && !action.meta.baseQueryMeta.request.url.includes("secret")) {

    const data = action.payload.error ?? action.payload.data?.detail

    api.dispatch(setIsError(true))
    api.dispatch(setErrorMessage(action.payload.data ? getErrorMessage(action.payload) : "Something went wrong"))

    const fetchError = new Error(
      `${action.meta.baseQueryMeta.request.method} ${action.meta.baseQueryMeta.request.url} ${action.payload.status} (${data})`
    )

    fetchError.name = "API Error"

    Sentry.captureException(fetchError);
  }
  return next(action)
}
