import AnyScaleLOGO from "../Assets/Images/Anyscale-logo-vertical.webp"
import AntropicLogo from "../Assets/Images/anthropic-logo.webp"
import { ReactComponent as DeepInfraLOGO } from "../Assets/Images/deepInfraLogo.svg"
import { ReactComponent as OpenAILogo } from "../Assets/Images/openai-svgrepo-com.svg"
import { ReactComponent as TogetherAILOGO } from "../Assets/Images/togetherAILogo.svg"

export const LLM_PROVIDER_OPTIONS = Object.freeze([
  {
    name: "OPENAI",
    icon: (width) => <OpenAILogo width={width} />
  },
  {
    name: "ANTHROPIC",
    icon: (width) => <img src={AntropicLogo} width={width} alt="" />
  },
  {
    name: "TOGETHER",
    icon: (width) => <TogetherAILOGO width={width} />
  },
  {
    name: "DEEPINFRA",
    icon: (width) => <DeepInfraLOGO width={width} />
  },
  {
    name: "ANYSCALE",
    icon: (width) => <img src={AnyScaleLOGO} alt="AnyScale" width={width} />
  },
])