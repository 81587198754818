/* eslint-disable no-unused-vars */
import { Divider, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import BoxSwitch from '../../Components/UiComponents/BoxSwitch'
import InputField from '../../Components/UiComponents/InputField'
import NavigationTabs from '../../Components/UiComponents/NavigationTabs'
import { SelectDropdown } from '../../Components/UiComponents/SelectDropdown'
import { CLOUD_CONFIG_WORKER_OPTIONS, CONFIGURE_OPTIONS, EMBEDDINGS_CLOUD_CONFIG_WORKER_OPTIONS } from '../../Configs/JobConstants'
import { PAGE_ROUTES } from '../../Configs/Routes'
import { color } from '../../Styles/Color'
import { MinThroughputConfig } from './MinThroughputConfig'
import { OptimalWorkerConfig } from './OptimalWorkerConfig'

export const ComputeConfig = ({
  watch, setValue, errors
}) => {
  const isInference = useLocation().pathname.includes(PAGE_ROUTES.inferences)

  const [deployType, setDeployType] = useState(CONFIGURE_OPTIONS[0])
  const [configType, setConfigType] = useState("0")
  const [regions, setRegions] = useState(["EU", "US", "ASIA", "CANADA"])

  useEffect(() => {
    setValue(
      "cloud_providers",
      [
        {
          name: "SCALEGENAI",
          regions: regions
        }
      ]
    )
  }, [regions, setValue])

  useEffect(() => {
    if (isInference) {
      if (configType === "1" || (watch('inf_type') === "embedding" && configType === "0")) {
        setValue('min_throughput_rate', null)
        setValue('initial_worker_config', {
          "min_workers": 0,
          "initial_workers_gpu": null,
          "initial_workers_gpu_num": null,
          "use_other_gpus": false,
          "instance_types": [],
          "use_on_prem": false,
          "use_cloudburst": false,
          "on_prem_node_ids": [],
          "expand_gpu_types": true,
          "max_workers": 5
        })
      } else {
        setValue('initial_worker_config', null)
        setValue('initial_worker_config.min_workers', 1)
        setValue('initial_worker_config.max_workers', 5)
      }
    }
  }, [configType, isInference, setValue, watch])

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Compute Configuration</Typography>
      <Stack
        spacing={3}
        borderRadius="12px"
        border={`1px solid ${color.borders}`}
        box-shadow="0px 1px 4px 0px #0000000A"
        p={3}
      >
        {/* <Stack spacing={1}>
          <RadioGroup row value={deployType} onChange={(e) => setDeployType(e.target.value)}>
            <FormControlLabel value={CONFIGURE_OPTIONS[0]} control={<Radio disableRipple />}
              label={<Typography variant="h3">{CONFIGURE_OPTIONS[0]}</Typography>} />
            <FormControlLabel value={CONFIGURE_OPTIONS[1]} control={<Radio disableRipple />}
              label={<Typography variant="h3">{CONFIGURE_OPTIONS[1]}</Typography>} />
          </RadioGroup>
          <Divider />
        </Stack> */}
        {
          deployType === CONFIGURE_OPTIONS[0] ?
            <>
              {/* <NavigationTabs
                tabs={
                  watch("inf_type") === "llm" ?
                    CLOUD_CONFIG_WORKER_OPTIONS :
                    EMBEDDINGS_CLOUD_CONFIG_WORKER_OPTIONS
                }
                panels={
                  watch("inf_type") === "llm" ?
                    [
                      <MinThroughputConfig
                        watch={watch}
                        setValue={setValue}
                      />,
                      <OptimalWorkerConfig
                        watch={watch}
                        setValue={setValue}
                      />
                    ] :
                    [
                      <OptimalWorkerConfig
                        watch={watch}
                        setValue={setValue}
                      />
                    ]
                }
                value={configType}
                setValue={setConfigType}
                fontSize="14px"
              /> */}
              <OptimalWorkerConfig
                watch={watch}
                setValue={setValue}
              />
              {/* <Stack spacing={1}>
                <Typography variant="h3" >
                  Cloud Provider(s)
                </Typography>
                <SelectDropdown
                  value={watch("cloud_providers").map(cp => cp.name)}
                  handleChange={(e) =>
                    setValue(
                      "cloud_providers",
                      [
                        {
                          name: "SCALEGENAI",
                          regions: regions
                        }
                      ]
                    )
                  }
                  options={["SCALEGENAI"]}
                  multiple={true}
                  placeholder="Choose cloud provider(s)"
                />
              </Stack> */}
              <Stack spacing={1}>
                <Typography variant="h3" >
                  Cloud Region(s)
                </Typography>
                <SelectDropdown
                  value={regions}
                  handleChange={(e) => setRegions(e.target.value)}
                  options={["EU", "US", "ASIA", "CANADA"]}
                  multiple={true}
                  placeholder="Choose cloud region(s)"
                />
              </Stack>
              <Stack spacing={1}>
                <FormControlLabel
                  value={watch(
                    isInference ? "allow_spot_instances" : "use_spot"
                  )}
                  control={
                    <BoxSwitch
                      onChange={(e) => setValue(
                        isInference ? "allow_spot_instances" : "use_spot",
                        e.target.checked
                      )}
                    />
                  }
                  label={<Typography variant="h3">Spot-Instances</Typography>}
                />
                <Typography variant='subtitle1' color={color.secondaryText}>
                  When enabled, cheaper spot-instances will be preferred over on-demand instances.<br />
                  Note: Might be affected by availability
                  <Typography
                    variant='subtitle1'
                    color={color.primary}
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => window.open("https://docs.scalegen.ai/inf-guide", "_blank")}
                  >
                    Learn more
                  </Typography>
                </Typography>
              </Stack>
            </> :
            <>
              <Stack spacing={1}>
                <Typography variant="h3" >
                  On-Prem Node
                </Typography>
                <SelectDropdown
                  options={["node1", "node2"]}
                  // multiple={true}
                  placeholder="Choose on-prem node"
                />
              </Stack>
              <Stack direction="row" gap={2}>
                <Stack spacing={1} width="49%">
                  <Typography variant="h3">GPU Type</Typography>
                  <InputField placeholder="Node gpu type" disabled />
                </Stack>
                <Stack spacing={1} width="49%">
                  <Typography variant="h3">No. of GPUs per Worker</Typography>
                  <InputField placeholder="Node no. of gpu" disabled />
                </Stack>
              </Stack>
              <FormControlLabel
                // value={watch("controller_cloud_config.use_ssl")}
                control={
                  <BoxSwitch
                  // onChange={(e) => setValue("controller_cloud_config.use_ssl", e.target.checked)}
                  />
                }
                label={<Typography variant="h3">Use CloudBurst</Typography>}
              />
              <OptimalWorkerConfig />
              <Stack spacing={1}>
                <Typography variant="h3" >
                  Cloud Provider(s)
                </Typography>
                <SelectDropdown
                  value={[]}
                  options={["cp1", "cp2"]}
                  multiple={true}
                  placeholder="Choose cloud provider(s)"
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="h3" >
                  Cloud Region(s)
                </Typography>
                <SelectDropdown
                  value={[]}
                  options={["cp1", "cp2"]}
                  multiple={true}
                  placeholder="Choose cloud region(s)"
                />
              </Stack>
              <Stack spacing={1}>
                <FormControlLabel
                  // value={watch("controller_cloud_config.use_ssl")}
                  control={
                    <BoxSwitch
                    // onChange={(e) => setValue("controller_cloud_config.use_ssl", e.target.checked)}
                    />
                  }
                  label={<Typography variant="h3">Spot-Instances</Typography>}
                />
                <Typography variant='subtitle1' color={color.secondaryText}>
                  When enabled, cheaper spot-instances will be preferred over on-demand instances.<br />
                  Note: Might affect availability
                  <Typography
                    variant='subtitle1'
                    color={color.primary}
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => window.open("https://docs.scalegen.ai/inf-guide", "_blank")}
                  >
                    Learn more
                  </Typography>
                </Typography>
              </Stack>
            </>
        }

      </Stack>
    </Stack>
  )
}
