import { ReactComponent as AgenticLogo } from "../Assets/Images/logo_scalegenai/small_logo_agentic.svg"

export const AGENTIC_BASE_MODELS = Object.freeze([
  {
    inf_type: 'llm',
    model: "TheAgentic-Coder",
    provider: 'TheAgenticAI',
    icon: <AgenticLogo
      height="80%"
      width="30%"
      style={{
        filter: "blur(5px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "TheAgentic-GP-Mini",
    provider: 'TheAgenticAI',
    icon: <AgenticLogo
      height="80%"
      width="30%"
      style={{
        filter: "blur(5px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "TheAgentic-GP-Medium",
    provider: 'TheAgenticAI',
    icon: <AgenticLogo
      height="80%"
      width="30%"
      style={{
        filter: "blur(5px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "TheAgentic-GP-Large",
    provider: 'TheAgenticAI',
    icon: <AgenticLogo
      height="80%"
      width="30%"
      style={{
        filter: "blur(5px)",
        transform: "translate(25%)"
      }}
    />,
  }
])