import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const trialApi = createApi({
  reducerPath: 'trialApi',
  tagTypes: ['Trials'],
  ...platformAuthConfig(),
  endpoints: builder => ({
    getTrials: builder.query({
      query: (jobId) => `/job/${jobId}/trials`,
      providesTags: ['Trials'],
    }),
    deleteTrial: builder.mutation({
      query: ({ jobId, trialId }) => ({
        url: `/job/${jobId}/trial/${trialId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Trials']
    })
  })
})

export const { useGetTrialsQuery, useDeleteTrialMutation } = trialApi

export default trialApi
