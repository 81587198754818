/* eslint-disable no-unused-vars */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDockerImageLink, setDockerIsPrivate, setDockerPassword, setDockerUserName, setInstallationPath, setRegistryURL } from '../../../DataStore/newJobSlice';
import { color } from '../../../Styles/Color';
import BoxSwitch from '../../UiComponents/BoxSwitch';
import InputField from '../../UiComponents/InputField';

const DockerImageInput = ({ register, watch }) => {

  const { imageLink, installationPath, dockerIsPrivate, registryURL, dockerUserName, dockerPassword }
    = useSelector((store) => store.newJobData)

  const dispatch = useDispatch()

  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Typography fontWeight="600" fontSize="15px">
        Docker Image
      </Typography>
      <InputField
        width="70%"
        placeholder="Enter Value"
        // state={imageLink}
        // setState={(e) => dispatch(setDockerImageLink(e.target.value))}
        register={register} field="customImage.image" required={true} watch={watch}
      />
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography fontWeight="600" fontSize="15px">
          Python Path
        </Typography>
        <Tooltip title="Information">
          <InfoOutlinedIcon sx={{
            color: '#ABABAB',
            fontSize: '20px'
          }} />
        </Tooltip>
      </Stack>
      <InputField
        width="70%"
        placeholder="Enter Value"
        // state={installationPath}
        // setState={(e) => dispatch(setInstallationPath(e.target.value))}
        register={register} field="customImage.pythonPath" required={true} watch={watch}
      />
      <FormControlLabel
        sx={{
          p: 2
        }}
        control={
          <BoxSwitch onChange={(event) => dispatch(setDockerIsPrivate(event.target.checked))} />}
        label={
          <Typography fontSize="17px">Is Docker Private ?</Typography>
        }
      />
      {
        dockerIsPrivate &&
        <Stack gap={2}>
          <Typography fontWeight="600" fontSize="15px">
            Registry URL
          </Typography>
          <InputField
            width="70%"
            placeholder="Enter Value"
            // state={registryURL}
            // setState={(e) => dispatch(setRegistryURL(e.target.value))}
            register={register} field="customImage.credentials.registry" watch={watch}
          />
          <Typography fontWeight="600" fontSize="15px">
            User Name
          </Typography>
          <InputField
            width="70%"
            placeholder="Enter Value"
            // state={dockerUserName}
            // setState={(e) => dispatch(setDockerUserName(e.target.value))}
            register={register} field="customImage.credentials.username" watch={watch}
          />
          <Typography fontWeight="600" fontSize="15px">
            Password
          </Typography>
          <InputField
            width="70%"
            placeholder="Enter Value"
            // state={dockerPassword}
            // setState={(e) => dispatch(setDockerPassword(e.target.value))}
            register={register} field="customImage.credentials.password" watch={watch}
          />
        </Stack>
      }
    </Stack >
  )
}

export default DockerImageInput