import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { color } from '../../../Styles/Color';
import { FTGPUConfig } from './FTGPUConfig';

export const CustomGPUConfig = ({
  setSpotInstance, gpuOptions, setGpuNodes, gpuNodes,
  // gputype, setGputype, 
  allowOtherGPU, setAllowOtherGPU, cloudProvider, setCloudProvider, region, setRegion, cloudProviderOptions, gpuTypeOptions, regionOptions, cloudBurst, setCloudBurst, gpuNodeType, setGPUNodeType, instanceType,
  setInstancetype, watch, setValue,
  // numberOfGPUs, setNumberOfGPUs, 
  instanceTypeOptions, gpuPerNode, setGPUPerNode, selectedConfig, setSelectedConfig, isFetchingGPUs, isFetchingInstances
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Stack
      p={1}
      border={`1px solid ${color.borders}`}
      borderRadius="8px"
      bgcolor={!isOpen && color.primary
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      >
        <Stack
          fontWeight="600"
          color={isOpen ? color.primary : "#FFFFFF"}
          fontSize="15px"
          direction="row"
          alignItems="center"
          gap={1}
        >
          <Stack height="20px" width="5px" borderRadius="8px" bgcolor={color.primary}></Stack>
          Custom GPU Configuration (optional)
        </Stack>
        {
          !isOpen ? <KeyboardArrowDownIcon sx={{ color: color.white }} />
            : <KeyboardArrowUpIcon sx={{ color: color.primary }} />
        }
      </Stack>
      {
        isOpen &&
        <Stack py={2}>
          <FTGPUConfig setSpotInstance={setSpotInstance}
            gpuOptions={gpuOptions}
            gpuNodes={gpuNodes}
            setGpuNodes={setGpuNodes}
            // gputype={gputype} setGputype={setGputype} 
            gpuTypeOptions={gpuTypeOptions}
            allowOtherGPU={allowOtherGPU} setAllowOtherGPU={setAllowOtherGPU}
            cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
            region={region} setRegion={setRegion} cloudProviderOptions={cloudProviderOptions}
            regionOptions={regionOptions} setCloudBurst={setCloudBurst} cloudBurst={cloudBurst}
            gpuNodeType={gpuNodeType} setGPUNodeType={setGPUNodeType} instanceType={instanceType}
            setInstancetype={setInstancetype}
            // numberOfGPUs={numberOfGPUs} setNumberOfGPUs={setNumberOfGPUs} 
            instanceTypeOptions={instanceTypeOptions} gpuPerNode={gpuPerNode} setGPUPerNode={setGPUPerNode}
            selectedConfig={selectedConfig} setSelectedConfig={setSelectedConfig}
            isFetchingGPUs={isFetchingGPUs} isFetchingInstances={isFetchingInstances}
            watch={watch} setValue={setValue}
          />
        </Stack>
      }
    </Stack >
  )
}
