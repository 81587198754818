import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GPU_CONFIG_TABS } from '../../../Configs/ConfigureNewJobConstants'
import { useGetGpuTypesMutation, useGetInstanceTypesMutation } from '../../../Services/cloudProviderApi'
import NavigationTabs from "../../UiComponents/NavigationTabs"
import { GPUInput } from './GPUInput'
import { InstanceTypeInput } from './InstanceTypeInput'

export const GPUConfig = ({ register, setValue, watch }) => {
  const [active, setActive] = useState('0')

  const { cloudProviders, cloudRegions } = useSelector(store => store.newJobData)

  const [getGPUTypes, { data: GPUOptions, isLoading: isFetchingGPUs,
    isSuccess: isGPUsFetched }] = useGetGpuTypesMutation()
  const [getInstanceTypes, { data: InstanceOptions, isLoading: isFetchingInstances,
    isSuccess: isInstancesFetched }] = useGetInstanceTypesMutation()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (cloudProviders.length > 0 && cloudRegions.length > 0) {
        getGPUTypes({
          cloudProviders: cloudProviders.map(cp => cp.name),
          cloudRegions: cloudRegions
        })
        getInstanceTypes({
          cloudProviders: cloudProviders.map(cp => cp.name),
          cloudRegions: cloudRegions
        })
      }
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [cloudProviders, cloudRegions, getGPUTypes, getInstanceTypes])


  useEffect(() => {
    if (isInstancesFetched && isGPUsFetched) {
      if (active === '0') {
        setValue("gpuTypes", [GPUOptions[0]])
        setValue("instanceTypes", null)
      } else {
        setValue("gpuTypes", null)
        setValue("instanceTypes", [InstanceOptions[0]])
      }
    }
  }, [GPUOptions, InstanceOptions, active, isGPUsFetched, isInstancesFetched, setValue])

  const GPU_CONFIG_PANELS = Object.freeze([
    <GPUInput GPUOptions={GPUOptions} register={register} watch={watch} setValue={setValue}
      isFetchingGPUs={isFetchingGPUs} />,
    <InstanceTypeInput InstanceOptions={InstanceOptions} watch={watch} setValue={setValue}
      isFetchingInstances={isFetchingInstances} />,
  ])

  return (
    <Stack>
      <NavigationTabs tabs={GPU_CONFIG_TABS}
        panels={GPU_CONFIG_PANELS} value={active} setValue={setActive} />
    </Stack>
  )
}
