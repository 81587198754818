import { TextField } from '@mui/material';
import React from 'react';
import { color } from '../../Styles/Color';

const InputField = (
  { state, setState, placeholder, width, disabled, multiLine, type, error }
) => {

  const handleWheel = (event) => {
    event.target.blur(); // Prevent scrolling when using the mouse wheel
  };

  return (
    <TextField
      onWheel={handleWheel}
      type={type ? type : 'text'}
      multiline={multiLine}
      rows={multiLine ? 4 : 1}
      disabled={disabled}
      value={state}
      onChange={setState}
      sx={{
        width: `${width ? width : "100%"}`,
        "& .MuiInputBase-input.Mui-disabled": {
          bgcolor: color.secondaryBackground,
          WebkitTextFillColor: "#000000",
        },
      }}
      placeholder={placeholder || "Enter value"}
      size='small'
      InputProps={{
        sx: {
          fontSize: "14px",
          borderRadius: "8px",
          backgroundColor: "#ffffff"
        }
      }}
      error={error ? true : false}
      helperText={error?.message}
    />
  )
}

export default InputField