import { Stack, Typography } from '@mui/material'
import React from 'react'
import { CLOUD_PROVIDERS_OPTIONS } from '../../Configs/CloudProviderConstants'
import { color } from '../../Styles/Color'

const ProviderOptions = ({ isActive, setIsActive }) => {

  const options = [...Object.values(CLOUD_PROVIDERS_OPTIONS)]

  return (
    <Stack spacing={4} py={2}>
      <Stack spacing={1}>
        <Typography variant='h3' >
          Choose Cloud Provider
        </Typography>
        <Typography variant='subtitle1' color={color.secondaryText}>
          Connect your existing cloud provider account
        </Typography>
      </Stack>
      <Stack direction="row" gap={3} flexWrap='wrap' width="45vw">
        {
          options.map(({ name, icon }) => (
            <Stack key={name} spacing={2} alignItems="center" onClick={() => setIsActive(name)}>
              <Stack
                width="80px"
                height="80px"
                justifyContent="center"
                alignItems="center"
                border="1px solid"
                borderColor={isActive === name ? color.primary : color.borders}
                borderRadius="6px"
                bgcolor={isActive === name && color.lightBlue}
                sx={{
                  cursor: "pointer",
                }}
              >
                {icon("50px")}
              </Stack>
              <Typography
                variant='h3'
                color={isActive === name && color.primary}
              >
                {name}
              </Typography>
            </Stack>
          ))
        }
      </Stack>
    </Stack>
  )
}

export default ProviderOptions