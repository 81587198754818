import { Box, Checkbox, Chip, FormControlLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { GPU_CONFIG_TABS } from '../../../Configs/ConfigureNewJobConstants'
import { color } from '../../../Styles/Color'
import InputField from '../../UiComponents/InputField'
import NavigationTabs from '../../UiComponents/NavigationTabs'

export const FTCloudConfig = ({
  gpuOptions,
  // gputype, setGputype, 
  allowOtherGPU, setAllowOtherGPU, cloudProvider, setCloudProvider, region, setRegion, cloudProviderOptions, regionOptions, instanceType, setInstanceType, instanceTypeOptions,
  // numberOfGPUs, setNumberOfGPUs,
  isFetchingGPUs, isFetchingInstances, watch, setValue
}) => {

  const [cloudConfigActiveTab, setCloudConfigActiveTab] = useState("0")

  useEffect(() => {
    setRegion(prev => prev.filter(region => cloudProvider.includes(region.split(":")[0])))
  }, [cloudProvider, setRegion])

  const CLOUD_CONFIG_PANELS = [
    <Stack spacing={2}>
      <Typography fontWeight="600" fontSize="15px">
        Preferred GPU Type
      </Typography>
      <Select
        displayEmpty
        size='small'
        sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
        value={watch("gpu_type")}
        onChange={(e) => setValue("gpu_type", e.target.value)}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          {isFetchingGPUs ? "...Loading" : "Choose GPU"}
        </MenuItem>
        {
          (gpuOptions?.filter(gpu => gpu.length > 0) || []).map((option, idx) => (
            <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
              {option}
            </MenuItem>
          ))
        }
      </Select>
      <Stack gap={1} >
        <Typography fontSize="15px">Number of GPUs Per Worker</Typography>
        <InputField
          placeholder="Enter number of GPUs"
          state={watch("gpu_count")}
          setState={(e) => setValue("gpu_count", e.target.value)}
          width="70%"
        />
      </Stack>
      <FormControlLabel sx={{ py: 1 }}
        control={<Checkbox
          checked={allowOtherGPU}
          onChange={(e) => setAllowOtherGPU(e.target.checked)}
        />}
        label={<Stack direction="row" alignItems="center">
          <Typography fontSize="17px" mx={1}>Allow other GPU types</Typography>
        </Stack >}
      />
    </Stack>,
    <Stack spacing={2}>
      <Typography fontWeight="600" fontSize="15px">
        Instance Type
      </Typography>
      <Select
        displayEmpty
        size='small'
        sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
        value={instanceType}
        onChange={(e) => setInstanceType(e.target.value)}

      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          {isFetchingInstances ? "...Loading" : "Choose Instance Type"}
        </MenuItem>
        {
          (instanceTypeOptions || []).map((option, idx) => (
            <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
              {option}
            </MenuItem>
          ))
        }
      </Select>
    </Stack>
  ]

  return (
    <Stack spacing={2}>
      <NavigationTabs tabs={GPU_CONFIG_TABS} panels={CLOUD_CONFIG_PANELS} value={cloudConfigActiveTab}
        setValue={setCloudConfigActiveTab} />
      <Stack gap={1.5} p={2}>
        <Typography fontWeight="600" fontSize="15px">
          Cloud Provider
        </Typography>
        <Select
          size='small'
          multiple
          value={cloudProvider}
          onChange={(e) => setCloudProvider(e.target.value)}
          input={<OutlinedInput />}
          renderValue={(selected, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography fontSize="15px">Choose Cloud Providers</Typography>
                  : selected.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))}
            </Box>
          )}
          sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
        >
          {
            (cloudProviderOptions
              ?.filter(d => ["AWS", "AZURE", "GCP", "SCALEGENAI"].includes(d.cloud_provider))
              || []).map((cloud, idx) => (
                <MenuItem
                  key={idx}
                  value={cloud.cloud_provider}
                  sx={{ fontSize: "15px" }}
                >
                  {cloud.cloud_provider}
                </MenuItem>
              ))}
        </Select>
        <Typography fontWeight="600" fontSize="15px">
          Cloud Region
        </Typography>
        <Select
          size='small'
          multiple
          displayEmpty
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          input={<OutlinedInput />}
          renderValue={(selected, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography fontSize="15px">Choose the region where you want to train</Typography>
                  : selected.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))}
            </Box>
          )}
          sx={{ bgcolor: color.white, borderRadius: "8px" }}
        >
          {
            (regionOptions.filter(region => cloudProvider.includes(region.split(":")[0])) || [])
              .map((region, idx) => (
                <MenuItem
                  key={idx}
                  value={region}
                  sx={{ fontSize: "15px" }}
                >
                  {region}
                </MenuItem>
              ))
          }
        </Select>
      </Stack>
    </Stack >
  )
}
