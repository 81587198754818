import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    user: {},
    platformBearer: undefined,
    sgUser: {}
};


export const userSlice = createSlice({
    name: 'userState',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setPlatformBearer: (state, action) => {
            state.platformBearer = action.payload
        },
        setSgUser: (state, action) => {
            state.sgUser = action.payload
        }
    }
});

export const {
    setUser,
    getUser,
    setPlatformBearer,
    getPlatformBearer,
    setSgUser,
    getSgUser
} = userSlice.actions;

export default userSlice;
