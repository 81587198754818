import { Divider, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import InputField from '../../Components/UiComponents/InputField'
import { RequiredHeader } from '../../Components/UiComponents/RequiredHeader'
import { SelectDropdown } from '../../Components/UiComponents/SelectDropdown'
import { FINE_TUNE_DATASET_OPTIONS } from '../../Configs/ConfigureNewJobConstants'
import { useGetVirtualMountsQuery } from '../../Services/virtualMountsApi'
import { color } from '../../Styles/Color'

export const DataConfig = ({
  watch, setValue, errors
}) => {

  const [selected, setSelected] = useState(FINE_TUNE_DATASET_OPTIONS[0])

  const user_dataset = watch('user_dataset')

  useEffect(() => {
    if (user_dataset) setSelected(FINE_TUNE_DATASET_OPTIONS[1])
  }, [user_dataset])

  const { data, isLoading } = useGetVirtualMountsQuery();

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Data Configuration</Typography>
      <Stack
        spacing={3}
        borderRadius="12px"
        border={`1px solid ${color.borders}`}
        box-shadow="0px 1px 4px 0px #0000000A"
        p={3}
      >
        <Stack spacing={1}>
          <RadioGroup
            row
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value)
              setValue("data_path", "")
              setValue("autotrain_params.data_path", "")
              if (e.target.value === FINE_TUNE_DATASET_OPTIONS[0]) {
                setValue("user_dataset", "")
              }
            }}
          >
            <FormControlLabel value={FINE_TUNE_DATASET_OPTIONS[0]} control={<Radio disableRipple />}
              label={<Typography variant="h3">{FINE_TUNE_DATASET_OPTIONS[0]}</Typography>} />
            <FormControlLabel value={FINE_TUNE_DATASET_OPTIONS[1]} control={<Radio disableRipple />}
              label={<Typography variant="h3">{FINE_TUNE_DATASET_OPTIONS[1]}</Typography>} />
          </RadioGroup>
          <Divider />
        </Stack>
        {
          selected === FINE_TUNE_DATASET_OPTIONS[0] ?
            <Stack spacing={1}>
              <RequiredHeader>
                <Typography variant="h3">Dataset Name</Typography>
              </RequiredHeader>
              <InputField
                state={watch('data_path')}
                setState={(e) => {
                  setValue('data_path', e.target.value)
                  setValue('autotrain_params.data_path', e.target.value)
                }}
                placeholder="Enter HuggingFace dataset name"
              />
              {
                errors?.data_path &&
                <Typography variant='subtitle1' color={color.error}>This field is required.</Typography>
              }
            </Stack>
            :
            <>
              <Stack spacing={1}>
                <SelectDropdown
                  value={watch('data_path')}
                  handleChange={(e) => {
                    setValue('data_path', e.target.value)
                    setValue('autotrain_params.data_path', e.target.value)
                  }}
                  options={data?.map(d => d.name)}
                  placeholder={isLoading ? "Fetching virtual mounts" : "Choose a virtual mount"}
                />
                {
                  errors?.data_path &&
                  <Typography variant='subtitle1' color={color.error}>This field is required.</Typography>
                }
              </Stack>
              <Stack spacing={1}>
                <Typography variant="h3">File Name</Typography>
                <InputField
                  state={watch('user_dataset')}
                  setState={(e) => setValue('user_dataset', e.target.value)}
                  placeholder="Enter file name"
                />
              </Stack>
            </>
        }

        <Stack direction="row" gap={2}>
          <Stack spacing={1} width="49%">
            <Typography variant="h3">Train Subset</Typography>
            <InputField
              state={watch('autotrain_params.train_subset')}
              setState={(e) => setValue('autotrain_params.train_subset', e.target.value)}
              placeholder="Enter value"
            />
          </Stack>
          <Stack spacing={1} width="49%">
            <Typography variant="h3">Text Column</Typography>
            <InputField
              state={watch('autotrain_params.text_column')}
              setState={(e) => setValue('autotrain_params.text_column', e.target.value)}
              placeholder="Enter value"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
