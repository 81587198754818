import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Chip, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { preLoadData } from '../../DataStore/newJobSlice';
import { useDeleteJobMutation } from '../../Services/jobsApi';
import { useGetEventLogsQuery } from '../../Services/logsApi';
import { color } from '../../Styles/Color';
import { getStatus } from '../TrainingViewComponents/JobsList';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';


export const JobStatus = ({ job }) => {

  const nav = useNavigate()
  const dispatch = useDispatch()

  const { data, isLoading } = useGetEventLogsQuery(job?.id)
  const [deleteJob, { isLoading: isDeleting, isSuccess }] = useDeleteJobMutation()

  if (isSuccess) {
    nav(PAGE_ROUTES.training)
  }

  return (
    <Stack spacing={2}>
      <Stack p={1} spacing={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`}>
        <Stack width="15%">
          {getStatus(job.status.toLowerCase())}
        </Stack>
        <Stack px={3} spacing={2}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Job ID</Typography>
            <Typography color={color.primary} fontSize="15px">
              {job.id}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">GPUs Requested</Typography>
            <Typography color={color.primary} fontSize="15px">
              {job.spec.config.gpuTypes?.join(', ') || ''}
            </Typography>
          </Stack>
          {
            job.spec.type !== "FINETUNING" && <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">Script Running</Typography>
              <Stack width="80%" bgcolor={color.white} border={`1px solid ${color.borders}`} borderRadius="8px" p={1}>
                <Typography fontSize="12px">
                  {job.spec.config.entrypoint || ''}
                </Typography>
              </Stack>
            </Stack>
          }
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Cost ($)</Typography>
            <Chip
              sx={{
                bgcolor: color.lightBlue,
                color: color.primary,
                borderRadius: "20px"
              }}
              label={
                <Stack direction="row" alignItems="center" gap={1} fontSize="12px">
                  {Math.round(job.cost * 100) / 100}
                </Stack>
              }
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="end" gap={1}>
          {
            !(job.status === "STOPPING" || job.status === "STOPPED") &&
            <CustomButton width="15%" onClick={() => deleteJob(job.id)} color="#FF6B6A">
              Stop Job
            </CustomButton>
          }
          <CustomButton width="25%"
            onClick={() => {
              dispatch(
                preLoadData(
                  {
                    config: job.spec.config,
                    name: job.name
                  }
                )
              )
              nav(
                job.spec.type !== "FINETUNING" ?
                  PAGE_ROUTES.relaunchJob :
                  PAGE_ROUTES.relaunchFinetune,
                { state: { id: job.id } }
              )
            }}
          >
            Relaunch Job <ArrowForwardIosIcon fontSize='24px' sx={{ marginLeft: 1 }} />
          </CustomButton>
        </Stack>
      </Stack >
      <Stack spacing={2}
        height={job.spec.type !== "FINETUNING" ? "38vh" : "45vh"}
        overflow="auto"
        sx={{
          '&::webkit-scrollbar': {
            display: 'none'
          }
        }}
        px={2}
      >
        <Typography fontSize="16px">Job Status</Typography>
        {
          isLoading || isDeleting ?
            <Stack height="30vh">
              <Loader />
            </Stack>
            :
            [...data].reverse().map((status, idx) => (
              <Stack spacing={1} key={idx}>
                <Stack direction="row" gap={1} justifyContent="space-between" >
                  <Stack direction="row" gap={2} width="60%">
                    {
                      (status.metadata === null ||
                        (status.metadata['error'] === undefined ||
                          !status.metadata['error'])
                      )
                        ?
                        <CheckCircleIcon sx={{ fontSize: "24px", color: "#2C974A" }} />
                        :
                        <ErrorIcon sx={{ fontSize: "24px", color: "#FF6B6A" }} />
                    }
                    <Typography fontSize="13px">{status.message}</Typography>
                  </Stack>
                  <Typography fontSize="13px" color="#ABABAB">{status.timestamp.split(".")[0]}</Typography>
                </Stack>
                {idx !== (data.length - 1) && < Divider />}
              </Stack>
            ))
        }
      </Stack>
    </Stack >
  )
}
