import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  "use_spot": false,
  "wandb_key": "",
  "model": "",
  "data_path": "",
  "job_name": "",
  "user_dataset": "",
  "hf_token": "",
  "artifacts_storage": "",
  "cloud_providers": [],
  "autotrain_params": {
    "use_peft": true,
    "quantization": "int4",
    "mixed_precision": "fp16",
    "disable_gradient_checkpointing": true,
    "use_flash_attention_2": true,
    "lora_r": 0,
    "lora_alpha": 0,
    "lora_dropout": 0,
    "lr": 0.00003,
    "batch_size": 1,
    "epochs": 1,
    "train_subset": "",
    "text_column": "",
    "gradient_accumulation": 0
  }
}

const newFineTuneSlice = createSlice({
  name: 'newInference',
  initialState,
  reducers: {
    clearStore: (store, action) => {
      Object.assign(store, initialState)
    },
    setUseSpotInstances: (store, action) => {
      store.use_spot = action.payload
    },
    setApiKey: (store, action) => {
      store.wandb_key = action.payload
    },
    setModel: (store, action) => {
      store.model = action.payload
    },
    setDataPath: (store, action) => {
      store.data_path = action.payload
    },
    setName: (store, action) => {
      store.job_name = action.payload
    },
    setUserDataset: (store, action) => {
      store.user_dataset = action.payload
    },
    setHFToken: (store, action) => {
      store.hf_token = action.payload
    },
    setArtifactStorage: (store, action) => {
      store.artifacts_storage = action.payload
    },
    setCloudProviders: (store, action) => {
      store.cloud_providers = action.payload
    },
    setQuantization: (store, action) => {
      store.autotrain_params.quantization = action.payload
    },
    setLORAr: (store, action) => {
      store.autotrain_params.lora_r = action.payload
    },
    setLORAalpha: (store, action) => {
      store.autotrain_params.lora_alpha = action.payload
    },
    setLORAdropout: (store, action) => {
      store.autotrain_params.lora_dropout = action.payload
    },
    setLearningRate: (store, action) => {
      store.autotrain_params.lr = action.payload
    },
    setEpochs: (store, action) => {
      store.autotrain_params.epochs = action.payload
    },

  }
})

export const {
  clearStore,
  setApiKey,
  setArtifactStorage,
  setCloudProviders,
  setDataPath,
  setEpochs,
  setHFToken,
  setLORAalpha,
  setLORAdropout,
  setLORAr,
  setLearningRate,
  setModel,
  setName,
  setQuantization,
  setUseSpotInstances,
  setUserDataset,
} = newFineTuneSlice.actions;

export default newFineTuneSlice;