import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyIcon from '@mui/icons-material/Key';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Tooltip, Typography } from '@mui/material';
import { forwardRef, useState } from 'react';
import { color } from '../../Styles/Color';
import InputField from '../UiComponents/InputField';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CreateKeyModal = ({ handleClose, isOpen, data }) => {

  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(data.client_secret)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              padding={1}
              borderRadius="8px"
              bgcolor={color.primary}
              color={color.white}
            >
              <KeyIcon fontSize='20px' />
            </Stack>
            <Typography variant='h2' color={color.primary}>Access Keys</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "20vh",
          width: "50vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // }
        }}
      >
        {
          data &&
          <Stack spacing={3} py={2}>
            <Typography variant='body2' color={color.error}>
              Note : This is one time pop-up , copy and keep it safe.
            </Typography>
            <Stack spacing={2}>
              <Typography variant='h3'>Access Key ID</Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <InputField state={data.client_id} disabled={true} width="90%" />
                <Tooltip title={isCopied ? "Copied" : "Copy"}>
                  <ContentCopyIcon
                    sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                    onClick={() => handleCopyClick(
                      data.client_secret
                    )}
                  />
                </Tooltip>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Typography variant='h3'>Access Key Secret</Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <InputField state={data.client_secret} disabled={true} width="90%" />
                <Tooltip title={isCopied ? "Copied" : "Copy"}>
                  <ContentCopyIcon
                    sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                    onClick={() => handleCopyClick(
                      data.client_secret
                    )}
                  />
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        }
      </DialogContent>
    </Dialog>
  )
}
