import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const logsApi = createApi({
  reducerPath: 'logsApi',
  ...platformAuthConfig(),
  tagTypes: ['EventLogs', 'BuildLogs'],
  endpoints: builder => ({
    getEventLogs: builder.query({
      query: (id) => ({
        url: `/events/${id}`
      }),
      providesTags: ['EventLogs']
    }),
    getBuildLogs: builder.query({
      query: (id) => ({
        url: `/build_logs/${id}`
      }),
      providesTags: ['BuildLogs']
    })
  })
})


export const {
  useGetEventLogsQuery,
  useGetBuildLogsQuery
} = logsApi

export default logsApi
