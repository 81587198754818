import { Stack, Typography } from '@mui/material'
import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import Filters from '../Components/TrainingViewComponents/Filters'
import JobsList from '../Components/TrainingViewComponents/JobsList'
import Loader from '../Components/UiComponents/Loader'
import { TRAINING_TYPE_OPTIONS } from '../Configs/TrainingFilterConstants'
import { useGetJobsQuery } from '../Services/jobsApi'



const TrainingView = () => {
  const [typeFilter, setTypeFilter] = useState(TRAINING_TYPE_OPTIONS[0])
  const [jobType, setJobType] = useState("All Jobs");
  const [jobs, setJobs] = useState([])
  const [searchedJob, setSearchedJob] = useState("")
  const [searchedData, setSearchedData] = useState(jobs)

  const { data: fetchedData, isLoading } = useGetJobsQuery(null, { pollingInterval: 10000 });

  useEffect(() => {
    let filteredJobs = fetchedData

    if (typeFilter === TRAINING_TYPE_OPTIONS[1]) {
      filteredJobs = fetchedData?.filter(job => job.spec.type === "SIMPLE_JOB")
    } else if (typeFilter === TRAINING_TYPE_OPTIONS[0]) {
      filteredJobs = fetchedData?.filter(job => job.spec.type === "FINETUNING")
    }

    if (jobType === "All Jobs") {
      setJobs(filteredJobs)
    } else {
      setJobs(filteredJobs.filter(j => j.status === jobType.toUpperCase()))
    }

  }, [fetchedData, jobType, setJobs, typeFilter])

  useEffect(() => {
    const options = {
      keys: ['name'],
      includeScore: true,
    }

    const fuse = new Fuse(jobs, options)

    if (searchedJob.length > 0) {
      setSearchedData(fuse.search(searchedJob).filter(j => j.score < 0.2).map(j => j.item))
    } else {
      setSearchedData(jobs)
    }
  }, [searchedJob, jobs])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Stack gap={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" pb={2}>Training</Typography>
      <Stack gap={2}>
        <Filters typeFilter={typeFilter} setTypeFilter={setTypeFilter}
          jobType={jobType} setJobType={setJobType} searchedJob={searchedJob}
          setSearchedJob={setSearchedJob} />
        <JobsList data={searchedData} />
      </Stack>
    </Stack>
  )
}

export default TrainingView