/* eslint-disable eqeqeq */
import ClearIcon from '@mui/icons-material/Clear';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, OutlinedInput, Slide, Slider, Stack, Typography
} from '@mui/material';
import { forwardRef, useEffect, useLayoutEffect, useState } from 'react';
import { color } from '../../Styles/Color';
// import Loader from '../UiComponents/Loader';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import CustomButton from '../../Components/UiComponents/CustomButton';
import InputField from '../../Components/UiComponents/InputField';
import NavigationTabs from '../../Components/UiComponents/NavigationTabs';
import SecondaryButton from '../../Components/UiComponents/SecondaryButton';
import { AUTOSCALE_STRATEGY, EMBEDDING_AUTOSCALE_STRATEGY } from '../../Configs/JobConstants';

const marks = [
  {
    value: 0,
    label: <NightsStayIcon fontSize='small' sx={{ color: color.primary }} />,
  },
  {
    value: 10,
    label: '|',
  },
  {
    value: 20,
    label: '|',
  },
  {
    value: 30,
    label: '|',
  },
  {
    value: 40,
    label: '|',
  },
  {
    value: 50,
    label: '5',
  },
  {
    value: 60,
    label: '|',
  },
  {
    value: 70,
    label: '|',
  },
  {
    value: 80,
    label: '|',
  },
  {
    value: 90,
    label: '|',
  },
  {
    value: 100,
    label: '10',
  },
];


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditAutoScaleModal = ({ isOpen, setIsOpen, watch, setValue }) => {

  let min_replica = watch('initial_worker_config.min_workers')
  let max_replica = watch('initial_worker_config.max_workers')
  let upper_allowed_threshold = watch('autoscaling_config.upper_allowed_threshold')
  let scale_window = watch('autoscaling_config.scale_up_time_window_sec')
  let scale_down_time = watch('autoscaling_config.scale_down_time_window_sec')
  let scale_to_zero_time = watch('autoscaling_config.scale_to_zero_timeout_sec')
  const engine = watch('engine')

  const autoscaling_strategy = watch("autoscaling_config.autoscaling_strategy")

  const [formValues, setFormValues] = useState({})
  const [activeTab, setActiveTab] = useState('0')

  useEffect(() => {
    if (autoscaling_strategy === "ttft_latency_sec") {
      setActiveTab('1')
    }
  }, [autoscaling_strategy])

  useLayoutEffect(() => {
    const initialFormState = {
      min_replica: min_replica,
      max_replica: max_replica,
      upper_allowed_threshold: upper_allowed_threshold,
      scale_window: scale_window,
      scale_down_time: scale_down_time,
      scale_to_zero_time: scale_to_zero_time,
    }

    setFormValues(initialFormState)
  }, [max_replica, min_replica, scale_down_time, scale_to_zero_time, scale_window, upper_allowed_threshold])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleUpdate = () => {
    setValue("initial_worker_config.min_workers", formValues.min_replica)
    setValue("initial_worker_config.max_workers", formValues.max_replica)
    setValue("autoscaling_config.upper_allowed_threshold", formValues.upper_allowed_threshold)
    setValue("autoscaling_config.scale_up_time_window_sec", formValues.scale_window)
    setValue("autoscaling_config.scale_down_time_window_sec", formValues.scale_down_time)
    if (formValues.min_replica == 0) {
      setValue("autoscaling_config.scale_to_zero", true)
      setValue("autoscaling_config.scale_to_zero_timeout_sec", formValues.scale_to_zero_time)
    } else {
      setValue("autoscaling_config.scale_to_zero", false)
      setValue("autoscaling_config.scale_to_zero_timeout_sec", null)
    }
    if (activeTab === "0") {
      setValue("autoscaling_config.autoscaling_strategy", "rps_per_worker")
    } else {
      setValue("autoscaling_config.autoscaling_strategy", "ttft_latency_sec")
    }
    handleClose()
  }

  const handleSlider = (event, newValue) => {

    const step = 10
    if (!Array.isArray(newValue)) {
      return;
    }
    setFormValues(prev => ({ ...prev, min_replica: (newValue[0] / step), max_replica: (newValue[1] / step) }));
  };

  const AUTOSCALING_STRATEGY_PANELS = [
    <Stack key={0} spacing={1}>
      <Typography variant='h3'>Concurrent Requests Per Worker</Typography>
      <Typography variant='subtitle1' color={color.secondaryText}>
        Specify the minimum concurrent requests per second for each worker.
        <Typography
          variant='subtitle1'
          color={color.primary}
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
        >
          Learn more
        </Typography>
      </Typography>
      <OutlinedInput
        value={formValues.upper_allowed_threshold}
        size='small'
        border={`1px solid ${color.borders}`}
        onChange={e => setFormValues(prev => ({ ...prev, upper_allowed_threshold: e.target.value }))}
        sx={{
          borderRadius: "8px",
          width: "50%",
        }}
        inputProps={
          {
            sx: {
              fontSize: "14px"
            }
          }
        }
        placeholder='Enter latency'
        endAdornment={
          <InputAdornment position="end">
            <Typography variant='body2' color={color.primary}>Requests / sec</Typography>
          </InputAdornment>
        }
      />
    </Stack>,
    <Stack key={1} spacing={1}>
      <Typography variant='h3'>Time To First Token Latency (TTFT)</Typography>
      <Typography variant='subtitle1' color={color.secondaryText}>
        Enter the time it takes to receive the first token during inference.
        <Typography
          variant='subtitle1'
          color={color.primary}
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
        >
          Learn more
        </Typography>
      </Typography>
      <OutlinedInput
        value={formValues.upper_allowed_threshold}
        size='small'
        border={`1px solid ${color.borders}`}
        onChange={e => setFormValues(prev => ({ ...prev, upper_allowed_threshold: e.target.value }))}
        sx={{
          borderRadius: "8px",
          width: "50%",
        }}
        inputProps={
          {
            sx: {
              fontSize: "14px"
            }
          }
        }
        placeholder='Enter requests'
        endAdornment={
          <InputAdornment position="end">
            <Typography variant='body2' color={color.primary}>Sec</Typography>
          </InputAdornment>
        }
      />
    </Stack>,
  ]

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant='h2' color={color.primary}>Configure Autoscaling</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "35vh",
          width: "45vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // }
        }}
      >
        <Stack spacing={3} py={1}>
          <Stack spacing={1}>
            <Typography variant='h3'>Replicas</Typography>
            <Typography variant='subtitle1' color={color.secondaryText}>
              Set the min and max number of replicas, autoscaling will be triggered based on the traffic.
              <Typography
                variant='subtitle1'
                color={color.primary}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
              >
                Learn more.
              </Typography>
            </Typography>
            <Stack direction="row" gap={6} alignItems="center">
              <Stack spacing={1} width="18%">
                <InputField
                  state={formValues.min_replica}
                  setState={e => setFormValues(prev => ({ ...prev, min_replica: e.target.value }))}
                  type="number"
                />
                <Typography variant='subtitle1'>Min</Typography>
              </Stack>
              <Slider
                step={10}
                value={[formValues.min_replica * 10, formValues.max_replica * 10]}
                onChange={handleSlider}
                marks={marks}
                sx={{
                  color: color.primary,
                  height: 3,
                  padding: '15px 0',
                  "& .MuiSlider-markLabel": {
                    color: color.secondaryText,
                    fontSize: '12px'
                  },
                  "& .MuiSlider-mark": {
                    display: "none"
                  },
                  '& .MuiSlider-thumb': {
                    height: 15,
                    width: 15,
                    backgroundColor: color.lightBlue,
                    border: `1px solid ${color.primary}`,
                    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
                    '&:focus, &:hover, &.Mui-active': {
                      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
                    },
                    '&:before': {
                      boxShadow:
                        '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
                    },
                  },
                  '& .MuiSlider-track': {
                    border: 'none',
                    height: 3,
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.5,
                    // boxShadow: 'inset 0px 0px 4px -2px #000',
                    backgroundColor: '#E4E4E4',
                  },

                }}
              />
              <Stack spacing={1} width="18%">
                <InputField
                  state={formValues.max_replica}
                  setState={e => setFormValues(prev => ({ ...prev, max_replica: e.target.value }))}
                  type="number"
                />
                <Typography variant='subtitle1'>Max</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2} >
            <NavigationTabs
              tabs={engine === "vllm" ? AUTOSCALE_STRATEGY : EMBEDDING_AUTOSCALE_STRATEGY}
              panels={AUTOSCALING_STRATEGY_PANELS}
              value={activeTab}
              setValue={setActiveTab}
            />
            <Stack direction="row" gap={2}>
              <Stack spacing={1}>
                <Typography variant='h3'>Scale-Up Window</Typography>
                <Typography variant='subtitle1' color={color.secondaryText}>
                  Set the time window to scale-up based on inference demand.
                </Typography>
                <OutlinedInput
                  value={formValues.scale_window}
                  size='small'
                  border={`1px solid ${color.borders}`}
                  onChange={e => setFormValues(prev => ({ ...prev, scale_window: e.target.value }))}
                  sx={{
                    borderRadius: "8px",
                  }}
                  inputProps={
                    {
                      sx: {
                        fontSize: "14px"
                      }
                    }
                  }
                  placeholder='Enter value'
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography variant='body2' color={color.primary}>Sec</Typography>
                    </InputAdornment>
                  }
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant='h3'>Scale-Down Window</Typography>
                <Typography variant='subtitle1' color={color.secondaryText}>
                  Set the time window to scale down based on reduced demand.
                </Typography>
                <OutlinedInput
                  value={formValues.scale_down_time}
                  size='small'
                  border={`1px solid ${color.borders}`}
                  onChange={e => setFormValues(prev => ({ ...prev, scale_down_time: e.target.value }))}
                  sx={{
                    borderRadius: "8px",
                  }}
                  inputProps={
                    {
                      sx: {
                        fontSize: "14px"
                      }
                    }
                  }
                  placeholder='Enter value'
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography variant='body2' color={color.primary}>Sec</Typography>
                    </InputAdornment>
                  }
                />
              </Stack>
            </Stack>
            {
              formValues.min_replica == 0 &&
              <Stack spacing={1}>
                <Typography variant='h3'>Scale-to-Zero Window</Typography>
                <Typography variant='subtitle1' color={color.secondaryText}>
                  Set the time window to scale down to zero when there's no demand.
                  <Typography
                    variant='subtitle1'
                    color={color.primary}
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
                  >
                    Learn More
                  </Typography>
                </Typography>
                <OutlinedInput
                  value={formValues.scale_to_zero_time}
                  size='small'
                  border={`1px solid ${color.borders}`}
                  onChange={e => setFormValues(prev => ({ ...prev, scale_to_zero_time: e.target.value }))}
                  sx={{
                    borderRadius: "8px",
                    width: "50%"
                  }}
                  inputProps={
                    {
                      sx: {
                        fontSize: "14px"
                      }
                    }
                  }
                  placeholder='Enter value'
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography variant='body2' color={color.primary}>Sec</Typography>
                    </InputAdornment>
                  }
                />
              </Stack>
            }
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <CustomButton
              onClick={handleUpdate}
              width="10%"
            >
              Update
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default EditAutoScaleModal