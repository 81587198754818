import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import CloudIcon from '@mui/icons-material/Cloud';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice';
import { closeCloudProviderModal } from '../../DataStore/modalSlice';
import { useAddCloudProviderMutation } from '../../Services/cloudProviderApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';
import SecondaryButton from '../UiComponents/SecondaryButton';
import ProviderCredtials from './ProviderCredtials';
import ProviderOptions from './ProviderOptions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ChooseProviderModal = () => {
  const [isActive, setIsActive] = useState("");
  const initialFormState = Object.freeze({
    "cloud_provider": "",
    "bucket_name": "some-random-bucket",
    "primary": false,
    "regions": [],
    "creds": {}
  })

  const [formValues, setFormValues] = useState(initialFormState)

  const [submit, { isLoading, isSuccess }] = useAddCloudProviderMutation();
  const { isCloudProviderModalOpen } = useSelector((store) => store.modal)

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleClose = () => {
    setIsActive("")
    setFormValues(initialFormState)
    dispatch(closeCloudProviderModal())
  }

  const handleNext = () => {
    setFormValues({ ...formValues, cloud_provider: isActive })
  }

  const handleCancel = () => {
    setFormValues(initialFormState)
    setIsActive("")
  }

  const handleAdd = () => {
    let isValid = true
    let errorField = ""
    Object.keys(formValues.creds).forEach(cred => {
      if (formValues.creds[cred]?.length === 0) {
        isValid = false
        errorField = cred
      }
    })
    if (isValid) {
      submit(formValues);
    } else {
      dispatch(setIsError(true))
      dispatch(setErrorMessage(errorField.replaceAll("_", " ") + " is Required"));
    }
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isCloudProviderModalOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              padding={1}
              borderRadius="8px"
              bgcolor={color.primary}
              color={color.white}
            >
              <CloudIcon fontSize='small' />
            </Stack>
            <Typography variant='h2' color={color.primary}>Add Cloud Provider</Typography>
          </Stack>
          {
            !formValues.cloud_provider ?
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
              </IconButton>
              :
              <Stack
                direction="row"
                gap={1}
                color={color.primary}
                alignItems="center"
                onClick={handleCancel}
                sx={{
                  "&:hover": {
                    cursor: "pointer"
                  }
                }}
              >
                <ArrowBackIcon fontSize='small' />
                <Typography>Go Back </Typography>
              </Stack>
          }
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "35vh",
          width: "45vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // }
        }}
      >
        {
          isLoading ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            <>
              {
                !formValues.cloud_provider ?
                  <ProviderOptions setIsActive={setIsActive} isActive={isActive} />
                  :
                  <ProviderCredtials
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
              }
            </>
        }
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          {
            !formValues.cloud_provider ?
              <CustomButton
                onClick={handleNext}
                width="10%"
                disabled={!isActive}
              >
                Next
              </CustomButton>
              :
              <Stack direction="row" gap={1}>
                <SecondaryButton onClick={handleClose}>
                  Cancel
                </SecondaryButton>
                <CustomButton
                  onClick={handleAdd}
                  width="10%"
                >
                  Add
                </CustomButton>
              </Stack>
          }

        </Stack>
      </DialogActions>
    </Dialog >
  )
}

export default ChooseProviderModal