import EditIcon from '@mui/icons-material/Edit'
import { Stack, TextField, Typography } from "@mui/material"
import { useEffect } from 'react'
import { useForm } from "react-hook-form"
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from "react-router"
import Loader from '../Components/UiComponents/Loader'
import { COMPLETIONS_MODEL_PROVIDERS, NEW_INFERENCE_OPTIONS } from '../Configs/JobConstants'
import { PAGE_ROUTES } from '../Configs/Routes'
import { setErrorMessage, setIsError } from '../DataStore/errorSlice'
import { toPascalCase } from '../NewComponents/Header'
import { CustomModelForm } from '../NewComponents/Inference/CustomModelForm'
import { InferenceTemplateForm } from '../NewComponents/Inference/InferenceTemplateForm'
import { useCreateInferenceMutation } from '../Services/inferenceApi'
import { useGetInferenceTemplatesQuery } from '../Services/templatesApi'
import { color } from '../Styles/Color'
import { getTrimmedData } from '../Utils/trimmer'

export const initialValue = {
  "name": "",
  "model": "",
  "base_model": null,
  "inf_type": "llm",
  "hf_token": "",
  "engine": "vllm",
  "custom_chat_template": "",
  "allow_spot_instances": true,
  "logs_store": "",
  "cloud_providers": [],
  "initial_worker_config": {
    "min_workers": 0,
    "initial_workers_gpu": null,
    "initial_workers_gpu_num": null,
    "use_other_gpus": false,
    "instance_types": [],
    "use_on_prem": false,
    "use_cloudburst": false,
    "on_prem_node_ids": [],
    "expand_gpu_types": true,
    "max_workers": 5
  },
  "autoscaling_config": {
    "autoscaling_strategy": "ttft_latency_sec",
    "enable_fast_autoscaling": false,
    "enable_speedup_shared": false,
    "lower_allowed_latency_sec": 0.2,
    "lower_allowed_threshold": 0.2,
    "scale_down_time_window_sec": 300,
    "scale_to_zero": false,
    "scale_to_zero_timeout_sec": 1800,
    "scale_up_time_window_sec": 300,
    "scaling_down_timeout_sec": 1200,
    "scaling_up_timeout_sec": 1200,
    "upper_allowed_latency_sec": 1,
    "upper_allowed_threshold": 1
  },
  "max_price_per_hour": 0,
  "min_throughput_rate": null,
  "controller_cloud_config": {
    "public_url": true,
    "use_ssl": true,
    "use_api_gateway": false,
    "vpc_id": null,
    "cloud_provider": "SCALEGENAI",
    "region": "",
    "api_gateway_data": null
  },
  // "controller_on_prem_config": {
  //   use_ssl: true,
  //   on_prem_node_id: ""
  // },
  "controller_on_prem_config": null,
  "llm_loras": [],
  "max_model_len": 0
}


export const LaunchInference = () => {

  const inf_type = useLocation().state?.type || "llm"
  const model_type = useLocation().state?.model
  const nav = useNavigate()
  const dispatch = useDispatch()

  const [submit, { isLoading: isSubmitting, isSuccess }] = useCreateInferenceMutation()

  const { data: models, isLoading: isFetchingModels, isSuccess: isModelsFetched } = useGetInferenceTemplatesQuery(
    null,
    {
      skip: model_type === COMPLETIONS_MODEL_PROVIDERS[2].name ||
        inf_type === NEW_INFERENCE_OPTIONS[1].type
    }
  )

  const handleBack = () => {
    nav(-1)
  }

  const { formState: { errors }, watch, setValue, setError, clearErrors, reset, handleSubmit } =
    useForm({
      defaultValues: {
        ...initialValue,
        "inf_type": inf_type,
        "engine": inf_type === "llm" ? "vllm" : "tei",
      }
    })

  useEffect(() => {
    if (isModelsFetched && models?.length > 0) {
      reset(models?.filter(m => m?.config?.model.includes(model_type))[0]?.config)
    }
  }, [isModelsFetched, model_type, models, reset])

  const handleValidation = () => {
    clearErrors()

    let isNameValid = watch('name').length > 0
    let isGpuValid = (watch('initial_worker_config') && watch('initial_worker_config.initial_workers_gpu')) || true
    let isGpuNumValid = (watch('initial_worker_config') && watch('initial_worker_config.initial_workers_gpu_num')) || true


    if (!isNameValid || !isGpuValid || !isGpuNumValid) {
      if (!isNameValid) {
        dispatch(setIsError(true))
        dispatch(setErrorMessage("Deploy Name is required"))
        setError(
          "name",
          { type: 'custom', message: 'Deploy name is required' }
        )
      }
      if (!isGpuNumValid) {
        setError(
          "initial_worker_config.initial_workers_gpu_num",
          { type: 'custom', message: 'This field is required' }
        )
      }
      if (!isGpuValid) {
        setError(
          "initial_worker_config.initial_workers_gpu",
          { type: 'custom', message: 'This field is required' }
        )
      }
    } else {
      const config = getTrimmedData(watch())
      handleSubmit(submit(config))
    }
  }

  if (isSubmitting || isFetchingModels) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  if (isSuccess) {
    nav(PAGE_ROUTES.inferences)
  }

  return (
    <Stack spacing={5}>
      <Stack direction="row" gap={2} alignItems={errors?.name ? "start" : "end"}>
        <Typography variant="h1">Deploy {model_type || toPascalCase(inf_type)}</Typography>
        <TextField
          size='small'
          variant="standard"
          placeholder='Enter deployment name'
          sx={{
            "& .MuiInput-root:before": {
              border: 0
            },
            "& .MuiInput-root:after": {
              border: 0
            },
            "& .MuiInput-input:hover": {
              border: 0
            }
          }}
          InputProps={{
            style: {
              fontSize: "14px",
              borderRadius: "8px",
              color: color.primary,
            },
          }}
          value={watch('name')}
          onChange={e => setValue('name', e.target.value)}
          // {...register("name", { required: { value: true, message: "This field is required" } })}
          error={errors?.name ? true : false}
          helperText={errors?.name?.message}
        />
        <EditIcon sx={{ color: color.primary, fontSize: "16px" }} />
      </Stack>
      {
        (
          model_type === COMPLETIONS_MODEL_PROVIDERS[2].name ||
          inf_type === NEW_INFERENCE_OPTIONS[1].type
        ) ?
          <CustomModelForm
            watch={watch}
            setValue={setValue}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            handleSubmit={handleValidation}
            reset={reset}
          /> :
          <InferenceTemplateForm
            isModelConfigured={false}
            handleBack={handleBack}
            backTitle="Change Model"
            setValue={setValue}
            watch={watch}
            modelList={
              models?.filter(m => m.config.model.includes(model_type))
            }
            handleSubmit={handleValidation}
            reset={reset}
          />
      }
    </Stack>
  )
}
