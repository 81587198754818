import EditIcon from '@mui/icons-material/Edit'
import { Stack, TextField, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'
import { InferenceTemplateForm } from '../NewComponents/Inference/InferenceTemplateForm'
import { color } from '../Styles/Color'

export const AgenticLaunch = () => {

  const model = useLocation().state.model

  const nav = useNavigate()

  const handleBack = () => {
    nav(-1)
  }

  return (
    <Stack spacing={5}>
      <Stack direction="row" gap={2} alignItems="end">
        <Typography variant="h1">Deploy </Typography>
        <TextField
          size='small'
          variant="standard"
          placeholder='Enter deployment name'
          sx={{
            "& .MuiInput-root:before": {
              border: 0
            },
            "& .MuiInput-root:after": {
              border: 0
            },
            "& .MuiInput-input:hover": {
              border: 0
            }
          }}
          InputProps={{
            style: {
              fontSize: "14px",
              borderRadius: "8px",
              color: color.primary,
            },
          }}
        // {...register("name", { required: { value: true, message: "This field is required" } })}
        // error={errors?.name ? true : false}
        // helperText={errors?.name?.message}
        />
        <EditIcon sx={{ color: color.primary, fontSize: "16px" }} />
      </Stack>
      <InferenceTemplateForm
        model={model}
        handleBack={handleBack}
        backTitle="Go back"
      />
    </Stack>
  )
}
