import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { VM_STORAGE_TYPE_OPTIONS } from '../../Configs/VirtualMountConstants';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';

function createData(name, path, id) {
  return { name, path, id };
}

const getIcons = (source) => {

  switch (source.split(":")[0]) {
    case "s3": return VM_STORAGE_TYPE_OPTIONS.find(storage => storage.name === "S3").icon("40px")
    case "az": return VM_STORAGE_TYPE_OPTIONS.find(storage => storage.name === "Azure Blob Storage").icon("40px")
    case "gs": return VM_STORAGE_TYPE_OPTIONS.find(storage => storage.name === "Google Cloud").icon("40px")
    case "sftp": return VM_STORAGE_TYPE_OPTIONS.find(storage => storage.name === "SFTP").icon("40px")
    default: return VM_STORAGE_TYPE_OPTIONS.find(storage => storage.name === "HTTP / HTTPS").icon("40px")
  }
}

export default function PathList({ data, handleDelete, openModal }) {

  const rows = (data || []).map((d) =>
    createData(d.name, d.path, d.id))

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  const [page, setPage] = useState(0)

  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "none",
        maxHeight: "65vh",
        border: "1px solid #E4E4E4",
      }}>
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="35%">Name</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="50%">Path</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow
                  hover
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <FolderOpenIcon fontSize='small' sx={{ color: color.primary }} />
                      {row.name}
                    </Stack>
                  </TableCell>
                  <TableCell >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Stack height="30px">
                        {getIcons(row.path)}
                      </Stack>
                      {row.path}
                    </Stack>
                  </TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(row.id)
                      }}
                    />
                  </TableCell>
                </TableRow>
              )) :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={1}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No Artifact Storage added. Please click on
                  </Typography>
                  <Typography variant='body2' color={color.primary}
                    onClick={openModal}
                    sx={{
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }}
                  >
                    Add Artifact Storage
                  </Typography>
                  <Typography variant='body2'>
                    to add artifact storages.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onPageChange={handleChangePage}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="Artifact Storage"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  );
}