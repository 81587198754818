/* eslint-disable no-unused-vars */
import { Divider, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import BoxSwitch from "../../Components/UiComponents/BoxSwitch"
import InputField from "../../Components/UiComponents/InputField"
import NavigationTabs from "../../Components/UiComponents/NavigationTabs"
import { RequiredHeader } from "../../Components/UiComponents/RequiredHeader"
import { SelectDropdown } from "../../Components/UiComponents/SelectDropdown"
import { CLOUD_CONFIG_WORKER_OPTIONS, EMBEDDINGS_CLOUD_CONFIG_WORKER_OPTIONS } from "../../Configs/JobConstants"
import { PAGE_ROUTES } from "../../Configs/Routes"
import { color } from "../../Styles/Color"
import { MinThroughputConfig } from "./MinThroughputConfig"
import { OptimalWorkerConfig } from "./OptimalWorkerConfig"

export const DeploymentConfig = ({
  watch, setValue, errors
}) => {

  const isInference = useLocation().pathname.includes(PAGE_ROUTES.inferences)

  const [deployType, setDeployType] = useState("Cloud")

  useEffect(() => {
    setValue("controller_cloud_config.cloud_provider", "SCALEGENAI")
    setValue("controller_cloud_config.region", "US")
  }, [setValue])

  const [configType, setConfigType] = useState(watch("min_throughput_rate") ? "1" : "0")
  const [regions, setRegions] = useState(["US", "EU", "ASIA", "CANADA"])

  useEffect(() => {
    setValue(
      "cloud_providers",
      [
        {
          name: "SCALEGENAI",
          regions: regions
        }
      ]
    )
  }, [regions, setValue])

  const max_workers = watch("initial_worker_config.max_workers")
  const min_workers = watch("initial_worker_config.min_workers")
  const gpu_type = watch("initial_worker_config.initial_workers_gpu")
  const gpu_count = watch("initial_worker_config.initial_workers_gpu_num")

  useEffect(() => {
    if (isInference) {
      if (configType === "0") {
        setValue('min_throughput_rate', null)
        setValue('initial_worker_config', {
          "min_workers": 0,
          "initial_workers_gpu": gpu_type || null,
          "initial_workers_gpu_num": gpu_count || null,
          "use_other_gpus": false,
          "instance_types": [],
          "use_on_prem": false,
          "use_cloudburst": false,
          "on_prem_node_ids": [],
          "expand_gpu_types": true,
          "max_workers": max_workers || 5
        })
      } else {
        setValue('initial_worker_config', null)
        // eslint-disable-next-line eqeqeq
        setValue('initial_worker_config.min_workers', min_workers != 0 ? min_workers : 1)
        setValue('initial_worker_config.max_workers', max_workers || 5)
      }
    }
  }, [configType, gpu_count, gpu_type, isInference, max_workers, min_workers, setValue, watch])

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Deployment Configuration</Typography>
      <Stack
        spacing={3}
        borderRadius="12px"
        border={`1px solid ${color.borders}`}
        box-shadow="0px 1px 4px 0px #0000000A"
        p={3}
      >
        {/* <Stack spacing={1}>
          <RadioGroup row value={deployType} onChange={(e) => setDeployType(e.target.value)}>
            <FormControlLabel value="Cloud" control={<Radio />} label="Cloud" />
            <FormControlLabel value="On-Prem" control={<Radio />} label="On-Prem" />
          </RadioGroup>
          <Divider />
        </Stack> */}
        {
          deployType === 'Cloud' ?
            <>
              <Stack spacing={1}>
                <RequiredHeader>
                  <Typography variant="h3" >
                    Deployment Region
                  </Typography>
                </RequiredHeader>
                <Typography variant="subtitle1" color={color.secondaryText} >
                  Select the cloud region where you want to deploy your model.
                </Typography>
                <SelectDropdown
                  value={watch("controller_cloud_config.region")}
                  handleChange={(e) => setValue("controller_cloud_config.region", e.target.value)}
                  options={["US", "EU", "Asia", "Canada"]}
                  placeholder="Choose a deployment region"
                />
              </Stack>
              {
                watch("controller_cloud_config.cloud_provider") === "AWS" &&
                <Stack spacing={1} >
                  <Typography variant="h3">VPC ID</Typography>
                  <InputField
                    state={watch("controller_cloud_config.vpc_id")}
                    setState={(e) => setValue("controller_cloud_config.vpc_id", e.target.value)}
                    placeholder="Enter VPC ID for the deployment region"
                  />
                </Stack>
              }
              {/* <Stack spacing={1}>
                <Typography variant="h3" >
                  Endpoint Security
                </Typography>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant='subtitle1' color={color.secondaryText}>
                    Configure inference endpoint security.
                  </Typography>
                  <Typography variant='subtitle1' color={color.primary}>
                    Learn more
                  </Typography>
                </Stack>
                <Stack direction="row" gap={2} p={1.5}>
                  {
                    (watch("controller_cloud_config.cloud_provider") === "AWS" ||
                      watch("controller_cloud_config.cloud_provider") === "SCALEGENAI") &&
                    <FormControlLabel
                      value={watch("controller_cloud_config.use_api_gateway")}
                      control={
                        <BoxSwitch
                          onChange={(e) => {
                            setValue("controller_cloud_config.use_api_gateway", e.target.checked)
                            if (e.target.checked) {
                              setValue("controller_cloud_config.public_url", true)
                              setValue("controller_cloud_config.use_ssl", true)
                            }
                          }
                          }
                        />
                      }
                      label={<Typography variant="h3">API Gateway</Typography>}
                    />
                  }
                  <FormControlLabel
                    value={watch("controller_cloud_config.public_url")}
                    control={
                      <BoxSwitch
                        onChange={(e) => setValue("controller_cloud_config.public_url", e.target.checked)}
                      />
                    }
                    disabled={watch("controller_cloud_config.use_api_gateway") === true}
                    label={<Typography variant="h3">Public URL</Typography>}
                  />
                  <FormControlLabel
                    value={watch("controller_cloud_config.use_ssl")}
                    control={
                      <BoxSwitch
                        onChange={(e) => setValue("controller_cloud_config.use_ssl", e.target.checked)}
                      />
                    }
                    disabled={watch("controller_cloud_config.use_api_gateway") === true}
                    label={<Typography variant="h3">Use SSL</Typography>}
                  />
                </Stack>
              </Stack> */}
            </> :
            <>
              <Stack spacing={1}>
                <Typography variant="h3" >
                  Deployment Node
                </Typography>
                <SelectDropdown options={["Node1", "Node2"]} placeholder="Choose a on-prem node" />
              </Stack>
              <FormControlLabel
                value={watch("controller_cloud_config.use_ssl")}
                control={
                  <BoxSwitch
                    onChange={(e) => setValue("controller_cloud_config.use_ssl", e.target.checked)}
                  />
                }
                label={<Typography variant="h3">Use SSL</Typography>}
              />
            </>
        }
        <NavigationTabs
          tabs={
            watch("inf_type") === "llm" ?
              CLOUD_CONFIG_WORKER_OPTIONS :
              EMBEDDINGS_CLOUD_CONFIG_WORKER_OPTIONS
          }
          panels={
            watch("inf_type") === "llm" ?
              [
                <OptimalWorkerConfig
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                />,
                <MinThroughputConfig
                  watch={watch}
                  setValue={setValue}
                />
              ] :
              [
                <OptimalWorkerConfig
                  watch={watch}
                  setValue={setValue}
                />
              ]
          }
          value={configType}
          setValue={setConfigType}
          fontSize="14px"
        />
      </Stack>
    </Stack>
  )
}
