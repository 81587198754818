import { Stack, Typography } from '@mui/material'
import React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { setMaxCPUWorkers } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'
import InputField from '../../UiComponents/InputField'

export const CPUConfig = ({ register, watch }) => {
  // const dispatch = useDispatch()
  // const { maxCPUWorkers } = useSelector(store => store.newJobData)


  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Typography fontWeight="600" fontSize="15px">
        Maximum CPU Workers
      </Typography>
      <InputField
        width="70%"
        placeholder="Enter Value"
        type='number'
        // state={maxCPUWorkers}
        // setState={(e) => dispatch(setMaxCPUWorkers(e.target.value))}
        register={register} field="maxCPUWorkers" required={true} watch={watch}
      />
    </Stack >
  )
}
