import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AddGPUMachineModal } from '../Components/GPUMachineViewComponents/AddGPUMachineModal';
import { GPUMachinesList } from '../Components/GPUMachineViewComponents/GPUMachinesList';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { useGetAvailableGPUsQuery, useGetGPUMachinesQuery } from '../Services/gpuMachinesApi';

export const GPUMachinesView = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)


  const { data: availableGPUList, isLoading } = useGetAvailableGPUsQuery()
  const { data: gpusList, isLoading: fetchingGPUMachines } =
    useGetGPUMachinesQuery(null, { pollingInterval: 10000 })

  if (isLoading || fetchingGPUMachines) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack>
      <Stack spacing={2}>
        <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" >GPU Machines</Typography>
        <Stack alignItems="end">
          <CustomButton width="30%" onClick={() => setIsModalOpen(true)}>
            <AddIcon fontSize='small' sx={{ mr: 1 }} />
            Add GPU Machines
          </CustomButton>
        </Stack>
        <GPUMachinesList data={gpusList || []} />
      </Stack>
      <AddGPUMachineModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} gpuList={availableGPUList || []} />
    </Stack >
  )
}