import { createApi } from '@reduxjs/toolkit/query/react';
import { platformAuthConfig } from './platformAuthConfig.js';

const fineTuneApi = createApi({
  reducerPath: 'fineTuneApi',
  ...platformAuthConfig(),
  tagTypes: ['FineTune'],
  endpoints: (builder) => ({
    createFineTune: builder.mutation({
      query: (config) => ({
        url: "/finetune/create",
        method: "POST",
        body: config
      }),
      invalidatesTags: ["FineTune"],
    }),
    getFinetune: builder.query({
      query: (id) => ({
        url: `/finetune/${id}`,
        method: "GET",
      }),
      providesTags: ['FineTune']
    }),
  }),
})

export const {
  useCreateFineTuneMutation,
  useGetFinetuneQuery
} = fineTuneApi;

export default fineTuneApi