import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Stack, Typography } from "@mui/material"
// import { useState } from 'react'
// import { useDispatch } from "react-redux"
import CustomButton from "../../Components/UiComponents/CustomButton"
import InputField from "../../Components/UiComponents/InputField"
import { SelectDropdown } from "../../Components/UiComponents/SelectDropdown"
// import { setErrorMessage, setIsError } from "../../DataStore/errorSlice"
import { useGetArtifactStoragePathsQuery } from '../../Services/artifactStorageApi'
import { color } from "../../Styles/Color"
// import { convertToCLI } from '../../Utils/convertToCLI'
import { AutoscalingConfig } from "./AutoscalingConfig"
// import { ComputeConfig } from "./ComputeConfig"
import { DeploymentConfig } from "./DeploymentConfig"

export const DetailsInferenceForm = ({
  watch, setValue, errors, handleBack, handleSubmit
}) => {

  // const dispatch = useDispatch()

  const { data: artifactStoragePaths, isLoading: isFetchingPaths } = useGetArtifactStoragePathsQuery()

  // const [isCopied, setIsCopied] = useState(false)

  // async function copyTextToClipboard(text) {
  //   if ('clipboard' in navigator) {
  //     return await navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand('copy', true, text);
  //   }
  // }


  // const handleCopy = async () => {
  //   let isNameValid = watch('name').length > 0

  //   if (!isNameValid) {
  //     dispatch(setIsError(true))
  //     dispatch(setErrorMessage("Deploy Name is required"))
  //   } else {
  //     copyTextToClipboard(getCommand())
  //       .then(() => {
  //         setIsCopied(true);
  //         setTimeout(() => {
  //           setIsCopied(false);
  //         }, 1500);
  //       })
  //   }
  // }

  // const getCommand = () => {

  //   const cloud_regions = watch('cloud_providers').map(cp => cp.regions.map(r => `${cp.name}:${r}`)).flat()

  //   const config = {
  //     name: watch('name'),
  //     model: watch('model'),
  //     base_model: watch('base_model'),
  //     inf_type: watch('inf_type'),
  //     engine: watch('engine'),
  //     hf_token: watch('hf_token'),
  //     logs_store: watch('logs_store'),
  //     max_price_per_hour: watch('max_price_per_hour'),
  //     cloud_regions: cloud_regions,
  //     use_spot: watch('allow_spot_instances'),
  //     autoScaling: watch('autoscaling_config'),
  //     min_throughput_rate: watch('min_throughput_rate'),
  //     min_workers: watch('initial_worker_config.min_workers'),
  //     use_same_gpus_when_scaling: !watch('initial_worker_config.use_other_gpus'),
  //     initial_workers_gpu_num: watch('initial_worker_config.initial_workers_gpu_num') || null,
  //     instance_types: watch('initial_worker_config.instance_types') || null,
  //     initial_workers_gpu_type: watch('initial_worker_config.initial_workers_gpu') || null,
  //     expand_gpu_types: watch('initial_worker_config.expand_gpu_types') || null,
  //     use_ssl: watch('controller_cloud_config.use_ssl') || watch('controller_on_prem_config.use_ssl'),
  //     use_public_url: watch('controller_cloud_config.public_url'),
  //     use_api_gateway: watch('controller_cloud_config.use_api_gateway'),
  //     controller_cloud_provider: watch('controller_cloud_config.cloud_provider'),
  //     controller_cloud_region: watch('controller_cloud_config.region'),
  //     vpc_id: watch('controller_cloud_config.vpc_id'),
  //     controller_on_prem_node_id: watch('controller_on_prem_config.on_prem_node_id'),
  //   }

  //   return `scalegen infer create \\ \n ${convertToCLI(config)}`
  // }

  return (
    <Stack direction="row" gap={2} width="100%" >
      <Stack
        spacing={1}
        width="65%"
      // width="55%"
      >
        <Stack
          height="62vh"
          overflow="auto"
          // sx={{
          //   '&::-webkit-scrollbar': {
          //     display: 'none',
          //   }
          // }}
          spacing={4}
          pb={1}
          pr={1}
        >
          <Stack spacing={2}>
            <Typography variant="h2">Model Configuration</Typography>
            <Stack
              spacing={3}
              borderRadius="12px"
              border={`1px solid ${color.borders}`}
              box-shadow="0px 1px 4px 0px #0000000A"
              p={3}
            >
              <Stack spacing={1}>
                <Typography variant="h3">Model Name</Typography>
                <Typography variant="subtitle1" color={color.secondaryText}>
                  The name of the model as stored in your HuggingFace model repository.
                </Typography>
                <InputField state={watch('model')} disabled={true} placeholder="Enter your model name" />
              </Stack>
              {
                watch('inf_type') !== 'llm' && watch('base_model')?.length > 0 &&
                <Stack spacing={1}>
                  <Typography variant="h3">Base Model</Typography>
                  <InputField
                    state={watch('base_model')}
                    setState={e => setValue('base_model', e.target.value)}
                    disabled={true}
                  />
                </Stack>
              }
              <Stack spacing={1}>
                <Typography variant="h3">HuggingFace Access Token</Typography>
                <InputField
                  state={watch('hf_token')}
                  setState={e => setValue('hf_token', e.target.value)}
                  placeholder="Enter your HuggingFace token"
                />
              </Stack>

            </Stack>
          </Stack>

          <DeploymentConfig
            watch={watch} setValue={setValue} errors={errors}
          />
          {/* <ComputeConfig
            watch={watch} setValue={setValue} errors={errors}
          /> */}
          <AutoscalingConfig
            watch={watch} setValue={setValue} errors={errors}
          />
          <Stack spacing={2}>
            <Typography variant="h2">Results folder</Typography>
            <Stack
              spacing={1}
              borderRadius="12px"
              border={`1px solid ${color.borders}`}
              box-shadow="0px 1px 4px 0px #0000000A"
              p={3}
            >
              <Typography variant="subtitle1" color={color.secondaryText}>
                Choose an artifacts storage to store logs and inference results.
              </Typography>
              <SelectDropdown
                value={watch('logs_store')}
                handleChange={(e) => setValue('logs_store', e.target.value)}
                options={artifactStoragePaths?.map(path => path.name)}
                placeholder={isFetchingPaths ? "Fetching result stores" : "Choose a result store"}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            onClick={handleBack}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            <ArrowBackIcon fontSize='small' />
            <Typography>
              Go back to model configuration
            </Typography>
          </Stack>
          <CustomButton onClick={handleSubmit}>Deploy Model</CustomButton>
        </Stack>
      </Stack>
      {/* <Stack width="43%" mt={-10} p={1}>
        <Stack
          border={`1px solid ${color.borders}`}
          borderRadius="6px"
          boxShadow="0px 1px 4px 0px #0000000A"
          p={2}
          pl={4}
          spacing={3}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">CLI command</Typography>
            <Tooltip title={isCopied ? "Copied" : "Copy"}>
              <ContentCopyIcon
                sx={{ fontSize: "24px", color: color.primary, "&:hover": { cursor: "pointer" } }}
                onClick={handleCopy}
              />
            </Tooltip>
          </Stack>
          <Stack
            bgcolor="#000"
            height="63vh"
            borderRadius="6px"
            px={3}
            py={1}
            color={color.white}
            overflow="auto"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              }
            }}
          >
            <Typography variant='subtitle1'>
              <pre>
                {getCommand()}
              </pre>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            onClick={() => window.open("https://docs.scalegen.ai/inf-cli", "_blank")}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            <Typography>
              Launch via CLI
            </Typography>
            <ArrowForwardIcon fontSize='small' />
          </Stack>
        </Stack>
      </Stack> */}
    </Stack>
  )
}
