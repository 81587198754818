import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCloudProviderModalOpen: false,
  isVMModalOpen: false,
  isStorageModalOpen: false,
}

const modalSlice = createSlice({
  name: 'globalModal',
  initialState,
  reducers: {
    openCloudProviderModal: (store) => {
      store.isCloudProviderModalOpen = true;
    },
    closeCloudProviderModal: (store) => {
      store.isCloudProviderModalOpen = false;
    },
    openVMModal: (store) => {
      store.isVMModalOpen = true;
    },
    closeVMModal: (store) => {
      store.isVMModalOpen = false;
    },
    openStorageModal: (store) => {
      store.isStorageModalOpen = true
    },
    closeStorageModal: (store) => {
      store.isStorageModalOpen = false
    },
  }
}
)

export const {
  openCloudProviderModal,
  closeCloudProviderModal,
  openStorageModal,
  closeStorageModal,
  openVMModal,
  closeVMModal,
} = modalSlice.actions

export default modalSlice