import { FormControlLabel, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import BoxSwitch from '../../Components/UiComponents/BoxSwitch'
import InputField from '../../Components/UiComponents/InputField'
import { RequiredHeader } from '../../Components/UiComponents/RequiredHeader'
import { SelectDropdown } from '../../Components/UiComponents/SelectDropdown'
import { useGetArtifactStoragePathsQuery } from '../../Services/artifactStorageApi'
import { color } from '../../Styles/Color'

export const StorageConfig = ({
  watch, setValue, errors
}) => {

  const { data: artifactStoragePaths, isLoading: isFetchingPaths, isSuccess: isStoragesFetched } = useGetArtifactStoragePathsQuery()

  useEffect(() => {
    if (isStoragesFetched && artifactStoragePaths?.length > 0) {
      setValue && setValue("artifacts_storage", artifactStoragePaths[0]?.name)
    }
  }, [isStoragesFetched, artifactStoragePaths, setValue])

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Storage Configuration</Typography>
      <Stack
        spacing={3}
        borderRadius="12px"
        border={`1px solid ${color.borders}`}
        box-shadow="0px 1px 4px 0px #0000000A"
        p={3}
      >
        <Stack spacing={2}>
          <Stack spacing={1}>
            <FormControlLabel
              value={watch("autotrain_params.push_to_hub")}
              control={
                <BoxSwitch
                  onChange={(e) => {
                    setValue("autotrain_params.push_to_hub", e.target.checked)
                    if (!e.target.checked) {
                      setValue('autotrain_params.repo_id', null)
                      setValue('autotrain_params.username', null)
                    }
                  }}
                />
              }
              label={<Typography variant="h3">Push to HuggingFace Model Hub</Typography>}
            />
            <Typography variant='subtitle1' color={color.secondaryText}>
              When enabled the fine-tuned model will get stored in your HF model repository. Enables version control.
            </Typography>
          </Stack>
          {
            watch("autotrain_params.push_to_hub") &&
            <Stack direction="row" gap={2}>
              <Stack spacing={1} width="49%">
                <RequiredHeader>
                  <Typography variant="h3">Repo ID</Typography>
                </RequiredHeader>
                <InputField
                  state={watch('autotrain_params.repo_id')}
                  setState={e => setValue('autotrain_params.repo_id', e.target.value)}
                  placeholder="Enter repo ID"
                  required="true"
                  error={errors?.push_to_hub?.repo_id}
                />
              </Stack>
              <Stack spacing={1} width="49%">
                <Typography variant="h3">Username</Typography>
                <InputField
                  state={watch('autotrain_params.username')}
                  setState={e => setValue('autotrain_params.username', e.target.value)}
                  placeholder="Enter username"
                />
              </Stack>
            </Stack>
          }
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h3">Checkpoint Store</Typography>
          <Typography variant='subtitle1' color={color.secondaryText}>
            Choose an artifacts storage to store model checkpoints during fine-tuning process.
          </Typography>
          <SelectDropdown
            value={watch('artifacts_storage')}
            handleChange={e => setValue('artifacts_storage', e.target.value)}
            options={artifactStoragePaths?.map(path => path.name)}
            placeholder={isFetchingPaths ? "Fetching checkpoint stores" : "Choose a checkpoint store"}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
