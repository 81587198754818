import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isError: false,
  errorMessage: "Something went wrong"
}

const errorSlice = createSlice({
  name: 'errorState',
  initialState,
  reducers: {
    setIsError: (store, action) => {
      store.isError = action.payload;
    },
    setErrorMessage: (store, action) => {
      store.errorMessage = action.payload;
    }
  }
}
)

export const {
  setIsError,
  setErrorMessage,
} = errorSlice.actions

export default errorSlice