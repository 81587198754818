import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import LLMProviderList from '../Components/LLMProviderComponents/LLMProviderList';
import { LLMProviderModal } from '../Components/LLMProviderComponents/LLMProviderModal';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { useDeleteLLMProviderMutation, useGetLLMProvidersQuery } from '../Services/llmgatewayApi';

export const LLMProvidersView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [handleDelete, { isLoading: isDeleting }] = useDeleteLLMProviderMutation()
  const { data, isLoading } = useGetLLMProvidersQuery()

  if (isDeleting || isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }
  return (
    <Stack>
      <Stack spacing={2}>
        <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" >LLM Providers</Typography>
        <Stack alignItems="end">
          <CustomButton width="30%" onClick={() => setIsModalOpen(true)}>
            <AddIcon fontSize='small' sx={{ mr: 1 }} />
            Add LLM Provider
          </CustomButton>
        </Stack>
        <LLMProviderList data={data} handleDelete={handleDelete} />
      </Stack>
      <LLMProviderModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </Stack >
  )
}
