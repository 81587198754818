import { Stack } from "@mui/material"
import Loader from "../Components/UiComponents/Loader"

const LoadingView = () => {
  return (
    <Stack height="100vh" width="100vw">
      <Loader />
    </Stack>
  )
}

export default LoadingView