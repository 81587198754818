import { createApi } from '@reduxjs/toolkit/query/react';
import { platformAuthConfig } from './platformAuthConfig.js';

const gpuMachinesApi = createApi({
  reducerPath: 'gpuMachinesApi',
  ...platformAuthConfig(),
  tagTypes: ['GPUMachines'],
  endpoints: (builder) => ({
    getAvailableGPUs: builder.query({
      query: () => '/gpu_machines/list_available',
      providesTags: ['GPUMachines']
    }),
    getGPUMachines: builder.query({
      query: () => '/gpu_machines/list',
      providesTags: ['GPUMachines']
    }),
    getGPUMachine: builder.query({
      query: (id) => `/gpu_machines/get?gpu_machine_id=${id}`,
      providesTags: ['GPUMachines']
    }),
    launchGPUMachine: builder.mutation({
      query: (config) => ({
        url: '/gpu_machines/create',
        method: 'POST',
        body: config
      }),
      invalidatesTags: ['GPUMachines']
    }),
    deleteGPUMachine: builder.mutation({
      query: (id) => ({
        url: `/gpu_machines/delete?gpu_machine_id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['GPUMachines']
    })
  }),
})

export const {
  useGetAvailableGPUsQuery,
  useGetGPUMachineQuery,
  useGetGPUMachinesQuery,
  useDeleteGPUMachineMutation,
  useLaunchGPUMachineMutation
} = gpuMachinesApi;

export default gpuMachinesApi;



