import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { NOTIFICATION_OPTIONS } from '../../Configs/SettingsConstants';
import { color } from '../../Styles/Color';
import BoxSwitch from '../UiComponents/BoxSwitch';


const NotificationSection = () => {
  const [isEditing, setIsEditing] = useState(false)
  return (
    <Stack
      borderRadius="12px"
      border={`1px solid ${color.borders}`}
      p={2}
      pl={4}
      spacing={4}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant='h2'>Email Notifications</Typography>
        <Stack
          bgcolor={isEditing ? color.primary : color.lightBlue}
          color={isEditing ? color.white : color.primary}
          borderRadius="6px"
          p={1}
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={() => setIsEditing(!isEditing)}
        >
          {
            isEditing ?
              <DoneIcon fontSize='small' /> :
              <EditIcon fontSize='small' />
          }
        </Stack>
      </Stack>

      {
        Object.entries(NOTIFICATION_OPTIONS).map((entry) => (

          <Stack spacing={1}>
            {
              entry[1].map((option) => (
                <Stack spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant='h3'>{option.text}</Typography>
                    <BoxSwitch disabled={!isEditing} />
                  </Stack>
                  <Typography variant='subtitle1' color={color.secondaryText}>{option.subText}</Typography>
                </Stack>
              ))
            }
          </Stack>
        )
        )
      }
    </Stack >
  )
}

export default NotificationSection