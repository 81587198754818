import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Chip, Stack } from '@mui/material';
import { color } from '../Styles/Color';


export const GPU_MACHINE_TYPE_OPTIONS = Object.freeze([
  "RTX_A5000",
  "A100_80GB",
  "A100",
  "V100",
  "H100_80GB",
  "RTX_A4000",
  "A40",
  "L40",
  "RTX_6000_ADA",
  "RTX_A6000",
  "RTX_3090",
  "RTX_A4500",
  "A10G",
  "RTX_4090",
  "T4",
  "L40S",
])

export const GPU_MACHINE_COUNT_OPTIONS = Object.freeze([1, 2, 4, 8])

export const getStatus = (status) => {
  switch (status) {
    case "ACTIVE": {
      return <Chip
        sx={{
          bgcolor: '#BEFFD0',
          color: "#2C974A",
          borderRadius: "6px",
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <SettingsBackupRestoreIcon sx={{ fontSize: "15px" }} /> Active
          </Stack>
        }
      />
    }

    case "FAILED": {
      return <Chip
        sx={{
          bgcolor: '#FFD2D2',
          color: "#FF6B6A",
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <CancelOutlinedIcon sx={{ fontSize: "15px" }} /> Failed
          </Stack>
        }
      />
    }

    case "CREATING": {
      return <Chip
        sx={{
          bgcolor: '#D8ECFF',
          color: color.primary,
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <RestoreIcon sx={{ fontSize: "15px" }} /> Creating
          </Stack>
        }
      />
    }

    case "DELETED": {
      return <Chip
        sx={{
          bgcolor: '#E4E4E4',
          color: "#ABABAB",
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <AutoDeleteIcon sx={{ fontSize: "15px" }} /> Deleted
          </Stack>
        }
      />
    }

    case "DELETING": {
      return <Chip
        sx={{
          bgcolor: '#FFEEC3',
          color: "#FF8A00",
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <RadioButtonCheckedIcon sx={{ fontSize: "15px" }} /> Deleting
          </Stack>
        }
      />
    }

    default: return <></>
  }
}