import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Chip, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import CardBG from '../../Assets/Images/logo_scalegenai/bg_image.svg';
import { ReactComponent as AgenticLogo } from '../../Assets/Images/logo_scalegenai/theagentic_logo_white.svg';
import CustomButton from '../../Components/UiComponents/CustomButton';
import { color } from '../../Styles/Color';

export const EnterApi = () => {
  const AGENTIC_FEATURES = [
    "Multi-Step Reasoning",
    "Long-Context",
    "Function-Calling"
  ]

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Stack width="80%" height="80%" spacing={5} px={1}>
        <Stack
          borderRadius="12px"
          sx={{
            background: `url(${CardBG})`,
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
          justifyContent="center"
          height="45%"
          direction="row"
          alignItems="center"
        >
          <Stack spacing={2} color={color.white} width="70%" >
            <AgenticLogo height="40px" width="200px" />
            <Typography variant='h1'>The Most Accurate LLM for AI Agents.</Typography>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {
                AGENTIC_FEATURES.map(feature =>
                  <Chip
                    sx={{
                      background: "rgba(255, 255, 255, 0.25)",
                      px: 1,
                    }}
                    label={
                      <Typography variant='h3' fontSize="12px" color={color.white}>
                        {feature}
                      </Typography>
                    }
                  />
                )
              }
            </Stack>
          </Stack>
          <Stack width="20%" >
            <Button
              sx={{
                bgcolor: color.white,
                borderRadius: "54px",
                color: "#000",
                textTransform: "none",
                gap: 2,
                alignItems: "center",
                "&:hover": {
                  bgcolor: color.white,
                },
                py: 1.5
              }}
              disableRipple
            >
              <Typography variant='h2'>Know more</Typography>
              <ArrowForwardIcon fontSize="small" />
            </Button>
          </Stack>
        </Stack>
        <Stack spacing={3}>
          <Stack direction="row" gap={2} alignItems="center">
            <ViewInArIcon sx={{ color: "#4660C8" }} />
            <Typography variant='h1'>Deploy TheAgentic AI Model</Typography>
          </Stack>
          <Stack
            p={3}
            border={`1px solid ${color.borders}`}
            spacing={2}
            borderRadius="12px"
          >
            <Typography variant='h2'>Enter the API key from your TheAgenticAI account</Typography>
            <Stack direction="row" gap={2}>
              <OutlinedInput
                size='small'
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ?
                        <VisibilityOff
                          sx={{
                            fontSize: "24px",
                            color: "#4660C8"
                          }}
                        /> :
                        <Visibility
                          sx={{
                            fontSize: "24px",
                            color: "#4660C8"
                          }}
                        />}
                    </IconButton>
                  </InputAdornment>
                }
                value={""}
                onChange={() => { }}
                sx={{
                  width: "80%",
                  color: color.primary,
                  borderRadius: "8px",
                }}
                disableUnderline
              />
              <CustomButton background="#4660C8">
                Submit
              </CustomButton>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography variant='body2' color={color.secondaryText}>
                Note : You can fetch api key from TheAgenticAI settings.
              </Typography>
              <Typography variant='body2' color={color.primary}>
                Learn More
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
