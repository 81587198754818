import ClearIcon from '@mui/icons-material/Clear';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { LAUNCH_JOB_OPTIONS } from '../../Configs/NewJobConstants';
import { clearStore } from '../../DataStore/newJobSlice';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';

export const NewJobModal = ({ isOpen, handleClose }) => {
  const navigate = useNavigate()

  const [selected, setSelected] = useState(LAUNCH_JOB_OPTIONS[0])

  const dispatch = useDispatch()

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="end" direction="row">
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{
        minHeight: "50vh",
        width: "50vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        <Stack gap={2}>
          <Stack justifyContent="center" alignItems="center" gap={1}>
            <Typography fontWeight="600">Launch New job</Typography>
            <Typography color="#ABABAB" fontSize="12px">
              Choose whether you want to train model from scratch or fine-tune the model
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} px={2} flexWrap="wrap">
            {
              LAUNCH_JOB_OPTIONS.map((option, idx) => (
                <Stack
                  key={idx}
                  onClick={() => {
                    setSelected(option)
                    if (option === LAUNCH_JOB_OPTIONS[1]) {
                      dispatch(clearStore())
                    }
                  }}
                  border="2px solid"
                  borderColor={selected.name === option.name ? color.primary : color.borders}
                  borderRadius="8px"
                  boxShadow={selected.name === option.name && "0px 0px 1px 2px #92CBFF"}
                  minWidth="45%"
                  p={2}
                  gap={4}
                  sx={{
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                >
                  <Stack width="30px" borderRadius="4px" p={1} bgcolor={color.lightBlue}>
                    {option.icon}
                  </Stack>
                  <Typography fontWeight={600} fontSize="20">{option.name}</Typography>
                </Stack>
              ))
            }
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={() => navigate(selected.rediect)}
          width="10%"
        >
          Next
        </CustomButton>
      </DialogActions>
    </Dialog >
  )
}
