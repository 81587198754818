import DoneIcon from '@mui/icons-material/Done';
import { Stack } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import YAML from 'yaml';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { STEPPER_STEPS } from '../../Configs/TrainingFilterConstants';
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice';
import { clearStore, setArtifactStorageName, setBranchCode, setCodeDirectory, setCudaVersion, setDockerImageLink, setDockerPassword, setDockerUserName, setEntryPointFileName, setFilter, setGPUTypes, setInstallationPath, setInstanceTypes, setMaxCPUWorkers, setNumberOfGPUs, setNumberOfNodes, setRegistryURL, setRepoCreds, setRepoHost, setRepoName, setRequirementsFileName, setVirtualMounts } from '../../DataStore/newJobSlice';
import { useCreateJobMutation } from '../../Services/jobsApi';
// import { useCreateTemplateMutation } from '../../Services/templatesApi';
import { CONFIGURATION_TYPE_OPTIONS } from '../../Configs/ConfigureNewJobConstants';
import { color } from '../../Styles/Color';
import Loader from '../UiComponents/Loader';
import ConfigureComponent from './ConfigureComponents/ConfigureComponent';
import DataComponent from './DataComponents/DataComponent';
import DeployComponent from './DeployComponent';
import NewJobFooter from './NewJobFooter';
import SourceCodeComponent from './SourceCodeComponent/SourceCodeComponent';

export default function StepperHeader() {
  const [activeStep, setActiveStep] = useState(0);
  const [activeConfigureTab, setActiveConfigureTab] = useState('0');
  const [activeTab, setActiveTab] = useState(CONFIGURATION_TYPE_OPTIONS[0])


  const navigate = useNavigate()

  const newJobData = useSelector(store => store.newJobData)

  const [submit, { isLoading: isSubmitting, isSuccess }] = useCreateJobMutation()
  // const [save, { isLoading: isSaving, isSuccess: isSaved }] = useCreateTemplateMutation()

  const { register, trigger, formState: { errors }, watch, setValue, setError, clearErrors } =
    useForm({
      defaultValues: {
        "name": newJobData.name,
        "cuda": newJobData.cudaVersion,
        "pythonVersion": newJobData.pythonVersion,
        "customImage": {
          "image": newJobData.imageLink,
          "credentials": {
            "registry": newJobData.registryURL,
            "username": newJobData.dockerUserName,
            "password": newJobData.dockerPassword
          },
          "pythonPath": newJobData.installationPath
        },
        "environment": {},
        "artifactsDestination": {
          "name": newJobData.artifactStorageName,
          "filter": newJobData.filter
        },
        "virtualMounts": newJobData.virtualMounts,
        "from_template": "",
        "useSpot": newJobData.spotInstances,
        "cloudProviders": newJobData.cloudProviders,
        "minvCPUs": newJobData.minVCPUs,
        "minMemory": newJobData.minMemory,
        "maxCPUWorkers": newJobData.maxCPUWorkers,
        "instanceTypes": newJobData.instanceTypes,
        "gpuTypes": newJobData.gpuTypes,
        "maxPricePerHour": -1,
        "gpusPerTrial": newJobData.numberOfGPUs || 1,
        "maxGpus": 0,
        "numNodes": newJobData.numberOfNodes,
        "entrypoint": newJobData.entryPointFileName,
        "requirements": newJobData.requirementsFileName,
        "codeTransfer": {
          "type": newJobData.repoHost.replace(" ", "_").toUpperCase(),
          "repo": newJobData.repoName,
          "ref": newJobData.branchCode,
          "codeDir": newJobData.codeDirectory,
          "credentials": newJobData.repoCreds
        },
        "preJobCommands": newJobData.preJobCommands.split("\n"),
        "postJobCommands": newJobData.postJobCommands.split("\n"),
        "maxTime": newJobData.maximumTime || null,
        "maxCost": newJobData.maximumCost || null,
        "tuning": null,
      }
    })

  const handleNext = async () => {
    let isValid = false

    switch (activeStep) {
      case 0:
        isValid = await trigger(['entrypoint', 'codeTransfer.type', 'codeTransfer.repo', 'requirements', 'customImage.image', 'customImage.pythonPath'])
        if (isValid) {
          dispatch(setEntryPointFileName(watch('entrypoint')))
          dispatch(setRepoHost(watch('codeTransfer.type')))
          dispatch(setRepoName(watch('codeTransfer.repo')))
          dispatch(setRequirementsFileName(watch('requirements')))
          dispatch(setDockerImageLink(watch('customImage.image')))
          dispatch(setInstallationPath(watch('customImage.pythonPath')))
          dispatch(setBranchCode(watch('codeTransfer.ref')))
          dispatch(setCodeDirectory((watch('codeTransfer.codeDir'))))
          dispatch(setRepoCreds(watch('codeTransfer.credentials')))
          dispatch(setRegistryURL(watch('customImage.credentials.registry')))
          dispatch(setDockerUserName(watch('customImage.credentials.username')))
          dispatch(setDockerPassword(watch('customImage.credentials.password')))
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break

      case 1:
        let isVmValid = true
        let isStorageValid = true
        // if (watch("virtualMounts")?.length === 0) {
        //   setError('virtualMounts', { type: 'custom', message: 'This section is required' });
        // } else {
        //   clearErrors('virtualMounts');
        //   isVmValid = true
        // }

        // if (watch("artifactsDestination.name")?.length === 0) {
        //   setError('artifactsDestination.name', { type: 'custom', message: 'This field is required' });
        // } else {
        //   clearErrors('artifactsDestination.name');
        //   isStorageValid = true
        // }
        isValid = Boolean(isVmValid && isStorageValid)

        if (isValid) {
          dispatch(setVirtualMounts(watch('virtualMounts')))
          dispatch(setArtifactStorageName(watch('artifactsDestination.name')))
          dispatch(setFilter(watch('artifactsDestination.filter')))
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        break

      case 2:
        let isGPUsValid = false
        clearErrors('gpu');

        if ((!(watch("gpuTypes") && watch("gpuTypes")?.length !== 0) || watch("gpusPerTrial").length === 0)
          && !(watch("instanceTypes") && watch("instanceTypes")?.length !== 0)) {
          setError('gpu', { type: 'custom', message: 'This section is required' });
          isGPUsValid = false
        } else {
          clearErrors('gpu');
          isGPUsValid = true
        }

        isValid = activeTab === CONFIGURATION_TYPE_OPTIONS[1] ?
          Boolean(await trigger(["maxCPUWorkers"])) : isGPUsValid

        if (isValid) {
          dispatch(setMaxCPUWorkers(watch('maxCPUWorkers')))
          dispatch(setGPUTypes(watch('gpuTypes')))
          dispatch(setInstanceTypes(watch('instanceTypes')))
          dispatch(setNumberOfGPUs(watch('gpusPerTrial')))
          dispatch(setNumberOfNodes(watch('numberOfNodes')))
          dispatch(setCudaVersion(watch('cudaVersion')))
          if (activeStep === 2 && activeConfigureTab === '0') {
            setActiveConfigureTab('1')
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          clearErrors('gpu');
        }

        break
      default: break
    }
  };

  const handleDeploy = async () => {
    // let isValid = false

    let isNameValid = newJobData.name.length > 0
    // isValid = Boolean(await trigger(['maxCost']) && isNameValid)

    if (!isNameValid) {
      dispatch(setIsError(true))
      dispatch(setErrorMessage("Job Name is required"))
    } else {

      const newJobConfig = {
        "config": {
          "name": newJobData.name,
          "cuda": newJobData.cudaVersion,
          "pythonVersion": newJobData.pythonVersion,
          "customImage": newJobData.imageLink ? {
            "image": newJobData.imageLink || null,
            "credentials": {
              "registry": newJobData.registryURL || null,
              "username": newJobData.dockerUserName || null,
              "password": newJobData.dockerPassword || null
            },
            "pythonPath": newJobData.installationPath || null
          } : null,
          "environment": newJobData.envVariables,
          "artifactsDestination": {
            "name": newJobData.artifactStorageName,
            "filter": newJobData.filter
          },
          "virtualMounts": newJobData.virtualMounts,
          "from_template": "",
          "useSpot": newJobData.spotInstances,
          "cloudProviders": newJobData.cloudProviders,
          "minvCPUs": newJobData.minVCPUs,
          "minMemory": newJobData.minMemory,
          "maxCPUWorkers": newJobData.maxCPUWorkers,
          "instanceTypes": newJobData.instanceTypes,
          "gpuTypes": newJobData.gpuTypes,
          "maxPricePerHour": -1,
          "gpusPerTrial": newJobData.numberOfGPUs,
          "maxGpus": 0,
          "numNodes": newJobData.numberOfNodes,
          "entrypoint": newJobData.entryPointFileName,
          "requirements": newJobData.requirementsFileName,
          "codeTransfer": {
            "type": newJobData.repoHost.replace(" ", "_").toUpperCase(),
            "repo": newJobData.repoName,
            "ref": newJobData.branchCode,
            "codeDir": newJobData.codeDirectory,
            "credentials": newJobData.repoCreds
          },
          "preJobCommands": newJobData.preJobCommands.length > 0 ? newJobData.preJobCommands.split("\n") : null,
          "postJobCommands": newJobData.postJobCommands.length > 0 ? newJobData.postJobCommands.split("\n") : null,
          "maxTime": watch('maxTime'),
          "maxCost": watch('maxCost'),
          "tuning": null,
          "experiment": { "args": { " ": [" "] } }
        },
        "type": "SIMPLE_JOB",
        "productType": "SCALEGEN"
      }

      submit(newJobConfig)
    }
  }

  // const handleSaveTemplate = async () => {
  //   let isValid = false
  //   let isTimeValid = false

  //   if (watch('maximumTime').length === 0 || watch('maximumTime') === ":") {
  //     setError('maximumTime', { type: 'custom', message: 'This field is required' })
  //   } else {
  //     clearErrors('maximumTime')
  //     isTimeValid = true
  //   }

  //   isValid = Boolean(await trigger(['maximumCost']) && isTimeValid)


  //   if (isValid) {
  //     const newJobConfig = {
  //       "config": {
  //         "name": newJobData.name,
  //         "cuda": newJobData.cudaVersion,
  //         "pythonVersion": newJobData.pythonVersion,
  //         "customImage": {
  //           "image": newJobData.imageLink,
  //           "credentials": {
  //             "registry": newJobData.registryURL,
  //             "username": newJobData.dockerUserName,
  //             "password": newJobData.dockerPassword
  //           },
  //           "pythonPath": newJobData.installationPath
  //         },
  //         "environment": {},
  //         "artifactsDestination": {
  //           "name": newJobData.artifactStorageName,
  //           "filter": newJobData.filter
  //         },
  //         "virtualMounts": newJobData.virtualMounts,
  //         "from_template": "",
  //         "useSpot": newJobData.spotInstances,
  //         "cloudProviders": newJobData.cloudProviders,
  //         "minvCPUs": newJobData.minVCPUs,
  //         "minMemory": newJobData.minMemory,
  //         "maxCPUWorkers": newJobData.maxCPUWorkers,
  //         "instanceTypes": newJobData.instanceTypes,
  //         "gpuTypes": newJobData.gpuTypes,
  //         "maxPricePerHour": -1,
  //         "gpusPerTrial": newJobData.numberOfGPUs,
  //         "maxGpus": 0,
  //         "numNodes": newJobData.numberOfNodes,
  //         "entrypoint": newJobData.entryPointFileName,
  //         "requirements": newJobData.requirementsFileName,
  //         "codeTransfer": {
  //           "type": newJobData.repoHost.replace(" ", "_").toUpperCase(),
  //           "repo": newJobData.repoName,
  //           "ref": newJobData.branchCode,
  //           "codeDir": newJobData.codeDirectory,
  //           "credentials": newJobData.repoCreds
  //         },
  //         "preJobCommands": newJobData.preJobCommands.split("\n"),
  //         "postJobCommands": newJobData.postJobCommands.split("\n"),
  //         "maxTime": watch('maximumTime'),
  //         "maxCost": watch('maximumCost'),
  //         "tuning": {
  //           "searchSpace": {
  //             "additionalProp1": {
  //               "type": "string",
  //               "value": [
  //                 "string"
  //               ]
  //             },
  //             "additionalProp2": {
  //               "type": "string",
  //               "value": [
  //                 "string"
  //               ]
  //             },
  //             "additionalProp3": {
  //               "type": "string",
  //               "value": [
  //                 "string"
  //               ]
  //             }
  //           },
  //           "tuner": {
  //             "name": "string",
  //             "optimizeMode": "string"
  //           }
  //         },
  //       },
  //       "type": "SIMPLE_JOB",
  //       "productType": "SCALEGEN"
  //     }
  //     save(newJobConfig)
  //   }
  // }

  const handleSaveYAML = async () => {
    let isNameValid = newJobData.name.length > 0

    if (!isNameValid) {
      dispatch(setIsError(true))
      dispatch(setErrorMessage("Job Name is required"))
    } else {
      const newJobConfig = {
        "config": {
          "name": newJobData.name,
          "cuda": newJobData.cudaVersion,
          "pythonVersion": newJobData.pythonVersion,
          "customImage": newJobData.imageLink ? {
            "image": newJobData.imageLink || null,
            "credentials": {
              "registry": newJobData.registryURL || null,
              "username": newJobData.dockerUserName || null,
              "password": newJobData.dockerPassword || null
            },
            "pythonPath": newJobData.installationPath || null
          } : null,
          "environment": newJobData.envVariables,
          "artifactsDestination": {
            "name": newJobData.artifactStorageName,
            "filter": newJobData.filter
          },
          "virtualMounts": newJobData.virtualMounts,
          "from_template": "",
          "useSpot": newJobData.spotInstances,
          "cloudProviders": newJobData.cloudProviders,
          "minvCPUs": newJobData.minVCPUs,
          "minMemory": newJobData.minMemory,
          "maxCPUWorkers": newJobData.maxCPUWorkers,
          "instanceTypes": newJobData.instanceTypes,
          "gpuTypes": newJobData.gpuTypes,
          "maxPricePerHour": -1,
          "gpusPerTrial": newJobData.numberOfGPUs,
          "maxGpus": 0,
          "numNodes": newJobData.numberOfNodes,
          "entrypoint": newJobData.entryPointFileName,
          "requirements": newJobData.requirementsFileName,
          "codeTransfer": {
            "type": newJobData.repoHost.replace(" ", "_").toUpperCase(),
            "repo": newJobData.repoName,
            "ref": newJobData.branchCode,
            "codeDir": newJobData.codeDirectory,
            "credentials": newJobData.repoCreds
          },
          "preJobCommands": newJobData.preJobCommands.length > 0 ? newJobData.preJobCommands.split("\n") : null,
          "postJobCommands": newJobData.postJobCommands.length > 0 ? newJobData.postJobCommands.split("\n") : null,
          "maxTime": watch('maxTime'),
          "maxCost": watch('maxCost'),
          "tuning": null,
          "experiment": { "args": { " ": [" "] } }
        },
        "type": "SIMPLE_JOB",
        "productType": "SCALEGEN"
      }

      const element = document.createElement("a");
      const file = new Blob([YAML.stringify(newJobConfig)], { type: 'text/yaml' });
      element.href = URL.createObjectURL(file);
      element.download = "SimpleJobConfig.yml";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }

  const handleReview = async () => {
    let isValid = false;
    let isGPUsValid = false

    if ((!(watch("gpuTypes") && watch("gpuTypes")?.length !== 0) || watch("gpusPerTrial").length === 0)
      && !(watch("instanceTypes") && watch("instanceTypes")?.length !== 0)) {
      setError('gpu', { type: 'custom', message: 'This section is required' });
      isGPUsValid = false
    } else {
      clearErrors('gpu');
      isGPUsValid = true
    }

    isValid = activeTab === CONFIGURATION_TYPE_OPTIONS[1] ?
      Boolean(await trigger(["maxCPUWorkers"])) : isGPUsValid

    if (isValid) {
      dispatch(setMaxCPUWorkers(watch('maxCPUWorkers')))
      dispatch(setGPUTypes(watch('gpuTypes')))
      dispatch(setInstanceTypes(watch('instanceTypes')))
      dispatch(setNumberOfGPUs(watch('gpusPerTrial')))
      dispatch(setNumberOfNodes(watch('numberOfNodes')))
      dispatch(setCudaVersion(watch('cudaVersion')))
      setActiveStep(3)
      clearErrors('gpu');
    }
  }

  function activeView(step) {
    switch (step) {
      case 0: return <SourceCodeComponent
        register={register} errors={errors} watch={watch} setValue={setValue}
      />;
      case 1: return <DataComponent register={register} errors={errors} watch={watch} setValue={setValue} />;
      case 2: return (
        <ConfigureComponent
          activeConfigureTab={activeConfigureTab}
          setActiveConfigureTab={setActiveConfigureTab}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )
      case 3: return <DeployComponent register={register} errors={errors} setValue={setValue} watch={watch} />;
      default: return
    }
  }

  const notCompletedIcon = (index) => (
    <Stack width="24px" height="24px"
      fontSize="15px"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      fontWeight="600"
      border={activeStep === index ? "2px solid #92CBFF" : `1px solid ${color.borders}`}
      boxShadow="0px 2px 4px 0px #0000001F"
      color={activeStep === index ? color.primary : "#ABABAB"}
    >
      {index + 1}
    </Stack >
  )

  const dispatch = useDispatch()

  const completedIcon = () => {
    return <Stack width="24px" height="24px"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      boxShadow="0px 2px 4px 0px #0000001F"
      bgcolor={color.primary}
      color={color.white}
    >
      <DoneIcon
        fontSize="15px"
      />
    </Stack>
  }

  if (isSubmitting
    // || isSaving
  ) {
    return <Stack height="50vh"><Loader /></Stack>
  }

  if (isSuccess
    // || isSaved
  ) {
    dispatch(clearStore())
    navigate(PAGE_ROUTES.training)
  }

  return (
    <Stack width='100%' gap={2}>
      <Stepper activeStep={activeStep}>
        {STEPPER_STEPS.map((label, index) => {
          return (
            <Step key={index} onClick={() => index < activeStep && setActiveStep(index)}>
              <StepLabel
                icon={
                  index < activeStep ? completedIcon() : notCompletedIcon(index)
                }
              >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper >
      <Stack height="70vh" gap={2}>
        <Stack height="64vh">{activeView(activeStep)}</Stack>
        <NewJobFooter handleNext={handleNext}
          activeStep={activeStep}
          handleReview={handleReview}
          handleDeploy={handleDeploy}
          // handleSaveTemplate={handleSaveTemplate}
          handleSaveYAML={handleSaveYAML}
        />
      </Stack>
    </Stack >
  );
}