import { Stack, Typography } from '@mui/material';
import { VM_STORAGE_TYPE_OPTIONS } from '../../Configs/VirtualMountConstants';
import { color } from '../../Styles/Color';

const ChooseStorageType = ({ isActive, setIsActive }) => {

  return (
    <Stack spacing={4} py={2} >
      <Stack spacing={1}>
        <Typography variant='h3'>
          Virtual Machine Cloud
        </Typography>
        <Typography variant='subtitle1' color={color.secondaryText}>
          Connect the storage bucket where the dataset is stored
        </Typography>
      </Stack>
      <Stack direction="row" gap={2} flexWrap='wrap' width="38vw">
        {
          VM_STORAGE_TYPE_OPTIONS.map(({ name, icon }) => {
            return <Stack
              key={name}
              spacing={2}
              alignItems="center"
              width="100px"
              onClick={() =>
                setIsActive(name)
              }
            >
              <Stack
                width="80px"
                height="80px"
                justifyContent="center"
                alignItems="center"
                border="1px solid"
                borderColor={isActive === name ? color.primary : color.borders}
                borderRadius="6px"
                bgcolor={isActive === name && color.lightBlue}
                sx={{
                  cursor: "pointer",
                }}
              >
                {icon("50px")}
              </Stack>
              <Typography
                variant='h3'
                color={isActive === name && color.primary}
                textAlign="center"
              >
                {name}
              </Typography>
            </Stack>
          })
        }
      </Stack>
    </Stack>
  )
}

export default ChooseStorageType