import { Divider, Stack, Typography } from '@mui/material';

export default function JobArgs({ job }) {

  return (
    <Stack spacing={2}>
      <Typography fontSize="16px">Arguments</Typography>
      {
        Object.entries(job.spec.config || {}).map(([key, value], idx) => (
          <Stack spacing={1} key={idx} py={1}>
            <Stack direction="row" gap={1} >
              <Stack width="25%">
                <Typography fontSize="13px">{key}</Typography>
              </Stack>
              <Stack width="75%">
                {
                  <Typography fontSize="13px" color="#ABABAB" sx={{ wordBreak: "," }}>{
                    key === 'name' ? job.name
                      :
                      value ?
                        typeof (value) === "object" ?
                          JSON.stringify(value).replace(/,/g, ", ") :
                          value :
                        "-"
                  }</Typography>
                }
              </Stack>
            </Stack>
            {idx !== (Object.entries(job.spec.config).length - 1) && < Divider />}
          </Stack>
        ))
      }
    </Stack>
  )
}
