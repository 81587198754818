import ClearIcon from '@mui/icons-material/Clear';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography } from '@mui/material';
import { forwardRef, useLayoutEffect, useState } from 'react';
import { color } from '../../Styles/Color';
// import Loader from '../UiComponents/Loader';
import CloudIcon from '@mui/icons-material/Cloud';
import LanguageIcon from '@mui/icons-material/Language';
import CustomButton from '../../Components/UiComponents/CustomButton';
import SecondaryButton from '../../Components/UiComponents/SecondaryButton';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeployModal = ({ isOpen, setIsOpen, watch, setValue }) => {

  const [formValues, setFormValues] = useState({})
  let region = watch("controller_cloud_config.region")

  useLayoutEffect(() => {
    const initialFormState = {
      region: region
    }

    setFormValues(initialFormState)
  }, [region])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleUpdate = () => {
    setValue("controller_cloud_config.region", formValues.region)
    handleClose()
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant='h2' color={color.primary}>Manage Deployment Region</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "35vh",
          width: "40vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // }
        }}
      >
        <Stack spacing={3} py={1}>
          <Stack
            bgcolor={color.secondaryBackground}
            border={`1px solid ${color.borders}`}
            p={2}
            spacing={1}
            borderRadius="6px"
          >
            <Typography variant='subtitle1' color={color.secondaryText}>
              Current deployment region
            </Typography>
            <Stack direction="row" gap={4} alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                <CloudIcon fontSize='small' sx={{ color: color.primary }} />
                <Typography variant='body2'>{watch('controller_cloud_config.cloud_provider')}</Typography>
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <LanguageIcon fontSize='small' sx={{ color: color.primary }} />
                <Typography variant='body2'>{watch('controller_cloud_config.region')}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Typography variant='h3'>Choose the Deployment Region</Typography>
            <Stack direction="row" gap={1}>
              <Typography variant='subtitle1' color={color.secondaryText}>
                Select the cloud region where you want to deploy your model.
              </Typography>
              <Typography
                variant='subtitle1'
                color={color.primary}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => window.open("https://docs.scalegen.ai/inf-guide#deployment-region", "_blank")}
              >
                Learn More.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            {
              ["US", "EU", "ASIA", "CANADA"].map((region) => {
                return (
                  <Chip
                    key={region}
                    sx={{
                      color: region === formValues.region && color.white,
                      bgcolor: region === formValues.region ? color.primary : color.secondaryBackground,
                      border: region !== formValues.region && `2px solid ${color.borders}`,
                      borderRadius: "6px",
                      fontSize: "12px",
                      minWidth: "12%",
                      "&:hover": {
                        bgcolor: region === formValues.region ? color.primary : color.secondaryBackground,
                      }
                    }}
                    label={region}
                    clickable={true}
                    onClick={() => {
                      region !== formValues.region && setFormValues({ region: region })
                    }}
                  />
                )
              }
              )
            }
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai/inf-guide#deployment-region", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <CustomButton
              onClick={handleUpdate}
              width="10%"
            >
              Update
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default DeployModal