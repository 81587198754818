import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const llmGatewayApi = createApi({
  reducerPath: 'llmGatewayApi',
  ...platformAuthConfig(),
  tagTypes: ["LLMGateway"],
  endpoints: builder => ({
    getLLMGateways: builder.query({
      query: () => '/llm_gateway/list',
      providesTags: ["LLMGateway"]
    }),
    getLLMGateway: builder.query({
      query: (id) => `/llm_gateway/get?llm_gateway_id=${id}`,
      providesTags: ["LLMGateway"]
    }),
    addLLMGateway: builder.mutation({
      query: (config) => ({
        url: '/llm_gateway/create',
        method: 'POST',
        body: config
      }),
      invalidatesTags: ["LLMGateway"]
    }),
    deleteLLMGateway: builder.mutation({
      query: (id) => ({
        url: `/llm_gateway/delete?llm_gateway_id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["LLMGateway"]
    }),
    getLLMProviders: builder.query({
      query: () => '/llm_gateway/list_llm_api',
      providesTags: ["LLMGateway"]
    }),
    addLLMProvider: builder.mutation({
      query: (config) => ({
        url: '/llm_gateway/register_llm_api',
        method: 'POST',
        body: config
      }),
      invalidatesTags: ["LLMGateway"]
    }),
    deleteLLMProvider: builder.mutation({
      query: (id) => ({
        url: `/llm_gateway/delete_llm_api?llm_prov_id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["LLMGateway"]
    }),
  })
})

export const {
  useGetLLMGatewaysQuery,
  useGetLLMGatewayQuery,
  useGetLLMProvidersQuery,
  useAddLLMGatewayMutation,
  useAddLLMProviderMutation,
  useDeleteLLMGatewayMutation,
  useDeleteLLMProviderMutation
} = llmGatewayApi

export default llmGatewayApi
