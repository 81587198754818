import { useSelector } from "react-redux"
import { Dashboard } from "../NewComponents/Agentic/Dashboard"
import { EnterApi } from "../NewComponents/Agentic/EnterApi"

export const AgenticDashboard = () => {

  const api_key = useSelector(state => state.userState.sgUser.user_metadata)

  return !api_key ? <EnterApi /> : <Dashboard />
}
