//define routes here before use to avoid spelling issues

export const PAGE_ROUTES = Object.freeze({
  login: "/login",
  dashboard: "/",

  admin: "/admin",
  cloudProviders: "/admin/cloud-providers",
  virtualMounts: "/admin/virtual-mounts",
  artifactStorages: "/admin/artifact-storages",
  billing: "/admin/billing",
  secrets: "/admin/secrets",
  team: '/admin/members',
  onPremNodes: "/admin/on-prem-nodes",
  llmProviders: '/admin/llm/providers',

  setting: "/settings",

  training: '/training',
  createJob: '/training/new/job',
  relaunchJob: '/training/relaunch/job',
  job: '/training/:id',

  fineTuning: '/fine-tuning',
  newFineTuning: '/fine-tuning/launch',
  finetuningLlamaMistral: '/training/new/finetuning/llama-mistral',
  relaunchFinetune: '/fine-tuning/relaunch',
  finetunejob: '/fine-tuning/:id',
  finetuneTemplates: '/fine-tuning/templates',

  inferences: '/inference',
  newInference: '/inference/launch',
  inferenceTemplates: '/inference/templates',
  newEmbeddingsInference: '/inference/launch-embeddings',
  inference: '/inference/:id',

  templates: '/templates',
  template: "/template/:id",

  llmGateways: '/llm/gateway',
  llmGateway: '/llm/gateway/:id',
  newLLMGateway: '/new/llm/gateway',

  machines: "/gpu-machine",
  machine: "/gpu-machine/:id",

  agenticDashboard: "/theagenticai",
  agenticLaunch: "/theagenticai/launch",

  notFoundError: '/not-found',
})