import { Stack } from "@mui/material"
import { useNavigate } from "react-router"
import { ReactComponent as NotFoundError } from "../Assets/Images/404_error.svg"
import CustomButton from "../Components/UiComponents/CustomButton"

export const NotFoundErrorPage = () => {

  const navigate = useNavigate()

  return (
    <Stack alignItems="center" justifyContent="center" height="80vh" spacing={2}>
      <NotFoundError height="50vh" />
      <CustomButton width="15%" onClick={() => navigate(-1)}>
        Go Back
      </CustomButton>
    </Stack>
  )
}
