import VisibilityIcon from '@mui/icons-material/Visibility';
import { Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';
import TeamMember from './TeamMember';

function createData(email) {
  return { email };
}

export default function TeamList({ data, handleDelete }) {

  const rows = (data || []).map((email) => createData(email))

  const [viewMember, setViewMember] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  const [page, setPage] = useState(0)

  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])


  return (
    <TableContainer component={Paper}
      sx={{
        border: "1px solid #E4E4E4",
        maxHeight: "55vh",
        boxShadow: "none",
      }}>
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="30%">
              Members ({data ? data.length : 0})
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="50%">Permissions</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow
                  key={idx}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell >{row.email}</TableCell>
                  <TableCell align='center'>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      sx={{
                        color: color.primary,
                        "&:hover": {
                          cursor: "pointer"
                        }
                      }}
                      onClick={() => {
                        setModalIsOpen(true)
                        setViewMember(row.email)
                      }
                      }
                    >
                      <VisibilityIcon fontSize='small' />
                      View
                    </Stack>
                  </TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(row.email)
                      }}
                    />
                  </TableCell>
                </TableRow>
              )) :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={1}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No Members added.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onPageChange={handleChangePage}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <TeamMember member={viewMember} team={data} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}
        setMemberData={setViewMember} />
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName={"Member with email " + deletingId}
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  );
}