import { Chip, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { DASHBOARD_CLI_TEMPLATE, MODEL_PROVIDERS } from '../../Configs/DashboardConfigs'
import { PAGE_ROUTES } from '../../Configs/Routes'
import { useGetInferenceTemplatesQuery } from '../../Services/templatesApi'
import { color } from '../../Styles/Color'

export const DashboardTemplates = () => {

  const nav = useNavigate()
  const { data, isLoading } = useGetInferenceTemplatesQuery()


  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant='h1' >Try ScaleGenAI</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant='body2' color={color.secondaryText}>
            Quick deploy models using pre-configured templates. Check out
          </Typography>
          <Typography
            variant='body2'
            color={color.primary}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai/category/tutorials", "_blank")}
          >
            Tutorials
          </Typography>
          <Typography variant='body2' color={color.secondaryText}>
            to see how you can fine-tune/deploy models.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={4} flexWrap="wrap" width="70%">
        {
          isLoading ?
            [...Array(4)].map((_, idx) =>
              <Stack
                key={idx}
                borderRadius="12px"
                border={`1px solid ${color.borders}`}
                boxShadow="0px 1px 4px 0px #0000000A"
                overflow="hidden"
                height="15vh"
                position="relative"
                minWidth="45%"
              >
                <Stack
                  position="absolute"
                  top={0} left={0}
                  zIndex="5"
                  spacing={1}
                  width="100%"
                  p={2}
                >
                  <Typography variant='h3' width="30%"><Skeleton /></Typography>
                  <Stack direction="row" gap={1} alignItems="end">
                    <Typography variant='body1' noWrap width="50%"><Skeleton /></Typography>
                    <Typography variant='subtitle1' noWrap width="25%"><Skeleton /></Typography>
                  </Stack>
                  <Typography variant='subtitle1' color={color.secondaryText}>
                    <Skeleton />
                  </Typography>
                </Stack>
              </Stack>
            ) :
            data?.map((temp, idx) => {
              const [provider, model] = temp.config.model.split("/")

              return (
                <Stack
                  key={idx}
                  minWidth="45%"
                  borderRadius="12px"
                  border={`1px solid ${color.borders}`}
                  boxShadow="0px 1px 4px 0px #0000000A"
                  overflow="hidden"
                  height="15vh"
                  position='relative'
                  sx={{
                    "&:hover": {
                      cursor: 'pointer'
                    },
                  }}
                  onClick={() => nav(PAGE_ROUTES.inferenceTemplates, {
                    state: {
                      model: temp.config.model,
                      config: temp.config
                    }
                  })}
                >
                  <Stack
                    position="absolute"
                    top={0} left={0}
                    height="100%"
                    width="100%"
                    justifyContent="center"
                    alignItems="end"
                  >
                    {MODEL_PROVIDERS[provider].icon}
                  </Stack>
                  <Stack
                    position="absolute"
                    top={0} left={0}
                    zIndex="5"
                    spacing={1}
                    width="100%"
                    p={2}
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant='h3'>DEPLOY</Typography>
                      <Chip
                        sx={{
                          background: "rgba(255, 255, 255, 0.55)",
                          textTransform: "uppercase",
                          px: 1,
                        }}
                        label={<Typography variant='h3' fontSize="12px">{temp.config.inf_type}</Typography>}
                      />
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="end">
                      <Typography variant='body1' noWrap >{model.replaceAll("-", " ")}</Typography>
                      <Typography variant='subtitle1' noWrap>by {MODEL_PROVIDERS[provider].name}</Typography>
                    </Stack>
                    <Typography variant='subtitle1' color={color.secondaryText}>
                      {/* {temp.model_type} | {temp.config.gpu} */}
                      {temp.config.initial_worker_config.initial_workers_gpu}
                    </Typography>
                  </Stack>
                </Stack>
              )
            })
        }
      </Stack>
      <Typography variant='h1' >Deploy via ScaleGenAI CLI</Typography>
      <Stack direction="row" gap={4} flexWrap="wrap" width="70%">
        {
          DASHBOARD_CLI_TEMPLATE.map((temp, idx) => (
            <Stack
              key={idx}
              minWidth="45%"
              borderRadius="12px"
              border={`1px solid ${color.borders}`}
              boxShadow="0px 1px 4px 0px #0000000A"
              overflow="hidden"
              height="15vh"
              position='relative'
              sx={{
                "&:hover": {
                  cursor: 'pointer'
                },
              }}
              onClick={() => window.open("https://docs.scalegen.ai/inf-cli", "_blank")}
            >
              <Stack
                position="absolute"
                top={0} left={0}
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="end"
              >
                {temp.icon}
              </Stack>
              <Stack
                position="absolute"
                top={0} left={0}
                zIndex="5"
                spacing={1}
                width="100%"
                p={2}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" >
                  <Typography variant='h3'>DOCS</Typography>
                  <Chip
                    sx={{
                      background: "rgba(255, 255, 255, 0.55)",
                      textTransform: "uppercase",
                      px: 1,
                    }}
                    label={<Typography variant='h3' fontSize="12px" >{temp.inf_type}</Typography>}
                  />
                </Stack>
                <Stack direction="row" gap={1} alignItems="end">
                  {/* {temp.icon} */}
                  <Typography variant='body1' noWrap >{temp.model}</Typography>
                  <Typography variant='subtitle1' noWrap>by {temp.provider}</Typography>
                </Stack>
                <Typography variant='subtitle1' color={color.secondaryText}>
                  {/* {temp.model_type} |  */}
                  {temp.gpu}
                </Typography>
              </Stack>
            </Stack>
          ))
        }
      </Stack>
    </Stack >
  )
}
