import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import AddVMModal from '../Components/VirtualMountsComponents/AddVMModal';
import VMList from '../Components/VirtualMountsComponents/VMList';
import { openVMModal } from '../DataStore/modalSlice';
import { useDeleteVirtualMountMutation, useGetVirtualMountsQuery } from '../Services/virtualMountsApi';


const VirtualMountsView = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetVirtualMountsQuery();
  const [handleDelete, { isLoading: isDeleting }] = useDeleteVirtualMountMutation()

  if (isLoading || isDeleting) {
    return <Loader />
  }

  return (
    <Stack
      spacing={5}
      overflow="auto"
      p={1}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" >
        <Typography variant='h1'>
          Virtual Mounts
        </Typography>
        <CustomButton width="30%" onClick={() => dispatch(openVMModal())}>
          <AddIcon fontSize='small' sx={{ mr: 1 }} />
          Add Virtual Mount
        </CustomButton>
      </Stack>
      <VMList data={data} handleDelete={handleDelete} openModal={() => dispatch(openVMModal())} />
      <AddVMModal />
    </Stack>
  )
}

export default VirtualMountsView