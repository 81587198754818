import { Stack, Typography } from "@mui/material"
import { ReactComponent as Error } from "../Assets/Images/No data-bro.svg"
import CustomButton from "../Components/UiComponents/CustomButton"
import { color } from "../Styles/Color"

export const ErrorFallbackPage = () => {

  return (
    <Stack alignItems="center" justifyContent="center" height="80vh" spacing={2}>
      <Error height="60vh" />
      <Typography color={color.primary}>Something went wrong</Typography>
      <CustomButton width="15%" onClick={() => window.location.reload()}>
        Reload
      </CustomButton>
    </Stack>
  )
}
