import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import React from 'react';

const DeleteButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        bgcolor: "#FFDBDB",
        p: 1,
        minWidth: '28px',
        '&:hover': {
          bgcolor: "#FFDBDB",
        }
      }} >
      <DeleteIcon sx={{ color: "#FF6B6A", p: 0, margin: 0 }} fontSize='small' />
    </Button >
  )
}

export default DeleteButton