import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PYTHON_VERSION } from '../../../Configs/ConfigureNewJobConstants'
import { addEnvVariables, deleteEnvVariables, setPythonVersion } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'
import CustomButton from '../../UiComponents/CustomButton'
import InputField from '../../UiComponents/InputField'
import { EnvironmentCommands } from './EnvironmentCommands'


export const EnvironmentConfigurations = () => {
  const dispatch = useDispatch()
  const { pythonVersion, envVariables } = useSelector(store => store.newJobData)

  const [key, setKey] = useState("")
  const [value, setValue] = useState("")

  const handleAdd = () => {
    if (key.length > 0 && value.length > 0) {
      dispatch(addEnvVariables({
        [key]: value
      }))
      setKey("")
      setValue("")
    }
  }

  return (
    <Stack gap={2}>
      <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`}>
        <Typography fontWeight="600" fontSize="15px">
          Python Version
        </Typography>
        <Select
          size='small'
          value={pythonVersion}
          onChange={(e) => dispatch(setPythonVersion(e.target.value))}
          sx={{ bgcolor: color.white, width: "70%", fontSize: "15px", borderRadius: "8px" }}
        >
          {
            PYTHON_VERSION
              .map((version, idx) => (
                <MenuItem
                  key={idx}
                  value={version}
                  sx={{ fontSize: "15px" }}
                >
                  {version}
                </MenuItem>
              ))}
        </Select>
      </Stack>
      <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
        <Typography fontWeight="600" fontSize="15px">
          Environment Variables
        </Typography>
        <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
          <Stack width="40%" gap={1}>
            <Typography fontSize="15px">
              Key
            </Typography>

          </Stack>
          <Stack width="40%" gap={1}>
            <Typography fontSize="15px">
              Value
            </Typography>

          </Stack>
        </Stack>
        {
          Object.entries(envVariables).map((entry, idx) => (
            <Stack direction="row" alignItems="center" fontSize="15px" gap={2} key={idx}>
              <InputField
                width="40%"
                disabled={true}
                state={entry[0]}
              />
              <InputField
                width="40%"
                disabled={true}
                state={entry[1]}
              />
              <Button
                onClick={() =>
                  dispatch(deleteEnvVariables(entry[0]))
                }
                sx={{
                  bgcolor: "#FFDBDB",
                  p: 1,
                  minWidth: '30px',
                  '&:hover': {
                    bgcolor: "#FFDBDB",
                  }
                }} >
                <DeleteIcon sx={{ color: "#FF6B6A", fontSize: "16px" }} />
              </Button >
            </Stack>
          ))
        }
        <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
          <InputField
            width="40%"
            placeholder="Enter key"
            state={key}
            setState={(e) => setKey(e.target.value)}
          />
          <InputField
            width="40%"
            placeholder="Enter value"
            state={value}
            setState={(e) => setValue(e.target.value)}
          />
          <CustomButton width="10%" onClick={handleAdd}>
            <Stack alignItems="center" direction="row" gap={0.5}>
              <AddIcon sx={{ fontSize: "16px" }} />
              Add
            </Stack>
          </CustomButton>
        </Stack>
      </Stack >
      <EnvironmentCommands />
    </Stack >
  )
}
