import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { color } from '../../Styles/Color';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: color.secondaryBackground,
  borderRadius: '8px',
  border: `1px solid ${color.borders}`,
  '& .MuiToggleButtonGroup-grouped': {
    margin: "5px",
    border: 0,
    fontSize: "12px",
    '&.MuiButtonBase-root': {
      minWidth: "100px",
      height: "32px",
      paddingInline: "16px",
    },
    '&.Mui-selected': {
      color: color.primary,
      backgroundColor: color.white,
      boxShadow: "0px 0px 4px 0px #00000029",
      '&:hover': {
        backgroundColor: color.white,
      }
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: "6px",
    },
    '&:first-of-type': {
      borderRadius: "6px",
    },
  },
}));

export default function CustomToggleButton({ options, selected, setSelected }) {

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSelected(newAlignment);
    }
  };

  return (
    <div>
      <StyledToggleButtonGroup
        size="small"
        value={selected}
        exclusive
        onChange={handleAlignment}
      >
        {
          options.map((option, index) => (
            <ToggleButton key={index} value={option}>
              {option}
            </ToggleButton>
          ))
        }
      </StyledToggleButtonGroup>
    </div>
  );
}