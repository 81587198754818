import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPostJobCommands, setPreJobCommands } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'
import InputField from '../../UiComponents/InputField'

export const EnvironmentCommands = () => {
  const dispatch = useDispatch()
  const { preJobCommands, postJobCommands } = useSelector(store => store.newJobData)

  return (
    <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px"
      border={`1px solid ${color.borders}`} >
      <Stack gap={1}>
        <Typography fontWeight="600" fontSize="15px">
          Pre-Job Commands
        </Typography>
        <InputField
          multiLine={true}
          state={preJobCommands}
          setState={(e) => dispatch(setPreJobCommands(e.target.value))}
          placeholder={`List of commands to run (without sudo). Each new command in new line\nExample:\napt-get update -y\napt-get install net-tools`}
        />
      </Stack>
      <Stack gap={1}>
        <Typography fontWeight="600" fontSize="15px">
          Post-Job Commands
        </Typography>
        <InputField
          multiLine={true}
          state={postJobCommands}
          setState={(e) => dispatch(setPostJobCommands(e.target.value))}
          placeholder={`List of commands to run (without sudo). Each new command in new line\nExample:\napt-get update -y\napt-get install net-tools`}
        />
      </Stack>
    </Stack >
  )
}
