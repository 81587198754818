import { Box, Chip, FormControlLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ONPREMISE_CONFIG_TYPE_TABS } from '../../../Configs/NewJobConstants'
import { color } from '../../../Styles/Color'
import BoxSwitch from '../../UiComponents/BoxSwitch'
import InputField from '../../UiComponents/InputField'
import NavigationTabs from '../../UiComponents/NavigationTabs'
import { FTCloudConfig } from './FTCloudConfig'

export const FTOnPremiseConfig = ({ gpuOptions, gpuNodes, setGpuNodes, gpuTypeOptions, allowOtherGPU, setAllowOtherGPU, cloudProvider, setCloudProvider, region, setRegion, cloudProviderOptions, regionOptions, cloudBurst, setCloudBurst, gpuNodeType, setGPUNodeType, numberOfGPUs, setNumberOfGPUs, instanceTypeOptions, instanceType, setInstancetype, gpuPerNode, setGPUPerNode, watch, setValue }) => {

  const [gpuTypes, setGPUTypes] = useState([])
  const [onPremType, setOnPremType] = useState("0")


  useEffect(() => {
    setGPUTypes(gpuOptions.map(gpu => gpu.gpu_type).filter(gpu => gpu.length > 0))
  }, [gpuOptions])

  const ONPREMISE_CONFIG_TYPE_PANELS = [
    <Stack spacing={1} >
      <Typography fontSize="15px">Choose GPU Nodes</Typography>
      <Select
        size='small'
        multiple
        value={gpuNodes}
        onChange={(e) => setGpuNodes(e.target.value)}
        input={<OutlinedInput />}
        renderValue={(selected, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {
              selected.length === 0 ?
                <Typography fontSize="15px">Choose GPU Nodes</Typography>
                : selected.map((value) => (
                  <Chip key={value} sx={{
                    bgcolor: color.lightBlue,
                    borderRadius: "6px",
                    fontSize: "12px",
                  }} label={value} />
                ))}
          </Box>
        )}
        sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
      >
        {
          gpuOptions?.map((gpu, idx) => (
            <MenuItem
              key={idx}
              value={gpu.ip}
              sx={{ fontSize: "15px" }}
            >
              {gpu.ip}{gpu.gpu_count > 0 && " - " + gpu.gpu_count + "x" + gpu.gpu_type}
            </MenuItem>
          ))}
      </Select>
      {
        gpuNodes.length > 0 &&
        <Stack gap={1} >
          <Typography fontSize="15px">GPU Type</Typography>
          <InputField
            state={gpuNodes.reduce((acc, node) => acc + gpuOptions.find(gpu => gpu.ip === node)?.gpu_type, "")}
            setState={(e) => { }} width="70%" />
        </Stack>
      }
    </Stack>,
    <Stack spacing={1} >
      <Typography fontSize="15px">Choose GPU Type</Typography>
      <Select
        size='small'
        multiple
        value={gpuNodeType}
        onChange={(e) => setGPUNodeType(e.target.value)}
        input={<OutlinedInput />}
        renderValue={(selected, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {
              selected.length === 0 ?
                <Typography fontSize="15px">Choose GPU Types</Typography>
                : selected.map((value) => (
                  <Chip key={value} sx={{
                    bgcolor: color.lightBlue,
                    borderRadius: "6px",
                    fontSize: "12px",
                  }} label={value} />
                ))}
          </Box>
        )}
        sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
      >
        {
          gpuTypes?.map((gpu, idx) => (
            <MenuItem
              key={idx}
              value={gpu}
              sx={{ fontSize: "15px" }}
            >
              {gpu}
            </MenuItem>
          ))}
      </Select>

    </Stack>
  ]

  return (
    < Stack spacing={2} >
      <NavigationTabs panels={ONPREMISE_CONFIG_TYPE_PANELS} tabs={ONPREMISE_CONFIG_TYPE_TABS}
        value={onPremType}
        setValue={setOnPremType} />
      {
        !cloudBurst &&
        <Stack spacing={1}>
          <Typography fontSize="15px">Number of GPUs Per Worker</Typography>
          <InputField width="70%" state={gpuPerNode} setState={(e) => setGPUPerNode(e.target.value)}
            placeholder="Enter number of GPUs" />
        </Stack>
      }
      <FormControlLabel sx={{ p: 1 }}
        control={
          <BoxSwitch
            value={cloudBurst}
            onChange={(e) => setCloudBurst(e.target.checked)}
          />
        }
        label={<Stack direction="row" alignItems="center">
          <Typography fontSize="17px" mx={1}>Use Cloudburst</Typography>
        </Stack >}
      />
      {
        cloudBurst && <FTCloudConfig
          gpuOptions={gpuTypeOptions} allowOtherGPU={allowOtherGPU}
          setAllowOtherGPU={setAllowOtherGPU}
          cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
          region={region} setRegion={setRegion} cloudProviderOptions={cloudProviderOptions}
          regionOptions={regionOptions} instanceTypeOptions={instanceTypeOptions} instanceType={instanceType}
          setInstancetype={setInstancetype} numberOfGPUs={numberOfGPUs} setNumberOfGPUs={setNumberOfGPUs}
          watch={watch} setValue={setValue}
        />
      }
    </Stack >
  )
}
