import ClearIcon from '@mui/icons-material/Clear';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
// import { useDispatch } from 'react-redux';
import { ReactComponent as VMIcon } from "../../../Assets/Images/vmIcon.svg";

import { useState } from 'react';
// import { addVirtualMounts } from '../../../DataStore/newJobSlice';
import { color } from '../../../Styles/Color';
import CustomButton from '../../UiComponents/CustomButton';
import InputField from '../../UiComponents/InputField';
import { AdvancedVM } from './AdvancedVM';


// const vmNames = [
//   "vm1",
//   "vm2",
//   "vm3"
// ]

const AddVMModal = ({ isOpen, handleClose, userVMs, setValue, watch }) => {

  const [newVm, setNewVm] = useState({
    name: "",
    src: "",
    destination: "",
    filter: "",
    unravelArchives: false,
    prefetch: false,
  })


  // const dispatch = useDispatch()

  const handleAdd = () => {
    // dispatch(addVirtualMounts(newVm))
    setValue("virtualMounts", [...watch("virtualMounts"), newVm])
    handleClose()
  }

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <VMIcon width="20px" height="20px" />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>
              Configure Virtual Mount
            </Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "50vh",
        width: "45vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        <Stack py={2} spacing={1.5}>
          <Typography fontWeight="600" fontSize="15px">
            Virtual Mount Name
          </Typography>
          <Select
            displayEmpty
            size='small'
            sx={{ fontSize: '15px', borderRadius: '8px', bgcolor: color.white }}
            value={newVm.name}
            onChange={(e) =>
              setNewVm((prev) => ({
                ...prev, name: e.target.value,
                src: userVMs?.find(v => v.name === e.target.value).src || "",
                destination: userVMs?.find(v => v.name === e.target.value).dest || ""
              }))
            }
          >
            <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
              Choose Virtual Mount
            </MenuItem>
            {
              userVMs?.map((vm, idx) => (
                <MenuItem value={vm.name} key={idx} sx={{ fontSize: "15px" }}>
                  {vm.name}
                </MenuItem>
              ))
            }
          </Select>
          <Stack gap={1} >
            <Typography fontSize="15px">Source</Typography>
            <InputField state={newVm.src} disabled={true} />
          </Stack>
          <Stack gap={1} >
            <Typography fontSize="15px">Destination</Typography>
            <InputField state={newVm.destination}
              placeholder={userVMs?.find(v => v.name === newVm.name)?.dest || ""}
              setState={(e) =>
                setNewVm((prev) => ({ ...prev, destination: e.target.value }))
              } />
          </Stack>
          <AdvancedVM setNewVm={setNewVm} newVm={newVm} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={handleAdd}
          width="10%"
        >
          Add
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddVMModal