import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material'
import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import { SecretsList } from '../Components/SecretsViewComponents/SecretsList'
import CustomButton from '../Components/UiComponents/CustomButton'
import InputField from '../Components/UiComponents/InputField'
import Loader from "../Components/UiComponents/Loader"
import { useAddSecretMutation, useDeleteSecretMutation, useGetSecretsQuery } from '../Services/secretsApi'
import { color } from '../Styles/Color'


export const SecretsView = () => {

  const [key, setKey] = useState("")
  const [value, setValue] = useState("")


  const { data, isLoading } = useGetSecretsQuery()
  const [deleteSecret, { isLoading: isDeleting }] = useDeleteSecretMutation()
  const [addKey, { isLoading: isAdding, isSuccess }] = useAddSecretMutation()

  useEffect(() => {
    if (isSuccess) {
      setKey("")
      setValue("")
    }
  }, [isSuccess])

  const [searchedKey, setSearchedKey] = useState("")

  const [searchedData, setSearchedData] = useState(data)

  useEffect(() => {
    const options = {
      includeScore: true,
    }

    const fuse = new Fuse(data, options)

    if (searchedKey.length > 0) {
      setSearchedData(fuse.search(searchedKey).filter(key => key.score < 0.2).map(key => key.item))
    } else {
      setSearchedData(data)
    }

  }, [data, searchedKey])

  const handleDelete = (deleteKey) => {
    deleteSecret(deleteKey)
  }

  if (isLoading || isDeleting || isAdding) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack
      spacing={5}
      overflow="auto"
      // sx={{
      //   '&::-webkit-scrollbar': {
      //     display: 'none',
      //   }
      // }}
      p={1}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" >
        <Typography variant='h1'>
          Secrets
        </Typography>
        <OutlinedInput
          value={searchedKey}
          size='small'
          border={`1px solid ${color.borders}`}
          onChange={e => setSearchedKey(e.target.value)}
          sx={{
            borderRadius: "8px",
            boxShadow: "0px 0px 4px 0px #00000029",
            width: "60%",
          }}
          placeholder='Search keys'
          startAdornment={<InputAdornment position="start">
            <SearchIcon fontSize='small' />
          </InputAdornment>}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant='h2'>Add a New Secret</Typography>
        <Stack direction="row" gap={1}>
          <Typography variant='body2' color={color.secondaryText}>
            Save secrets like access tokens, API keys, and passwords to securely access credentials from your models.
          </Typography>
          <Typography variant='body2' color={color.primary}>
            Learn More
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" fontSize="15px" gap={4}>
          <Stack width="25%">
            <Typography variant='h2'>
              Name
            </Typography>
          </Stack>
          <Stack width="40%">
            <Typography variant='h2'>
              Token
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" fontSize="15px" gap={4}>
          <InputField
            width="25%"
            placeholder="e.g. hf_token"
            state={key}
            setState={(e) => setKey(e.target.value)}
          />
          <InputField
            width="40%"
            placeholder="e.g. hf_JGHufsbcusiHdhfbsiXdjcisiijcHfjb"
            state={value}
            setState={(e) => setValue(e.target.value)}
          />
          <CustomButton width="10%"
            disabled={(!key || !value) && true}
            onClick={() =>
              addKey(
                {
                  secret_key: key,
                  secret_value: value,
                  typed: false
                }
              )
            }
          >
            <Stack alignItems="center" direction="row" gap={0.5} >
              Add
            </Stack>
          </CustomButton>
        </Stack>
      </Stack>
      <Stack maxHeight="55vh">
        <SecretsList data={searchedData || []} handleDelete={handleDelete} />
      </Stack>
    </Stack>
  )
}
