import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const nodeApi = createApi({
  reducerPath: 'nodeApi',
  ...platformAuthConfig(),
  tagTypes: ['UserOnPremNodes'],
  endpoints: builder => ({
    getUserOnPremNodes: builder.query({
      query: () => '/on_prem/nodes',
      providesTags: ['UserOnPremNodes']
    }),
    addUserOnPremNode: builder.mutation({
      query: (config) => ({
        url: '/on_prem/register_node',
        method: 'POST',
        body: config
      }),
      invalidatesTags: ['UserOnPremNodes']
    }),
    updateUserOnPremNode: builder.mutation({
      query: (config) => ({
        url: '/on_prem/node',
        method: 'PUT',
        body: config
      }),
      invalidatesTags: ['UserOnPremNodes']
    }),
    deleteUserOnPremNode: builder.mutation({
      query: (nodeId) => ({
        url: `/on_prem/node/${nodeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['UserOnPremNodes']
    })
  })
})

export const {
  useGetUserOnPremNodesQuery,
  useAddUserOnPremNodeMutation,
  useUpdateUserOnPremNodeMutation,
  useDeleteUserOnPremNodeMutation,
} = nodeApi

export default nodeApi
