import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import Loader from '../Components/UiComponents/Loader'
import { ActiveJobs } from '../NewComponents/Dashboard/ActiveJobs'
import { DashboardTemplates } from '../NewComponents/Dashboard/DashboardTemplates'
import { useGetUserInferencesQuery } from '../Services/inferenceApi'
import { useGetJobsQuery } from '../Services/jobsApi'

export const Dashboard = () => {
  const platformBearer = useSelector((state) => state.userState.platformBearer);

  const { data: fetchedData, isLoading } =
    useGetJobsQuery({}, { skip: !platformBearer });

  const { data: inferenceData, isLoading: isFetchingInferenceJobs } =
    useGetUserInferencesQuery({}, { skip: !platformBearer });


  if (isLoading || isFetchingInferenceJobs) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (inferenceData && fetchedData && inferenceData?.length === 0 && fetchedData?.length === 0) ?
    <DashboardTemplates /> :
    <ActiveJobs fetchedData={fetchedData} inferenceData={inferenceData} />

}
