import { createApi } from "@reduxjs/toolkit/query/react";
import { platformAuthConfig } from "./platformAuthConfig.js";

const secretsApi = createApi({
  reducerPath: "secretsApi",
  ...platformAuthConfig(),
  tagTypes: ["UserSecrets", "UserSecret"],
  endpoints: (builder) => ({
    getSecrets: builder.query({
      query: (type) => `/secrets${type ? `?type=${type}` : ''}`,
      providesTags: ["UserSecrets"],
    }),
    getSecret: builder.query({
      query: (id) => `/secret/${id}`,
      providesTags: ["UserSecret"],
    }),
    addSecret: builder.mutation({
      query: (config) => ({
        url: "/secret",
        method: "POST",
        body: config,
      }),
      invalidatesTags: ["UserSecrets"],
    }),
    deleteSecret: builder.mutation({
      query: (secretId) => ({
        url: `/secret/${secretId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserSecrets", "UserSecret"],
    }),
  }),
});

export const {
  useGetSecretsQuery,
  useGetSecretQuery,
  useAddSecretMutation,
  useDeleteSecretMutation,
} = secretsApi;

export default secretsApi;
