import { Stack, Typography } from "@mui/material"
import { color } from "../../Styles/Color"

export const RequiredHeader = ({ children }) => {
  return (
    <Stack direction="row" gap={0.5}>
      {children}
      <Typography variant="h3" color={color.error}>*</Typography>
    </Stack>
  )
}
