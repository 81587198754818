import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { color } from '../../../Styles/Color';
import FileInput from '../../UiComponents/FileInput';


const UploadZip = () => {
  return (
    <Stack
      py={2} px={3} gap={1.5}
      bgcolor={color.secondaryBackground}
      borderRadius="8px"
      border={`1px solid ${color.borders}`}
    >
      <Typography fontWeight="600" fontSize="15px">
        Upload Zip File
      </Typography>
      <FileInput width="30%" accept=".zip">
        <InsertLinkOutlinedIcon fontSize='small' sx={{ mr: 1 }} /> Upload (.zip)
      </FileInput>
    </Stack >
  )
}

export default UploadZip