import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { FormControlLabel, Stack, Typography } from "@mui/material"
import { useLocation } from 'react-router'
import BoxSwitch from "../../Components/UiComponents/BoxSwitch"
import CustomButton from '../../Components/UiComponents/CustomButton'
import InputField from "../../Components/UiComponents/InputField"
import { SelectDropdown } from "../../Components/UiComponents/SelectDropdown"
// import { useGetModelsQuery } from '../../Services/inferenceApi'
import { RequiredHeader } from '../../Components/UiComponents/RequiredHeader'
import { PAGE_ROUTES } from '../../Configs/Routes'
import { useGetModelsQuery } from '../../Services/inferenceApi'
import { useGetFineTuneTemplatesQuery, useGetInferenceTemplatesQuery } from '../../Services/templatesApi'
import { color } from "../../Styles/Color"

export const ModelConfiguration = ({
  handleBack, handleNext, isFineTuned, setIsFineTuned, watch, setValue, errors, reset, clearErrors
}) => {

  const isInference = useLocation().pathname.includes(PAGE_ROUTES.inferences)

  const inf_type = useLocation().state?.type

  const { data: models, isLoading: isFetchingModels } = useGetModelsQuery(
    null,
    {
      skip: !isFineTuned
    }
  )

  const { data, isLoading } = useGetInferenceTemplatesQuery(
    null,
    {
      skip: !isFineTuned && !isInference
    }
  )

  const { data: finetuneData, isLoading: isFetchingFineTune } = useGetFineTuneTemplatesQuery(
    null,
    {
      skip: !isFineTuned || isInference
    }
  )

  return (
    <Stack spacing={4} width="55%">
      <Typography variant="h2">Model Configuration</Typography>
      <Stack spacing={1}>
        <RequiredHeader>
          <Typography variant="h3">Model Name</Typography>
        </RequiredHeader>
        <Typography variant="subtitle1" color={color.secondaryText}>
          Enter the name of the model as stored in your HuggingFace model repository.
        </Typography>
        <InputField
          placeholder="Your Model name here"
          state={watch("model")} setState={e => {
            setValue("model", e.target.value)
            if (!isInference) {
              setValue("autotrain_params.model", e.target.value)
            }
          }}
          error={errors.model}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h3">HuggingFace Access Token</Typography>
        <InputField
          placeholder="Enter your HuggingFace token"
          state={watch(isInference ? "hf_token" : "autotrain_params.hf_token")}
          setState={e => setValue((isInference ? "hf_token" : "autotrain_params.hf_token"), e.target.value)}
        />
      </Stack>
      <Stack spacing={2}>
        <FormControlLabel
          value={isFineTuned}
          control={
            <BoxSwitch
              onChange={(e) => {
                setIsFineTuned(e.target.checked)
                if (e.target.checked === false) {
                  setValue("base_model", null)
                  clearErrors("base_model")
                }
              }}
            />
          }
          label={
            <Typography variant="h3">
              {
                isInference ?
                  "Is it a fine-tuned model?" :
                  "Is it built on a foundational base model?"
              }
            </Typography>
          }
        />
        <Typography variant="subtitle1" color={color.secondaryText} >
          Enable this option, if the your model is
          {
            isInference ? " fine-tuned " : " built "
          }
          on top of an open-source foundational model like Llama, Mistral, Gemma, etc.
          <Typography
            variant='subtitle1'
            color={color.primary}
            sx={{
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => window.open(
              isInference ? "https://docs.scalegen.ai/inf-guide#model-configuration"
                : "https://docs.scalegen.ai/ft-guide#model-configuration",
              "_blank"
            )}
          >
            Learn more
          </Typography>
        </Typography>
        {
          isFineTuned &&
          <>
            <SelectDropdown
              value={watch("base_model")}
              handleChange={(e) => {
                if (isInference) {
                  if (inf_type === 'llm') {
                    const config = data.find(m => m.config.model === e.target.value).config
                    reset({
                      ...config,
                      model: watch("model"),
                      hf_token: watch("hf_token"),
                      name: watch("name"),
                    })
                  } else {
                    setValue('base_model', e.target.value)
                  }
                } else {
                  const config = finetuneData.find(m => m.config.model === e.target.value).config
                  reset({
                    ...config,
                    model: watch("model"),
                    job_name: watch("job_name"),
                    autotrain_params: {
                      ...config.autotrain_params,
                      hf_token: watch("autotrain_params.hf_token"),
                      model: watch("model")
                    }
                  })
                }
              }}
              options={
                isInference ?
                  inf_type === 'llm' ?
                    data?.map(d => d.config.model) :
                    models
                      ?.filter(m => m.type === (isInference ? inf_type : "llm"))
                      .map(m => m.model) :
                  finetuneData?.map(d => d.config.model)
              }
              placeholder={
                isLoading || isFetchingFineTune || isFetchingModels ?
                  "Fetching base foundational models" : "Choose base foundational model"
              }
            />
            {
              errors.base_model &&
              <Typography variant='subtitle1' color={color.error} >This field is required.</Typography>
            }
          </>
        }
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
        <Stack
          direction="row"
          gap={1}
          color={color.primary}
          alignItems="center"
          onClick={handleBack}
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
        >
          <ArrowBackIcon fontSize='small' />
          <Typography>Change Model</Typography>
        </Stack>
        <CustomButton onClick={handleNext}>Next</CustomButton>
      </Stack>
    </Stack>
  )
}
