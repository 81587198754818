import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Chip, Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { color } from '../../Styles/Color';

function createData(id, name, model, timestamp, current_price_per_hour, status, cost) {
  current_price_per_hour = Math.round(current_price_per_hour * 10) / 10;
  cost = Math.round(cost * 10) / 10
  timestamp = timestamp.split(".")[0]
  if (id.length > 25) {
    id = id.slice(0, 25) + "...";
  }
  if (name.length > 25) {
    name = name.slice(0, 25) + "...";
  }
  return { id, name, model, timestamp, current_price_per_hour, status, cost };
}

export const getStatus = (status) => {
  switch (status) {
    case "INACTIVE": {
      return <Chip
        sx={{
          bgcolor: '#D8ECFF',
          color: color.primary,
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={0.5} fontSize="14px">
            <DoneAllIcon sx={{ fontSize: "14px" }} /> Inactive
          </Stack>
        }
      />
    }

    case "FAILED": {
      return <Chip
        sx={{
          bgcolor: '#FFD2D2',
          color: "#FF6B6A",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={0.5} fontSize="14px">
            <CancelOutlinedIcon sx={{ fontSize: "14px" }} /> Failed
          </Stack>
        }
      />
    }

    case "ACTIVE": {
      return <Chip
        sx={{
          bgcolor: '#BEFFD0',
          color: "#2C974A",
          borderRadius: "8px",
        }}
        label={
          <Stack direction="row" alignItems="center" gap={0.5} fontSize="14px">
            <SettingsBackupRestoreIcon sx={{ fontSize: "14px" }} /> Active
          </Stack>
        }
      />
    }

    case "PROVISIONING": {
      return <Chip
        sx={{
          bgcolor: '#FAFFC3',
          color: "#DAC400",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={0.5} fontSize="14px">
            <RestoreIcon sx={{ fontSize: "14px" }} /> Provisioning
          </Stack>
        }
      />
    }

    case "DELETING": {
      return <Chip
        sx={{
          bgcolor: '#FFEEC3',
          color: "#FF8A00",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={0.5} fontSize="14px">
            <RadioButtonCheckedIcon sx={{ fontSize: "14px" }} /> Deleting
          </Stack>
        }
      />
    }

    case "DELETED": {
      return <Chip
        sx={{
          bgcolor: '#E4E4E4',
          color: "#ABABAB",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={0.5} fontSize="14px">
            <AutoDeleteIcon sx={{ fontSize: "14px" }} /> Deleted
          </Stack>
        }
      />
    }
    default: return <></>
  }
}

export default function InferenceList({ data, height, filter, type }) {

  const navigate = useNavigate()

  const rows = (
    data ?
      filter.length > 0 ?
        data.filter(inf => filter.includes(inf.status)) :
        data :
      []
  ).map((d) =>
    createData(d.id, d.name, d.model, d.timestamp, d.current_price_per_hour, d.status, d.cost))


  const [page, setPage] = useState(0)

  const rowsPerPage = 5

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E4E4E4",
        maxHeight: height || "70vh",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="25%">
              {rows.length} items
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="25%">Model</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="18%">Timestamp</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="12%">Cost</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow
                  key={idx}
                  hover
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    },
                    '&:hover': {
                      cursor: 'pointer',
                    }
                  }}
                  onClick={() => navigate(PAGE_ROUTES.inferences + '/' + row.id)}
                >
                  <TableCell component="th" scope="row" >
                    <Stack direction="row" gap={2} alignItems="center">
                      <ViewInArIcon fontSize='small' sx={{ color: color.primary }} />
                      <Stack spacing={0.5}>
                        <Typography variant='h3'>{row.name}</Typography>
                        <Typography variant='body2' color={color.primary}>{row.id}</Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell >{row.model}</TableCell>
                  <TableCell >{row.timestamp}</TableCell>
                  <TableCell>
                    <Stack spacing={0.5}>
                      <Typography variant='h3' color={color.primary}>$ {row.cost}</Typography>
                      <Typography variant='subtitle1' color={color.secondaryText}>
                        $ {row.current_price_per_hour} /hr
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell >{
                    getStatus(row.status)
                  }</TableCell>
                </TableRow>
              ))
              :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={0.5}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No
                  </Typography>
                  <Typography variant='body2' color={color.primary}>
                    {
                      (data && data.length > 0) ?
                        filter?.map(f => f.toLowerCase()).join(" , ") :
                        ""
                    } {type || "inference"}
                  </Typography>
                  <Typography variant='body2'>
                    deployments.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer >
  );
}