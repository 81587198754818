import { Box, Chip, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
// import { useDispatch, useSelector } from 'react-redux'
import { CUDA_VERSION } from '../../../Configs/ConfigureNewJobConstants'
// import { setCudaVersion, setGPUTypes, setNumberOfGPUs } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'
import InputField from '../../UiComponents/InputField'


export const GPUInput = ({ GPUOptions, register, watch, setValue, isFetchingGPUs }) => {

  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Typography fontWeight="600" fontSize="15px">
        GPU Types
      </Typography>
      <Select
        size='small'
        multiple
        value={watch("gpuTypes") || []}
        onChange={(e) => setValue("gpuTypes", e.target.value)}
        input={<OutlinedInput />}
        renderValue={(selected, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {
              selected?.length === 0 ?
                <Typography fontSize="15px">Choose GPU types</Typography>
                : selected?.map((value) => (
                  <Chip key={value} sx={{
                    bgcolor: color.lightBlue,
                    borderRadius: "6px",
                    fontSize: "12px",
                  }} label={value} />
                ))}
          </Box>
        )}
        displayEmpty
        sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          {isFetchingGPUs ? "...Loading" : "Choose GPU type"}
        </MenuItem>
        {
          (GPUOptions?.filter(gpu => gpu.length > 0) || []).map(
            (instance, idx) => (
              <MenuItem
                key={idx}
                value={instance}
                sx={{ fontSize: "15px" }}
              >
                {instance}
              </MenuItem>
            )
          )
        }
      </Select>
      <Typography fontWeight="600" fontSize="15px">
        Number of GPUs
      </Typography>
      <InputField
        width="70%"
        placeholder="Enter value"
        type={'number'}
        // state={numberOfGPUs}
        // setState={(e) => dispatch(setNumberOfGPUs(e.target.value))}
        register={register} field="gpusPerTrial" watch={watch}
      />
      <Typography fontWeight="600" fontSize="15px">
        CUDA Version
      </Typography>
      <Select
        value={watch("cudaVersion") || ""}
        onChange={(e) => setValue("cudaVersion", e.target.value)}
        displayEmpty
        size='small'
        sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          Choose a version
        </MenuItem>
        {
          CUDA_VERSION
            .map((version, idx) => (
              <MenuItem
                key={idx}
                value={version}
                sx={{ fontSize: "15px" }}
              >
                {version}
              </MenuItem>
            ))
        }
      </Select>
    </Stack >
  )
}
