import { Stack } from '@mui/material'
import { useState } from 'react'
import { useLocation } from 'react-router'
import { LiveLogs } from '../Components/JobViewComponents/LiveLogs'
import Loader from '../Components/UiComponents/Loader'
import NavigationTabs from '../Components/UiComponents/NavigationTabs'
import { FinetuneJobStatus } from '../NewComponents/FineTuning/FinetuneJobStatus'
import { useGetFinetuneQuery } from '../Services/fineTuneApi'
import { useGetJobQuery } from '../Services/jobsApi'
import { NotFoundErrorPage } from '../Views/NotFoundErrorPage'

export const FineTuneDetails = () => {

  const location = useLocation()

  const { data: job, isLoading } =
    useGetJobQuery(
      location.pathname.split('/').pop(),
      {
        refetchOnMountOrArgChange: true,
      }
    )

  const { data, isLoading: isFetching } = useGetFinetuneQuery(location.pathname.split('/').pop())

  const [activeTab, setActiveTab] = useState('0')

  if (isLoading || isFetching) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return job ? (
    <Stack mt={-2}>
      <NavigationTabs
        value={activeTab}
        setValue={setActiveTab}
        tabs={["Status", "Live Logs"]}
        panels={
          [
            <FinetuneJobStatus job={job} config={data} />,
            <LiveLogs job={job} />
          ]
        }
      />
    </Stack>
  ) : <NotFoundErrorPage />
}
