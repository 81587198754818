import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { ON_PREM_NODE_TYPE_MAP } from '../../Configs/OnPremNodesContants';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';

function createData(id, ip, username, isVerified, verification_message, port, gpu_type, gpu_count) {
  return { id, ip, username, isVerified, verification_message, port, gpu_type, gpu_count };
}

export default function NodesList({ data, handleDelete, type, openModal }) {

  const rows = (data || []).map((d) =>
    createData(d.id, d.ip, d.username, d.verified, d.verification_message, d.port, d.gpu_type, d.gpu_count))


  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  const [page, setPage] = useState(0)

  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "none",
        maxHeight: "40vh",
        border: "1px solid #E4E4E4"
      }}>
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Node IP</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Username</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%">Port</TableCell>
            {
              type === ON_PREM_NODE_TYPE_MAP.WORKER &&
              <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">GPU</TableCell>
            }
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%">Verified</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">
              Verification message
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {
            rows.length > 0 ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow
                  hover
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" >
                    {row.ip}
                  </TableCell>
                  <TableCell >{row.username}</TableCell>
                  <TableCell >{row.port}</TableCell>
                  {
                    type === ON_PREM_NODE_TYPE_MAP.WORKER &&
                    <TableCell >{row.gpu_type} x {row.gpu_count}</TableCell>
                  }
                  <TableCell>
                    {
                      row.isVerified ?
                        <CheckIcon sx={{ fontSize: "20px", color: "#2C974A" }} /> :
                        <ClearIcon sx={{ fontSize: "20px", color: "#FF6B6A" }} />
                    }
                  </TableCell>
                  <TableCell >{row.verification_message}</TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(row.id)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
              :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={1}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No nodes added. Please click on
                  </Typography>
                  <Typography variant='body2' color={color.primary}
                    onClick={openModal}
                    sx={{
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }}
                  >
                    Add On-Prem Node
                  </Typography>
                  <Typography variant='body2'>
                    to add on-prem nodes.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky',
          }}
        >
          <TableRow sx={{
            backgroundColor: color.secondaryBackground
          }}
          >
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onPageChange={handleChangePage}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="On-prem Node"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  );
}