import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const {
  NODE_ENV,
  REACT_APP_LOGS_URL_PROXY
} = process.env;

export const logsViewerConfig = () => {
  return {
    baseQuery: fetchBaseQuery({
      baseUrl: NODE_ENV === "development" ? "http://127.0.0.1:9000/" : REACT_APP_LOGS_URL_PROXY,
    }),
  };
};

const liveLogsApi = createApi({
  reducerPath: 'liveLogsApi',
  ...logsViewerConfig(),
  tagTypes: ['Logs'],
  endpoints: builder => ({
    getLatestLines: builder.mutation({
      query: (config) => ({
        url: `/logs/${config.job_id}/${config.trial_id}/get-last-lines/?num=50&mtime=${config.mtime}`
      }),
      providesTags: ['Logs']
    }),
    getLines: builder.mutation({
      query: (config) => ({
        url: `/logs/${config.job_id}/${config.trial_id}/get-lines?start=${config.start}&num=50`
      }),
      providesTags: ['Logs']
    })
  })
})


export const {
  useGetLatestLinesMutation,
  useGetLinesMutation
} = liveLogsApi

export default liveLogsApi
