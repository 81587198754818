// import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { PAGE_ROUTES } from '../Configs/Routes'
import {
  // SIDEBAR_AGENTIC_ITEMS, 
  SIDEBAR_ITEMS
} from '../Configs/SideNavBarConstants'
import { color } from '../Styles/Color'

export const Navbar = () => {

  const nav = useNavigate()
  const location = useLocation()
  const productLogo = process.env.REACT_APP_PRODUCT_LARGE_LOGO
  const isAdmin = useSelector((state) => state.userState.sgUser?.user_metadata?.is_admin)

  return (
    <Stack width="100%" bgcolor="#FAFAFA" height="100%" borderRight="1px solid #E4E4E4">
      <Stack
        height="8%"
        borderBottom="1px solid #E4E4E4"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: "pointer" }}
      >
        {productLogo ?
          <img
            src={productLogo}
            style={{
              maxWidth: "140px",
            }}
            alt={process.env.REACT_APP_PRODUCT_TYPE}
            onClick={() => nav(PAGE_ROUTES.dashboard)}
          />
          : <Typography
            color={color.white}
            fontSize="32px" fontWeight={800}
            onClick={() => nav(PAGE_ROUTES.dashboard)}
          >
            {process.env.REACT_APP_PRODUCT_TYPE}
          </Typography>
        }
      </Stack>
      <Stack height="92%" p={3} spacing={3} >
        {
          SIDEBAR_ITEMS.filter(item => item.label !== `${isAdmin ? "" : "Admin"}`).map((item, idx) =>
            <Stack
              alignItems="center"
              pl={1}
              sx={{ cursor: "pointer" }}
              onClick={() => nav(item.redirectURL)}
              direction="row"
              gap={2}
              key={idx}
              color={location.pathname.includes(item.redirectURL) && color.primary}
            >
              {item.icon}
              <Typography variant='h3'>
                {item.label}
              </Typography>
            </Stack>
          )
        }
        {/* <Divider />
        {
          SIDEBAR_AGENTIC_ITEMS.map((item, idx) =>
            <Stack
              direction="row"
              alignItems="end"
              justifyContent="space-between"
              borderRadius="8px"
              border={`1px solid ${location.pathname.includes(item.redirectURL) ? "#4660C8" : color.white}`}
              p={1}
              onClick={() => nav(item.redirectURL)}
              sx={{ cursor: "pointer" }}
            >
              {
                item.icon
              }
              <ArrowForwardIcon fontSize='small' sx={{ color: "#4660C8" }} />
            </Stack>
          )
        } */}
      </Stack>
    </Stack >
  )
}
