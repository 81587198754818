import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CustomButton from '../../Components/UiComponents/CustomButton';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { color } from '../../Styles/Color';

function createData(project_id, name, base_model, timestamp,) {
  timestamp = timestamp.split(".")[0]
  // if (id.length > 25) {
  //   id = id.slice(0, 25) + "...";
  // }
  if (name.length > 25) {
    name = name.slice(0, 25) + "...";
  }
  return { project_id, name, base_model, timestamp, };
}



export default function ModelList({ data }) {

  const navigate = useNavigate()

  const rows = (
    data || []
  ).map((d) =>
    createData(d.project_id, d.name, d.base_model, d.timestamp,))


  const [page, setPage] = useState(0)

  const rowsPerPage = 5

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E4E4E4",
        maxHeight: "70vh",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="30%">
              {rows.length} items
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="30%">Base Model</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="25%">Last Modified</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow
                  key={idx}
                  hover
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    },
                    '&:hover': {
                      cursor: 'pointer',
                    }
                  }}
                >
                  <TableCell component="th" scope="row" >
                    <Stack direction="row" gap={2} alignItems="center">
                      <ViewInArIcon fontSize='small' sx={{ color: color.primary }} />
                      <Stack spacing={0.5}>
                        <Typography variant='h3'>{row.name}</Typography>
                        <Typography variant='body2' color={color.primary}>{row.project_id}</Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell >{row.base_model}</TableCell>
                  <TableCell >{row.timestamp}</TableCell>
                  <TableCell>
                    <CustomButton
                      background="#4660C8"
                      onClick={() =>
                        navigate(
                          PAGE_ROUTES.agenticLaunch,
                          {
                            state: {
                              model: "TheAgenticAI"
                            }
                          }
                        )
                      }
                    >
                      Deploy
                    </CustomButton>
                  </TableCell>

                </TableRow>
              ))
              :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={0.5}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No
                  </Typography>
                  <Typography variant='body2' color="#4660C8">
                    TheAgenticAI
                  </Typography>
                  <Typography variant='body2'>
                    fine-tuned models.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer >
  );
}
