import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAddAccessKeysMutation, useDeleteAccessKeysMutation } from '../../Services/accessKeysApi';
import { color } from '../../Styles/Color';
// import CustomButton from '../UiComponents/CustomButton';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';
import { CreateKeyModal } from './CreateKeyModal';

// const dummy = {
//   "client_id": "wVHGlp3d7cKzcVOG5Dt7rL5M8x4mIxMV",
//   "client_secret": "CJ71JOf0MOY_kBmSA9Ujr7pZRQWEuthhVF82TeBji-9R1Dvg0X2SQU7aU_3VzVYc"
// }

export const AccessKeys = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [deleteKey, { isLoading }] = useDeleteAccessKeysMutation()
  const [addKey, { data: accessKey, isLoading: isAdding, isSuccess }] = useAddAccessKeysMutation()

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(true)
    }
  }, [isSuccess])

  return (
    <Stack
      borderRadius="12px"
      border={`1px solid ${color.borders}`}
      p={3}
      spacing={2}
    >
      {
        data ?
          <>
            <Stack spacing={1}>
              <Typography variant='h3'>Access Key ID</Typography>
              <InputField state={data[0].access_key_id} disabled={true} />
            </Stack>
            <Stack spacing={1}>
              <Typography variant='h3'>Access Key Secret</Typography>
              <InputField state={"*".repeat(8)} disabled={true} />
            </Stack>
          </>
          :
          <Typography variant='h3'>
            No Access Key currently present , Click below button to create one.
          </Typography>
      }
      <Stack direction="row" justifyContent="end">
        {
          data ?
            <Typography
              variant='body2'
              color={color.deleteText}
              sx={{
                pt: 1,
                pr: 1,
                "&:hover": {
                  cursor: "pointer"
                }
              }}
              onClick={() => deleteKey(data[0].access_key_id)}
            >
              {
                isLoading ?
                  "Deleting...." :
                  "Delete access key"
              }
            </Typography> :
            <CustomButton onClick={() => addKey()} height="24px">
              {
                isAdding ?
                  "Generating...." :
                  "Generate Access key"
              }
            </CustomButton>
        }
      </Stack>
      <CreateKeyModal isOpen={isOpen} handleClose={() => setIsOpen(false)} data={accessKey} />
    </Stack >
  )
}
