import { MenuItem, Select, Stack, Typography } from '@mui/material'
// import { useDispatch, useSelector } from 'react-redux'
// import { setArtifactStorageName, setFilter } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'
import InputField from '../../UiComponents/InputField'

const StorageInput = ({ artifactStoragePaths, register, setValue, watch }) => {
  // const { artifactStorageName, filter } = useSelector(store => store.newJobData)
  // const dispatch = useDispatch()

  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Typography fontWeight="600" fontSize="15px">
        Name
      </Typography>
      <Select
        displayEmpty
        size='small'
        sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
        value={watch('artifactsDestination.name') || ""}
        onChange={(e) => setValue("artifactsDestination.name", e.target.value)}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          Choose Artifact Storage
        </MenuItem>
        {
          artifactStoragePaths?.map((path, idx) => (
            <MenuItem value={path.name} key={idx} sx={{ fontSize: "15px" }}>
              {path.name}
            </MenuItem>
          ))
        }
      </Select>
      <Typography fontWeight="600" fontSize="15px">
        Filter
      </Typography>
      <InputField
        width="70%"
        placeholder="Enter filter value"
        // state={filter}
        // setState={(e) => dispatch(setFilter(e.target.value))}
        register={register}
        field="artifactsDestination.filter" required={true}
        watch={watch}
      />
    </Stack >
  )
}

export default StorageInput
