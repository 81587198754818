import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const templatesApi = createApi({
  reducerPath: 'templatesApi',
  ...platformAuthConfig(),
  tagTypes: ['InferenceTemplates', 'FinetuneTemplates'],
  refetchOnMountOrArgChange: false,
  endpoints: builder => ({
    getInferenceTemplates: builder.query({
      query: () => '/fixed_templates/inference',
      providesTags: ['InferenceTemplates']
    }),
    getFineTuneTemplates: builder.query({
      query: () => '/fixed_templates/finetune',
      providesTags: ['FinetuneTemplates']
    }),
    // getTemplates: builder.query({
    //   query: () => '/templates',
    //   providesTags: ['UserTemplates']
    // }),
    // createTemplate: builder.mutation({
    //   query: (config) => ({
    //     url: '/templates',
    //     method: 'POST',
    //     body: config
    //   }),
    //   invalidatesTags: ['UserTemplates']
    // }),
    // editTemplate: builder.mutation({
    //   query: ({ id, config }) => ({
    //     url: `/template/${id}`,
    //     method: 'PUT',
    //     body: config
    //   }),
    //   invalidatesTags: ['UserTemplates']
    // }),
    // deleteTemplate: builder.mutation({
    //   query: (id) => ({
    //     url: `/template/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['UserTemplates']
    // })
  })
})

export const {
  useGetFineTuneTemplatesQuery,
  useGetInferenceTemplatesQuery
  // useGetTemplatesQuery,
  // useCreateTemplateMutation,
  // useEditTemplateMutation,
  // useDeleteTemplateMutation
} = templatesApi

export default templatesApi
