import { ReactComponent as LATENCY_ILLUSTRATION } from "../Assets/Images/latency_illustration.svg"
import { ReactComponent as REQUEST_ILLUSTRATION } from "../Assets/Images/request_illustration.svg"
import { ReactComponent as THROUGHPUT_ILLUSTRATION } from "../Assets/Images/throughtput_illustration.svg"


export const MAIN_FEATURES = Object.freeze([
  {
    text: "Throughput relative to vllm",
    times: 4,
    determiner: "Faster",
    illustration: <THROUGHPUT_ILLUSTRATION />
  },
  {
    text: "TTFT latency relative to vllm",
    times: 23,
    determiner: "Faster",
    illustration: <LATENCY_ILLUSTRATION />
  },
  {
    text: "Request processed relative to vllm",
    times: 4,
    determiner: "More",
    illustration: <REQUEST_ILLUSTRATION />
  },
])