import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { color } from '../../Styles/Color';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function FileInput({ children, width, accept }) {
  return (
    <Button component="label" variant="contained"
      sx={{
        width: width,
        bgcolor: color.white,
        color: color.primary,
        border: `1px solid ${color.borders}`,
        boxShadow: "0px 0px 4px 0px #0000001F",
        borderRadius: "6px",
        paddingInline: 2,
        fontSize: "15px",
        "&:hover": {
          bgcolor: color.white,
        }
      }}
    >
      {children}
      < VisuallyHiddenInput type="file" accept={accept && '.zip'} />
    </Button >
  );
}