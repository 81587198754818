import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setMaximumCost, setMaximumTime } from '../../DataStore/newJobSlice';
import { color } from '../../Styles/Color';
import InputField from '../UiComponents/InputField';


const DeployComponent = ({ register, errors, setValue, watch }) => {
  // const { maximumCost } = useSelector((store) => store.newJobData)
  // const dispatch = useDispatch()

  const [maxHR, setMaxHr] = useState("")
  const [maxMin, setMaxMin] = useState("")

  useEffect(() => {
    if (maxMin.length > 0 && maxHR.length > 0) {
      setValue("maxTime", `${maxHR}:${maxMin}`)
    }
  }, [maxMin, maxHR, setValue])

  return (
    <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography fontWeight="600" fontSize="15px">
          Maximum Cost
        </Typography>
        <Tooltip title="Information">
          <InfoOutlinedIcon sx={{
            color: '#ABABAB',
            fontSize: '20px'
          }} />
        </Tooltip>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Stack
          color={color.white}
          border="1px solid #92CBFF"
          bgcolor={color.primary}
          borderRadius="8px"
          px={1.5} py={1}
          fontSize="15px"
        >
          $
        </Stack>
        <InputField width="70%" placeholder="Enter Value"
          // state={maximumCost}
          // setState={(e) => dispatch(setMaximumCost(e.target.value))}
          register={register} field="maxCost" required={true} error={errors.maxCost}
          watch={watch}
        />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography fontWeight="600" fontSize="15px">
          Maximum Time
        </Typography>
        <Tooltip title="Information">
          <InfoOutlinedIcon sx={{
            color: '#ABABAB',
            fontSize: '20px'
          }} />
        </Tooltip>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Stack
          color={color.white}
          border="1px solid #92CBFF"
          bgcolor={color.primary}
          borderRadius="8px"
          px={1.5} py={1}
          fontSize="15px"
        >
          Hr
        </Stack>
        <InputField width="20%" placeholder="Enter Value" state={maxHR}
          setState={(e) => setMaxHr(e.target.value)} />
        <Stack
          color={color.white}
          border="1px solid #92CBFF"
          bgcolor={color.primary}
          borderRadius="8px"
          px={1.5} py={1}
          fontSize="15px"
        >
          Min
        </Stack>
        <InputField width="20%" placeholder="Enter Value" state={maxMin}
          setState={(e) => {
            if (e.target.value <= 60) {
              setMaxMin(e.target.value)
            }
          }} />
      </Stack >
      {
        errors.maximumTime &&
        <Typography color={color.error} fontSize="15px">This field is required</Typography>
      }
    </Stack >
  )
}

export default DeployComponent