import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import ChooseProviderModal from '../Components/CloudProviderComponents/ChooseProviderModal';
import ProviderTable from '../Components/CloudProviderComponents/ProviderTable';
import { ScalegenCloud } from '../Components/CloudProviderComponents/ScalegenCloud';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { openCloudProviderModal } from '../DataStore/modalSlice';
import { useDeleteCloudProviderMutation, useGetUserCloudsQuery } from '../Services/cloudProviderApi';


const CloudProvidersView = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetUserCloudsQuery();
  const [handleDelete, { isLoading: isDeleting }] = useDeleteCloudProviderMutation()

  if (isLoading || isDeleting) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack
      spacing={5}
      overflow="auto"
      // sx={{
      //   '&::-webkit-scrollbar': {
      //     display: 'none',
      //   }
      // }}
      p={1}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" >
        <Typography variant='h1'>
          Cloud Providers
        </Typography>
        <CustomButton onClick={() => dispatch(openCloudProviderModal())}>
          <AddIcon fontSize='small' sx={{ mr: 1 }} />
          Add Cloud Provider
        </CustomButton>
      </Stack>
      <Stack maxHeight={"50vh"}>
        <ProviderTable data={data} handleDelete={handleDelete}
          openModal={() => dispatch(openCloudProviderModal())}
        />
      </Stack>
      <ScalegenCloud />
      <ChooseProviderModal />
    </Stack>
  )
}

export default CloudProvidersView