
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PAGE_ROUTES } from "../src/Configs/Routes";
import GlobalErrorHandler from './Errors/GlobalErrorHandler';
import ArtifactStoragesView from './Views/ArtifactStoragesView';
import CloudProvidersView from './Views/CloudProvidersView';
import CreateJobView from './Views/CreateJobView';
// import { CreateNewInferenceView } from './Views/CreateNewInferenceView';
// import { CreateNewLLMGateway } from './Views/CreateNewLLMGateway';
// import DashboardView from './Views/DashboardView';
import { FineTuningLlamaMistral } from './Views/FineTunningLlamaMistral';
import { GPUMachineDetails } from './Views/GPUMachineDetails';
import { GPUMachinesView } from './Views/GPUMachinesView';
// import { InferenceDetailView } from './Views/InferenceDetailView';
// import { InferenceView } from './Views/InferenceView';
import { JobView } from './Views/JobView';
// import { LLMGatewayDetailsView } from './Views/LLMGatewayDetailsView';
// import { LLMGatewayView } from './Views/LLMGatewayView';
import { LLMProvidersView } from './Views/LLMProvidersView';
// import LandingView from './Views/LandingView';
// import Layout from './Views/Layout';
// import { NewFineTuning } from './Views/NewFineTuning';
import { NotFoundErrorPage } from './Views/NotFoundErrorPage';
import { OnPremNodesView } from './Views/OnPremNodesView';
import { SecretsView } from './Views/SecretsView';
import SettingsView from './Views/SettingsView';
import TeamView from './Views/TeamView';
// import { TemplateJobView } from './Views/TemplateJobView';
// import { TemplatesView } from './Views/TemplatesView';
import SuccessAlert from './Components/UiComponents/SuccessAlert';
import { AdminLayout } from './Pages/AdminLayout';
import { AgenticDashboard } from './Pages/AgenticDashboard';
import { AgenticLaunch } from './Pages/AgenticLaunch';
import { Dashboard } from './Pages/Dashboard';
import { FineTuneDetails } from './Pages/FineTuneDetails';
import { FineTuning } from './Pages/FineTuning';
import { Inference } from './Pages/Inference';
import { InferenceDetails } from './Pages/InferenceDetails';
import { InferenceTemplates } from './Pages/InferenceTemplates';
import { Landing } from './Pages/Landing';
import { LaunchFineTuning } from './Pages/LaunchFineTuning';
import { LaunchInference } from './Pages/LaunchInference';
import { Layout } from './Pages/Layout';
import { RelaunchFinetune } from './Pages/RelaunchFinetune';
import { BillingView } from './Views/BillingView';
import TrainingView from './Views/TrainingView';
import VirtualMountsView from './Views/VirtualMountsView';


const App = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path={PAGE_ROUTES.login} element={<Landing />} />
                    <Route path={PAGE_ROUTES.dashboard} element={<Layout />}>
                        <Route index element={<Dashboard />} />
                        <Route path={PAGE_ROUTES.admin} element={<AdminLayout />}>
                            <Route index element={<Navigate to={PAGE_ROUTES.virtualMounts} />} />
                            <Route path={PAGE_ROUTES.onPremNodes} element={<OnPremNodesView />} />
                            <Route path={PAGE_ROUTES.cloudProviders} element={<CloudProvidersView />} />
                            <Route path={PAGE_ROUTES.virtualMounts} element={<VirtualMountsView />} />
                            <Route path={PAGE_ROUTES.artifactStorages} element={<ArtifactStoragesView />} />
                            <Route path={PAGE_ROUTES.secrets} element={<SecretsView />} />
                            <Route path={PAGE_ROUTES.team} element={<TeamView />} />
                            <Route path={PAGE_ROUTES.billing} element={<BillingView />} />
                            <Route path={PAGE_ROUTES.llmProviders} element={<LLMProvidersView />} />
                        </Route>
                        <Route path={PAGE_ROUTES.setting} element={<SettingsView />} />
                        <Route path={PAGE_ROUTES.fineTuning} >
                            <Route index element={<FineTuning />} />
                            <Route path={PAGE_ROUTES.newFineTuning} element={<LaunchFineTuning />} />
                            <Route path={PAGE_ROUTES.relaunchFinetune} element={<RelaunchFinetune />} />
                            <Route path={PAGE_ROUTES.finetunejob} element={<FineTuneDetails />} />
                        </Route>
                        <Route path={PAGE_ROUTES.training}>
                            <Route index element={<TrainingView />} />
                            <Route path={PAGE_ROUTES.createJob} element={<CreateJobView />} />
                            <Route path={PAGE_ROUTES.relaunchJob} element={<CreateJobView />} />
                            <Route
                                path={PAGE_ROUTES.finetuningLlamaMistral}
                                element={<FineTuningLlamaMistral />}
                            />
                        </Route>
                        {/* <Route path={PAGE_ROUTES.templates} element={<TemplatesView />} /> */}
                        {/* <Route path={PAGE_ROUTES.template} element={<TemplateJobView />} /> */}
                        <Route path={PAGE_ROUTES.inferences} >
                            <Route index element={<Inference />} />
                            <Route path={PAGE_ROUTES.newInference} element={<LaunchInference />} />
                            <Route path={PAGE_ROUTES.inference} element={<InferenceDetails />} />
                            <Route path={PAGE_ROUTES.inferenceTemplates} element={<InferenceTemplates />} />
                        </Route>
                        <Route path={PAGE_ROUTES.job} element={<JobView />} />
                        {/* <Route path={PAGE_ROUTES.llmGateways} element={<LLMGatewayView />} />
                        <Route path={PAGE_ROUTES.llmGateway} element={<LLMGatewayDetailsView />} />
                        <Route path={PAGE_ROUTES.newLLMGateway} element={<CreateNewLLMGateway />} /> */}
                        <Route path={PAGE_ROUTES.machines} element={<GPUMachinesView />} />
                        <Route path={PAGE_ROUTES.machine} element={<GPUMachineDetails />} />

                        <Route path={PAGE_ROUTES.agenticDashboard} >
                            <Route index element={<AgenticDashboard />} />
                            <Route path={PAGE_ROUTES.agenticLaunch} element={<AgenticLaunch />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFoundErrorPage />} />
                </Routes>
            </BrowserRouter>
            <GlobalErrorHandler />
            <SuccessAlert />
        </>
    );
}

export default App;
