import { Chip, FormControlLabel, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { SG_CLOUD_CERTIFICATIONS, SG_CLOUD_PROVIDERS, SG_CLOUD_REGIONS } from "../../Configs/CloudProviderConstants"
import { color } from "../../Styles/Color"
import BoxSwitch from "../UiComponents/BoxSwitch"
import CustomButton from "../UiComponents/CustomButton"

export const ScalegenCloud = () => {
  const [isOpen, setIsOpen] = useState(true)
  const [selectedCertification, setSelectedCertification] = useState(SG_CLOUD_CERTIFICATIONS)
  const [selectedClouds, setSelectedClouds] = useState(SG_CLOUD_PROVIDERS)
  const [selectedRegion, setSelectedRegion] = useState(SG_CLOUD_REGIONS)

  const isSelectedRegion = (region) => selectedRegion.includes(region)
  const isSelectedCertification = (cert) => selectedCertification.includes(cert)
  const isSelectedCloud = (cloud) => selectedClouds.includes(cloud)

  return (
    <Stack
      p={1}
      py={2}
      border={`1px solid ${color.borders}`}
      borderRadius="8px"
      bgcolor={!isOpen && color.primary}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        px={2}
      >
        <Typography variant="h2" color={isOpen ? color.primary : color.white}>
          ScaleGenAI Cloud
        </Typography>
        <FormControlLabel sx={{ p: 1 }}
          value={isOpen}
          control={
            <BoxSwitch
              onChange={(e) => setIsOpen(e.target.checked)}
            />
          }
        />
      </Stack>
      {
        isOpen &&
        <Stack gap={2} p={2}>
          <Typography variant="h3">
            Cloud Regions
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {
              SG_CLOUD_REGIONS.map((region) => (
                <Chip
                  key={region}
                  sx={{
                    color: isSelectedRegion(region) && color.white,
                    bgcolor: isSelectedRegion(region) ? color.primary : color.secondaryBackground,
                    border: !isSelectedRegion(region) && `2px solid ${color.borders}`,
                    borderRadius: "6px",
                    fontSize: "12px",
                    minWidth: "12%",
                    "&:hover": {
                      bgcolor: isSelectedRegion(region) ? color.primary : color.secondaryBackground,
                    }
                  }}
                  label={region}
                  clickable={true}
                  onClick={() => {
                    isSelectedRegion(region) ?
                      setSelectedRegion(selectedRegion.filter(r => r !== region)) :
                      setSelectedRegion(prev => [...prev, region])
                  }}
                />
              ))
            }
          </Stack>
          <Typography variant="h3">
            Certifications
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {
              SG_CLOUD_CERTIFICATIONS.map((cert) => (
                <Chip
                  key={cert}
                  sx={{
                    color: isSelectedCertification(cert) && color.white,
                    bgcolor: isSelectedCertification(cert) ? color.primary : color.secondaryBackground,
                    border: !isSelectedCertification(cert) && `2px solid ${color.borders}`,
                    borderRadius: "6px",
                    fontSize: "12px",
                    minWidth: "12%",
                    "&:hover": {
                      bgcolor: isSelectedCertification(cert) ? color.primary : color.secondaryBackground,
                    }
                  }}
                  label={cert}
                  clickable={true}
                  onClick={() => {
                    isSelectedCertification(cert) ?
                      setSelectedCertification(selectedCertification.filter(r => r !== cert)) :
                      setSelectedCertification(prev => [...prev, cert])
                  }}
                />
              ))
            }
          </Stack>
          <Typography variant="h3">
            Cloud Providers
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {
              SG_CLOUD_PROVIDERS.map((cp) => (
                <Chip
                  key={cp}
                  sx={{
                    color: isSelectedCloud(cp) && color.white,
                    bgcolor: isSelectedCloud(cp) ? color.primary : color.secondaryBackground,
                    border: !isSelectedCloud(cp) && `2px solid ${color.borders}`,
                    borderRadius: "6px",
                    fontSize: "12px",
                    minWidth: "12%",
                    "&:hover": {
                      bgcolor: isSelectedCloud(cp) ? color.primary : color.secondaryBackground,
                    }
                  }}
                  label={cp}
                  clickable={true}
                  onClick={() => {
                    isSelectedCloud(cp) ?
                      setSelectedClouds(selectedClouds.filter(r => r !== cp)) :
                      setSelectedClouds(prev => [...prev, cp])
                  }}
                />
              ))
            }
          </Stack>
          <Stack justifyContent="end" direction="row">
            <CustomButton
              onClick={() => { }}
              width="15%"
            >
              Save
            </CustomButton>
          </Stack>
        </Stack>
      }
    </Stack >
  )
}
