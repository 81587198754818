import { Divider, Stack, Typography } from '@mui/material'
import { VM_STORAGE_TYPE_OPTIONS, VM_STORAGE_TYPE_OPTIONS_CREDENTIALS } from '../../Configs/VirtualMountConstants'
import { color } from '../../Styles/Color'
import { AdvancedVM } from '../TrainingViewComponents/DataComponents/AdvancedVM'
import InputField from '../UiComponents/InputField'

const VMDetailsForm = ({
  formValues,
  setFormValues,
  storageType
}) => {
  const { name, icon } = VM_STORAGE_TYPE_OPTIONS.find(s => s.name === storageType)

  const getByPlaceholderText = () => {
    switch (name) {
      case "S3": return "s3://"
      case "S3 Like": return "s3://"
      case "Azure Blob Storage": return "az://"
      case "Google Cloud": return "gs://"
      case "SFTP": return "sftp://"
      default: return ""
    }
  }

  return (
    <Stack spacing={3} py={1.5}>
      <Stack spacing={1}>
        <Typography variant='h3'>
          Storage Type
        </Typography>
        <Stack direction="row" gap={1.5} alignItems="center">
          <Stack
            width="40px"
            height="40px"
            justifyContent="center"
            alignItems="center"
            border="1px solid"
            borderColor={color.primary}
            borderRadius="8px"
            bgcolor={color.lightBlue}
            sx={{
              cursor: "pointer",
            }}
          >
            {icon("20px")}
          </Stack>
          <Typography
            variant='h3'
            color={color.primary}
          >
            {name}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1} >
        <Typography variant='h3'>Virtual Mount Name</Typography>
        <InputField state={formValues.name}
          setState={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))}
        />
      </Stack>
      <Stack gap={1} >
        <Typography variant='h3'>Source</Typography>
        <InputField state={formValues.src}
          placeholder={getByPlaceholderText()}
          setState={(e) => setFormValues((prev) => ({ ...prev, src: e.target.value }))}
        />
      </Stack>
      <Stack gap={1} >
        <Typography variant='h3'>Destination</Typography>
        <InputField state={formValues.dest}
          setState={(e) => setFormValues((prev) => ({ ...prev, dest: e.target.value }))}
        />
      </Stack>
      <Divider />
      {
        VM_STORAGE_TYPE_OPTIONS_CREDENTIALS[storageType] &&
        <Stack spacing={3}>
          <Typography variant='h3' color={color.primary}>Storage Credentials</Typography>
          {
            VM_STORAGE_TYPE_OPTIONS_CREDENTIALS[storageType].map((creds, idx) => {
              return <Stack spacing={1} key={idx}>
                <Typography variant='h3'>{creds.label}</Typography>
                <InputField
                  state={formValues.credentials[creds.formKey]}
                  setState={(e) => setFormValues(
                    {
                      ...formValues,
                      credentials: {
                        ...formValues.credentials,
                        [creds.formKey]: e.target.value
                      }
                    })}
                  placeholder={creds.placeholder}
                />
              </Stack>
            })
          }
        </Stack>
      }
      <AdvancedVM setNewVm={setFormValues} newVm={formValues} />
    </Stack >
  )
}

export default VMDetailsForm