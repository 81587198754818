import { Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice'
import { getArtifact } from '../../Services/artifactsApi'
import { color } from '../../Styles/Color'
import CustomButton from '../UiComponents/CustomButton'
import Loader from '../UiComponents/Loader'

export const ArtifactLogViewer = ({ job_id, trial_id, artifacts_destination, job }) => {

  const platformBearer = useSelector((state) => state.userState.platformBearer)
  const scrollRef = useRef(null);

  const dispatch = useDispatch()

  const [logFile, setLogFile] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const link = document.createElement('a');

  useEffect(() => {
    if (job_id && trial_id && artifacts_destination) {
      let promise = [];
      promise.push(
        getArtifact(artifacts_destination + '/' + job_id + '/' + trial_id + '/stdout.log', platformBearer)
      );
      if (promise.length === 0) {
        return;
      }

      Promise.all(promise)
        .then(results => {
          const response = results[0];
          if (response?.error) {
            setIsLoading(false);
          } else {
            const blob = response.blob;
            const reader = new FileReader();
            reader.onload = () => {
              const textContent = reader.result;
              setLogFile(textContent);
              setIsLoading(false);
            };
            reader.readAsText(blob);

            link.href = response.href;
            link.setAttribute(
              'download',
              response.fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);
          }
        })
        .catch(error => {
          setIsLoading(false);
          dispatch(setIsError(true))
          dispatch(setErrorMessage("Something went wrong"))
        });
    }
  }, [job_id, trial_id, artifacts_destination, platformBearer, isLoading, link, dispatch]);

  useEffect(() => {
    if (logFile) {
      setIsLoading(false);
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [logFile])

  const handleClick = () => {
    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }

  if (isLoading) {
    return <Stack height="30vh">
      <Loader />
    </Stack>
  }

  if (logFile.length === 0) {
    if (job.status === "QUEUED") {
      return <Typography color={color.primary} variant='h2'>
        Logs will be visible after the job starts.
      </Typography>
    }
    return <Typography color={color.primary} variant='h2'>
      Can not Retrieve Logs , Please check again later.
    </Typography>
  }

  return (
    <Stack spacing={2}>
      {
        (job.status === "STOPPED" || job.status === "FAILED" || job.status === "STOPPING") &&
        <Stack spacing={2} width="15%">
          <CustomButton onClick={handleClick}>
            Download Logs File
          </CustomButton>
        </Stack>
      }
      <Stack
        px={2}
        bgcolor={color.secondaryBackground}
        borderRadius="8px"
        height="55vh"
        overflow="auto"
        border={`1px solid ${color.borders}`}
        fontFamily="BDOGroteskRegular"
        fontSize="14px"
        fontWeight={400}
        lineHeight="19.6px"
        sx={
          {
            '&::-webkit-scrollbar': {
              display: 'none',
            }
          }
        }
      >
        <p dangerouslySetInnerHTML={{ __html: logFile.replace(/(?:\r\n|\r|\n)/g, "<br />") }} />
        <p ref={scrollRef}></p>
      </Stack >
    </Stack>
  )
}
