import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { ADDITIONAL_REQUIREMENT_OPTIONS, UPLOAD_OPTIONS } from '../../../Configs/NewJobConstants';
// import { setEntryPointFileName } from '../../../DataStore/newJobSlice';
import { color } from '../../../Styles/Color';
import CustomToggleButton from "../../UiComponents/CustomToggleButton";
import InputField from "../../UiComponents/InputField";
import CodeFromRepository from './CodeFromRepository';
import DockerImageInput from './DockerImageInput';
import RequirementsFileInput from './RequirementsFileInput';
import UploadZip from './UploadZip';

const SourceCodeComponent = ({ register, errors, watch, setValue }) => {

  const [uploadType, setUploadType] = useState(UPLOAD_OPTIONS[0])
  const [requirementsType, selectedRequirementsType] = useState(ADDITIONAL_REQUIREMENT_OPTIONS[0])
  // const { entryPointFileName } = useSelector((store) => store.newJobData)
  // const dispatch = useDispatch()

  useEffect(() => {
    if (requirementsType === ADDITIONAL_REQUIREMENT_OPTIONS[0]) {
      setValue("customImage", null)
    } else {
      setValue("requirements", null)
    }
  }, [requirementsType, setValue])

  return (
    <Stack py={1} gap={3}
      overflow="auto"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        }
      }}
    >
      <Typography fontWeight="600">Source Code</Typography>
      <Stack
        py={2} px={3} gap={1.5}
        bgcolor={color.secondaryBackground}
        borderRadius="8px"
        border={`1px solid ${color.borders}`}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography fontWeight="600" fontSize="15px">
            Entry Point File Name
          </Typography>
          <Tooltip title="Information">
            <InfoOutlinedIcon sx={{
              color: '#ABABAB',
              fontSize: '20px'
            }} />
          </Tooltip>
        </Stack>
        <InputField placeholder="Enter Value"
          //state={entryPointFileName}
          //setState={(e) => dispatch(setEntryPointFileName(e.target.value))}
          register={register} field="entrypoint" error={errors?.entrypoint} required={true}
          watch={watch}
        />
      </Stack>


      <Stack gap={1}>
        <CustomToggleButton options={UPLOAD_OPTIONS} selected={uploadType} setSelected={setUploadType} />
        {
          uploadType === UPLOAD_OPTIONS[0] ?
            <CodeFromRepository watch={watch} register={register} setValue={setValue} errors={errors} /> :
            <UploadZip />
        }
        {
          ((errors?.repoHost || errors?.repo) || (errors?.zipFile)) &&
          <Typography color={color.error} fontSize="15px">This section is required</Typography>
        }
      </Stack>

      <Stack gap={1}>
        <CustomToggleButton
          options={ADDITIONAL_REQUIREMENT_OPTIONS}
          selected={requirementsType} setSelected={selectedRequirementsType}
        />
        {
          requirementsType === ADDITIONAL_REQUIREMENT_OPTIONS[0] ?
            <RequirementsFileInput register={register} watch={watch} />
            :
            <DockerImageInput register={register} watch={watch} />
        }
        {
          ((errors?.customImage?.image || errors?.customImage?.pythonPath) || (errors?.requirements)) &&
          <Typography color={color.error} fontSize="15px">This section is required</Typography>
        }
      </Stack>
    </Stack >
  )
}

export default SourceCodeComponent