import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const {
    // NODE_ENV,
    REACT_APP_BASE_API_URL,
} = process.env;


export const platformAuthConfig = () => {
    return {
        baseQuery: fetchBaseQuery({
            baseUrl:
                REACT_APP_BASE_API_URL,
            prepareHeaders: (headers, { getState }) => {
                headers.set("Content-Type", "application/json");
                headers.set("authorization", `Bearer ${getState().userState.platformBearer}`);
                return headers;
            }
        }),
        keepUnusedDataFor: 20,
        refetchOnMountOrArgChange: true,
    };
};

export default platformAuthConfig;
