import { Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useGetArtifactStoragePathsQuery } from '../../Services/artifactStorageApi'
import { useGetTrialsQuery } from '../../Services/trialsApi'
import { color } from '../../Styles/Color'
import Loader from '../UiComponents/Loader'
import { ArtifactLogViewer } from './ArtifactLogViewer'
import { LatestLogViewer } from './LatestLogViewer'

export const LiveLogs = ({ job }) => {
  const location = useLocation()

  const [artifactsDestinationPath, setArtifactsDestinationPath] = useState(null);

  const { data, isLoading, isSuccess } =
    useGetTrialsQuery(location.pathname.split('/').pop())

  const {
    data: artifactStoragePaths
  } = useGetArtifactStoragePathsQuery()

  const trialRows = useMemo(() => {
    if (isSuccess && Array.isArray(data)) {
      const filteredData = data
        .filter(
          (item) =>
            item.is_dapp_trial === false || item.is_dapp_trial === undefined || item.is_dapp_trial === null
        )
      return filteredData.map((item) => ({
        id: item.trial_id,
        ...item
      }))
    }

    return []
  }, [isSuccess, data])


  useEffect(() => {
    artifactStoragePaths?.forEach((item) => {
      if (job?.spec.config.artifactsDestination.name === item.name) {
        setArtifactsDestinationPath(item.path);
      }
    })
  }, [artifactStoragePaths, job?.spec.config.artifactsDestination.name])


  if (isLoading) {
    return <Stack height="70vh"><Loader /></Stack>
  }

  return (
    <Stack py={1}>
      {!!data ?
        (trialRows.map((trial, index) => (
          <Stack key={index} minHeight="30vh" spacing={2}>
            <Typography variant='h2'>Trial ID - {trial.id}</Typography>
            {
              (job.status === "DAPP_RUNNING" || job.status === "RUNNING") && trial.status === "RUNNING" &&
              <LatestLogViewer job_id={job.id} trial_id={trial.id} />
            }
            {
              (job.status === "DAPP_RUNNING" || job.status === "RUNNING") && trial.status !== "RUNNING" &&
              <Typography color={color.primary} variant='h2'>
                Live logs are not available at the moment. Please check again later.
              </Typography>
            }
            {
              !(job.status === "DAPP_RUNNING" || job.status === "RUNNING") && artifactsDestinationPath &&
              <ArtifactLogViewer job_id={job.id} trial_id={trial.id}
                artifacts_destination={artifactsDestinationPath} job={job}
              />
            }
          </Stack>
        ))) :
        <Typography color={color.primary} variant='h2'>
          Can not Retrieve Logs , Please check again later.
        </Typography>
      }
    </Stack>
  )
}
