import { Box, Chip, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
// import { useDispatch, useSelector } from 'react-redux'
import { CUDA_VERSION } from '../../../Configs/ConfigureNewJobConstants'
// import { setCudaVersion, setInstanceTypes } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'


export const InstanceTypeInput = ({ InstanceOptions, watch, setValue, isFetchingInstances }) => {
  // const dispatch = useDispatch()
  // const { instanceTypes, cudaVersion } = useSelector(store => store.newJobData)

  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Typography fontWeight="600" fontSize="15px">
        Instance Types
      </Typography>
      <Select
        size='small'
        displayEmpty
        multiple
        value={watch("instanceTypes") || []}
        onChange={(e) => setValue("instanceTypes", e.target.value)}
        input={<OutlinedInput />}
        renderValue={(selected, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {
              selected?.length === 0 ?
                <Typography fontSize="15px">Choose Instance Types</Typography>
                : selected?.map((value) => (
                  <Chip key={value} sx={{
                    bgcolor: color.lightBlue,
                    borderRadius: "6px",
                    fontSize: "12px",
                  }} label={value} />
                ))}
          </Box>
        )}
        sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          {isFetchingInstances ? "...Loading" : "Choose Instance type"}
        </MenuItem>
        {
          InstanceOptions?.map((instance, idx) => (
            <MenuItem
              key={idx}
              value={instance}
              sx={{ fontSize: "15px" }}
            >
              {instance}
            </MenuItem>
          ))}
      </Select>
      <Typography fontWeight="600" fontSize="15px">
        CUDA Version
      </Typography>
      <Select
        value={watch("cudaVersion") || ""}
        onChange={(e) => setValue("cudaVersion", e.target.value)}
        displayEmpty
        size='small'
        sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          Choose a version
        </MenuItem>
        {
          CUDA_VERSION
            .map((version, idx) => (
              <MenuItem
                key={idx}
                value={version}
                sx={{ fontSize: "15px" }}
              >
                {version}
              </MenuItem>
            ))
        }
      </Select>
    </Stack >
  )
}
