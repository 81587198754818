import ClearIcon from '@mui/icons-material/Clear';
import DvrIcon from '@mui/icons-material/Dvr';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Slide, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useAddUserOnPremNodeMutation } from '../../Services/nodeApi';
import { color } from '../../Styles/Color';
import BoxSwitch from '../UiComponents/BoxSwitch';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';
import Loader from '../UiComponents/Loader';
import SecondaryButton from '../UiComponents/SecondaryButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNodeModal = ({ isOpen, setIsOpen }) => {
  const initialFormState = Object.freeze({
    ip: "",
    port: "",
    username: "root",
    ssh_private_key: "",
    role: ""
  })

  const [formValues, setFormValues] = useState(initialFormState)

  const [handleSubmit, { isLoading, isSuccess }] = useAddUserOnPremNodeMutation();

  const handleClose = useCallback(() => {
    setFormValues(initialFormState)
    setIsOpen(false)
  }, [initialFormState, setIsOpen])

  useEffect(() => {
    if (isSuccess) {
      setFormValues(initialFormState)
      handleClose()
    }
  }, [isSuccess, handleClose, initialFormState])

  const handleAdd = () => {
    const encodedFormValues = {
      ...formValues,
      ssh_private_key: btoa(formValues.ssh_private_key)
    }
    handleSubmit(encodedFormValues)
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              padding={1}
              borderRadius="8px"
              bgcolor={color.primary}
              color={color.white}
            >
              <DvrIcon fontSize='small' />
            </Stack>
            <Typography variant='h2' color={color.primary}>Add On-Prem Nodes</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "50vh",
          width: "40vw",
          p: 2,
          '&::-webkit-scrollbar': {
            display: 'none',
          }
        }}
      >
        {
          isLoading ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            <Stack gap={3} py={1.5}>
              <Stack gap={1} >
                <Typography variant='h3'>Node IP</Typography>
                <Typography variant='subtitle1' color={color.secondaryText}>
                  Enter IP/DNS adddress of the on-prem node
                </Typography>
                <InputField state={formValues.ip}
                  setState={(e) => setFormValues((prev) => ({ ...prev, ip: e.target.value }))}
                />
              </Stack>
              <Stack gap={1} >
                <Typography variant='h3'>Port</Typography>
                <Typography variant='subtitle1' color={color.secondaryText}>
                  Enter IP/DNS adddress of the on-prem node
                </Typography>
                <InputField state={formValues.port}
                  setState={(e) => setFormValues((prev) => ({ ...prev, port: e.target.value }))}
                />
              </Stack>
              <Stack gap={1} >
                <Typography variant='h3'>Username</Typography>
                <InputField state={formValues.username}
                  setState={(e) => setFormValues((prev) => ({ ...prev, username: e.target.value }))}
                />
              </Stack>
              <Stack gap={1} >
                <Typography variant='h3'>SSH Private Key</Typography>
                <Typography variant='subtitle1' color={color.secondaryText}>
                  Enter the SSH key for the GPU node
                </Typography>
                <InputField state={formValues.ssh_private_key}
                  setState={(e) => setFormValues((prev) => ({ ...prev, ssh_private_key: e.target.value }))}
                />
              </Stack>
              <FormControlLabel sx={{ px: 2 }}
                control={
                  <BoxSwitch
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setFormValues((prev) => ({ ...prev, role: "INFERENCE_CONTROLLER" }))
                      } else {
                        setFormValues((prev) => ({ ...prev, role: "INFERENCE_WORKER" }))
                      }
                    }
                    }
                  />
                }
                label={<Typography variant='h3'>Controller Only</Typography>}
              />
            </Stack>
        }
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <CustomButton
              onClick={handleAdd}
              width="10%"
            >
              {"Add"}
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default AddNodeModal