import EditIcon from '@mui/icons-material/Edit';
import { Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router";
import Loader from '../Components/UiComponents/Loader';
import { PAGE_ROUTES } from '../Configs/Routes';
import { setErrorMessage, setIsError } from '../DataStore/errorSlice';
import { InferenceTemplateForm } from '../NewComponents/Inference/InferenceTemplateForm';
import { useCreateInferenceMutation } from '../Services/inferenceApi';
import { color } from '../Styles/Color';
import { getTrimmedData } from '../Utils/trimmer';

export const InferenceTemplates = () => {

  const model = useLocation().state.model
  const config = useLocation().state.config

  const dispatch = useDispatch()

  const [submit, { isLoading: isSubmitting, isSuccess }] = useCreateInferenceMutation()

  const { formState: { errors }, watch, setValue, handleSubmit, setError } =
    useForm({
      defaultValues: config
    })

  const nav = useNavigate()

  const handleBack = () => {
    nav(-1)
  }

  const handleValidation = () => {
    let isNameValid = watch('name').length > 0

    if (!isNameValid) {
      dispatch(setIsError(true))
      dispatch(setErrorMessage("Deploy Name is required"))
      setError(
        "name",
        { type: 'custom', message: 'Deploy name is required' }
      )
    } else {
      const config = getTrimmedData(watch())
      handleSubmit(submit(config))
    }
  }

  if (isSubmitting) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  if (isSuccess) {
    nav(PAGE_ROUTES.inferences)
  }

  return (
    <Stack spacing={5}>
      <Stack direction="row" gap={2} alignItems={errors?.name ? "start" : "end"}>
        <Typography variant="h1">Deploy </Typography>
        <TextField
          size='small'
          variant="standard"
          placeholder='Enter deployment name'
          sx={{
            "& .MuiInput-root:before": {
              border: 0
            },
            "& .MuiInput-root:after": {
              border: 0
            },
            "& .MuiInput-input:hover": {
              border: 0
            }
          }}
          InputProps={{
            style: {
              fontSize: "14px",
              borderRadius: "8px",
              color: color.primary,
            },
          }}
          value={watch('name')}
          onChange={e => setValue('name', e.target.value)}
          // {...register("name", { required: { value: true, message: "This field is required" } })}
          error={errors?.name ? true : false}
          helperText={errors?.name?.message}
        />
        <EditIcon sx={{ color: color.primary, fontSize: "16px" }} />
      </Stack>
      <InferenceTemplateForm
        model={model}
        handleBack={handleBack}
        backTitle="Go back"
        setValue={setValue}
        watch={watch}
        handleSubmit={handleValidation}
      />
    </Stack>
  )
}
