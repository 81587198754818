import { Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useGetArtifactStoragePathsQuery } from '../../../Services/artifactStorageApi'
import { useGetVirtualMountsQuery } from "../../../Services/virtualMountsApi"
import { color } from '../../../Styles/Color'
import Loader from "../../UiComponents/Loader"
import StorageInput from './StorageInput'
import VMData from './VMData'

const DataComponent = ({ register, errors, watch, setValue }) => {

  const { data: userVMs, isLoading: isFetchingVMs } = useGetVirtualMountsQuery()
  const { data: artifactStoragePaths, isLoading: isFetchingPaths } = useGetArtifactStoragePathsQuery()

  useEffect(() => {
    if (artifactStoragePaths && artifactStoragePaths?.length > 0) {
      setValue("artifactsDestination.name", artifactStoragePaths[0]?.name)
    }
  }, [artifactStoragePaths, setValue])

  if (isFetchingPaths && isFetchingVMs) {
    return <Stack height="40vh" >
      <Loader />
    </Stack>

  }

  return (
    <Stack
      gap={3}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
      <Typography fontWeight="600">Data Sources</Typography>
      <VMData userVms={userVMs} watch={watch} setValue={setValue} />
      {
        errors.virtualMounts &&
        <Typography color={color.error} fontSize="15px">This section is required</Typography>
      }
      <Typography fontWeight="600">Artifact Storage</Typography>
      <StorageInput artifactStoragePaths={artifactStoragePaths} register={register} setValue={setValue}
        watch={watch} />
      {
        (errors?.artifactDestination?.name || errors?.artifactDestination?.filter) &&
        <Typography color={color.error} fontSize="15px">This section is required</Typography>
      }
    </Stack>
  )
}

export default DataComponent