import { ReactComponent as GoogleLogo } from "../Assets/Images/google_icon.svg"
import { ReactComponent as MetaLogo } from "../Assets/Images/meta-icon.svg"
import { ReactComponent as MicrosoftLogo } from "../Assets/Images/Microsoft_icon.svg"
import { ReactComponent as MistralLogo } from "../Assets/Images/mistral-ai-icon.svg"
import { ReactComponent as QwenLogo } from "../Assets/Images/qwen-logo.svg"

export const MODEL_PROVIDERS = Object.freeze({
  "meta-llama": {
    name: "Meta",
    icon: <MetaLogo
      height="75%"
      style={{
        filter: "blur(6px)",
        transform: "translate(25%)"
      }}
    />
  },
  "mistralai": {
    name: "Mistral AI",
    icon: <MistralLogo
      height="75%"
      style={{
        filter: "blur(6px)",
        transform: "translate(35%)"
      }}
    />
  },
  "microsoft": {
    name: "Microsoft",
    icon: <MicrosoftLogo
      height="75%"
      style={{
        filter: "blur(6px)",
        transform: "translate(47%)"
      }}
    />
  },
  "google": {
    name: "Google",
    icon: <GoogleLogo
      height="100%"
      style={{
        filter: "blur(6px)",
        transform: "translate(30%)"
      }}
    />
  },
  "Qwen": {
    name: "Qwen",
    icon: <QwenLogo
      height="80%"
      width="40%"
      style={{
        filter: "blur(6px)",
        transform: "translate(25%)"
      }}
    />
  }
})

export const INFERENCE_TEMPLATES_OPTIONS = Object.freeze([
  {
    inf_type: 'llm',
    model: "Llama 3.1 8B",
    provider: 'Meta',
    model_type: "Chat",
    gpu: "A100",
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #2E87D9)',
    icon: <QwenLogo
      height="80%"
      width="40%"
      style={{
        filter: "blur(6px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Llama 3.1 70B Instruct",
    provider: 'Meta',
    model_type: "Instruct",
    gpu: "H100",
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #2E87D9)',
    icon: <GoogleLogo
      height="100%"
      style={{
        filter: "blur(6px)",
        transform: "translate(30%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Mistral 7B Instruct",
    provider: 'Mistral AI',
    model_type: "Chat",
    gpu: "H100",
    // color: 'linear-gradient(76.86deg, #FFFFFF 60% , #FEDB6A 78.15%, #FF8A58)',
    icon: <MistralLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(35%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Phi 3.5 mini instruct",
    provider: 'Microsoft',
    model_type: "Chat",
    gpu: "A100",
    // color: 'linear-gradient(76.86deg, #FFFFFF 60%, #59FFB4 78.15%, #2ED9B4)',
    icon: <MicrosoftLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(47%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Llama 3.1 70B Instruct",
    provider: 'Meta',
    model_type: "Instruct",
    gpu: "H100",
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #2E87D9)',
    icon: <MetaLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Mistral 7B Instruct",
    provider: 'Mistral AI',
    model_type: "Chat",
    gpu: "H100",
    // color: 'linear-gradient(76.86deg, #FFFFFF 60% , #FEDB6A 78.15%, #FF8A58)',
    icon: <MistralLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(35%)"
      }}
    />,
  },
])

export const DASHBOARD_INFERENCE_TEMPLATE = Object.freeze([
  {
    inf_type: 'llm',
    model: "Llama 3.1 8B",
    provider: 'Meta',
    model_type: "Chat",
    gpu: "A100",
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #2E87D9)',
    icon: <MetaLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Llama 3.1 70B",
    provider: 'Meta',
    model_type: "Chat",
    gpu: "A100",
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #2E87D9)',
    icon: <MetaLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Mistral 7B Instruct",
    provider: 'Mistral AI',
    model_type: "Chat",
    gpu: "H100",
    // color: 'linear-gradient(76.86deg, #FFFFFF 60% , #FEDB6A 78.15%, #FF8A58)',
    icon: <MistralLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(35%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Phi 3.5 mini instruct",
    provider: 'Microsoft',
    model_type: "Chat",
    gpu: "A100",
    // color: 'linear-gradient(76.86deg, #FFFFFF 60%, #59FFB4 78.15%, #2ED9B4)',
    icon: <MicrosoftLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(47%)"
      }}
    />,
  },
])

export const DASHBOARD_CLI_TEMPLATE = Object.freeze([
  {
    inf_type: 'llm',
    model: "Llama 3.1 8B",
    provider: 'Meta',
    model_type: "Chat",
    gpu: "A100",
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #E4E4E4)',
    icon: <MetaLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Llama 3.1 70B",
    provider: 'Meta',
    model_type: "Chat",
    gpu: "A100_80GB",
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #E4E4E4)',
    icon: <MetaLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(25%)"
      }}
    />,
  },
])