export const getTrimmedData = obj => {
  if (obj && typeof obj === "object") {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === "object") {
        getTrimmedData(obj[key]);
      } else if (typeof obj[key] === "string") {
        if (obj[key].length > 0) {
          obj[key] = obj[key].trim()
        } else {
          obj[key] = null
        }
      }
    });
  }
  return obj;
};