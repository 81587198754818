import { createApi } from '@reduxjs/toolkit/query/react';
import { platformAuthConfig } from './platformAuthConfig.js';

const inferenceApi = createApi({
  reducerPath: 'inferenceApi',
  ...platformAuthConfig(),
  tagTypes: ['Inference', 'Price'],
  endpoints: (builder) => ({
    createInference: builder.mutation({
      query: (config) => ({
        url: "/sg_inf/create",
        method: "POST",
        body: config,
      }),
      invalidatesTags: ["Inference"],
    }),
    updateInference: builder.mutation({
      query: (config) => {
        return ({
          url: `/sg_inf/${config.id}`,
          method: "PUT",
          body: config.config,
        })
      },
      invalidatesTags: ["Inference"],
    }),
    getUserInferences: builder.query({
      query: () => "/sg_inf/list",
      providesTags: ["Inference"],
    }),
    getInference: builder.query({
      query: (id) => "/sg_inf/" + id,
      providesTags: ["Inference"],
    }),
    deleteInference: builder.mutation({
      query: (id) => ({
        url: `/sg_inf/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Inference"]
    }),
    getGPUNodes: builder.query({
      query: (id) => `/sg_inf/${id}/gpu_nodes`,
      invalidatesTags: ["Inference"]
    }),
    getModels: builder.query({
      query: (id) => "/sg_inf/supported_models",
      invalidatesTags: ["Inference"]
    }),
    scaleInference: builder.mutation({
      query: ({ id, direction }) => {
        return (
          {
            url: `/sg_inf/${id}/scale/${direction}`,
            method: 'POST'
          }
        )
      },
      invalidatesTags: ["Inference"]
    }),
    getPriceEstimation: builder.mutation({
      query: (config) => ({
        url: `/sg_inf/price_estimation`,
        method: 'POST',
        body: config
      }),
      invalidatesTags: ["Price"]
    })
  }),
})

export const {
  useCreateInferenceMutation,
  useUpdateInferenceMutation,
  useGetUserInferencesQuery,
  useGetInferenceQuery,
  useDeleteInferenceMutation,
  useGetGPUNodesQuery,
  useGetModelsQuery,
  useScaleInferenceMutation,
  useGetPriceEstimationMutation
} = inferenceApi;

export default inferenceApi