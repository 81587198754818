
export const MAIN_NAVIGATION_TABS = Object.freeze(["Congiguration", "Environment Configuration"])

export const CONFIGURATION_TYPE_OPTIONS = Object.freeze([
  "GPU",
  "CPU",
  "ADT"
])

export const GPU_CONFIG_TABS = Object.freeze(["GPUs", "Instance Types"])

export const CUDA_VERSION = Object.freeze(["12.0", "11.8", "11.7", "11.6", "11.5", "11.4", "11.3", "11.2"])

export const PYTHON_VERSION = Object.freeze([
  "3.8",
  "3.9",
  "3.10"
])

export const FINE_TUNE_DATASET_OPTIONS = Object.freeze(["HuggingFace", "Virtual Mount"])

export const FINE_TUNE_API_KEY_OPTIONS = Object.freeze(["WANDB", "COMETML"])