import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { useGetArtifactStoragePathsQuery } from "../../Services/artifactStorageApi";
// import { useGetVirtualMountsQuery } from "../../Services/virtualMountsApi";
import { color } from "../../Styles/Color";
import Loader from "../UiComponents/Loader";
import { AdvancedMachineConfig } from "./AdvancedMachineConfig";

export const LaunchGPU = ({
  selected, gpuStore, setGPUStore, dockerConfig, setDockerConfig, isOpen, setIsOpen
  // gpuDataset, setGPUDataset , gpuRegion, setGPURegion,
}) => {

  // const { data: vmList, isLoading: isFetchingVM } = useGetVirtualMountsQuery()
  const { data: storeList, isLoading: isFetchingStore } = useGetArtifactStoragePathsQuery();


  if (isFetchingStore
    //  || isFetchingVM
  ) {
    return <Stack height="50vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack
      p={1}
      spacing={2}
      height="50vh"
    >
      <Typography fontFamily="IBMPlexSansSemiBold">
        Review and Launch GPU
      </Typography>
      <Stack spacing={1} px={1}>
        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
          <Typography fontSize="15px">GPU type x GPU count</Typography>
          <Typography color={color.primary} fontSize="15px">
            {selected.gpu_type} x {selected.gpu_count}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
          <Typography fontSize="15px">Price/Hr</Typography>
          <Typography color={color.primary} fontSize="15px">
            {Math.round(selected.on_demand * 100) / 100}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
          <Typography fontSize="14px">Region</Typography>
          <Typography color={color.primary} fontSize="14px">
            {selected.region}
          </Typography>
        </Stack>
        {/* <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
          <Typography fontSize="15px">Dataset</Typography>
          <Select
            displayEmpty
            size='small'
            sx={{ fontSize: '15px', width: "60%", borderRadius: '8px', background: '#fff' }}
            value={gpuDataset}
            onChange={(e) => setGPUDataset(e.target.value)}
          >
            <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
              Choose Dataset (Optional)
            </MenuItem>
            {
              vmList?.map((path, idx) => (
                <MenuItem value={path.name} key={idx} sx={{ fontSize: "15px" }}>
                  {path?.name}
                </MenuItem>
              ))
            }
          </Select>
        </Stack> */}
        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
          <Typography fontSize="15px">Result Store</Typography>
          <Select
            displayEmpty
            size='small'
            sx={{ fontSize: '15px', width: "60%", borderRadius: '8px', background: '#fff' }}
            value={gpuStore}
            onChange={(e) => setGPUStore(e.target.value)}
          >
            <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
              Choose Results Store (Optional)
            </MenuItem>
            {
              storeList?.map((path, idx) => (
                <MenuItem value={path.name} key={idx} sx={{ fontSize: "15px" }}>
                  {path?.name}
                </MenuItem>
              ))
            }
          </Select>
        </Stack>
      </Stack>
      <Stack pb={2}>
        <AdvancedMachineConfig dockerConfig={dockerConfig} setDockerConfig={setDockerConfig}
          isOpen={isOpen} setIsOpen={setIsOpen} />
      </Stack>
    </Stack>
  )
}
