import { Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { color } from '../../Styles/Color';

function createData(date, amount, type, status) {
  amount = Math.round(amount * 10) / 10
  const newdate = new Date(date * 1000);
  date = newdate.toISOString().split(".")[0].replace("T", " ");
  return { date, amount, type, status };
}

export default function TransactionList({ data, handleRedirect }) {

  const rows = (data || []).map((d) =>
    createData(d.created_at, d.amount, d.order_type, d.status));


  const [page, setPage] = React.useState(0)

  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E4E4E4",
        maxHeight: "65vh",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Amount ($)</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">
              Transaction Type
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Created At</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows?.length > 0 ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow
                  key={idx}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ fontSize: "13px" }}>
                    $ {row.amount}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>
                    {row.type}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>
                    {row.date}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>
                    {row.status}
                  </TableCell>
                </TableRow>
              )) :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={0.5}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No Transactions. Please click on
                  </Typography>
                  <Typography variant='body2' color={color.primary}
                    onClick={handleRedirect}
                    sx={{
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }}
                  >
                    Top-Up
                  </Typography>
                  <Typography variant='body2'>
                    to top-up balances.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}