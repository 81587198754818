/* eslint-disable eqeqeq */
import NightsStayIcon from '@mui/icons-material/NightsStay'
import { FormControlLabel, InputAdornment, OutlinedInput, Slider, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import BoxSwitch from "../../Components/UiComponents/BoxSwitch"
import InputField from "../../Components/UiComponents/InputField"
import NavigationTabs from '../../Components/UiComponents/NavigationTabs'
import { AUTOSCALE_STRATEGY, EMBEDDING_AUTOSCALE_STRATEGY } from '../../Configs/JobConstants'
import { color } from "../../Styles/Color"

const marks = [
  {
    value: 0,
    label: <NightsStayIcon fontSize='small' sx={{ color: color.primary }} />,
  },
  {
    value: 10,
    label: '|',
  },
  {
    value: 20,
    label: '|',
  },
  {
    value: 30,
    label: '|',
  },
  {
    value: 40,
    label: '|',
  },
  {
    value: 50,
    label: '5',
  },
  {
    value: 60,
    label: '|',
  },
  {
    value: 70,
    label: '|',
  },
  {
    value: 80,
    label: '|',
  },
  {
    value: 90,
    label: '|',
  },
  {
    value: 100,
    label: '10',
  },
];


export const AutoscalingConfig = ({
  watch, setValue
}) => {

  const minReplica = watch('initial_worker_config.min_workers')
  const engine = watch('engine')

  const [activeTab, setActiveTab] = useState("0")

  const handleSlider = (event, newValue) => {

    const step = 10

    if (!Array.isArray(newValue)) {
      return;
    }

    setValue('initial_worker_config.min_workers', (newValue[0] / step))
    setValue('initial_worker_config.max_workers', (newValue[1] / step))
  };

  useEffect(() => {
    if (activeTab === "0") {
      setValue("autoscaling_config.autoscaling_strategy", "rps_per_worker")
    } else {
      setValue("autoscaling_config.autoscaling_strategy", "ttft_latency_sec")
    }
  }, [activeTab, setValue])


  useEffect(() => {
    if (minReplica === 0) {
      setValue("autoscaling_config.scale_to_zero", true)
      setValue("autoscaling_config.scale_to_zero_timeout_sec", 1800)
    } else {
      setValue("autoscaling_config.scale_to_zero", false)
      setValue("autoscaling_config.scale_to_zero_timeout_sec", null)
    }
  }, [minReplica, setValue])

  const AUTOSCALING_STRATEGY_PANELS = [
    <Stack key={0} spacing={1}>
      <Typography variant='h3'>Concurrent Requests Per Worker</Typography>
      <Typography variant='subtitle1' color={color.secondaryText}>
        Specify the minimum concurrent requests per second for each worker.
        <Typography
          variant='subtitle1'
          color={color.primary}
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
        >
          Learn more
        </Typography>
      </Typography>
      <OutlinedInput
        size='small'
        type='number'
        border={`1px solid ${color.borders}`}
        value={watch("autoscaling_config.upper_allowed_threshold")}
        onChange={e => setValue("autoscaling_config.upper_allowed_threshold", e.target.value)}
        sx={{
          borderRadius: "8px",
          width: "50%",
        }}
        inputProps={
          {
            sx: {
              fontSize: "14px"
            }
          }
        }
        placeholder='Enter latency'
        endAdornment={
          <InputAdornment position="end">
            <Typography variant='body2' color={color.primary}>Requests / sec</Typography>
          </InputAdornment>
        }
      />
    </Stack>,
    <Stack key={1} spacing={1}>
      <Typography variant='h3'>Time To First Token Latency (TTFT)</Typography>
      <Typography variant='subtitle1' color={color.secondaryText}>
        Enter the time it takes to receive the first token during inference.
        <Typography
          variant='subtitle1'
          color={color.primary}
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
        >
          Learn more
        </Typography>
      </Typography>
      <OutlinedInput
        size='small'
        type='number'
        border={`1px solid ${color.borders}`}
        value={watch("autoscaling_config.upper_allowed_threshold")}
        onChange={e => setValue("autoscaling_config.upper_allowed_threshold", e.target.value)}
        sx={{
          borderRadius: "8px",
          width: "50%",
        }}
        inputProps={
          {
            sx: {
              fontSize: "14px"
            }
          }
        }
        placeholder='Enter requests'
        endAdornment={
          <InputAdornment position="end">
            <Typography variant='body2' color={color.primary}>Sec</Typography>
          </InputAdornment>
        }
      />
    </Stack>,
  ]

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Autoscaling</Typography>
      <Stack
        spacing={3}
        borderRadius="12px"
        border={`1px solid ${color.borders}`}
        box-shadow="0px 1px 4px 0px #0000000A"
        p={3}
      >
        <Stack spacing={1}>
          <Typography variant='h3'>Replicas</Typography>
          <Typography variant='subtitle1' color={color.secondaryText}>
            Set the min and max number of replicas, autoscaling will be triggered based on the traffic.
            <Typography
              variant='subtitle1'
              color={color.primary}
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
            >
              Learn more.
            </Typography>
          </Typography>
          <Stack direction="row" gap={6} alignItems="center">
            <Stack spacing={1} width="18%">
              <InputField
                state={watch('initial_worker_config.min_workers')}
                setState={e => setValue("initial_worker_config.min_workers", e.target.value)}
                type="number"
              />
              <Typography variant='subtitle1'>Min</Typography>
            </Stack>
            <Slider
              step={10}
              value={
                [watch('initial_worker_config.min_workers') * 10, watch('initial_worker_config.max_workers') * 10]
              }
              onChange={handleSlider}
              marks={marks}
              sx={{
                color: color.primary,
                height: 3,
                padding: '15px 0',
                "& .MuiSlider-markLabel": {
                  color: color.secondaryText,
                  fontSize: '12px'
                },
                "& .MuiSlider-mark": {
                  display: "none"
                },
                '& .MuiSlider-thumb': {
                  height: 15,
                  width: 15,
                  backgroundColor: color.lightBlue,
                  border: `1px solid ${color.primary}`,
                  boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
                  '&:focus, &:hover, &.Mui-active': {
                    boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
                  },
                  '&:before': {
                    boxShadow:
                      '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
                  },
                },
                '& .MuiSlider-track': {
                  border: 'none',
                  height: 3,
                },
                '& .MuiSlider-rail': {
                  opacity: 0.5,
                  // boxShadow: 'inset 0px 0px 4px -2px #000',
                  backgroundColor: '#E4E4E4',
                },

              }}
            />
            <Stack spacing={1} width="18%">
              <InputField
                state={watch('initial_worker_config.max_workers')}
                setState={e => setValue("initial_worker_config.max_workers", e.target.value)}
                type="number"
              />
              <Typography variant='subtitle1'>Max</Typography>
            </Stack>
          </Stack>
        </Stack>
        <NavigationTabs
          tabs={engine === "vllm" ? AUTOSCALE_STRATEGY : EMBEDDING_AUTOSCALE_STRATEGY}
          panels={AUTOSCALING_STRATEGY_PANELS}
          value={activeTab}
          setValue={setActiveTab}
        />
        <Stack direction="row" gap={2}>
          <Stack spacing={1} width="45%">
            <Typography variant="h3">Scale-Up Window</Typography>
            <Typography variant="subtitle1" color={color.secondaryText}>
              Set the time window to evaluate and trigger scale-up based on inference demand.
            </Typography>
            <OutlinedInput
              value={watch("autoscaling_config.scale_up_time_window_sec")}
              size='small'
              border={`1px solid ${color.borders}`}
              onChange={e => setValue('autoscaling_config.scale_up_time_window_sec', e.target.value)}
              sx={{
                borderRadius: "8px",
                width: "80%",
              }}
              inputProps={
                {
                  sx: {
                    fontSize: "14px"
                  }
                }
              }
              placeholder='Enter value'
              endAdornment={
                <InputAdornment position="end">
                  <Typography variant='body2' color={color.primary}>Sec</Typography>
                </InputAdornment>
              }
            />
          </Stack>
          <Stack spacing={1} width="53%">
            <Typography variant="h3">Scale-Down Window</Typography>
            <Typography variant="subtitle1" color={color.secondaryText}>
              Set the time window to evaluate and trigger scale-down based on reduced inference demand.
            </Typography>
            <OutlinedInput
              value={watch("autoscaling_config.scale_down_time_window_sec")}
              size='small'
              border={`1px solid ${color.borders}`}
              onChange={e => setValue("autoscaling_config.scale_down_time_window_sec", e.target.value)}
              sx={{
                borderRadius: "8px",
                width: "80%",
              }}
              inputProps={
                {
                  sx: {
                    fontSize: "14px"
                  }
                }
              }
              placeholder='Enter value'
              endAdornment={
                <InputAdornment position="end">
                  <Typography variant='body2' color={color.primary}>Sec</Typography>
                </InputAdornment>
              }
            />
          </Stack>
        </Stack>
        {
          watch("autoscaling_config.scale_to_zero") &&
          <Stack spacing={1} >
            <Typography variant="h3">Scale-to-Zero Window </Typography>
            <OutlinedInput
              value={watch("autoscaling_config.scale_to_zero_timeout_sec")}
              size='small'
              border={`1px solid ${color.borders}`}
              onChange={e => setValue("autoscaling_config.scale_to_zero_timeout_sec", e.target.value)}
              sx={{
                borderRadius: "8px",
                width: "50%",
              }}
              inputProps={
                {
                  sx: {
                    fontSize: "14px"
                  }
                }
              }
              placeholder='Enter value'
              endAdornment={
                <InputAdornment position="end">
                  <Typography variant='body2' color={color.primary}>Sec</Typography>
                </InputAdornment>
              }
            />
          </Stack>
        }
        <Stack spacing={1} >
          <FormControlLabel
            value={watch("autoscaling_config.enable_fast_autoscaling")}
            control={
              <BoxSwitch
                onChange={(e) => {
                  setValue("autoscaling_config.enable_fast_autoscaling", e.target.checked)
                }
                }
              />
            }
            label={<Typography variant="h3">Fast Auto-Scaling</Typography>}
          />
          <Typography variant='subtitle1' color={color.secondaryText}>
            {"Scales-up < 1 minute"}
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant='subtitle1' color={color.secondaryText}>
              Note: Incurs fixed monthly cost for model caching. Learn more
            </Typography>
            <Typography
              variant='subtitle1'
              color={color.primary}
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => window.open("https://docs.scalegen.ai/inf-guide#auto-scaling", "_blank")}
            >
              Learn more
            </Typography>
          </Stack>
        </Stack>
        {/* <Stack spacing={1} >
          <Typography variant="h3">Maximum Price/hour</Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant='subtitle1' color={color.secondaryText}>
              Enter the maximum price per hour threshold while autoscaling.
            </Typography>
            <Typography variant='subtitle1' color={color.primary}>
              Learn more
            </Typography>
          </Stack>
          <OutlinedInput
            value={watch("max_price_per_hour")}
            size='small'
            border={`1px solid ${color.borders}`}
            onChange={e => setValue("max_price_per_hour", e.target.value)}
            type='number'
            sx={{
              borderRadius: "8px",
              width: "50%",
            }}
            inputProps={
              {
                sx: {
                  fontSize: "14px"
                }
              }
            }
            placeholder='Enter value'
            startAdornment={
              <InputAdornment position="start">
                <Typography variant='body2' color={color.primary}>$</Typography>
              </InputAdornment>
            }
          />
        </Stack> */}
      </Stack>
    </Stack>
  )
}
