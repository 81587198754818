import { Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';


export const SecretsList = ({ data, handleDelete }) => {

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  const [page, setPage] = useState(0)

  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "none",
        maxHeight: "55vh",
        border: "1px solid #E4E4E4",
        width: "80%"
      }}>
      <Table sx={{ minWidth: 450 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="80%">Name</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.length > 0 ?
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((key, idx) => (
                <TableRow
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: "13px" }}>
                    {
                      key.includes("ENV_") ? key.slice(4) : key
                    }
                  </TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(key.slice(4))
                      }
                      }
                    />
                  </TableCell>
                </TableRow>
              )) :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={1}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No Secrets added.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onPageChange={handleChangePage}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="Secret"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  )
}
