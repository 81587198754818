import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { color } from '../../Styles/Color';
import InputField from '../UiComponents/InputField';
;

const Profile = () => {
  const user = useSelector(state => state.userState.user)

  return (
    <Stack
      borderRadius="12px"
      border={`1px solid ${color.borders}`}
      p={3}
      direction="row"
      alignItems="center"
      gap={2}
    >
      <Stack width="40%" spacing={1}>
        <Typography variant='h3'>Name</Typography>
        <InputField state={user.nickname} disabled={true} />
      </Stack>
      <Stack width="60%" spacing={1}>
        <Typography variant='h3'>Email Address</Typography>
        <InputField state={user.email} disabled={true} />
      </Stack>
    </Stack >
  )
}

export default Profile