import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import ClearIcon from '@mui/icons-material/Clear';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LLM_PROVIDER_OPTIONS } from '../../Configs/LLMProviderConstants';
import { useAddLLMProviderMutation } from '../../Services/llmgatewayApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';
import Loader from '../UiComponents/Loader';


export const LLMProviderModal = ({ isOpen, setIsOpen }) => {

  const [provider, setProvider] = useState(LLM_PROVIDER_OPTIONS[0].name)
  const [apiKey, setAPIKey] = useState("")

  const [submit, { isLoading, isSuccess }] = useAddLLMProviderMutation();

  const handleClose = () => {
    setAPIKey("")
    setProvider("")
    setIsOpen(false)
  }

  useEffect(() => {
    if (isSuccess) handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleAdd = () => {
    submit({ "provider": provider, "api_key": apiKey });
  }

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose} PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <AccountTreeRoundedIcon fontSize='20px' sx={{ color: color.primary }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Add LLM Provider</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "50vh",
        width: "50vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        {
          isLoading ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            <Stack spacing={5}>
              <Stack spacing={2}>
                <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold" >
                  Choose Provider
                </Typography>
                <Stack direction="row" gap={2} flexWrap='wrap' width="50vw">
                  {
                    LLM_PROVIDER_OPTIONS.map(({ name, icon }) => {
                      return <Stack key={name} spacing={1} alignItems="center" onClick={() => setProvider(name)}>
                        <Stack
                          width="120px"
                          height="120px"
                          justifyContent="center"
                          alignItems="center"
                          border="2px solid"
                          borderColor={provider === name ? color.primary : color.borders}
                          borderRadius="8px"
                          sx={{
                            cursor: "pointer",
                            boxShadow: provider === name ? "0px 0px 1px 2px #92CBFF" : ""
                          }}
                        >
                          {icon("90px")}
                        </Stack>
                        <Typography
                          fontFamily="IBMPlexSansSemiBold"
                          fontSize="15px"
                          color={provider === name && color.primary}
                        >
                          {name}
                        </Typography>
                      </Stack>
                    })
                  }
                </Stack>
              </Stack>
              {
                provider.length > 0 &&
                <Stack borderRadius="8px" border="1px solid #ABABAB" spacing={1}>
                  <Stack p={1} px={2} bgcolor={color.lightBlue}
                    sx={{
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: '8px'
                    }}
                  >
                    <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold">Provider Credentials</Typography>
                  </Stack>
                  <Stack py={2.5} px={2} spacing={2}>
                    <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold">API Key</Typography>
                    <InputField
                      state={apiKey}
                      setState={(e) => setAPIKey(e.target.value)}
                      placeholder={`Enter ${provider} API Key`}
                    />
                  </Stack>
                </Stack>
              }
            </Stack>
        }
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={handleAdd}
          width="10%"
        >
          Add
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}
