import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import YAML from 'yaml'
import CustomButton from '../../Components/UiComponents/CustomButton'
import InputField from '../../Components/UiComponents/InputField'
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice'
import { color } from '../../Styles/Color'
import { ComputeConfig } from '../Inference/ComputeConfig'
import { AutoTrainConfig } from './AutoTrainConfig'
import { DataConfig } from './DataConfig'
import { StorageConfig } from './StorageConfig'
import { TrackingConfig } from './TrackingConfig'

export const DetailFinetuneForm = ({
  handleBack, handleSubmit, watch, setValue, errors
}) => {
  const dispatch = useDispatch()

  const handleDownload = async () => {
    let isNameValid = watch('job_name').length > 0

    if (!isNameValid) {
      dispatch(setIsError(true))
      dispatch(setErrorMessage("Job Name is required"))
    } else {
      const element = document.createElement("a");
      const file = new Blob([YAML.stringify(watch())], { type: 'text/yaml' });
      element.href = URL.createObjectURL(file);
      element.download = `${watch('job_name')}-fine-tune-config.yml`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }
  return (
    <Stack direction="row" gap={2} width="100%" >
      <Stack
        spacing={1}
        width="58%"
      >
        <Stack
          height="62vh"
          overflow="auto"
          // sx={{
          //   '&::-webkit-scrollbar': {
          //     display: 'none',
          //   }
          // }}
          spacing={4}
          pb={1}
          pr={1}
        >
          <Stack spacing={2}>
            <Typography variant="h2">Model Configuration</Typography>
            <Stack
              spacing={3}
              borderRadius="12px"
              border={`1px solid ${color.borders}`}
              box-shadow="0px 1px 4px 0px #0000000A"
              p={3}
            >
              <Stack spacing={1}>
                <Typography variant="h3">
                  Model Name
                </Typography>
                <InputField
                  state={watch('model')}
                  disabled={true} placeholder="Enter your model name"
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="h3">HuggingFace Access Token</Typography>
                <InputField
                  state={watch('autotrain_params.hf_token')}
                  setState={(e) => setValue('autotrain_params.hf_token', e.target.value)}
                  placeholder="Enter your HuggingFace token"
                />
              </Stack>
            </Stack>
          </Stack>
          <DataConfig
            watch={watch} setValue={setValue} errors={errors}
          />
          <StorageConfig
            watch={watch} setValue={setValue}
          />
          <ComputeConfig
            watch={watch} setValue={setValue}
          />
          <AutoTrainConfig
            watch={watch} setValue={setValue}
          />
          <TrackingConfig
            watch={watch} setValue={setValue}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            onClick={handleBack}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            <ArrowBackIcon fontSize='small' />
            <Typography>
              Go back to model configuration
            </Typography>
          </Stack>
          <CustomButton onClick={handleSubmit}>Launch Job</CustomButton>
        </Stack>
      </Stack>
      <Stack width="40%" mt={-10} p={1}>
        <Stack
          border={`1px solid ${color.borders}`}
          borderRadius="6px"
          boxShadow="0px 1px 4px 0px #0000000A"
          p={2}
          pl={4}
          spacing={3}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">config.yaml</Typography>
            <CustomButton onClick={handleDownload}>Download</CustomButton>
          </Stack>
          <Stack
            bgcolor="#000"
            height="60vh"
            borderRadius="6px"
            px={3}
            py={1}
            color={color.white}
            overflow="auto"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              }
            }}
          >
            <Typography variant='subtitle1'>
              <pre>
                {YAML.stringify(watch())}
              </pre>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            onClick={() => window.open("https://docs.scalegen.ai/ft-cli", "_blank")}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            <Typography>
              Launch via CLI
            </Typography>
            <ArrowForwardIcon fontSize='small' />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
