import { Button } from '@mui/material'
import React from 'react'
import { color } from '../../Styles/Color'

const SecondaryButton = ({ children, onClick, height }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        border: `1px solid ${color.borders}`,
        color: "#000",
        fontSize: "14px",
        borderRadius: "8px",
        height: `${height ? height : "36px"}`,
        p: 2,
        bgColor: color.secondaryBackground,
        "&:hover": {
          bgcolor: color.secondaryBackground
        },
        textTransform: "none",
      }}
    >
      {children}
    </Button >
  )
}

export default SecondaryButton