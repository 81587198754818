import { MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../Components/UiComponents/InputField'
import { RequiredHeader } from '../../Components/UiComponents/RequiredHeader'
import { SelectDropdown } from '../../Components/UiComponents/SelectDropdown'
import { MIXED_PRECISION_OPTIONS, QUANTIZATION_OPTIONS, TORCH_DTYPE_OPTIONS } from '../../Configs/JobConstants'
import { color } from '../../Styles/Color'

export const AutoTrainConfig = ({
  watch, setValue
}) => {

  const isSpot = watch('use_spot')
  const [isLora, setIsLora] = useState(true)

  useEffect(() => {
    if (isSpot) {
      setIsLora(true)
    }
  }, [isSpot])

  return (
    <Stack spacing={2}>
      <Typography variant="h2">AutoTrain Parameters</Typography>
      <Stack
        spacing={3}
        borderRadius="12px"
        border={`1px solid ${color.borders}`}
        box-shadow="0px 1px 4px 0px #0000000A"
        p={3}
      >
        <Stack direction="row" alignItems="center" gap={1} >
          <Stack spacing={1} width="35%">
            <Typography variant='h3'>Epochs</Typography>
            <InputField
              state={watch('autotrain_params.epochs')}
              setState={e => setValue('autotrain_params.epochs', e.target.value)}
              placeholder="Enter value" type="number"
            />
          </Stack>
          <Stack spacing={1} width="35%">
            <Typography variant='h3'>Learning Rate</Typography>
            <InputField
              state={watch('autotrain_params.lr')}
              setState={e => setValue('autotrain_params.lr', e.target.value)}
              placeholder="Enter value" type="number"
            />
          </Stack>
          <Stack spacing={1} width="30%">
            <Typography variant='h3'>Batch Size</Typography>
            <InputField
              state={watch('autotrain_params.batch_size')}
              setState={e => setValue('autotrain_params.batch_size', e.target.value)}
              placeholder="Enter value" type="number"
            />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} >
          <Stack spacing={1} width="35%">
            <Typography variant='h3'>Block Size</Typography>
            <InputField
              state={watch('autotrain_params.block_size')}
              setState={e => setValue('autotrain_params.block_size', e.target.value)}
              placeholder="Enter value" type="number"
            />
          </Stack>
          <Stack spacing={1} width="35%">
            <Typography variant='h3'>Model Max Length</Typography>
            <InputField
              state={watch('autotrain_params.model_max_length')}
              setState={e => setValue('autotrain_params.model_max_length', e.target.value)}
              placeholder="Enter value" type="number"
            />
          </Stack>
          <Stack spacing={1} width="30%">
            <Typography variant='h3'>Seed</Typography>
            <InputField
              state={watch('autotrain_params.seed')}
              setState={e => setValue('autotrain_params.seed', e.target.value)}
              placeholder="Enter value" type="number"
            />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} >
          <Stack spacing={1} width="35%">
            <Typography variant='h3'>Gradient Accumulation</Typography>
            <InputField
              state={watch('autotrain_params.gradient_accumulation_steps')}
              setState={e => setValue('autotrain_params.gradient_accumulation_steps', e.target.value)}
              placeholder="Enter value" type="number"
            />
          </Stack>
          <Stack spacing={1} width="35%">
            <Typography variant='h3'>Mixed Precision</Typography>
            <SelectDropdown
              value={watch('autotrain_params.mixed_precision')}
              handleChange={e => setValue('autotrain_params.mixed_precision', e.target.value)}
              options={MIXED_PRECISION_OPTIONS} placeholder="Select a option"
            />
          </Stack>
          <Stack spacing={1} width="30%">
            <Typography variant='h3'>Quantization</Typography>
            <SelectDropdown
              value={watch('autotrain_params.quantization')}
              handleChange={e => setValue('autotrain_params.quantization', e.target.value)}
              options={QUANTIZATION_OPTIONS} placeholder="Select a option"
            />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Stack spacing={1} width="49%">
            <Typography variant='h3'>Torch dType</Typography>
            <SelectDropdown
              value={watch('autotrain_params.torch_dtype')}
              handleChange={e => setValue('autotrain_params.torch_dtype', e.target.value)}
              options={TORCH_DTYPE_OPTIONS} placeholder="Select a option"
            />
          </Stack>
          <Stack spacing={1} width="49%">
            <Typography variant='h3'>Use Deepspeed</Typography>
            <SelectDropdown
              value={watch('autotrain_params.use_deepspeed')}
              handleChange={e => setValue("autotrain_params.use_deepspeed", e.target.value)}
              options={["stage_2", "stage_3"]} placeholder="Select a option"
            />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} >
          <Stack spacing={1} width="49%">
            <Typography variant='h3'>Disable Gradient Checkpointing</Typography>
            <Select
              size='small'
              value={watch('autotrain_params.disable_gradient_checkpointing')}
              onChange={e =>
                setValue('autotrain_params.disable_gradient_checkpointing', e.target.value)
              }
              displayEmpty
              input={<OutlinedInput />}
              sx={{ bgcolor: color.white, borderRadius: "8px", fontSize: "14px" }}
            >
              <MenuItem
                value={true}
                sx={{ fontSize: "14px" }}
              >
                Enabled
              </MenuItem>
              <MenuItem
                value={false}
                sx={{ fontSize: "14px" }}
              >
                Disabled
              </MenuItem>
            </Select>
          </Stack>
          <Stack spacing={1} width="49%">
            <Typography variant='h3'>Use FlashAttention2</Typography>
            <Select
              size='small'
              value={watch('autotrain_params.use_flash_attention_2')}
              onChange={e =>
                setValue('autotrain_params.use_flash_attention_2', e.target.value)
              }
              displayEmpty
              input={<OutlinedInput />}
              sx={{ bgcolor: color.white, borderRadius: "8px", fontSize: "14px" }}
            >
              <MenuItem
                value={true}
                sx={{ fontSize: "14px" }}
              >
                Enabled
              </MenuItem>
              <MenuItem
                value={false}
                sx={{ fontSize: "14px" }}
              >
                Disabled
              </MenuItem>
            </Select>
          </Stack>
        </Stack>

        <Stack spacing={1} >
          <Typography variant='h3'>LoRA</Typography>
          <Typography variant='subtitle1' color={color.deleteText}>
            Note : Enabled by default while using spot instances.
          </Typography>
          <Stack width="34%">
            <Select
              size='small'
              value={isLora}
              onChange={e =>
                setIsLora(e.target.value)
              }
              displayEmpty
              input={<OutlinedInput />}
              sx={{ bgcolor: color.white, borderRadius: "8px", fontSize: "14px" }}
            >
              <MenuItem
                value={true}
                sx={{ fontSize: "14px" }}
              >
                Enabled
              </MenuItem>
              <MenuItem
                value={false}
                sx={{ fontSize: "14px" }}
                disabled={isSpot}
              >
                Disabled
              </MenuItem>
            </Select>
          </Stack>
        </Stack>
        {
          isLora &&
          <Stack direction="row" alignItems="center" gap={1}>
            <Stack spacing={1} width="35%">
              <RequiredHeader>
                <Typography variant='h3'>LoRA r</Typography>
              </RequiredHeader>
              <InputField
                state={watch('autotrain_params.lora_r')}
                setState={e => setValue('autotrain_params.lora_r', e.target.value)}
                placeholder="Enter value" type="number"
              />
            </Stack>
            <Stack spacing={1} width="35%">
              <RequiredHeader>
                <Typography variant='h3'>LoRA Alpha</Typography>
              </RequiredHeader>
              <InputField
                state={watch('autotrain_params.lora_alpha')}
                setState={e => setValue('autotrain_params.lora_alpha', e.target.value)}
                placeholder="Enter value" type="number"
              />
            </Stack>
            <Stack spacing={1} width="30%">
              <RequiredHeader>
                <Typography variant='h3'>LoRA Dropout</Typography>
              </RequiredHeader>
              <InputField
                state={watch('autotrain_params.lora_dropout')}
                setState={e => setValue('autotrain_params.lora_dropout', e.target.value)}
                placeholder="Enter value" type="number"
              />
            </Stack>
          </Stack>
        }
      </Stack>
    </Stack>
  )
}
