import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsError } from '../DataStore/errorSlice'

export default function GlobalErrorHandler() {
  const { isError, errorMessage } = useSelector((state) => state.errorState)
  const dispatch = useDispatch()

  return (
    <Snackbar
      open={isError}
      autoHideDuration={10000}
      onClose={() => dispatch(setIsError(false))}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ width: "30%" }}
    >
      <Alert severity="error" sx={{ width: "100%" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  )
}

