import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import CloudIcon from '@mui/icons-material/Cloud'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import DnsIcon from '@mui/icons-material/Dns'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
// import EditIcon from '@mui/icons-material/Edit'
import LanguageIcon from '@mui/icons-material/Language'
import ScheduleIcon from '@mui/icons-material/Schedule'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Divider, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import YAML from 'yaml'
import { getStatus } from '../Components/InferenceViewComponents/InferenceList'
import CustomButton from '../Components/UiComponents/CustomButton'
import Loader from '../Components/UiComponents/Loader'
import { useGetGPUNodesQuery, useScaleInferenceMutation } from '../Services/inferenceApi'
import { useGetSecretQuery } from '../Services/secretsApi'
import { color } from '../Styles/Color'

export const InferenceOverview = ({ inferenceDetails, setIsDeleteModalOpen, }) => {

  const { data: gpuNodes, isLoading: isLoadingGPUNodes } =
    useGetGPUNodesQuery(inferenceDetails.id, {
      skip: !inferenceDetails || (inferenceDetails?.status === "DELETED" || inferenceDetails?.status === "FAILED")
    })

  const { data: secret, isLoading: isLoadingSecret } =
    useGetSecretQuery("AUTH_ENDPOINT_KEY_" + inferenceDetails.id)

  const [scaleInference, { isLoading: isScaling }] = useScaleInferenceMutation()

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
  }

  const handleDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const handleDownloadYaml = () => {
    const element = document.createElement("a");
    const file = new Blob([YAML.stringify(inferenceDetails)], { type: 'text/yaml' });
    element.href = URL.createObjectURL(file);
    element.download = `${inferenceDetails.name}-inference-config.yml`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  if (isLoadingGPUNodes || isLoadingSecret || isScaling) {
    return <Stack height="65vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack direction="row" gap={2} py={2}>
      <Stack width="63%" spacing={5}>
        <Stack direction="row" justifyContent="space-between" alignItems="end">
          <Stack direction="row" gap={1} alignItems="self-end">
            <Typography variant="h1">{inferenceDetails.name}</Typography>
            <Typography variant="body1" color={color.primary}>{inferenceDetails.id}</Typography>
          </Stack>
          {
            inferenceDetails.status === "ACTIVE" && inferenceDetails?.inf_type === "llm" &&
            inferenceDetails?.link?.length > 0 &&
            <CustomButton onClick={() => window.open(inferenceDetails.link + "/chat", "_blank")}>
              LLM Playground
            </CustomButton>
          }
        </Stack >
        <Stack spacing={2} px={0.5}>
          <Stack
            p={3}
            border={`1px solid ${color.borders}`}
            spacing={4}
            boxShadow="0px 1px 4px 0px #0000000A"
            borderRadius="6px"
          >
            <Stack direction="row" gap={2} alignItems="center">
              <ViewInArIcon sx={{ color: color.primary, fontSize: "16px" }} />
              <Typography variant='h3'>{inferenceDetails.model}</Typography>
              <Tooltip title={isCopied ? "Copied" : "Copy"}>
                <ContentCopyIcon
                  sx={{
                    fontSize: "16px",
                    color: color.primary,
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                  onClick={() =>
                    handleCopyClick(inferenceDetails.model)
                  }
                />
              </Tooltip>
              {getStatus(inferenceDetails.status)}
            </Stack>
            <Stack spacing={2}>
              {
                inferenceDetails.base_model &&
                <>
                  <Stack direction="row" alignItems="center">
                    <Typography variant='h3' width="20%">Base Model</Typography>
                    <Typography
                      variant='body2'
                      color={color.primary}
                      width="75%"
                    >
                      {inferenceDetails.base_model}
                    </Typography>
                  </Stack>
                  <Divider />
                </>
              }
              {
                inferenceDetails.status !== "DELETED" && inferenceDetails.link &&
                <>
                  <Stack direction="row" alignItems="center">
                    <Typography variant='h3' width="20%">API Endpoint</Typography>
                    <Typography
                      variant='body2'
                      color={color.primary}
                      width="75%"
                    >
                      {inferenceDetails.link + (inferenceDetails?.inf_type !== "llm" ? "/embed/v1" : "/inference/v1")}
                    </Typography>
                    <Tooltip title={isCopied ? "Copied" : "Copy"}>
                      <ContentCopyIcon
                        sx={{
                          fontSize: "16px",
                          color: color.primary,
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                        onClick={() =>
                          handleCopyClick(
                            inferenceDetails.link +
                            (inferenceDetails?.inf_type !== "llm" ? "/embed/v1" : "/inference/v1")
                          )
                        }
                      />
                    </Tooltip>
                  </Stack>
                  <Divider />
                  {
                    secret?.secret["AUTH_ENDPOINT_KEY_" + inferenceDetails.id] &&
                    <Stack direction="row" alignItems="center">
                      <Typography variant='h3' width="20%">API Key</Typography>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ?
                                <VisibilityOff
                                  sx={{ fontSize: "20px", color: color.primary }}
                                /> :
                                <Visibility
                                  sx={{ fontSize: "20px", color: color.primary }}
                                />}
                            </IconButton>
                          </InputAdornment>
                        }
                        value={secret?.secret["AUTH_ENDPOINT_KEY_" + inferenceDetails.id]}
                        onChange={() => { }}
                        sx={{
                          fontFamily: "BDOGroteskRegular",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19.6px",
                          width: "75%",
                          color: color.primary,
                        }}
                        disableUnderline
                      />
                      <Tooltip title={isCopied ? "Copied" : "Copy"}>
                        <ContentCopyIcon
                          sx={{
                            fontSize: "16px",
                            color: color.primary,
                            "&:hover": {
                              cursor: "pointer"
                            }
                          }}
                          onClick={() =>
                            handleCopyClick(secret?.secret["AUTH_ENDPOINT_KEY_" + inferenceDetails.id])
                          }
                        />
                      </Tooltip>
                    </Stack>
                  }
                  <Divider />
                  <Stack direction="row" >
                    <Typography variant='h3' width="20%">Metric Dashboard</Typography>
                    <Link to={inferenceDetails.link + "/metrics"} target="_blank"
                      style={{
                        width: "75%"
                      }}
                    >
                      <Typography
                        variant='body2'
                        color={color.primary}
                      >
                        {inferenceDetails.link + "/metrics"}
                      </Typography>
                    </Link>
                  </Stack>
                  <Typography variant='subtitle1' color={color.secondaryText}>
                    Note: For metrics dashboard login with Inference Deployment ID as username and API key as password.
                  </Typography>
                  <Divider />
                </>
              }
              <Stack direction="row" alignItems="center">
                <Typography variant='h3' width="20%">Result Store</Typography>
                <Typography
                  variant='body2'
                  color={color.primary}
                  width="75%"
                >
                  {inferenceDetails.logs_store || "None"}
                </Typography>
              </Stack>
              {
                !(
                  inferenceDetails.status === "DELETING" ||
                  inferenceDetails.status === "FAILED"
                ) &&
                <>
                  <Divider />
                  <Stack py={2} direction='row' >
                    <Stack direction="row" gap={1} alignItems="center" width="90%">
                      {
                        inferenceDetails.status === "DELETED" &&
                        <>
                          <DeleteSweepIcon fontSize='small' sx={{ color: color.deleteText }} />
                          <Stack direction="row" gap={1}>
                            <Typography variant='body2'>Deployment has been deleted.</Typography>
                            <Typography
                              variant='body2'
                              color={color.primary}
                              onClick={handleDownloadYaml}
                              sx={{
                                "&:hover": {
                                  cursor: "pointer"
                                }
                              }}
                            >
                              Download config
                            </Typography>
                            <Typography variant='body2'>as .yaml file</Typography>
                          </Stack>
                        </>
                      }
                      {
                        inferenceDetails.status === "ACTIVE" ?
                          <>
                            <ArrowCircleUpIcon fontSize='small' sx={{ color: color.successText }} />
                            <Typography variant='body2'>Deployment is active and utilizing resources.</Typography>
                          </> :
                          inferenceDetails.status === "INACTIVE" ?
                            <>
                              <ArrowCircleDownIcon fontSize='small' sx={{ color: color.deleteText }} />
                              <Stack direction="row" gap={1}>
                                <Typography variant='body2'>Deployment is currently inactive.</Typography>
                                <Typography
                                  variant='body2'
                                  color={color.primary}
                                  onClick={() => scaleInference(
                                    {
                                      id: inferenceDetails.id,
                                      direction: "up"
                                    }
                                  )}
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer"
                                    }
                                  }}
                                >
                                  Activate
                                </Typography>
                              </Stack>
                            </> :
                            inferenceDetails.status === "PROVISIONING" &&
                            <Typography variant='body2'>
                              Provisioning infrastructure. This may take few minutes.
                            </Typography>
                      }
                    </Stack>
                    {
                      inferenceDetails.status !== "DELETED" &&
                      <Typography
                        variant='body2'
                        color={color.deleteText}
                        sx={{
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                        onClick={handleDelete}
                      >
                        Delete
                      </Typography>
                    }
                  </Stack>
                </>
              }
            </Stack>
          </Stack>
          {
            gpuNodes && gpuNodes.length > 0 &&
            <Stack
              py={2}
              px={3}
              border={`1px solid ${color.borders}`}
              spacing={2}
              boxShadow="0px 1px 4px 0px #0000000A"
              borderRadius="6px"
            >
              <Typography variant='h2'>Active GPU Machines</Typography>
              {
                gpuNodes.map((node, idx) => (
                  <Stack
                    key={idx}
                    border={`1px solid ${color.borders}`}
                    p={2}
                    borderRadius="6px"
                    direction="row"
                    alignItems="center"
                    gap={3}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <DynamicFormIcon fontSize='small' sx={{ color: color.primary }} />
                      <Stack>
                        <Typography variant='h3'>{node.gpu_count} x {node.gpu_type}</Typography>
                        <Typography variant='subtitle1' color={color.primary}>
                          {
                            node.id.length > 20 ?
                              node.id.slice(0, 20) + "..." : node.id
                          }
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider orientation='vertical' />
                    <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
                      <Stack
                        borderRadius="6px"
                        bgcolor={color.primary}
                        py={1}
                        px={1}
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        color={color.white}
                      >
                        <CloudIcon sx={{ fontSize: "16px" }} />
                        <Typography variant='subtitle1' >{node.cloud}</Typography>
                      </Stack>
                      <Stack
                        borderRadius="6px"
                        bgcolor={color.primary}
                        py={1}
                        px={1}
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        color={color.white}
                      >
                        <LanguageIcon sx={{ fontSize: "16px" }} />
                        <Typography variant='subtitle1' >{node.region}</Typography>
                      </Stack>
                      {
                        node.spot ?
                          <Stack
                            borderRadius="6px"
                            bgcolor={color.yellow}
                            py={1}
                            px={1}
                            direction="row"
                            alignItems="center"
                            gap={0.5}
                            color={color.white}
                          >
                            <ScheduleIcon sx={{ fontSize: "16px" }} />
                            <Typography variant='subtitle1' >Spot</Typography>
                          </Stack>
                          :
                          <Stack
                            borderRadius="6px"
                            bgcolor={color.successText}
                            py={1}
                            px={1}
                            direction="row"
                            alignItems="center"
                            gap={0.5}
                            color={color.white}
                          >
                            <DnsIcon sx={{ fontSize: "16px" }} />
                            <Typography variant='subtitle1' >On-Demand</Typography>
                          </Stack>
                      }
                    </Stack>
                  </Stack>
                ))
              }
            </Stack>
          }
        </Stack>
      </Stack>
      <Stack width="35%" spacing={2} mt={-2}>
        <Stack
          py={2}
          px={3}
          border={`1px solid ${color.borders}`}
          spacing={4}
          boxShadow="0px 1px 4px 0px #0000000A"
          borderRadius="6px"
        >
          <Typography variant='h2'>Usage</Typography>
          <Stack gap={2}>
            <Stack direction="row" gap={1} alignItems="end">
              <Typography variant='h1'>$ {Math.round(inferenceDetails.cost * 100) / 100}</Typography>
              <Typography variant='subtitle1' color={color.secondaryText}>cost for this deployment</Typography>
            </Stack>
            <Divider />
            <Stack direction="row" gap={1} alignItems="end">
              <Typography variant='body1'>
                $ {Math.round(inferenceDetails.current_price_per_hour * 100) / 100}
              </Typography>
              <Typography variant='subtitle1' color={color.secondaryText}>per hour cost</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          py={2}
          px={3}
          border={`1px solid ${color.borders}`}
          spacing={4}
          boxShadow="0px 1px 4px 0px #0000000A"
          borderRadius="6px"
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant='h2'>AutoScaling</Typography>
            {/* {
              (inferenceDetails.status === "ACTIVE" || inferenceDetails.status === "INACTIVE") &&
              <Stack bgcolor={color.lightBlue} color={color.primary} borderRadius="6px" p={1}>
                <EditIcon fontSize='small' />
              </Stack>
            } */}
          </Stack>
          <Stack gap={2}>
            <Stack direction="row" alignItems="center" >
              <Stack spacing={1} width="50%">
                <Typography variant='subtitle1' color={color.secondaryText}>
                  {
                    inferenceDetails.autoscaling_config.autoscaling_strategy === "ttft_latency_sec" ?
                      "TTFT Latency" : "Request per second"
                  }
                </Typography>
                <Typography variant='body2'>
                  {inferenceDetails.autoscaling_config.upper_allowed_latency_sec} {
                    inferenceDetails.autoscaling_config.autoscaling_strategy === "ttft_latency_sec" ?
                      "sec" : "requests"
                  }
                </Typography>
              </Stack>
              <Stack spacing={1} width="50%">
                <Typography variant='subtitle1' color={color.secondaryText}>Scale-Up window</Typography>
                <Typography variant='body2'>
                  {inferenceDetails.autoscaling_config.scale_up_time_window_sec}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" >
              <Stack spacing={1} width="50%">
                <Typography variant='subtitle1' color={color.secondaryText}>Scale-Down window</Typography>
                <Typography variant='body2'>
                  {inferenceDetails.autoscaling_config.scale_down_time_window_sec}
                </Typography>
              </Stack>
              <Stack spacing={1} width="50%">
                <Typography variant='subtitle1' color={color.secondaryText}>Scale-to-Zero</Typography>
                <Typography variant='body2'>
                  {
                    inferenceDetails.autoscaling_config.scale_to_zero ?
                      inferenceDetails.autoscaling_config.scale_to_zero_timeout_sec :
                      "Disabled"
                  }
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" >
              <Stack spacing={1} width="50%">
                <Typography variant='subtitle1' color={color.secondaryText}>Min Replicas</Typography>
                <Typography variant='body2'>
                  {inferenceDetails.initial_worker_config.min_workers}
                </Typography>
              </Stack>
              <Stack spacing={1} width="50%">
                <Typography variant='subtitle1' color={color.secondaryText}>Max Replicas</Typography>
                <Typography variant='body2'>
                  {inferenceDetails.initial_worker_config.max_workers}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* <Stack
          py={2}
          px={3}
          border={`1px solid ${color.borders}`}
          spacing={4}
          boxShadow="0px 1px 4px 0px #0000000A"
          borderRadius="6px"
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant='h2'>Worker Details</Typography>
            {
              (inferenceDetails.status === "ACTIVE" || inferenceDetails.status === "INACTIVE") &&
              <Stack bgcolor={color.lightBlue} color={color.primary} borderRadius="6px" p={1}>
                <EditIcon fontSize='small' />
              </Stack>
            }
          </Stack>
          <Stack gap={2}>
            {
              (inferenceDetails?.initial_worker_config !== null
                && inferenceDetails?.initial_worker_config?.min_workers !== null) &&
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant='h4' color={color.secondaryText}>
                    Minimum workers
                  </Typography>
                  <Typography variant='body2'>
                    {inferenceDetails.initial_worker_config.min_workers}
                  </Typography>
                </Stack>
                <Divider />
              </>
            }
            {
              inferenceDetails?.min_throughput_rate !== null &&
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant='h4' color={color.secondaryText}>
                    Minimum Throughput
                  </Typography>
                  <Typography variant='body2'>
                    {inferenceDetails.min_throughput_rate}
                  </Typography>
                </Stack>
                <Divider />
              </>
            }
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant='h4' color={color.secondaryText}>
                Maximum price per hour
              </Typography>
              <Typography variant='body2'>
                $ {inferenceDetails.max_price_per_hour || 0}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant='h4' color={color.secondaryText}>
                Allow Spot Instances
              </Typography>
              <Typography variant='body2'>
                {inferenceDetails.allow_spot_instances ? "Enabled" : "Disabled"}
              </Typography>
            </Stack>
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  )
}
