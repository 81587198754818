import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Chip, Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { color } from '../../Styles/Color';


function createData(id, name, startTime, endTime, cost, status, type) {
  cost = Math.round(cost * 10) / 10
  startTime = startTime?.split(".")[0]
  endTime = endTime?.split(".")[0]
  type = type.toLowerCase()
  return { id, name, startTime, endTime, cost, status, type };
}

export const getStatus = (status) => {
  switch (status) {
    case "stopped": {
      return <Chip
        sx={{
          bgcolor: '#D8ECFF',
          color: color.primary,
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="14px">
            <DoneAllIcon sx={{ fontSize: "14px" }} /> Stopped
          </Stack>
        }
      />
    }

    case "failed": {
      return <Chip
        sx={{
          bgcolor: '#FFD2D2',
          color: "#FF6B6A",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="14px">
            <CancelOutlinedIcon sx={{ fontSize: "14px" }} /> Failed
          </Stack>
        }
      />
    }

    case "stopping": {
      return <Chip
        sx={{
          bgcolor: '#FFEEC3',
          color: "#FF8A00",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="14px">
            <RadioButtonCheckedIcon sx={{ fontSize: "14px" }} /> Stopping
          </Stack>
        }
      />
    }

    case "running": {
      return <Chip
        sx={{
          bgcolor: '#BEFFD0',
          color: "#2C974A",
          borderRadius: "8px",
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="14px">
            <SettingsBackupRestoreIcon sx={{ fontSize: "14px" }} /> Running
          </Stack>
        }
      />
    }

    case "queued": {
      return <Chip
        sx={{
          bgcolor: '#FAFFC3',
          color: "#DAC400",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="14px">
            <RestoreIcon sx={{ fontSize: "14px" }} /> Queued
          </Stack>
        }
      />
    }

    case "deleted": {
      return <Chip
        sx={{
          bgcolor: '#E4E4E4',
          color: "#ABABAB",
          borderRadius: "8px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="14px">
            <AutoDeleteIcon sx={{ fontSize: "14px" }} /> Deleted
          </Stack>
        }
      />
    }
    default: return <></>
  }
}

const Row = ({ row, navigate }) => {
  return (
    <TableRow
      hover
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0
        },
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => {
        if (row.type === "finetuning") {
          navigate(PAGE_ROUTES.fineTuning + "/" + row.id)
        } else {
          navigate(PAGE_ROUTES.training + "/" + row.id)
        }
      }}
    >
      <TableCell component="th" scope="row">
        <Stack direction="row" gap={2} alignItems="center">
          <ViewInArIcon fontSize='small' sx={{ color: color.primary }} />
          <Typography variant='body2' >{row.id}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {row.startTime}
      </TableCell>
      <TableCell>
        {row.endTime}
      </TableCell>
      <TableCell>
        <Typography color={color.primary} variant='h3'>
          $ {row.cost}
        </Typography>
      </TableCell>
      <TableCell>
        {
          getStatus(row.status.toLowerCase())
        }
      </TableCell>
    </TableRow >
  )
}

export default function JobsList({ data, height, type, filter }) {
  const navigate = useNavigate()


  const rows = (
    data ?
      filter?.length > 0 ?
        data.filter(job => filter.includes(job.status)) :
        data :
      []
  ).map((d) =>
    createData(d.id, d.name, d.start_time, d.end_time, d.cost, d.status, d.spec.type))

  const [page, setPage] = useState(0)

  const rowsPerPage = 5

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data && (data.length < page * rowsPerPage)) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        maxHeight: height || "65vh",
        border: "1px solid #E4E4E4",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="25%">
              {rows.length} items
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Start Time</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">End Time</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Cost</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              (rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []).map((row, idx) => (
                <Row row={row} key={idx} navigate={navigate} />
              ))
              :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={1}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No
                  </Typography>
                  <Typography variant='body2' color={color.primary}>
                    {
                      (data && data.length > 0) ?
                        filter?.map(f => f.toLowerCase()).join(" , ") :
                        ""
                    } {type === "FINETUNING" ? "fine-tuning" : "runScript"}
                  </Typography>
                  <Typography variant='body2'>
                    jobs currently.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              style={{
                borderTop: `1px solid ${color.borders}`,
              }}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}