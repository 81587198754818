import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeVMModal } from '../../DataStore/modalSlice';
import { useCreateVirtualMountMutation } from '../../Services/virtualMountsApi';
import { color } from '../../Styles/Color';
import { getTrimmedData } from '../../Utils/trimmer';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';
import SecondaryButton from '../UiComponents/SecondaryButton';
import ChooseStorageType from './ChooseStorageType';
import VMDetailsForm from './VMDetailsForm';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddVMModal = () => {
  const [isActive, setIsActive] = useState("");
  const [storageType, setStorageType] = useState("");
  const initialFormState = Object.freeze({
    name: "",
    src: "",
    dest: "",
    filter: "",
    prefetch: false,
    unravelArchives: false,
    credentials: {}
  })

  const [formValues, setFormValues] = useState(initialFormState)

  const [handleSubmit, { isLoading, isSuccess }] = useCreateVirtualMountMutation();
  const { isVMModalOpen } = useSelector((store) => store.modal)

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleClose = () => {
    dispatch(closeVMModal())
    setIsActive("")
    setStorageType("")
    setFormValues(initialFormState);
  }

  const handleNext = () => {
    setStorageType(isActive)
  }

  const handleAdd = () => {
    const config = getTrimmedData(formValues)
    handleSubmit(config);
  }

  const handleCancel = () => {
    setFormValues(initialFormState)
    setStorageType("")
    setIsActive("")
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isVMModalOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              padding={1}
              borderRadius="8px"
              bgcolor={color.primary}
              color={color.white}
            >
              <DynamicFormIcon fontSize='20px' />
            </Stack>
            <Typography variant='h2' color={color.primary}>Add Virtual Mount</Typography>
          </Stack>
          {
            !storageType ?
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
              </IconButton>
              :
              <Stack
                direction="row"
                gap={1}
                color={color.primary}
                alignItems="center"
                onClick={handleCancel}
                sx={{
                  "&:hover": {
                    cursor: "pointer"
                  }
                }}
              >
                <ArrowBackIcon fontSize='small' />
                <Typography>Go Back </Typography>
              </Stack>
          }
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "35vh",
          width: "40vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // }
        }}
      >
        {
          isLoading ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            <>
              {
                storageType === "" ?
                  <ChooseStorageType isActive={isActive} setIsActive={setIsActive} /> :
                  <VMDetailsForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    storageType={storageType}
                  />
              }
            </>
        }
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          {
            !storageType ?
              <CustomButton
                onClick={handleNext}
                width="10%"
                disabled={!isActive}
              >
                Next
              </CustomButton>
              :
              <Stack direction="row" gap={1}>
                <SecondaryButton onClick={handleClose}>
                  Cancel
                </SecondaryButton>
                <CustomButton
                  onClick={handleAdd}
                  width="10%"
                >
                  Add
                </CustomButton>
              </Stack>
          }

        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default AddVMModal