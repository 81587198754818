import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router'
import JobConfig from '../Components/JobViewComponents/JobConfig'
import Loader from '../Components/UiComponents/Loader'
import { useGetJobQuery } from '../Services/jobsApi'
import { color } from '../Styles/Color'
import { NotFoundErrorPage } from './NotFoundErrorPage'

export const JobView = () => {
  const location = useLocation()
  const { data: job, isLoading } =
    useGetJobQuery(
      location.pathname.split('/').pop(),
      {
        refetchOnMountOrArgChange: true,
      }
    )

  if (isLoading) {
    return <Stack height="80vh"><Loader /></Stack>
  }

  return job ? (
    <Stack
      spacing={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Typography fontSize="16px">Job Name</Typography>
        <Typography color={color.primary} fontSize="16px">{job?.name}</Typography>
      </Stack>
      <JobConfig job={job} />
    </Stack >
  ) : (
    <NotFoundErrorPage />
  )
}
