import { Stack } from '@mui/material';
import { CardElement, useElements } from '@stripe/react-stripe-js';



const cardElementOptions = {
  style: {
    base: {
      fontFamily: "BDOGroteskMedium",
      fontSize: "18px",
    }
  }
}

let cardElement;

export const getCardElement = () => {
  return cardElement
}

export const StripeCard = ({ key }) => {
  const elements = useElements();

  if (elements !== null) {
    cardElement = elements.getElement(CardElement)
  }

  return (
    <Stack width="90%">
      <CardElement key={key} options={cardElementOptions} />
    </Stack>
  )
}

