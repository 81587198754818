import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { JOB_TYPE_SELECT_OPTIONS, TRAINING_TYPE_OPTIONS } from '../../Configs/TrainingFilterConstants';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import CustomToggleButton from '../UiComponents/CustomToggleButton';
import { NewJobModal } from './NewJobModal';

const Filters = ({ typeFilter, setTypeFilter, jobType, setJobType, searchedJob, setSearchedJob }) => {

  const [isNewJobModalOpen, setIsNewJobModalOpen] = useState(false)

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <CustomToggleButton
          options={TRAINING_TYPE_OPTIONS}
          selected={typeFilter}
          setSelected={setTypeFilter}
        />
        <Stack gap={2} direction="row" width="75%">
          <OutlinedInput
            value={searchedJob}
            size='small'
            fullWidth
            border={`1px solid ${color.borders}`}
            onChange={e => setSearchedJob(e.target.value)}
            sx={{
              fontSize: '15px',
              borderRadius: "8px",
              boxShadow: "0px 0px 4px 0px #00000029"
            }}
            placeholder='Search for job'
            startAdornment={<InputAdornment position="start">
              <SearchIcon fontSize='small' />
            </InputAdornment>}
          />
          <CustomButton height="42px" width="60%" onClick={() => setIsNewJobModalOpen(true)}>
            <Stack direction="row" alignItems="center" height="32px">
              <PlayArrowIcon />
              <Typography fontSize="15px">Launch New Job</Typography>
            </Stack>
          </CustomButton>
        </Stack>
      </Stack>
      <Select
        placeholder='Select Status'
        size='small'
        sx={{ fontSize: '15px', width: "25%", borderRadius: '8px', bgcolor: '#fff' }}
        value={jobType}
        onChange={(e) => setJobType(e.target.value)}
      >
        {
          JOB_TYPE_SELECT_OPTIONS.map((option, idx) => (
            <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
              {option}
            </MenuItem>
          ))
        }
      </Select>
      <NewJobModal isOpen={isNewJobModalOpen} handleClose={() => setIsNewJobModalOpen(false)} />
    </Stack>
  )
}

export default Filters