import DeleteIcon from '@mui/icons-material/Delete';
import { DialogContent, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import SecondaryButton from '../UiComponents/SecondaryButton';


export default function DeleteConfirmationModal({ open, setOpen, itemName, handleDelete }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}
    >
      <Stack spacing={2}>
        <DialogTitle sx={{ p: 2, pb: 1 }}>
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid"
              borderColor={color.error}
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="8px"
            >
              <DeleteIcon fontSize='15px' sx={{ color: color.error }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>
              {`Delete ${itemName} ?`}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "40vw"
          }}
        >
          <Typography fontSize="15px">
            {`Are you sure you want to delete this ${itemName.toLowerCase()}? This action cannot be undone.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>
            Cancel
          </SecondaryButton>
          <CustomButton onClick={handleDelete} autoFocus background={color.error} width="20%">
            Delete
          </CustomButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}