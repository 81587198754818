import {
  // Box, Chip,
  Divider,
  FormControlLabel,
  // MenuItem, OutlinedInput, Select, 
  Stack, Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CLOUD_PROVIDERS_OPTIONS, CLOUD_PROVIDERS_OPTIONS_CREDENTIALS } from '../../Configs/CloudProviderConstants'
import { AWS } from '../../Configs/Constants'
import { useGetCloudProviderRegionsQuery } from '../../Services/cloudProviderApi'
import { color } from '../../Styles/Color'
import BoxSwitch from '../UiComponents/BoxSwitch'
import InputField from '../UiComponents/InputField'
import Loader from '../UiComponents/Loader'
import { SelectDropdown } from '../UiComponents/SelectDropdown'

const ProviderCredtials = ({
  formValues,
  setFormValues,
}) => {
  const { name, icon } = CLOUD_PROVIDERS_OPTIONS[formValues.cloud_provider]

  const { data: regions, isLoading } = useGetCloudProviderRegionsQuery(name.toUpperCase());

  const [isCustomVPC, setIsCustomVPC] = useState(false)

  useEffect(() => {
    Object.keys(formValues.creds)
      .filter(key => key.includes("VPC"))
      .filter(key =>
        !formValues.regions.includes(key.split("_")[1])
      )
      .forEach(key => delete formValues.creds[key])

    if (isCustomVPC) {
      formValues.regions.forEach(region => {
        if (!formValues.creds[`AWS_${region}_VPC_ID`]) {
          formValues.creds[`AWS_${region}_VPC_ID`] = ""
        }
      })
    } else {
      Object.keys(formValues.creds)
        .filter(key => key.includes("VPC"))
        .forEach(key => delete formValues.creds[key])
    }
  }, [formValues.creds, formValues.regions, isCustomVPC])

  if (isLoading) {
    return <Stack height="45vh">
      <Loader />
    </Stack>
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormValues({ ...formValues, regions: value })
  };

  return (
    <Stack spacing={3} py={1.5}>
      <Stack spacing={1}>
        <Typography variant='h3'>
          Provider
        </Typography>
        <Stack direction="row" gap={1.5} alignItems="center">
          <Stack
            width="40px"
            height="40px"
            justifyContent="center"
            alignItems="center"
            border="1px solid"
            borderColor={color.primary}
            borderRadius="8px"
            bgcolor={color.lightBlue}
            sx={{
              cursor: "pointer",
            }}
          >
            {icon("20px")}
          </Stack>
          <Typography
            variant='h3'
            color={color.primary}
          >
            {name}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant='h3' >
          Regions
        </Typography>
        <Typography variant='subtitle1' color={color.secondaryText}>
          Choose the region where you want your cloud to be
        </Typography>
        {/* <Select
          size='small'
          multiple
          value={formValues.regions}
          onChange={handleChange}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={(selected, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography color={color.secondaryText} fontSize="15px">All Regions</Typography>
                  : selected?.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))}
            </Box>
          )}
          sx={{ bgcolor: color.white, borderRadius: "8px" }}
        >
          {(regions || ["region1"])?.map((name, idx) => (
            <MenuItem
              key={idx}
              value={name}
              sx={{ fontSize: "15px" }}
            >
              {name}
            </MenuItem>
          ))}
        </Select> */}
        <SelectDropdown
          value={formValues.regions}
          handleChange={handleChange}
          options={regions || ["us-east-1"]}
          placeholder="All Regions"
          multiple={true}
        />
      </Stack>
      <Divider />
      <Stack spacing={3}>
        <Typography variant='h3' color={color.primary}>
          Cloud Provider Credtials
        </Typography>
        {
          CLOUD_PROVIDERS_OPTIONS_CREDENTIALS[formValues.cloud_provider]?.map((creds, idx) => {
            let key = creds.formKey;
            return <Stack key={idx} spacing={1}>
              <Typography variant='h3'>{creds.label}</Typography>
              <InputField
                state={formValues.creds[key]}
                setState={(e) => setFormValues(
                  {
                    ...formValues,
                    creds: {
                      ...formValues.creds,
                      [key]: e.target.value
                    }
                  })}
                placeholder={creds.placeholder}
              />
            </Stack>
          })
        }
      </Stack>
      {
        formValues.cloud_provider === AWS &&
        <>
          <Divider />
          <Stack spacing={2}>

            <FormControlLabel
              value={isCustomVPC}
              control={
                <BoxSwitch
                  disabled={formValues.cloud_provider !== AWS || formValues.regions.length === 0}
                  onChange={(e) => setIsCustomVPC(e.target.checked)}
                />
              }
              label={<Typography fontSize="17px">Custom VPCs</Typography>}
            />
            {
              isCustomVPC === true &&
              <Stack spacing={2} >
                <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
                  <Stack width="40%" gap={1}>
                    <Typography fontSize="15px">
                      Region
                    </Typography>
                  </Stack>
                  <Stack width="40%" gap={1}>
                    <Typography fontSize="15px">
                      VPC ID
                    </Typography>
                  </Stack>
                </Stack>
                {
                  formValues.regions.map((region, idx) =>
                    <Stack direction="row" key={idx} alignItems="center" fontSize="15px" gap={2}>
                      <InputField
                        width="40%"
                        disabled={true}
                        state={region}
                      />
                      <InputField
                        width="40%"
                        placeholder="Enter VPC ID"
                        state={formValues.creds[`AWS_${region}_VPC_ID`]}
                        setState={(e) => setFormValues(
                          {
                            ...formValues,
                            creds: {
                              ...formValues.creds,
                              [`AWS_${region}_VPC_ID`]: e.target.value
                            }
                          })}
                      />
                    </Stack>
                  )
                }
              </Stack >
            }
          </Stack>
        </>
      }
    </Stack >
  )
}

export default ProviderCredtials