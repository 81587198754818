import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import AddPathModal from '../Components/StoragePathCompnents/AddPathsModal';
import PathList from '../Components/StoragePathCompnents/PathList';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { openStorageModal } from '../DataStore/modalSlice';
import { useDeleteArtifactStoragePathMutation, useGetArtifactStoragePathsQuery } from '../Services/artifactStorageApi';


const ArtifactStoragesView = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetArtifactStoragePathsQuery();
  const [handleDelete, { isLoading: isDeleting }] = useDeleteArtifactStoragePathMutation()

  if (isLoading || isDeleting) {
    return <Loader />
  }

  return (
    <Stack
      spacing={5}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
      p={1}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" >
        <Typography variant='h1'>
          Artifact Storages
        </Typography>
        <CustomButton width="30%" onClick={() => dispatch(openStorageModal())}>
          <AddIcon fontSize='small' sx={{ mr: 1 }} />
          ADD ARTIFACT STORAGE
        </CustomButton>
      </Stack>
      <PathList data={data} handleDelete={handleDelete} openModal={() => dispatch(openStorageModal())} />
      <AddPathModal />
    </Stack>
  )
}

export default ArtifactStoragesView