import { Stack, Typography } from "@mui/material"
import { useGetJobGPUNodesQuery } from "../../Services/jobsApi"
import { color } from "../../Styles/Color"
import Loader from "../UiComponents/Loader"

export const ActiveGPUs = ({ jobId, status }) => {

  const { data: gpuNodes, isLoading: isLoadingGPUNodes } = useGetJobGPUNodesQuery(
    jobId,
    {
      skip: (status === "DELETED" || status === "FAILED")
    }
  )

  if (isLoadingGPUNodes) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack direction="row" gap={2} flexWrap="wrap" px={1}>
      {
        gpuNodes && gpuNodes.length > 0 ?
          gpuNodes.map((gpu, i) => (
            <Stack
              key={i}
              minWidth="30%"
              borderRadius="12px" justifyContent="center"
              boxShadow="0px 1px 4px 0px #0000001F" p={2}
              bgcolor={color.white}
              spacing={1}
            >
              <Typography
                fontFamily="IBMPlexSansSemiBold"
                align="center"
              >
                {gpu.cloud.toUpperCase()}
              </Typography>
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <Typography fontSize="15px" >GPU ID</Typography>
                <Typography color={color.primary} fontSize="15px">
                  {
                    gpu.id.length > 20 ?
                      gpu.id.slice(0, 20) + "..." : gpu.id
                  }
                </Typography>
              </Stack>
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <Typography fontSize="15px" >GPU Type</Typography>
                <Typography color={color.primary} fontSize="15px">
                  {gpu.gpu_type || ""}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <Typography fontSize="15px" >GPU count</Typography>
                <Typography color={color.primary} fontSize="15px">
                  {gpu.gpu_count || 0}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <Typography fontSize="15px" >Region</Typography>
                <Typography color={color.primary} fontSize="15px">
                  {gpu.region}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <Typography fontSize="15px" >Role</Typography>
                <Typography color={color.primary} fontSize="15px">
                  {gpu.role}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <Typography fontSize="15px" >Is Spot Instace</Typography>
                <Typography color={color.primary} fontSize="15px">
                  {gpu.spot.toString()}
                </Typography>
              </Stack>
            </Stack>
          )) :
          <Typography color={color.primary} fontSize="15px">No Active GPUs</Typography>
      }
    </Stack>
  )
}
