import React, { useState } from 'react'
import NavigationTabs from '../UiComponents/NavigationTabs'
import { ActiveGPUs } from './ActiveGPUs'
import { BuildLogs } from './BuildLogs'
import JobArgs from './JobArgs'
import { JobStatus } from './JobStatus'
import { LiveLogs } from './LiveLogs'

const JobConfig = ({ job }) => {

  const [activeTab, setActiveTab] = useState('0')

  const JOB_PANELS = Object.freeze([
    <JobStatus job={job} />,
    <JobArgs job={job} />,
    <ActiveGPUs jobId={job.id} status={job.status} />,
    <BuildLogs job={job} />,
    <LiveLogs job={job} />
  ])

  const FINE_TUNE_PANELS = Object.freeze([
    <JobStatus job={job} />,
    <ActiveGPUs jobId={job.id} status={job.status} />,
    <LiveLogs job={job} />
  ])

  const JOB_TABS = Object.freeze([
    "Job Status",
    "Job Args",
    "Active GPUs",
    "Build Logs",
    "Live Logs"
  ])

  const FINETUNEING_JOB_TABS = Object.freeze([
    "Job Status",
    "Active GPUs",
    "Live Logs",
  ])

  return (
    <NavigationTabs
      tabs={job.spec.type === "FINETUNING" ? FINETUNEING_JOB_TABS : JOB_TABS}
      panels={job.spec.type === "FINETUNING" ? FINE_TUNE_PANELS : JOB_PANELS}
      value={activeTab} setValue={setActiveTab}
    />
  )
}

export default JobConfig