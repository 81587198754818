import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Chip, Stack } from '@mui/material';
import { CONFIGURE_OPTIONS } from '../../../Configs/JobConstants';
import { color } from '../../../Styles/Color';
import CustomToggleButton from '../../UiComponents/CustomToggleButton';
import { FTCloudConfig } from './FTCloudConfig';
import { FTOnPremiseConfig } from './FTOnPremiseConfig';

export const FTGPUConfig = ({ setSpotInstance, gpuOptions, gpuNodes, setGpuNodes,
  // gputype, setGputype,
  gpuTypeOptions, allowOtherGPU, setAllowOtherGPU, cloudProvider, setCloudProvider, region, setRegion, cloudProviderOptions, regionOptions, cloudBurst, setCloudBurst, gpuNodeType, setGPUNodeType, instanceType,
  setInstancetype, watch, setValue,
  // numberOfGPUs, setNumberOfGPUs, 
  instanceTypeOptions, gpuPerNode, setGPUPerNode, selectedConfig, setSelectedConfig, isFetchingGPUs, isFetchingInstances }) => {

  return (
    <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Chip
        sx={
          {
            bgcolor: "#FFFFFF",
            color: color.primary,
            width: "25%",
            borderRadius: "6px",
            fontSize: "15px"
          }
        }
        label={
          <Stack gap={1} direction="row" alignItems="center">
            <AppRegistrationIcon sx={{ color: color.primary, fontSize: "16px" }} />
            Configure
          </Stack>
        } />
      <CustomToggleButton options={CONFIGURE_OPTIONS} selected={selectedConfig} setSelected={setSelectedConfig} />
      {
        selectedConfig === CONFIGURE_OPTIONS[1] ?
          <FTOnPremiseConfig gpuOptions={gpuOptions} setGpuNodes={setGpuNodes} gpuNodes={gpuNodes}
            // gputype={gputype} setGputype={setGputype}
            gpuTypeOptions={gpuTypeOptions} allowOtherGPU={allowOtherGPU}
            setAllowOtherGPU={setAllowOtherGPU}
            cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
            region={region} setRegion={setRegion} cloudProviderOptions={cloudProviderOptions}
            regionOptions={regionOptions} setSpotInstance={setSpotInstance}
            cloudBurst={cloudBurst} setCloudBurst={setCloudBurst} setGPUNodeType={setGPUNodeType}
            gpuNodeType={gpuNodeType}
            // numberOfGPUs={numberOfGPUs} setNumberOfGPUs={setNumberOfGPUs}
            instanceTypeOptions={instanceTypeOptions} instanceType={instanceType}
            setInstancetype={setInstancetype} gpuPerNode={gpuPerNode} setGPUPerNode={setGPUPerNode}
            watch={watch} setValue={setValue}
          />
          : <FTCloudConfig
            // gputype={gputype} setGputype={setGputype}
            gpuOptions={gpuTypeOptions} allowOtherGPU={allowOtherGPU}
            setAllowOtherGPU={setAllowOtherGPU}
            cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
            region={region} setRegion={setRegion} cloudProviderOptions={cloudProviderOptions}
            regionOptions={regionOptions} setSpotInstance={setSpotInstance} instanceType={instanceType}
            setInstancetype={setInstancetype} instanceTypeOptions={instanceTypeOptions}
            // numberOfGPUs={numberOfGPUs} setNumberOfGPUs={setNumberOfGPUs}
            isFetchingGPUs={isFetchingGPUs} isFetchingInstances={isFetchingInstances}
            watch={watch} setValue={setValue}
          />
      }
    </Stack >
  )
}
