import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';
import * as Sentry from "@sentry/react";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { PAGE_ROUTES } from '../Configs/Routes';
import { setPlatformBearer, setSgUser, setUser } from '../DataStore/userSlice';
import { Header } from '../NewComponents/Header';
import { Navbar } from '../NewComponents/Navbar';
import { useVerifyUserMutation } from '../Services/userApi';
import LoadingView from '../Views/LoadingView';

export const Layout = () => {
  const nav = useNavigate()
  const dispatch = useDispatch()
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const platformBearer = useSelector((state) => state.userState.platformBearer);
  const [verifyUser, { data: sgUser, isLoading: isUserVerifing, isSuccess }] = useVerifyUserMutation();
  const [appLoading, setAppLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && isAuthenticated) {
        dispatch(setUser(user));
        Sentry.setUser({ email: user.email });
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:all",
        });
        dispatch(setPlatformBearer(token));
        verifyUser()
          .catch(() => nav(PAGE_ROUTES.login))
      }
    };

    fetchData();
  }, [user, isAuthenticated, isLoading, dispatch, platformBearer, getAccessTokenSilently, verifyUser, nav]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSgUser(sgUser))
    }
  }, [dispatch, isSuccess, sgUser]);

  useEffect(() => {
    if (!isLoading && !isUserVerifing) {
      setAppLoading(false)
    }
  }, [isLoading, isUserVerifing])

  if (!isAuthenticated && !isLoading) {
    nav(PAGE_ROUTES.login)
  }

  return appLoading ?
    <LoadingView /> :
    (
      <Stack direction="row" height="100vh">
        <Stack width="15%">
          <Navbar />
        </Stack>
        <Stack height="100vh" width="85%">
          <Header />
          <Stack p={4} pr={7} height="92vh" overflow="auto">
            <Outlet />
          </Stack>
        </Stack>
      </Stack>
    )
}
