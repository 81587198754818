import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FormControlLabel, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { color } from '../../../Styles/Color';
import BoxSwitch from '../../UiComponents/BoxSwitch';
import InputField from '../../UiComponents/InputField';

export const AdvancedVM = ({ newVm, setNewVm }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Stack
      p={1}
      py={1.5}
      border={`1px solid ${color.borders}`}
      borderRadius="8px"
      bgcolor={!isOpen && color.primary}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        px={2}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography variant="h2" color={isOpen ? color.primary : color.white}>
          Advanced
        </Typography>
        {
          !isOpen ? <KeyboardArrowDownIcon sx={{ color: color.white }} />
            : <KeyboardArrowUpIcon sx={{ color: color.primary }} />
        }
      </Stack>
      {
        isOpen &&
        <Stack gap={2} p={2}>
          <Typography variant='h3'>
            Filter
          </Typography>
          <InputField
            placeholder="Enter filter value"
            state={newVm.filter}
            setState={(e) => setNewVm((prev) => ({ ...prev, filter: e.target.value }))}
          />
          <FormControlLabel
            sx={{
              p: 1
            }}
            control={
              <BoxSwitch
                onChange={(e) => setNewVm(prev => ({ ...prev, unravelArchives: e.target.checked }))}
              />
            }
            label={
              <Typography variant='h3'>VirtualMount UnravelArchives</Typography>
            }
          />
          <FormControlLabel
            sx={{
              p: 1
            }}
            control={
              <BoxSwitch
                onChange={(e) => setNewVm(prev => ({ ...prev, prefetch: e.target.checked }))}
              />
            }
            label={
              <Typography variant='h3'>VirtualMount Prefetch</Typography>
            }
          />
        </Stack>
      }
    </Stack >
  )
}
