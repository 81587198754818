/* eslint-disable eqeqeq */
import ClearIcon from '@mui/icons-material/Clear';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Slide, Stack, Typography } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { color } from '../../Styles/Color';
// import Loader from '../UiComponents/Loader';
// import BoltIcon from '@mui/icons-material/Bolt';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import { useLocation } from 'react-router';
import CustomButton from '../../Components/UiComponents/CustomButton';
import SecondaryButton from '../../Components/UiComponents/SecondaryButton';
import { SelectDropdown } from '../../Components/UiComponents/SelectDropdown';
import { POPULAR_GPU_TYPES } from '../../Configs/JobConstants';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { useGetPriceEstimationMutation } from '../../Services/inferenceApi';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditInstanceModal = ({ isOpen, setIsOpen, watch, setValue, currentEstimate }) => {

  const isInference = useLocation().pathname.includes(PAGE_ROUTES.inferences)
  let regions = watch("cloud_providers")[0]?.regions
  let gpu_type = watch(isInference ? "initial_worker_config.initial_workers_gpu" : 'gpu_type')
  let gpu_count = watch(isInference ? "initial_worker_config.initial_workers_gpu_num" : 'gpu_count')
  let allow_other_gpus = watch("initial_worker_config.use_other_gpus")

  const [getPrice, { data, isLoading }] = useGetPriceEstimationMutation()

  const [formValues, setFormValues] = useState({})

  useEffect(() => {
    const initialFormState = {
      regions: regions,
      gpu_type: gpu_type,
      gpu_count: gpu_count,
      allow_other_gpus: allow_other_gpus,
    }

    setFormValues(initialFormState)
  }, [allow_other_gpus, gpu_count, gpu_type, isInference, regions])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleUpdate = () => {
    setValue(
      "cloud_providers",
      [
        {
          name: "SCALEGENAI",
          regions: formValues.regions
        }
      ]
    )
    setValue(isInference ? "initial_worker_config.initial_workers_gpu" : 'gpu_type', formValues.gpu_type)
    setValue(isInference ? "initial_worker_config.initial_workers_gpu_num" : 'gpu_count', formValues.gpu_count)
    setValue("initial_worker_config.use_other_gpus", formValues.allow_other_gpus)
    handleClose()
  }

  const cloudProviders = watch('cloud_providers')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formValues.gpu_count !== null && formValues.gpu_type !== null) {
        getPrice(
          {
            "cloud": cloudProviders.map(cp => cp.name),
            "gpu": [
              {
                "type": formValues.gpu_type,
                "count": formValues.gpu_count
              }
            ],
            "region": formValues.regions
          }
        )
      }
    }, 1500)
    return () => {
      clearTimeout(timer)
    }
  }, [cloudProviders, formValues.gpu_count, formValues.gpu_type, formValues.regions, getPrice])

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant='h2' color={color.primary}>
              {isInference ? "Manage Instance Type" : "Manage GPU Configurations"}
            </Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "35vh",
          width: "40vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // }
        }}
      >
        <Stack spacing={2} py={1}>
          <Stack
            bgcolor={color.secondaryBackground}
            border={`1px solid ${color.borders}`}
            p={2}
            spacing={1}
            borderRadius="6px"
          >
            <Typography variant='subtitle1' color={color.secondaryText}>
              Current Instance
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                <DynamicFormIcon fontSize='small' sx={{ color: color.primary }} />
                <Typography variant='body2'>
                  {
                    watch(isInference ? "initial_worker_config.initial_workers_gpu_num" : 'gpu_count')}x {
                    watch(
                      isInference ? "initial_worker_config.initial_workers_gpu" : 'gpu_type'
                    )
                  }
                </Typography>
              </Stack>
              {/* {
                isInference &&
                <Stack direction="row" gap={1} alignItems="end">
                  <BoltIcon fontSize='small' sx={{ color: color.primary }} />
                  <Typography variant='body2'>400</Typography>
                  <Typography variant='subtitle1' color={color.secondaryText}>tokens/sec</Typography>
                </Stack>
              } */}
              {
                currentEstimate &&
                <Stack direction="row" alignItems="end" gap={0.5}>
                  <Typography variant='body2'>approx. $ {Math.round(currentEstimate * 100) / 100}</Typography>
                  <Typography variant='subtitle1' color={color.secondaryText}>/hr</Typography>
                </Stack>
              }
            </Stack>
          </Stack>
          <Typography variant='h3'>Choose GPU Type</Typography>
          <Stack direction="row" gap={1} flexWrap="wrap" >
            {
              POPULAR_GPU_TYPES.map((gpu) => {
                return (
                  <Chip
                    key={gpu.type}
                    sx={{
                      color: gpu.type == formValues.gpu_type && color.white,
                      bgcolor: gpu.type == formValues.gpu_type ? color.primary : color.secondaryBackground,
                      border: gpu.type != formValues.gpu_type && `2px solid ${color.borders}`,
                      borderRadius: "6px",
                      fontSize: "12px",
                      minWidth: "12%",
                      "&:hover": {
                        bgcolor: gpu.type == formValues.gpu_type ? color.primary : color.secondaryBackground,
                      }
                    }}
                    label={
                      <Typography variant='body2'>
                        {gpu.name}
                      </Typography>
                    }
                    clickable={true}
                    onClick={() => {
                      gpu.type != formValues.gpu_type && setFormValues(prev => ({ ...prev, gpu_type: gpu.type }))
                    }}
                  />
                )
              }
              )
            }
          </Stack>
          <Typography variant='h3'>No. of GPUs</Typography>
          <Stack direction="row" gap={2} flexWrap="wrap" >
            {
              ["1", "2", "4", "8"].map((count) => (
                <Chip
                  key={count}
                  sx={{
                    color: count == formValues.gpu_count && color.white,
                    bgcolor: count == formValues.gpu_count ? color.primary : color.secondaryBackground,
                    border: count != formValues.gpu_count && `2px solid ${color.borders}`,
                    borderRadius: "6px",
                    fontSize: "12px",
                    minWidth: "12%",
                    "&:hover": {
                      bgcolor: count == formValues.gpu_count ? color.primary : color.secondaryBackground,
                    }
                  }}
                  label={
                    <Typography variant='body2'>
                      {count}
                    </Typography>
                  }
                  clickable={true}
                  onClick={() => {
                    count != formValues.gpu_count && setFormValues(prev => ({ ...prev, gpu_count: count }))
                  }}
                />
              )
              )
            }
          </Stack>
          {
            !isInference && <>
              <Typography variant='h3'>Choose Cloud Region(s)</Typography>
              <SelectDropdown
                value={formValues.regions} handleChange={(e) =>
                  setFormValues(prev => ({ ...prev, regions: e.target.value }))
                }
                options={["US", "EU", "ASIA", "CANADA"]} multiple="true"
              />
            </>
          }
          <Stack
            border={`1px solid ${color.borders}`}
            p={2}
            spacing={1}
            borderRadius="6px"
          >
            <Typography variant='subtitle1' color={color.secondaryText}>
              Selected Instance
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                <DynamicFormIcon fontSize='small' sx={{ color: color.primary }} />
                <Typography variant='body2'>{formValues.gpu_count}x {formValues.gpu_type}</Typography>
              </Stack>
              {/* {
                isInference &&
                <Stack direction="row" gap={1} alignItems="end">
                  <BoltIcon fontSize='small' sx={{ color: color.primary }} />
                  <Typography variant='body2'>500</Typography>
                  <Typography variant='subtitle1' color={color.secondaryText}>tokens/sec</Typography>
                </Stack>
              } */}
              {
                data ?
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <Typography variant='body2'>
                      approx. $ {Math.round(data.on_demand_price.min * 100) / 100}
                    </Typography>
                    <Typography variant='body2' color={color.secondaryText}>/ hr</Typography>
                  </Stack> :
                  isLoading &&
                  <Typography variant='body2' color={color.secondaryText}>
                    calculating estimates....
                  </Typography>
              }
            </Stack>
          </Stack>
          {
            isInference &&
            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    sx={{
                      pl: 0
                    }}
                    checked={formValues.allow_other_gpus ? true : false}
                    onChange={(e) => setFormValues(prev => ({ ...prev, allow_other_gpus: e.target.checked }))}
                  />
                }
                label={<Typography variant="h3">Allow other GPU types</Typography>}
              />
              <Typography variant='subtitle1' color={color.secondaryText}>
                When enabled, if preferred GPU configuration is not available in the region of choice, an equivalent configuration will be selected.
                <Typography variant='subtitle1' color={color.secondaryText}>
                  Note: Might affect latency/throughput based on available config.
                </Typography>
              </Typography>
            </Stack>
          }
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open(
              isInference ?
                "https://docs.scalegen.ai/inf-guide#gpu-configuration"
                : "https://docs.scalegen.ai/ft-guide#gpu-configuration",
              "_blank")
            }
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <CustomButton
              onClick={handleUpdate}
              width="10%"
            >
              Update
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default EditInstanceModal