import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ClearIcon from '@mui/icons-material/Clear';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography
} from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { forwardRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice';
import { setIsSuccess, setSuccessMessage } from '../../DataStore/successSlice';
import { useCreateSubscriptionMutation } from '../../Services/billingApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';
import SecondaryButton from '../UiComponents/SecondaryButton';
import { getCardElement, StripeCard } from './StripeCard';

// import { lazy } from 'react';

// const StripeCard = lazy(() => import('./StripeCard'));

// const StripeCard = lazy(
//   () => import('./StripeCard').then(module => ({ default: module.StripeCard }))
// );


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SubscriptionModal = ({ isOpen, setIsOpen, refetchBalance, refetchTransaction, key }) => {

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()

  const user = useSelector(state => state.userState.sgUser)

  const [createSubscription, { isLoading: creatingSub }] = useCreateSubscriptionMutation()

  const [amount, setAmount] = useState("");
  const [months, setMonths] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = useCallback(() => {
    setIsProcessing(false)
    setAmount("")
    setMonths(1)
    setIsOpen(false)
  }, [setIsOpen])

  const handlePay = async (event) => {
    event.preventDefault();

    if (elements === null) {
      return;
    }

    setIsProcessing(true)

    createSubscription({
      amount: amount,
      months: months,
      payment_method_type: 'card'
    })
      .unwrap()
      .then(async res => {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          res.clientSecret,
          {
            payment_method: {
              card: getCardElement(),
              // customer: stripe_customer_id,
              billing_details: {
                name: user.email,
              },
            },
          }
        );

        if (error) {
          dispatch(setIsError(true))
          dispatch(setErrorMessage(error.message || "Payment Declined"))
          setIsProcessing(false)
        } else {
          dispatch(setIsSuccess(true))
          dispatch(setSuccessMessage("Payment " + paymentIntent.status))
          refetchBalance()
          refetchTransaction()
          handleClose()
        }
      })
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              padding={1}
              borderRadius="8px"
              bgcolor={color.primary}
              color={color.white}
            >
              <CardMembershipIcon fontSize='20px' />
            </Stack>
            <Typography variant='h2' color={color.primary}>Add Subscription</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          height: "35vh",
          width: "35vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // },
        }}
      >
        <Stack spacing={4} alignItems="center" justifyContent="center" height="30vh">
          <Stack spacing={2} width="90%">
            <Typography variant='h3'>Payment Amount ($ / month) </Typography>
            <InputField
              state={amount}
              setState={(e) => setAmount(e.target.value)}
              type='number'
            />
            <Typography variant='h3'>Months</Typography>
            <InputField
              state={months}
              setState={(e) => setMonths(e.target.value)}
              type='number'
              width="100%"
            />
          </Stack>
          <StripeCard key={key} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <CustomButton width="60%" onClick={handlePay}
              disabled={!stripe || !elements || isProcessing || amount.length === 0 || creatingSub}
            >
              {isProcessing || creatingSub ? "Processing Payment ..." : "Pay"}
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default SubscriptionModal