import { createApi } from "@reduxjs/toolkit/query/react";
import { platformAuthConfig } from "./platformAuthConfig.js";

const artifactStorageApi = createApi({
  reducerPath: "artifactStorageApi",
  tagTypes: ["StoragePaths"],
  ...platformAuthConfig(),
  endpoints: (builder) => ({
    getArtifactStoragePaths: builder.query({
      query: () => "/artifacts_storage",
      providesTags: ["StoragePaths"],
    }),
    createArtifactStoragePath: builder.mutation({
      query: (config) => ({
        url: "/artifacts_storage",
        method: "POST",
        body: config,
      }),
      invalidatesTags: ["StoragePaths"],
    }),
    deleteArtifactStoragePath: builder.mutation({
      query: (id) => ({
        url: `/artifacts_storage?artifacts_storage_id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["StoragePaths"],
    }),
    editArtifactStoragePath: builder.mutation({
      query: (config) => ({
        url: `/artifacts_storage`,
        method: "PUT",
        body: config,
      }),
    }),
  }),
});

export const {
  useGetArtifactStoragePathsQuery,
  useCreateArtifactStoragePathMutation,
  useDeleteArtifactStoragePathMutation,
  useEditArtifactStoragePathMutation,
} = artifactStorageApi;

export default artifactStorageApi;
