import { Divider, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import InputField from '../../Components/UiComponents/InputField'
import { FINE_TUNE_API_KEY_OPTIONS } from '../../Configs/ConfigureNewJobConstants'
import { color } from '../../Styles/Color'

export const TrackingConfig = ({
  watch, setValue
}) => {

  const [selected, setSelected] = useState(FINE_TUNE_API_KEY_OPTIONS[0])

  const comet_ML_Key = watch('autotrain_params.comet_ml_key')

  useEffect(() => {
    if (comet_ML_Key) setSelected(FINE_TUNE_API_KEY_OPTIONS[1])
  }, [comet_ML_Key])

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Experiment Tracking</Typography>
      <Stack
        spacing={3}
        borderRadius="12px"
        border={`1px solid ${color.borders}`}
        box-shadow="0px 1px 4px 0px #0000000A"
        p={3}
      >
        <Stack spacing={1}>
          <RadioGroup row value={selected} onChange={(e) => setSelected(e.target.value)}>
            <FormControlLabel
              value={FINE_TUNE_API_KEY_OPTIONS[0]}
              control={<Radio disableRipple />}
              label={<Typography variant="h3">{FINE_TUNE_API_KEY_OPTIONS[0]}</Typography>}
            />
            <FormControlLabel
              value={FINE_TUNE_API_KEY_OPTIONS[1]}
              control={<Radio disableRipple />}
              label={<Typography variant="h3">{FINE_TUNE_API_KEY_OPTIONS[1]}</Typography>}
            />
          </RadioGroup>
          <Divider />
        </Stack>
        {selected === FINE_TUNE_API_KEY_OPTIONS[0] ?
          <InputField
            key={0}
            state={watch('autotrain_params.wandb_key')}
            setState={(e) => setValue('autotrain_params.wandb_key', e.target.value)}
            placeholder="Enter WANDB API key"
          /> :
          <InputField
            key={1}
            state={watch('autotrain_params.comet_ml_key')}
            setState={(e) => setValue('autotrain_params.comet_ml_key', e.target.value)}
            placeholder="Enter CometML API key"
          />
        }
      </Stack>
    </Stack>
  )
}
