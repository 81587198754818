import { InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material'
import { color } from '../../Styles/Color'

export const MinThroughputConfig = ({ watch, setValue }) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h3" >
          Minimum Throughput
        </Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant='subtitle1' color={color.secondaryText}>
            Specify the minimum tokens/second throughput.
          </Typography>
          <Typography
            variant='subtitle1'
            color={color.primary}
            sx={{
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai/inf-guide", "_blank")}
          >
            Learn more
          </Typography>
        </Stack>
        <OutlinedInput
          value={watch('min_throughput_rate')}
          size='small'
          type='number'
          border={`1px solid ${color.borders}`}
          onChange={e => setValue('min_throughput_rate', e.target.value)}
          sx={{
            borderRadius: "8px",
            width: "60%",
          }}
          inputProps={
            {
              sx: {
                fontSize: "14px"
              }
            }
          }
          placeholder='Enter minimum throughput'
          endAdornment={
            <InputAdornment position="end">
              <Typography variant='body2' color={color.primary}>Tokens/sec</Typography>
            </InputAdornment>
          }
        />
      </Stack>
    </Stack>
  )
}
