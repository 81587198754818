import EditIcon from '@mui/icons-material/Edit';
import { Stack, TextField, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import StepperHeader from '../Components/TrainingViewComponents/StepperHeader';
import { setName } from '../DataStore/newJobSlice';
import { color } from '../Styles/Color';

const CreateJobView = () => {

  const { name: jobName } = useSelector(store => store.newJobData)
  const dispatch = useDispatch()

  return (
    <Stack gap={2}>
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" pb={1}>Training</Typography>
      <Stack direction="row" gap={2} alignItems="center" pb={1}>
        <Typography fontSize="15px">Job Name</Typography>
        <TextField
          size='small'
          value={jobName}
          variant="standard"
          placeholder='Enter job name'
          onChange={(event) => dispatch(setName(event.target.value))}
          sx={{
            fontFamily: "IBMPlexSansSemiBold",
          }}
          InputProps={{
            style: {
              fontFamily: "IBMPlexSansSemiBold", fontSize: "15px",
              color: color.primary
            },
          }}
        />
        <EditIcon sx={{ color: color.primary, fontSize: "16px" }} />
      </Stack>
      <StepperHeader />
    </Stack>
  )
}

export default CreateJobView