import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Stack } from '@mui/material';
// import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { color } from '../../Styles/Color';

export default function NavigationTabs({ panels, tabs, value, setValue, fontSize }) {

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        sx={{
          minHeight: "4vh",
          borderBottom: `1px solid ${color.borders}`,
          color: "red",
          '& .MuiTabs-indicator': {
            backgroundColor: `${color.primary}`,
          },
          '& .MuiTab-textColorPrimary': {
            color: color.secondaryText,
          },
          '& .Mui-selected': {
            color: `${color.primary}`,
          },
        }}
      >
        {
          tabs?.map((label, i) =>
            <Tab
              key={i}
              label={label}
              value={String(i)}
              sx={{
                fontWeight: 500,
                lineHeight: "19.6px",
                fontSize: fontSize || "16px",
                p: 1,
                mr: 4,
                mb: 1,
                minHeight: 0,
                minWidth: 0,
                textTransform: 'none',
              }}
            />
          )
        }
      </TabList>
      <Stack
        overflow="auto"
        sx={{
          pt: 1,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {
          panels?.map((tab, i) =>
            <TabPanel sx={{ p: 0, my: 0.5 }} key={i} value={String(i)}>{tab}</TabPanel>
          )
        }
      </Stack>
    </TabContext>
  );
}