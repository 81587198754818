import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, Typography } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { setRequirementsFileName } from '../../../DataStore/newJobSlice';
import { color } from '../../../Styles/Color';
import InputField from '../../UiComponents/InputField';

const RequirementsFileInput = ({ register, watch }) => {
  // const { requirementsFileName } = useSelector((store) => store.newJobData)
  // const dispatch = useDispatch()

  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography fontWeight="600" fontSize="15px">
          Requirements File Name
        </Typography>
        <Tooltip title="Information">
          <InfoOutlinedIcon sx={{
            color: '#ABABAB',
            fontSize: '20px'
          }} />
        </Tooltip>
      </Stack>
      <InputField width="70%" placeholder="Enter Value"
        // state={requirementsFileName}
        // setState={(e) => dispatch(setRequirementsFileName(e.target.value))}
        register={register} field="requirements" required={true} watch={watch}
      />
    </Stack >
  )
}

export default RequirementsFileInput