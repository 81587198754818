export const TRAINING_TYPE_OPTIONS = Object.freeze([
  "Fine-Tuning",
  "Training",
])

export const JOB_TYPE_SELECT_OPTIONS = Object.freeze([
  "All Jobs",
  "Queued",
  "Running",
  "Stopping",
  "Stopped",
  "Deleting",
  "Deleted",
  "Restarting",
  "Failed"
])

export const STEPPER_STEPS = Object.freeze(
  [
    "Code",
    "Data",
    "Configure",
    "Deploy"
  ]
)