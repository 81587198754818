import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, MenuItem, Select, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { GPU_MACHINE_COUNT_OPTIONS, GPU_MACHINE_TYPE_OPTIONS } from '../../Configs/GPUMachinesConstants';
import { color } from '../../Styles/Color';


export const GPUMachineOptions = ({
  filteredList, selected, setSelected, setFilterGPUCount, setFilterGPUType, filterGPUCount, filterGPUType,
}) => {

  return (
    <Stack spacing={5}>
      <Stack spacing={2}>
        <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold" >
          Choose GPU Machine
        </Typography>
        <Stack direction="row" gap={2}>
          <Select
            fullWidth
            displayEmpty
            size='small'
            sx={{ fontSize: '15px', borderRadius: '8px', background: '#fff' }}
            value={filterGPUType}
            onChange={(e) => {
              setFilterGPUType(e.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon fontSize='small' />
              </InputAdornment>
            }
          >
            <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
              Search by GPU Type
            </MenuItem>
            {
              GPU_MACHINE_TYPE_OPTIONS.map((option, idx) => (
                <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
                  {option}
                </MenuItem>
              ))
            }
          </Select>
          <Select
            fullWidth
            displayEmpty
            size='small'
            sx={{ fontSize: '15px', borderRadius: '8px', background: '#fff' }}
            value={filterGPUCount}
            onChange={(e) => {
              setFilterGPUCount(e.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon fontSize='small' />
              </InputAdornment>
            }
          >
            <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
              Search by GPU Count
            </MenuItem>
            {
              GPU_MACHINE_COUNT_OPTIONS.map((option, idx) => (
                <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
                  {option}
                </MenuItem>
              ))
            }
          </Select>
          <Stack width="10%" alignItems="center" justifyContent="center">
            <Tooltip title='Clear Filters' TransitionComponent={Zoom} placement="bottom-end">
              <IconButton
                onClick={() => {
                  setFilterGPUCount("")
                  setFilterGPUType("")
                }}
              >
                <ClearIcon sx={{ fontSize: '24px' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2} flexWrap='wrap' width="100%">
          {
            filteredList
              .filter(gpu => filterGPUType.length > 0 ? gpu.gpu_type.includes(filterGPUType) : gpu)
              .filter(gpu => filterGPUCount > 0 ? gpu.gpu_count === filterGPUCount : gpu)
              .length > 0
              ?
              filteredList
                .filter(gpu => filterGPUType.length > 0 ? gpu.gpu_type.includes(filterGPUType) : gpu)
                .filter(gpu => filterGPUCount > 0 ? gpu.gpu_count === filterGPUCount : gpu)
                .map((gpu, idx) => {
                  return (
                    <Stack
                      width="32%"
                      border="2px solid"
                      borderColor={selected === idx ? color.primary : color.borders}
                      borderRadius="8px"
                      boxShadow={selected === idx ? "0px 0px 1px 2px #92CBFF" : ""}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelected(idx)
                      }}
                      px={2}
                      py={2}
                      key={idx}
                      spacing={2}
                    >
                      {/* <Typography
                        fontFamily="IBMPlexSansSemiBold"
                        fontSize="15px"
                      >
                        {gpu.cloud.toUpperCase()}
                      </Typography> */}
                      <Stack spacing={1}>
                        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                          <Typography fontSize="14px">GPU Type</Typography>
                          <Typography color={color.primary} fontSize="14px">
                            {gpu.gpu_type}
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                          <Typography fontSize="14px">GPU count</Typography>
                          <Typography color={color.primary} fontSize="14px">
                            {gpu.gpu_count}
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                          <Typography fontSize="14px">Price/Hr</Typography>
                          <Typography color={color.primary} fontSize="14px">
                            {Math.round(gpu.on_demand * 100) / 100}
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                          <Typography fontSize="14px">Region</Typography>
                          <Typography color={color.primary} fontSize="14px">
                            {gpu.region}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                })
              : <Typography color={color.primary} fontSize="15px">No GPU available</Typography>

          }
        </Stack>
      </Stack>
    </Stack >
  )
}
