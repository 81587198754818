import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import React from 'react';
import { color } from '../../Styles/Color';

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          position: "absolute",
          top: "50%",
          left: '50%',
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          // color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          color: color.primary,
          animationDuration: '550ms',
          position: 'absolute',
          top: "50%",
          left: '50%',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const Loader = () => {
  return (
    <Stack width="100%" height="100%">
      <FacebookCircularProgress />
    </Stack>
  )
}

export default Loader