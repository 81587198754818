import TollIcon from '@mui/icons-material/Toll';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { ReactComponent as MetaLogo } from "../Assets/Images/meta-icon.svg";
// import { ReactComponent as MicrosoftLogo } from "../Assets/Images/Microsoft_icon.svg";
// import { ReactComponent as MistralLogo } from "../Assets/Images/mistral-ai-icon.svg";
import { ReactComponent as QwenLogo } from "../Assets/Images/qwen-logo.svg";

import { color } from '../Styles/Color';
import { PAGE_ROUTES } from "./Routes";


export const CONFIGURE_OPTIONS = Object.freeze([
  "Cloud",
  "On-Premise",
])

export const CLOUD_CONFIG_WORKER_OPTIONS = Object.freeze([
  "Optimal Worker Configuration",
  "Minimum Throughput Configuration",
])

export const EMBEDDINGS_CLOUD_CONFIG_WORKER_OPTIONS = Object.freeze([
  "Optimal Worker Configuration",
])

export const SCALING_POLICY_OPTIONS = Object.freeze([
  "Mean Latency Threshold"
])

export const QUANTIZATION_OPTIONS = Object.freeze([
  "fp4",
  "nf4",
  "int8",
])

export const MIXED_PRECISION_OPTIONS = Object.freeze([
  "fp16",
  "bf16"
])

export const TORCH_DTYPE_OPTIONS = Object.freeze([
  "auto",
  "bfloat16",
  "float16",
  "float32"
])

export const NEW_INFERENCE_OPTIONS = Object.freeze([
  {
    name: "Completions Model",
    rediect: PAGE_ROUTES.newInference,
    icon: <ViewInArIcon fontSize='small' />,
    type: "llm"
  },
  {
    name: "Embeddings Model",
    rediect: PAGE_ROUTES.newEmbeddingsInference,
    icon: <TollIcon fontSize='small' />,
    type: "embedding"
  },
])

export const INFERENCE_STATUS_FILTER_LIST = Object.freeze(
  ["Active", "Inactive", "Provisioning", "Failed", "Deleting", "Deleted"]
)

export const JOBS_STATUS_FILTER_LIST = Object.freeze(
  ["Running", "Queued", "Restarting", "Stopped", "Failed", "Deleted"]
)

export const EMBEDDINGS_MODEL_PROVIDERS = Object.freeze(
  [
    {
      name: "Custom Model",
      icon: <ViewInArIcon fontSize='large' sx={{ color: color.primary }} />
    },
  ]
)

export const COMPLETIONS_MODEL_PROVIDERS = Object.freeze(
  [
    {
      name: "Llama",
      icon: <MetaLogo width="40px" />
    },
    {
      name: "Qwen",
      icon: <QwenLogo
        height="80%"
        width="40%"
      />
    },
    // {
    //   name: "Mistral",
    //   icon: <MistralLogo width="40px" />
    // },
    // {
    //   name: "Phi-3",
    //   icon: <MicrosoftLogo width="40px" />
    // },
    {
      name: "Custom Model",
      icon: <ViewInArIcon fontSize='large' sx={{ color: color.primary }} />
    },
  ]
)
export const FINETUNE_TEMPLATES_OPTIONS = Object.freeze([
  {
    inf_type: 'llm',
    model: "Llama",
    provider: 'Meta',
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #2E87D9)',
    icon: <MetaLogo
      height="75%"
      style={{
        filter: "blur(8px)",
        transform: "translate(25%)"
      }}
    />,
  },
  {
    inf_type: 'llm',
    model: "Qwen",
    provider: 'Qwen',
    // color: 'linear-gradient(79.26deg, #FFFFFF 60%, #2E87D9)',
    icon: <QwenLogo
      height="80%"
      width="40%"
      style={{
        filter: "blur(6px)",
        transform: "translate(25%)"
      }}
    />,
  },
  // {
  //   inf_type: 'llm',
  //   model: "Mistral",
  //   provider: 'Mistral AI',
  //   // color: 'linear-gradient(76.86deg, #FFFFFF 60% , #FEDB6A 78.15%, #FF8A58)',
  //   icon: <MistralLogo
  //     height="75%"
  //     style={{
  //       filter: "blur(8px)",
  //       transform: "translate(35%)"
  //     }}
  //   />,
  // },
  // {
  //   inf_type: 'llm',
  //   model: "Phi-3",
  //   provider: 'Microsoft',
  //   // color: 'linear-gradient(76.86deg, #FFFFFF 60%, #59FFB4 78.15%, #2ED9B4)',
  //   icon: <MicrosoftLogo
  //     height="75%"
  //     style={{
  //       filter: "blur(8px)",
  //       transform: "translate(47%)"
  //     }}
  //   />,
  // }
])

export const AUTOSCALE_STRATEGY = Object.freeze(
  ["Request per Second", "TTFT Latency"]
)

export const EMBEDDING_AUTOSCALE_STRATEGY = Object.freeze(
  ["Request per Second"]
)

export const POPULAR_GPU_TYPES = Object.freeze(
  [
    {
      name: 'A100 (40GB)',
      type: "A100"
    },
    {
      name: 'A100 (80GB)',
      type: "A100_80GB"
    },
    {
      name: 'H100 (80GB)',
      type: "H100_80GB"
    },
    {
      name: 'L40',
      type: "L40"
    },
    {
      name: 'RTX (A6000)',
      type: "RTX_A6000"
    }
  ]
)