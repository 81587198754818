import ClearIcon from '@mui/icons-material/Clear';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography } from '@mui/material';
import { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { COMPLETIONS_MODEL_PROVIDERS, EMBEDDINGS_MODEL_PROVIDERS, NEW_INFERENCE_OPTIONS } from '../../Configs/JobConstants';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import SecondaryButton from '../UiComponents/SecondaryButton';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NewInferenceModal = ({ isOpen, handleClose }) => {
  const navigate = useNavigate()

  const [selected, setSelected] = useState(NEW_INFERENCE_OPTIONS[0])
  const [isActive, setIsActive] = useState(COMPLETIONS_MODEL_PROVIDERS[0].name)

  const handleLaunch = () => {
    if (selected === NEW_INFERENCE_OPTIONS[0]) {
      navigate(PAGE_ROUTES.newInference, { state: { model: isActive, type: selected.type } })
    } else {
      navigate(
        PAGE_ROUTES.newInference,
        {
          state: {
            model: null,
            type: selected.type
          }
        }
      )
    }
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              padding={1}
              borderRadius="8px"
              bgcolor={color.primary}
              color={color.white}
            >
              <ModelTrainingIcon fontSize='20px' />
            </Stack>
            <Typography variant='h2' color={color.primary}>Launch New Inference</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "15vh",
          width: "40vw",
          p: 2,
          '&::-webkit-scrollbar': {
            display: 'none',
          }
        }}
      >
        <Stack spacing={3}>
          <Stack direction="row" gap={2} >
            {
              NEW_INFERENCE_OPTIONS.map((option, idx) => (
                <Stack
                  key={idx}
                  onClick={() => {
                    setSelected(option)
                    if (option === NEW_INFERENCE_OPTIONS[1]) {
                      setIsActive(EMBEDDINGS_MODEL_PROVIDERS[0].name)
                    } else {
                      setIsActive(COMPLETIONS_MODEL_PROVIDERS[0].name)
                    }
                  }}
                  border="1px solid"
                  borderColor={selected.name === option.name ? color.primary : color.borders}
                  borderRadius="6px"
                  px={2}
                  py={1}
                  gap={2}
                  direction="row"
                  bgcolor={selected.name === option.name && color.lightBlue}
                  color={selected.name === option.name && color.primary}
                  minWidth="48%"
                  sx={{
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                >
                  {option.icon}
                  <Typography variant='h3'>{option.name}</Typography>
                </Stack>
              ))
            }
          </Stack>
          <Typography variant='h3'>Choose a model for Inference</Typography>
          <Stack direction="row" gap={3} flexWrap='wrap' width="38vw" pb={3}>
            {
              (selected === NEW_INFERENCE_OPTIONS[0] ?
                COMPLETIONS_MODEL_PROVIDERS :
                EMBEDDINGS_MODEL_PROVIDERS
              )
                .map(({ name, icon }) => (
                  <Stack key={name} spacing={2} alignItems="center" onClick={() => setIsActive(name)}>
                    <Stack
                      width="80px"
                      height="80px"
                      justifyContent="center"
                      alignItems="center"
                      border="1px solid"
                      borderColor={isActive === name ? color.primary : color.borders}
                      borderRadius="6px"
                      bgcolor={isActive === name && color.lightBlue}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {icon}
                    </Stack>
                    <Typography
                      variant='h3'
                      color={isActive === name && color.primary}
                    >
                      {name}
                    </Typography>
                  </Stack>
                ))
            }
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai/inf-guide", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>

          <Stack direction="row" gap={1}>
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <CustomButton
              onClick={handleLaunch}
              width="10%"
            >
              Launch
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog >
  )
}
