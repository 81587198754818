import SearchIcon from '@mui/icons-material/Search';
import { Chip, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import { useState } from "react";
import InferenceList from "../../Components/InferenceViewComponents/InferenceList";
import { INFERENCE_STATUS_FILTER_LIST } from '../../Configs/JobConstants';
import { color } from "../../Styles/Color";

export const Endpoints = () => {

  const [searched, setSearched] = useState("")
  const [filter, setFilter] = useState(["ACTIVE", "INACTIVE", "PROVISIONING"])

  return (
    <Stack spacing={5} py={2}>
      <Typography variant="h1">Deploy TheAgentic Base Models</Typography>
      <Stack spacing={2}>
        <OutlinedInput
          value={searched}
          size='small'
          border={`1px solid ${color.borders}`}
          onChange={e => setSearched(e.target.value)}
          sx={{
            borderRadius: "8px",
            boxShadow: "0px 0px 4px 0px #00000029",
          }}
          placeholder='Search by name or id'
          startAdornment={<InputAdornment position="start">
            <SearchIcon fontSize='small' />
          </InputAdornment>}
        />
        <Stack direction="row" gap={1}>
          {
            INFERENCE_STATUS_FILTER_LIST.map((label, idx) => {
              return (
                <Chip
                  key={idx}
                  label={label}
                  clickable
                  sx={{
                    bgcolor: filter.includes(label.toUpperCase()) ? color.primary : color.white,
                    color: filter.includes(label.toUpperCase()) && color.white,
                    px: 0.5,
                    "&:hover": {
                      bgcolor: filter.includes(label.toUpperCase()) ? color.primary : color.white,
                    },
                  }}
                  onClick={() => {
                    const index = filter.indexOf(label.toUpperCase())
                    if (index === -1) {
                      setFilter(prev => [...prev, label.toUpperCase()])
                    } else {
                      setFilter(prev => prev.filter(item => item !== label.toUpperCase()))
                    }
                  }}
                />
              )
            })
          }
        </Stack>
        <InferenceList data={[]} filter={filter} type="TheAgenticAI" />
      </Stack>
    </Stack>
  )
}
