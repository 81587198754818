import { Stack, Typography } from '@mui/material'
// import React, { useState } from 'react'
import { useState } from 'react'
import InferenceList from '../../Components/InferenceViewComponents/InferenceList'
import JobsList from '../../Components/TrainingViewComponents/JobsList'
import NavigationTabs from '../../Components/UiComponents/NavigationTabs'


export const ActiveJobs = ({ fetchedData, inferenceData }) => {

  const [active, setActive] = useState("0")

  return (
    <Stack spacing={2}>
      <Typography variant='h1'>Active Jobs</Typography>
      <NavigationTabs
        tabs={
          [
            "Inference",
            "Fine-tuning",
            // "Run-Script"
          ]
        }
        panels={
          [
            <InferenceList data={inferenceData} filter={["ACTIVE"]} />,
            <JobsList
              data={fetchedData?.filter(job => job.spec.type === "FINETUNING")}
              type="FINETUNING"
              filter={["RUNNING"]}
            />,
            // <JobsList data={fetchedData.filter(job => job.spec.type !== "FINETUNING")} type="RUNSCRIPT" />
          ]
        }
        value={active} setValue={setActive}
      />
    </Stack>
  )
}
