import { Button } from '@mui/material'
import React from 'react'
import { color } from '../../Styles/Color'

const CustomButton = ({ children, onClick, background, height, disabled }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        bgcolor: `${background || color.primary}`,
        color: color.white,
        borderRadius: "8px",
        fontSize: "14px",
        "&:hover": {
          bgcolor: `${background || color.primary}`
        },
        "&:disabled": {
          bgcolor: "#92CBFF",
          color: '#fff'
        },
        height: `${height ? height : "36px"}`,
        // width: `${width ? width : "100%"}`,
        p: 2,
        textTransform: "none",
      }} >
      {children}
    </Button>
  )
}

export default CustomButton