import EditIcon from '@mui/icons-material/Edit';
import { Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router";
import Loader from '../Components/UiComponents/Loader';
import { PAGE_ROUTES } from '../Configs/Routes';
import { setErrorMessage, setIsError } from '../DataStore/errorSlice';
import { FinetuneTemplateForm } from '../NewComponents/FineTuning/FinetuneTemplateForm';
import { useCreateFineTuneMutation } from '../Services/fineTuneApi';
import { color } from '../Styles/Color';
import { getTrimmedData } from '../Utils/trimmer';

export const RelaunchFinetune = () => {

  const config = useLocation().state.config

  const dispatch = useDispatch()

  const [submit, { isLoading: isSubmitting, isSuccess }] = useCreateFineTuneMutation()

  const { formState: { errors }, watch, setValue, setError, clearErrors, handleSubmit } =
    useForm({
      defaultValues: config
    })

  const nav = useNavigate()

  const handleBack = () => {
    nav(-1)
  }

  const handleValidation = () => {
    clearErrors()

    if (watch('job_name').length === 0) {
      dispatch(setIsError(true))
      dispatch(setErrorMessage("Job Name is required"))
      setError('job_name', { type: 'custom', message: 'Job Name is required' })
      return
    }

    if (watch('data_path').length === 0) {
      setError('dataset', { type: 'custom', message: 'This field is required' })
      return

    }

    if (watch('push_to_hub') && watch('username').length === 0) {
      setError('push_to_hub.username', { type: 'custom', message: 'This field is required' })
      return
    }

    if (watch('push_to_hub') && watch('repo_id').length === 0) {
      setError('push_to_hub.repo_id', { type: 'custom', message: 'This field is required' })
      return
    }

    if (watch("autotrain_params.use_peft") === "lora" && (
      watch('autotrain_params.lora_alpha') === "0" ||
      watch('autotrain_params.lora_dropout') === "0" ||
      watch('autotrain_params.lora_r') === "0" ||
      watch('autotrain_params.lora_alpha') === null ||
      watch('autotrain_params.lora_dropout') === null ||
      watch('autotrain_params.lora_r') === null
    )) {
      dispatch(setIsError(true))
      dispatch(setErrorMessage("Disable lora toggle , If you don't want lora"))
      return
    }

    const config = getTrimmedData(watch())
    handleSubmit(submit(config))
  }

  if (isSubmitting) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  if (isSuccess) {
    nav(PAGE_ROUTES.fineTuning)
  }

  return (
    <Stack spacing={5}>
      <Stack direction="row" gap={2} alignItems={errors?.job_name ? "start" : "end"}>
        <Typography variant="h1">Relaunch </Typography>
        <TextField
          size='small'
          variant="standard"
          placeholder='Enter deployment name'
          sx={{
            "& .MuiInput-root:before": {
              border: 0
            },
            "& .MuiInput-root:after": {
              border: 0
            },
            "& .MuiInput-input:hover": {
              border: 0
            }
          }}
          InputProps={{
            style: {
              fontSize: "14px",
              borderRadius: "8px",
              color: color.primary,
            },
          }}
          value={watch('job_name')}
          onChange={e => setValue('job_name', e.target.value)}
          // {...register("job_name", { required: { value: true, message: "This field is required" } })}
          error={errors?.job_name ? true : false}
          helperText={errors?.job_name?.message}
        />
        <EditIcon sx={{ color: color.primary, fontSize: "16px" }} />
      </Stack>
      <FinetuneTemplateForm
        handleBack={handleBack}
        backTitle="Go back"
        setValue={setValue}
        watch={watch}
        handleSubmit={handleValidation}
        isBaseModel={false}
      />
    </Stack>
  )
}
