import { Stack, Typography } from '@mui/material'
import { AccessKeys } from '../Components/SettingsComponents/AccessKeys'
import ConnectSlack from '../Components/SettingsComponents/ConnectSlack'
import NotificationSection from '../Components/SettingsComponents/NotificationSection'
import Profile from '../Components/SettingsComponents/Profile'
import Loader from '../Components/UiComponents/Loader'
import { useGetAccessKeysQuery } from '../Services/accessKeysApi'

const SettingsView = () => {
  const { data, isLoading } = useGetAccessKeysQuery()

  if (isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack spacing={4}>
      <Typography variant="h1">Settings</Typography>
      <Stack direction="row" gap={2}>
        <Stack spacing={3} width="60%">
          <Stack spacing={2}>
            <Typography variant="h2">Account</Typography>
            <Profile />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2">Access key</Typography>
            <AccessKeys data={data} />
          </Stack>
          <ConnectSlack />
        </Stack>
        <Stack width="40%" mt={-2}>
          <NotificationSection />
        </Stack>
      </Stack>
    </Stack >
  )
}

export default SettingsView