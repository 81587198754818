import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { Chip, Divider, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material'
import Fuse from 'fuse.js'
import React, { useEffect, useState } from 'react'
import JobsList from '../Components/TrainingViewComponents/JobsList'
import CustomButton from '../Components/UiComponents/CustomButton'
import Loader from '../Components/UiComponents/Loader'
import { JOBS_STATUS_FILTER_LIST } from '../Configs/JobConstants'
import { FinetuneTemplates } from '../NewComponents/FineTuning/FinetuneTemplates'
import { NewFineTuningModal } from '../NewComponents/FineTuning/NewFineTuningModal'
import { useGetJobsQuery } from '../Services/jobsApi'
import { color } from '../Styles/Color'


export const FineTuning = () => {

  const [searched, setSearched] = useState("")
  const [filter, setFilter] = useState(["RUNNING", "QUEUED"])
  const [searchedData, setSearchedData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data: fetchedData, isLoading, isSuccess, } =
    useGetJobsQuery(null, { pollingInterval: 10000 });

  useEffect(() => {
    if (isSuccess && fetchedData) {

      const fineTuneData = fetchedData.filter(job => job.spec.type === "FINETUNING")

      const options = {
        keys: ['name'],
        includeScore: true,
      }

      const fuse = new Fuse(fineTuneData, options)

      if (searched.length > 0) {
        setSearchedData(
          fuse.search(searched)
            .filter(j => j.score < 0.5)
            .map(j => j.item)
        )
      } else {
        setSearchedData(fineTuneData)
      }
    }
  }, [fetchedData, isSuccess, searched])


  if (isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack spacing={5}>
      <Stack spacing={2}>
        <Typography variant='h1' >Fine-Tuning</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant='body2' color={color.secondaryText}>
            Fine-tune popular LLMs like Llama, Mistral and Phi on your domain-specific data.
          </Typography>
          <Typography
            variant='body2'
            color={color.primary}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai/tutorials/finetune_llama3-1_8b", "_blank")}
          >
            Tutorials
          </Typography>
        </Stack>
      </Stack>
      <FinetuneTemplates />
      <Stack spacing={1.8}>
        <Stack direction="row" justifyContent='space-between' alignItems="center" >
          <OutlinedInput
            value={searched}
            size='small'
            border={`1px solid ${color.borders}`}
            onChange={e => setSearched(e.target.value)}
            sx={{
              borderRadius: "8px",
              boxShadow: "0px 0px 4px 0px #00000029",
              width: "80%",
            }}
            placeholder='Search by name'
            startAdornment={<InputAdornment position="start">
              <SearchIcon fontSize='small' />
            </InputAdornment>}
          />
          <CustomButton onClick={() => setIsModalOpen(true)}>
            <AddIcon fontSize='small' sx={{ mr: 1 }} />
            Launch New Job
          </CustomButton>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant='h2'>Status</Typography>
          <Divider sx={{ borderColor: color.secondaryText }} orientation='vertical' variant='middle' flexItem />
          {
            JOBS_STATUS_FILTER_LIST.map((label, idx) => {
              return (
                <Chip
                  key={idx}
                  label={label}
                  clickable
                  sx={{
                    bgcolor: filter.includes(label.toUpperCase()) ? color.primary : color.white,
                    color: filter.includes(label.toUpperCase()) && color.white,
                    px: 0.5,
                    "&:hover": {
                      bgcolor: filter.includes(label.toUpperCase()) ? color.primary : color.white,
                    },
                  }}
                  onClick={() => {
                    const index = filter.indexOf(label.toUpperCase())
                    if (index === -1) {
                      setFilter(prev => [...prev, label.toUpperCase()])
                    } else {
                      setFilter(prev => prev.filter(item => item !== label.toUpperCase()))
                    }
                  }}
                />
              )
            })
          }
        </Stack>
      </Stack>
      <JobsList
        data={searchedData}
        type="FINETUNING"
        filter={filter}
      />
      <NewFineTuningModal
        isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}
      />
    </Stack>
  )
}
