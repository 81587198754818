import { TabContext, TabList } from '@mui/lab'
import { Stack, Tab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { PAGE_ROUTES } from '../Configs/Routes'
import { color } from '../Styles/Color'

const tabs = [
  // {
  //   label: "Cloud Providers",
  //   route: PAGE_ROUTES.cloudProviders,
  // },
  // {
  //   label: "On-Prem Nodes",
  //   route: PAGE_ROUTES.onPremNodes
  // },
  {
    label: "Virtual Mounts",
    route: PAGE_ROUTES.virtualMounts,
  },
  {
    label: "Artifact Storages",
    route: PAGE_ROUTES.artifactStorages
  },
  {
    label: "Billing",
    route: PAGE_ROUTES.billing
  },
  // {
  //   label: "Secrets",
  //   route: PAGE_ROUTES.secrets
  // },
  // {
  //   label: "Members",
  //   route: PAGE_ROUTES.team
  // }
]

export const AdminLayout = () => {
  const nav = useNavigate()
  const location = useLocation().pathname

  const [currentTab, setCurrentTab] = useState()

  useEffect(() => {
    setCurrentTab(tabs.indexOf(tabs.find(tab => tab.route.includes(location))))
  }, [location])

  const handleChange = (event, newValue) => {
    nav(tabs[newValue].route)
  };

  return (
    <Stack height="100%" gap={1} marginTop="-2vh">
      <TabContext value={currentTab} >
        <TabList
          onChange={handleChange}
          sx={{
            minHeight: "6vh",
            borderBottom: "1px solid #E4E4E4",
            color: "red",
            '& .MuiTabs-indicator': {
              backgroundColor: `${color.primary}`,
            },
            '& .MuiTab-textColorPrimary': {
              color: color.secondaryText,
            },
            '& .Mui-selected': {
              color: `${color.primary}`,
            },
          }}
        >
          {
            tabs.map((tab, i) =>
              <Tab
                key={i}
                label={tab.label}
                value={i}
                sx={{
                  fontWeight: 500,
                  lineHeight: "19.6px",
                  fontSize: "16px",
                  p: 1,
                  mr: 4,
                  mb: 1,
                  minHeight: 0,
                  minWidth: 0,
                  textTransform: 'none',
                }}
              />
            )
          }
        </TabList>
      </TabContext>
      <Stack height="77vh" pt={2}>
        <Outlet />
      </Stack>
    </Stack>
  )
}
