import { useAuth0 } from '@auth0/auth0-react'
import { Stack, Typography } from '@mui/material'
import CustomButton from '../Components/UiComponents/CustomButton'
import { MAIN_FEATURES } from '../Configs/LandingPageConstants'
import { color } from '../Styles/Color'

export const Landing = () => {

  const productLogo = process.env.REACT_APP_PRODUCT_LARGE_LOGO
  const { loginWithRedirect, error } = useAuth0()

  return (
    <Stack
      direction="row"
      height="100vh"
      sx={{
        background: `linear-gradient(90deg, ${color.white} 30%, #A6D4FF)`
      }}
    >
      <Stack
        width="40%"
        justifyContent="center"
        alignItems="center"
      >
        <Stack width="60%" height="60%" spacing={3}>
          <Stack height="10%">
            {productLogo ?
              <img
                src={productLogo}
                style={{
                  maxWidth: "140px",
                }}
                alt={process.env.REACT_APP_PRODUCT_TYPE}
              />
              : <Typography
                color={color.white}
                fontSize="32px"
                fontWeight={800}
              >
                {process.env.REACT_APP_PRODUCT_TYPE}
              </Typography>
            }
          </Stack>
          <Typography variant='h1' fontSize={"32px"}>Get Started</Typography>
          <Typography variant='body1' fontSize={"20px"}>
            {error ?
              error.error_description :
              "Sign in with your registered email."
            }
          </Typography>
          <Stack width="40%">
            <CustomButton
              onClick={() => loginWithRedirect()}
            >
              Sign-in
            </CustomButton>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant='body1' color={color.secondaryText}>Don't have an account?</Typography>
            <Typography
              variant='body1'
              color={color.primary}
              sx={{
                textDecoration: "underline",
                "&:hover": {
                  cursor: "pointer"
                }
              }}
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                    prompt: 'login'
                  }
                })
              }
            >
              Sign Up
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        width="60%"
        justifyContent="center"
        alignItems="center"
      // bgcolor={color.primary}
      >
        <Stack width="70%" height="80%" spacing={3} mt={5}>
          <Typography variant='h1' color={color.primary} >
            Private LLMs and Cost-efficient AI Compute for Startups and Enterprises.
          </Typography>
          {
            MAIN_FEATURES.map((feature, idx) =>
              <Stack
                key={idx}
                bgcolor={color.white}
                border={`1px solid ${color.borders}`}
                borderRadius="8px"
                gap={2}
                p={2}
                height="23%"
                direction="row"
              >
                <Stack spacing={2} height="100%" justifyContent="center" width="45%" pl={2}>
                  <Stack direction='row' alignItems="end" >
                    <Typography variant='h1' fontSize="36px" color={color.primary}>{feature.times}</Typography>
                    <Typography variant='h2' fontSize="24px" color={color.primary}>
                      x {feature.determiner}
                    </Typography>
                  </Stack>
                  <Typography variant='body2' >{feature.text}</Typography>
                </Stack>
                <Stack spacing={2} height="100%" justifyContent="center" width="55%">
                  {
                    feature.illustration
                  }
                </Stack>
              </Stack>
            )
          }
        </Stack>
      </Stack>
    </Stack>
  )
}

//http://localhost:3000/?error=unauthorized&error_description=Please%20verify%20your%20email%20before%20logging%20in.&state=YkhmYW16eG11QnZpVXJ%2BRDh1cjQxSl9uMEVwS2xHZWZoZmpwN0NlcGFCag%3D%3D