import { Stack } from '@mui/material'
import { MAIN_NAVIGATION_TABS } from '../../../Configs/ConfigureNewJobConstants'
import NavigationTabs from '../../UiComponents/NavigationTabs'
import { EnvironmentConfigurations } from "./EnvironmentConfigurations"
import { NormalConfigurations } from './NormalConfigurations'

const ConfigureComponent = ({
  activeConfigureTab, setActiveConfigureTab, register, watch, setValue, errors,
  activeTab, setActiveTab
}) => {

  const MAIN_NAVIGATION_PANELS = Object.freeze([
    <NormalConfigurations
      register={register} watch={watch} setValue={setValue} errors={errors}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />,
    <EnvironmentConfigurations register={register} />
  ])

  return (
    <Stack
      overflow="auto"
      sx={{
        my: 1,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <NavigationTabs tabs={MAIN_NAVIGATION_TABS} panels={MAIN_NAVIGATION_PANELS}
        value={activeConfigureTab} setValue={setActiveConfigureTab} />
    </Stack>
  )
}

export default ConfigureComponent