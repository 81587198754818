// import { ReactComponent as DCLogo } from "../Assets/Images/DC_Logo_SQ.svg"
// import AntropicLogo from "../Assets/Images/anthropic-logo_fb47aaa7-9725-42fb-9aeb-889c99cdccf0_r4xcuu.webp"
import { ReactComponent as AWSLogo } from "../Assets/Images/aws.svg"
import { ReactComponent as AzureLogo } from "../Assets/Images/azure.svg"
// import CudoLogo from "../Assets/Images/cudo.jpeg"
// import CWlogo from "../Assets/Images/cw_logo_navy_horizontal_(1)_(3).jpg"
// import ExoscaleLogo from "../Assets/Images/exocale-logo.jpg"
import { ReactComponent as GCPLogo } from "../Assets/Images/google-cloud.svg"
// import { ReactComponent as LambdaLogo } from "../Assets/Images/lambda-logo 2.svg"
// import RunpodLogo from "../Assets/Images/runpod.png"
// import VastAiLogo from "../Assets/Images/vastai_small3.png"

import {
  // ANTHROPIC,
  AWS, AZURE,
  // CORE_WEAVE, CUDO, 
  DATACRUNCH,
  // EXOSCALE, 
  GCP,
  //  LAMBDA, 
  RUNPOD, VASTAI
} from "./Constants"

export const CLOUD_PROVIDERS_OPTIONS = Object.freeze({
  [AWS]: {
    name: AWS,
    icon: (width) => <AWSLogo width={width} />
  },
  [GCP]: {
    name: GCP,
    icon: (width) => <GCPLogo width={width} />
  },
  [AZURE]: {
    name: AZURE,
    icon: (width) => <AzureLogo width={width} />
  },
  // [DATACRUNCH]: {
  //   name: DATACRUNCH,
  //   icon: (width) => <DCLogo width={width} />
  // },
  // [LAMBDA]: {
  //   name: LAMBDA,
  //   icon: (width) => <LambdaLogo width={width} />
  // },
  // [EXOSCALE]: {
  //   name: EXOSCALE,
  //   icon: (width) => <img src={ExoscaleLogo} width={width} alt="" />
  // },
  // [CUDO]: {
  //   name: CUDO,
  //   icon: (width) => <img src={CudoLogo} width={width} alt="" />
  // },
  // [CORE_WEAVE]: {
  //   name: CORE_WEAVE,
  //   icon: (width) => <img src={CWlogo} width={width} alt="" />
  // },
  // [VASTAI]: {
  //   name: VASTAI,
  //   icon: (width) => <img src={VastAiLogo} width={width} alt="" />
  // },
  // [ANTHROPIC]: {
  //   name: ANTHROPIC,
  //   icon: (width) => <img src={AntropicLogo} width={width} alt="" />
  // }
  // [RUNPOD]: {
  //   name: RUNPOD,
  //   icon: (width) => <img src={RunpodLogo} width={width} alt="" />
  // }
})

export const CLOUD_PROVIDERS_OPTIONS_CREDENTIALS = Object.freeze({
  [AWS]: [
    {
      label: "AWS Access Key ID",
      placeholder: "Enter value",
      formKey: "AWS_ACCESS_KEY_ID"
    },
    {
      label: "AWS Secret Access Key",
      placeholder: "Enter value",
      formKey: "AWS_SECRET_ACCESS_KEY"
    }],
  [AZURE]: [
    {
      label: "AZURE Client ID",
      placeholder: "Enter value",
      formKey: "AZURE_CLIENT_ID"
    },
    {
      label: "AZURE Client Secret",
      placeholder: "Enter value",
      formKey: "AZURE_CLIENT_SECRET"
    },
    {
      label: "AZURE Tenant ID",
      placeholder: "Enter value",
      formKey: "AZURE_TENANT_ID"
    },
    {
      label: "AZURE Subscription ID",
      placeholder: "Enter value",
      formKey: "AZURE_TENANT_ID"
    }
  ],
  [GCP]: [
    {
      label: "Service Account JSON",
      placeholder: "Enter value",
      formKey: "SERVICE_ACCOUNT_JSON"
    }
  ],
  [DATACRUNCH]: [
    {
      label: "Client ID",
      placeholder: "Enter value",
      formKey: "DATACRUNCH_CLIENT_ID"
    },
    {
      label: "Client Secret",
      placeholder: "Enter value",
      formKey: "DATACRUNCH_CLIENT_SECRET"
    },
  ],
  [VASTAI]: [
    {
      label: "API Key",
      placeholder: "Enter value",
      formKey: "VASTAI_API_KEY"
    }
  ],
  [RUNPOD]: [
    {
      label: "API Key",
      placeholder: "Enter value",
      formKey: "RUNPOD_API_KEY"
    }
  ],
})

export const SG_CLOUD_REGIONS = Object.freeze([
  "US", "EU", "ASIA", "CANADA"
])

export const SG_CLOUD_CERTIFICATIONS = Object.freeze([
  "ISO", "SOC-2", "HIPAA"
])

export const SG_CLOUD_PROVIDERS = Object.freeze([
  "DATACRUNCH",
  "OBLIVUS",
  "HYPERSTACK",
  "LAMBDA",
  "LINODE",
  "SCALEWAY",
  "MASSEDCOMPUTE",
  "CURSOE",
  "LATITUDE",
  "RUNPOD",
  "VULTR"
])