import { createSlice } from "@reduxjs/toolkit";
import { REPO_HOSTS } from "../Configs/NewJobConstants";

const initialState = {
  name: "",
  entryPointFileName: "",
  zipFile: null,
  repoHost: REPO_HOSTS[0].replace(" ", "_").toUpperCase(),
  repoName: "",
  branchCode: "",
  codeDirectory: "",
  isRepoPrivate: false,
  repoCreds: {},
  requirementsFileName: "",
  imageLink: "",
  installationPath: "",
  dockerIsPrivate: false,
  registryURL: "",
  dockerUserName: "",
  dockerPassword: "",
  virtualMounts: [],
  artifactStorageName: "",
  filter: "",
  maximumCost: 0,
  maximumTime: null,
  spotInstances: false,
  maxCPUWorkers: "0",
  instanceTypes: [],
  cudaVersion: "",
  gpuTypes: [],
  numberOfGPUs: 1,
  numberOfNodes: 1,
  pythonVersion: "3.8",
  envVariables: {},
  preJobCommands: "",
  postJobCommands: "",
  cloudProviders: [],
  cloudRegions: [],
  minVCPUs: -1,
  minMemory: -1,
}

const newJobSlice = createSlice({
  name: 'newJobData',
  initialState,
  reducers: {
    setName: (store, action) => {
      store.name = action.payload
    },
    setEntryPointFileName: (store, action) => {
      store.entryPointFileName = action.payload
    },
    setZipFile: (store, action) => {
      store.zipFile = action.payload
    },
    setRepoHost: (store, action) => {
      store.repoHost = action.payload
    },
    setRepoName: (store, action) => {
      store.repoName = action.payload
    },
    setBranchCode: (store, action) => {
      store.branchCode = action.payload
    },
    setCodeDirectory: (store, action) => {
      store.codeDirectory = action.payload
    },
    setIsRepoPrivate: (store, action) => {
      store.isRepoPrivate = action.payload
    },
    setRepoCreds: (store, action) => {
      store.repoCreds = action.payload
    },
    setRequirementsFileName: (store, action) => {
      store.requirementsFileName = action.payload
    },
    setDockerImageLink: (store, action) => {
      store.imageLink = action.payload
    },
    setInstallationPath: (store, action) => {
      store.installationPath = action.payload
    },
    setDockerIsPrivate: (store, action) => {
      store.dockerIsPrivate = action.payload
    },
    setRegistryURL: (store, action) => {
      store.registryURL = action.payload
    },
    setDockerUserName: (store, action) => {
      store.dockerUserName = action.payload
    },
    setDockerPassword: (store, action) => {
      store.dockerPassword = action.payload
    },
    addVirtualMounts: (store, action) => {
      store.virtualMounts = [...store.virtualMounts, action.payload]
    },
    removeVirtualMounts: (store, action) => {
      store.virtualMounts = store.virtualMounts.filter(vm => vm.name !== action.payload)
    },
    setVirtualMounts: (store, action) => {
      store.virtualMounts = action.payload
    },
    setArtifactStorageName: (store, action) => {
      store.artifactStorageName = action.payload
    },
    setFilter: (store, action) => {
      store.filter = action.payload
    },
    setMaximumCost: (store, action) => {
      store.maximumCost = action.payload
    },
    setMaximumTime: (store, action) => {
      store.maximumTime = action.payload
    },
    setSpotInstances: (store, action) => {
      store.spotInstances = action.payload
    },
    setMaxCPUWorkers: (store, action) => {
      store.maxCPUWorkers = action.payload
    },
    setInstanceTypes: (store, action) => {
      store.instanceTypes = action.payload
    },
    setGPUTypes: (store, action) => {
      store.gpuTypes = action.payload
    },
    setCudaVersion: (store, action) => {
      store.cudaVersion = action.payload
    },
    setNumberOfGPUs: (store, action) => {
      store.numberOfGPUs = action.payload
    },
    setNumberOfNodes: (store, action) => {
      store.numberOfNodes = action.payload
    },
    setPythonVersion: (store, action) => {
      store.pythonVersion = action.payload
    },
    addEnvVariables: (store, action) => {
      const key = Object.keys(action.payload)[0]
      const value = action.payload[key]

      store.envVariables[key] = value
    },
    deleteEnvVariables: (store, action) => {
      delete store.envVariables[action.payload]
    },
    setPreJobCommands: (store, action) => {
      store.preJobCommands = action.payload
    },
    setPostJobCommands: (store, action) => {
      store.postJobCommands = action.payload
    },
    setCloudProviders: (store, action) => {
      store.cloudProviders = action.payload
    },
    setCloudRegions: (store, action) => {
      store.cloudRegions = action.payload
    },
    setMinVCPUs: (store, action) => {
      store.minVCPUs = action.payload
    },
    setMinMemory: (store, action) => {
      store.minMemory = action.payload
    },

    clearStore: (store, action) => {
      // store = initialState
      Object.assign(store, initialState)
    },

    preLoadData: (store, action) => {
      store.name = action.payload.name
      store.entryPointFileName = action.payload.config.entrypoint
      store.cudaVersion = action.payload.config.cuda
      store.pythonVersion = action.payload.config.pythonVersion
      if (action.payload.config.customImage) {
        store.imageLink = action.payload.config.customImage.image
        if (action.payload.config.customImage.credentials) {
          store.dockerIsPrivate = true
          store.registryURL = action.payload.config.customImage.credentials.registry
          store.dockerUserName = action.payload.config.customImage.credentials.username
          store.dockerPassword = action.payload.config.customImage.credentials.password
        }
        store.installationPath = action.payload.config.customImage.pythonPath
      }
      store.artifactStorageName = action.payload.config.artifactsDestination.name
      store.filter = action.payload.config.artifactsDestination.filter
      store.virtualMounts = action.payload.config.virtualMounts
      store.spotInstances = action.payload.config.useSpot
      store.cloudProviders = action.payload.config.cloudProviders
      store.minVCPUs = action.payload.config.minvCPUs
      store.minMemory = action.payload.config.minMemory
      store.maxCPUWorkers = action.payload.config.maxCPUWorkers
      store.instanceTypes = action.payload.config.instanceTypes
      store.gpuTypes = action.payload.config.gpuTypes
      store.numberOfGPUs = action.payload.config.gpusPerTrial
      store.numberOfNodes = action.payload.config.numNodes
      store.requirementsFileName = action.payload.config.requirements
      if (action.payload.config.codeTransfer) {
        store.repoHost = action.payload.config.codeTransfer.type
        store.repoName = action.payload.config.codeTransfer.repo
        store.branchCode = action.payload.config.codeTransfer.ref
        store.codeDirectory = action.payload.config.codeTransfer.codeDir
        if (action.payload.config.codeTransfer.credentials) {
          store.isRepoPrivate = true
          store.repoCreds = action.payload.config.codeTransfer.credentials
        }
      }
      store.preJobCommands = action.payload.config.preJobCommands.join("/n")
      store.postJobCommands = action.payload.config.postJobCommands.join("/n")
      store.maximumTime = action.payload.config.maxTime
      store.maximumCost = action.payload.config.maxCost
      store.envVariables = [action.payload.config.environment]
    }
  }
})

export const {
  setName,
  setEntryPointFileName,
  setBranchCode,
  setCodeDirectory,
  setDockerImageLink,
  setDockerIsPrivate,
  setDockerPassword,
  setDockerUserName,
  setInstallationPath,
  setIsRepoPrivate,
  setRegistryURL,
  setRepoHost,
  setRepoName,
  setRepoCreds,
  setRequirementsFileName,
  setZipFile,
  addVirtualMounts,
  removeVirtualMounts,
  setArtifactStorageName,
  setFilter,
  setMaximumCost,
  setMaximumTime,
  setSpotInstances,
  setMaxCPUWorkers,
  setInstanceTypes,
  setGPUTypes,
  setCudaVersion,
  setNumberOfGPUs,
  setNumberOfNodes,
  setPythonVersion,
  addEnvVariables,
  deleteEnvVariables,
  setPostJobCommands,
  setPreJobCommands,
  setCloudProviders,
  setCloudRegions,
  setMinVCPUs,
  setMinMemory,
  clearStore,
  preLoadData,
  setVirtualMounts
} = newJobSlice.actions;

export default newJobSlice;