import { Stack } from '@mui/material'
import React, { useState } from 'react'
import NavigationTabs from '../../Components/UiComponents/NavigationTabs'
import { Endpoints } from './Endpoints'
import { Models } from './Models'

export const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("0")

  return (
    <Stack marginTop="-2vh">
      <NavigationTabs
        value={activeTab}
        setValue={setActiveTab}
        tabs={[
          "Models",
          "Endpoints"
        ]}
        panels={[
          <Models />,
          <Endpoints />
        ]}

      />
    </Stack>
  )
}
