import { Chip, Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { CLOUD_PROVIDERS_OPTIONS } from '../../Configs/CloudProviderConstants';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';

function createData(provider, regions, id) {
  return { provider, regions, id };
}

function getLogo(provider) {
  return CLOUD_PROVIDERS_OPTIONS[provider]?.icon("40px")
}

export default function ProviderTable({ data, handleDelete, openModal }) {

  const rows = (data || [])
    .filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
    .map((d) =>
      createData(d.cloud_provider, d.regions, d.id)
    );

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  const [page, setPage] = useState(0)

  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.length < page * rowsPerPage) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        maxHeight: "50vh",
        border: "1px solid #E4E4E4",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "BDOGroteskMedium"
          }
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="30%">Cloud Provider</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="50%">Region</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows?.length > 0 ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover
                >
                  <TableCell
                    component="th" scope="row"
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      {getLogo(row.provider)}
                      {row.provider}
                    </Stack>
                  </TableCell>
                  <TableCell >
                    <Stack direction="row" gap={1} flexWrap="wrap">
                      {
                        row.regions.map((region, idx) =>
                          <Chip
                            key={idx}
                            sx={{
                              borderRadius: "6px",
                              bgcolor: "#D8ECFF",
                              color: color.primary,
                              p: 1,
                            }}
                            label={region}
                          />
                        )
                      }
                    </Stack>
                  </TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(row.id)
                      }}
                    />
                  </TableCell>
                </TableRow>
              )) :
              <TableCell colSpan={7} >
                <Stack
                  direction="row"
                  gap={1}
                  height="5vh"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='body2'>
                    No Cloud Provider added. Please click on
                  </Typography>
                  <Typography variant='body2' color={color.primary}
                    onClick={openModal}
                    sx={{
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }}
                  >
                    Add Cloud Provider
                  </Typography>
                  <Typography variant='body2'>
                    to add cloud providers.
                  </Typography>
                </Stack>
              </TableCell>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onPageChange={handleChangePage}
              style={{
                borderTop: "1px solid #E4E4E4",
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="Cloud Provider"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  );
}