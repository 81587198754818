import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import SecondaryButton from '../UiComponents/SecondaryButton';

const NewJobFooter = ({
  handleNext, activeStep, handleReview, handleDeploy,
  //  handleSaveTemplate,
  handleSaveYAML
}) => {

  function getButtonText(step) {
    switch (step) {
      case 0: return "Configure Data"
      case 3: return "Deploy"
      default: return "Next"
    }
  }

  return (
    <Stack justifyContent="end" width="100%" direction="row" gap={1}>
      {/* {
        activeStep === 3 &&
        <SecondaryButton onClick={handleSaveTemplate}>
          <Typography color={color.primary} fontSize="15px">Save as Template</Typography>
        </SecondaryButton>
      } */}
      {
        activeStep === 3 &&
        <SecondaryButton onClick={handleSaveYAML}>
          <Typography color={color.primary} fontSize="15px">Download as YAML Config</Typography>
        </SecondaryButton >
      }
      {
        activeStep === 2 &&
        <CustomButton width="25%" onClick={handleReview}>
          Review & Deploy <ArrowForwardIosIcon fontSize='24px' sx={{ marginLeft: 1 }} />
        </CustomButton>
      }
      <CustomButton width="25%" onClick={activeStep === 3 ? handleDeploy : handleNext}>
        {getButtonText(activeStep)} <ArrowForwardIosIcon fontSize='24px' sx={{ marginLeft: 1 }} />
      </CustomButton>
    </Stack >
  )
}

export default NewJobFooter