import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';


export const AdvancedMachineConfig = ({ dockerConfig, setDockerConfig, isOpen, setIsOpen }) => {
  const [key, setKey] = useState("")
  const [value, setValue] = useState("")

  const handleAdd = () => {
    if (key.length > 0 && value.length > 0) {
      setDockerConfig({
        ...dockerConfig,
        env_vars: {
          ...dockerConfig.env_vars,
          [key]: value
        }
      })
      setKey("")
      setValue("")
    }
  }

  const [containerPort, setContainerPort] = useState("")
  const [hostPort, setHostPort] = useState("")

  const handleAddPort = () => {
    if (containerPort.length > 0 && hostPort.length > 0) {
      setDockerConfig({
        ...dockerConfig,
        ports: [
          ...dockerConfig.ports,
          {
            "container_port": containerPort,
            "host_port": hostPort
          }
        ]
      })
      setContainerPort("")
      setHostPort("")
    }
  }

  return (
    <Stack
      p={1}
      border={`1px solid ${color.borders}`}
      borderRadius="8px"
      bgcolor={!isOpen && color.primary}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      >
        <Stack
          fontWeight="600"
          color={isOpen ? color.primary : "#FFFFFF"}
          fontSize="15px"
          direction="row"
          alignItems="center"
          gap={1}
        >
          <Stack height="20px" width="5px" borderRadius="8px" bgcolor={color.primary}></Stack>
          Docker Configuration ( Optional )
        </Stack>
        {
          !isOpen ? <KeyboardArrowDownIcon sx={{ color: color.white }} />
            : <KeyboardArrowUpIcon sx={{ color: color.primary }} />
        }
      </Stack>
      {
        isOpen &&
        <Stack gap={2} p={2}>
          <Typography fontWeight="600" fontSize="15px">
            Docker Image
          </Typography>
          <InputField
            placeholder="Enter Docker Image value"
            state={dockerConfig.image}
            setState={(e) => setDockerConfig((prev) => ({ ...prev, image: e.target.value }))}
          />
          <Typography fontWeight="600" fontSize="15px">
            Args
          </Typography>
          <InputField
            placeholder="Enter Docker Args value"
            state={dockerConfig.args}
            setState={(e) => setDockerConfig((prev) => ({ ...prev, args: e.target.value }))}
          />

          <Typography fontWeight="600" fontSize="15px">
            Environment Variables
          </Typography>
          <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
            <Stack width="40%" gap={1}>
              <Typography fontSize="15px">
                Key
              </Typography>

            </Stack>
            <Stack width="40%" gap={1}>
              <Typography fontSize="15px">
                Value
              </Typography>
            </Stack>
          </Stack>
          {
            Object.entries(dockerConfig.env_vars).map((entry, idx) => (
              <Stack direction="row" alignItems="center" fontSize="15px" gap={2} key={idx}>
                <InputField
                  width="40%"
                  disabled={true}
                  state={entry[0]}
                />
                <InputField
                  width="40%"
                  disabled={true}
                  state={entry[1]}
                />
                <Button
                  onClick={() => {
                    setDockerConfig((prevState) => {
                      const newState = { ...prevState };
                      delete dockerConfig.env_vars[entry[0]]
                      return newState;
                    });
                  }}
                  sx={{
                    bgcolor: "#FFDBDB",
                    p: 1,
                    minWidth: '30px',
                    '&:hover': {
                      bgcolor: "#FFDBDB",
                    }
                  }} >
                  <DeleteIcon sx={{ color: "#FF6B6A", fontSize: "16px" }} />
                </Button >
              </Stack>
            ))
          }
          <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
            <InputField
              width="40%"
              placeholder="Enter key"
              state={key}
              setState={(e) => setKey(e.target.value)}
            />
            <InputField
              width="40%"
              placeholder="Enter value"
              state={value}
              setState={(e) => setValue(e.target.value)}
            />
            <CustomButton width="15%" onClick={handleAdd}>
              <Stack alignItems="center" direction="row" gap={0.5}>
                <AddIcon sx={{ fontSize: "16px" }} />
                Add
              </Stack>
            </CustomButton>
          </Stack>

          <Typography fontWeight="600" fontSize="15px">
            Ports
          </Typography>
          <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
            <Stack width="40%" gap={1}>
              <Typography fontSize="15px">
                Container Port
              </Typography>

            </Stack>
            <Stack width="40%" gap={1}>
              <Typography fontSize="15px">
                Host Port
              </Typography>
            </Stack>
          </Stack>
          {
            dockerConfig.ports.map((port, idx) => (
              <Stack direction="row" alignItems="center" fontSize="15px" gap={2} key={idx}>
                <InputField
                  width="40%"
                  disabled={true}
                  state={port.container_port}
                />
                <InputField
                  width="40%"
                  disabled={true}
                  state={port.host_port}
                />
                <Button
                  onClick={() => {
                    setDockerConfig((prevState) => {
                      const newState = { ...prevState };
                      newState.ports.splice(idx, 1);
                      return newState;
                    });
                  }}
                  sx={{
                    bgcolor: "#FFDBDB",
                    p: 1,
                    minWidth: '30px',
                    '&:hover': {
                      bgcolor: "#FFDBDB",
                    }
                  }} >
                  <DeleteIcon sx={{ color: "#FF6B6A", fontSize: "16px" }} />
                </Button >
              </Stack>
            ))
          }
          <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
            <InputField
              width="40%"
              placeholder="Enter container port"
              type='number'
              state={containerPort}
              setState={(e) => setContainerPort(e.target.value)}
            />
            <InputField
              width="40%"
              placeholder="Enter host port"
              type='number'
              state={hostPort}
              setState={(e) => setHostPort(e.target.value)}
            />
            <CustomButton width="15%" onClick={handleAddPort}>
              <Stack alignItems="center" direction="row" gap={0.5}>
                <AddIcon sx={{ fontSize: "16px" }} />
                Add
              </Stack>
            </CustomButton>
          </Stack>
        </Stack>
      }
    </Stack >
  )
}
