import { Stack, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useGetBuildLogsQuery } from '../../Services/logsApi'
import { color } from '../../Styles/Color'
import Loader from '../UiComponents/Loader'

export const BuildLogs = ({ job }) => {
  const scrollRef = useRef(null);

  const { data, isLoading } = useGetBuildLogsQuery(
    job.id
  )

  useEffect(() => {
    if (data)
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [data]);

  if (isLoading) {
    return <Stack height="70vh"><Loader /></Stack>
  }

  if (!data) {
    return <Typography color={color.primary} fontSize="15px">
      Can not Retrieve Logs , Please check again later.
    </Typography>
  }

  return (
    <Stack
      p={2}
      bgcolor={color.secondaryBackground}
      borderRadius="8px"
      height="70vh"
      overflow="auto"
      border={`1px solid ${color.borders}`}
      fontFamily="IBMPlexMonoMedium"
      fontSize="12px"
      lineHeight="20px"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }
      }
    >
      <p dangerouslySetInnerHTML={{ __html: data?.replace(/(?:\r\n|\r|\n)/g, "<br />") }} />
      <p ref={scrollRef}></p>
    </Stack >
  )
}
