export const ROLE_OPTIONS = Object.freeze([
  "Product Manager",
  "Junior Developer",
  "Developer",
  "Senior Developer",
  "Team Lead"
])

export const NOTIFICATION_OPTIONS = Object.freeze({
  "Email": [
    {
      text: "Provisioning infrastructure",
      subText: 'Notify when infrastructure provisioning gets finished',
    },
    {
      text: "Setting up environment",
      subText: "Notify when environment is finished setting up"
    },
    {
      text: "Job starts running",
      subText: "Notify when jobs starts running"
    },
    {
      text: "Job stops running",
      subText: "Notify when job stops running or when job gets finished"
    },
    {
      text: 'Job fails',
      subText: "Notify when job fails"
    }
  ]
})