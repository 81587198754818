import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Chip, Skeleton, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import { MODEL_PROVIDERS } from '../../Configs/DashboardConfigs';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { useGetInferenceTemplatesQuery } from '../../Services/templatesApi';
import { color } from '../../Styles/Color';

export const InferenceTemplates = () => {

  const nav = useNavigate()

  const { data, isLoading } = useGetInferenceTemplatesQuery()

  const myRef = useRef(null);

  const handleScroll = () => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      myRef.current.scrollLeft += 25;
      scrollAmount += Math.abs(25);
      if (scrollAmount >= 600) {
        clearInterval(slideTimer);
      }
    }, 10)
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2}>
        <Stack
          ref={myRef}
          direction="row"
          gap={2}
          overflow="auto"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
              scrollBehavior: "smooth"
            }
          }}
          width="100%"
        >
          {
            isLoading ?
              [...Array(3)].map((_, idx) =>
                <Stack
                  key={idx}
                  minWidth="32%"
                  borderRadius="12px"
                  border={`1px solid ${color.borders}`}
                  boxShadow="0px 1px 4px 0px #0000000A"
                  overflow="hidden"
                  height="15vh"
                  position='relative'
                  sx={{
                    "&:hover": {
                      cursor: 'pointer'
                    },
                  }}
                >
                  <Stack
                    position="absolute"
                    top={0} left={0}
                    zIndex="5"
                    spacing={1}
                    width="100%"
                    p={2}
                  >
                    <Typography variant='h3' width="30%"><Skeleton /></Typography>
                    <Stack direction="row" gap={1} alignItems="end">
                      <Typography variant='body1' noWrap width="50%"><Skeleton /></Typography>
                      <Typography variant='subtitle1' noWrap width="25%"><Skeleton /></Typography>
                    </Stack>
                    <Typography variant='subtitle1' color={color.secondaryText}>
                      <Skeleton />
                    </Typography>
                  </Stack>
                </Stack>
              )
              : data?.map((temp, idx) => {
                const [provider, model] = temp.config.model.split("/")
                return (
                  <Stack
                    key={idx}
                    minWidth="32%"
                    borderRadius="12px"
                    border={`1px solid ${color.borders}`}
                    boxShadow="0px 1px 4px 0px #0000000A"
                    overflow="hidden"
                    height="15vh"
                    position='relative'
                    sx={{
                      "&:hover": {
                        cursor: 'pointer'
                      },
                    }}
                    onClick={() => nav(PAGE_ROUTES.inferenceTemplates, {
                      state: {
                        model: temp.config.model,
                        config: temp.config
                      }
                    })}
                  >
                    <Stack
                      position="absolute"
                      top={0} left={0}
                      height="100%"
                      width="100%"
                      justifyContent="center"
                      alignItems="end"
                    >
                      {MODEL_PROVIDERS[provider].icon}
                    </Stack>
                    <Stack
                      position="absolute"
                      top={0} left={0}
                      zIndex="5"
                      spacing={1}
                      width="100%"
                      p={2}
                    >
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant='h3'>DEPLOY</Typography>
                        <Chip
                          sx={{
                            background: "rgba(255, 255, 255, 0.55)",
                            textTransform: "uppercase",
                            px: 1,
                          }}
                          label={<Typography variant='h3' fontSize="12px">{temp.config.inf_type}</Typography>}
                        />
                      </Stack>
                      <Stack direction="row" gap={1} alignItems="end">
                        <Typography variant='body1' noWrap >{model.replaceAll("-", " ")}</Typography>
                        <Typography variant='subtitle1' noWrap>by {MODEL_PROVIDERS[provider].name}</Typography>
                      </Stack>
                      <Typography variant='subtitle1' color={color.secondaryText}>
                        {/* {temp.model_type} | */}
                        {temp.config.initial_worker_config.initial_workers_gpu}
                      </Typography>
                    </Stack>
                  </Stack>
                )
              })
          }
        </Stack>
        {
          data?.length > 2 &&
          <Stack
            width="5%"
            borderRadius="8px"
            bgcolor={color.secondaryBackground}
            border={`1px solid ${color.borders}`}
            justifyContent="center"
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={handleScroll}
          >
            <ArrowForwardIosIcon />
          </Stack>
        }
      </Stack>
      {
        data &&
        <Stack
          direction="row"
          color={color.primary}
          gap={1}
          alignItems="center"
          width="25%"
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={() => window.open("https://docs.scalegen.ai/category/tutorials", "_blank")}
        >
          <Typography variant='h3'>Checkout More Templates</Typography>
          <ArrowForwardIcon sx={{ fontSize: "14px" }} />
        </Stack>
      }
    </Stack>
  )
}
