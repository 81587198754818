import ClearIcon from '@mui/icons-material/Clear';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, OutlinedInput, Select, Slide, Stack, Typography } from '@mui/material';
import { forwardRef, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomButton from '../../Components/UiComponents/CustomButton';
import InputField from '../../Components/UiComponents/InputField';
import { RequiredHeader } from '../../Components/UiComponents/RequiredHeader';
import SecondaryButton from '../../Components/UiComponents/SecondaryButton';
import { SelectDropdown } from '../../Components/UiComponents/SelectDropdown';
import { MIXED_PRECISION_OPTIONS, QUANTIZATION_OPTIONS, TORCH_DTYPE_OPTIONS } from '../../Configs/JobConstants';
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice';
import { color } from '../../Styles/Color';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditAutoTrainModal = ({ isOpen, setIsOpen, watch, setValue }) => {

  const dispatch = useDispatch()

  let autoTrain_params = watch('autotrain_params')

  const isSpot = watch('use_spot')

  const [formValues, setFormValues] = useState({})
  const [isLora, setIsLora] = useState(true)

  useLayoutEffect(() => {
    const initialFormState = autoTrain_params
    setFormValues(initialFormState)

    if (isSpot && watch('autotrain_params.peft') === 'lora') {
      setIsLora(true)
    }

  }, [isSpot, autoTrain_params, watch])


  const handleClose = () => {
    setIsOpen(false)
  }

  const handleUpdate = () => {
    if (isLora &&
      (
        formValues.lora_alpha === "" || formValues.lora_dropout === "" || formValues.lora_r === "" ||
        formValues.lora_alpha === "0" || formValues.lora_dropout === "0" || formValues.lora_r === "0"
      )
    ) {
      dispatch(setIsError(true))
      dispatch(setErrorMessage("LoRA parameters cannot be empty or 0"))
    } else {
      setValue('autotrain_params', formValues)
      handleClose()
    }
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          px: 2,
          py: 1,
          boxShadow: "0px 0px 4px 0px #00000014"
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: {
            background: "#E4E4E48A",
            backdropFilter: "blur(3px)",
          },
        }
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant='h2' color={color.primary}>AutoTrain Parameters</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px', color: color.primary }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "35vh",
          width: "45vw",
          p: 2,
          // '&::-webkit-scrollbar': {
          //   display: 'none',
          // }
        }}
      >
        <Stack
          spacing={3}
        >
          <Stack direction="row" alignItems="center" gap={1} >
            <Stack spacing={1} width="35%">
              <Typography variant='h3'>Epochs</Typography>
              <InputField
                state={formValues.epochs}
                setState={e => setFormValues(prev => ({ ...prev, epochs: e.target.value }))}
                placeholder="Enter value" type="number"
              />
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='h3'>Learning Rate</Typography>
              <InputField
                state={formValues.lr}
                setState={e => setFormValues(prev => ({ ...prev, lr: e.target.value }))}
                placeholder="Enter value" type="number"
              />
            </Stack>
            <Stack spacing={1} width="30%">
              <Typography variant='h3'>Batch Size</Typography>
              <InputField
                state={formValues.batch_size}
                setState={e => setFormValues(prev => ({ ...prev, batch_size: e.target.value }))}
                placeholder="Enter value" type="number"
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1} >
            <Stack spacing={1} width="35%">
              <Typography variant='h3'>Block Size</Typography>
              <InputField
                state={formValues.block_size}
                setState={e => setFormValues(prev => ({ ...prev, block_size: e.target.value }))}
                placeholder="Enter value" type="number"
              />
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='h3'>Model Max Length</Typography>
              <InputField
                state={formValues.model_max_length}
                setState={e => setFormValues(prev => ({ ...prev, model_max_length: e.target.value }))}
                placeholder="Enter value" type="number"
              />
            </Stack>
            <Stack spacing={1} width="30%">
              <Typography variant='h3'>Seed</Typography>
              <InputField
                state={formValues.seed}
                setState={e => setFormValues(prev => ({ ...prev, seed: e.target.value }))}
                placeholder="Enter value" type="number"
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1} >
            <Stack spacing={1} width="35%">
              <Typography variant='h3'>Gradient Accumulation</Typography>
              <InputField
                state={formValues.gradient_accumulation_steps}
                setState={e => setFormValues(prev => ({ ...prev, gradient_accumulation_steps: e.target.value }))}
                placeholder="Enter value" type="number"
              />
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='h3'>Mixed Precision</Typography>
              <SelectDropdown
                value={formValues.mixed_precision}
                handleChange={e => setFormValues(prev => ({ ...prev, mixed_precision: e.target.value }))}
                options={MIXED_PRECISION_OPTIONS} placeholder="Select a option"
              />
            </Stack>
            <Stack spacing={1} width="30%">
              <Typography variant='h3'>Quantization</Typography>
              <SelectDropdown
                value={formValues.quantization}
                handleChange={e => setFormValues(prev => ({ ...prev, quantization: e.target.value }))}
                options={QUANTIZATION_OPTIONS} placeholder="Select a option"
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1} >
            <Stack spacing={1} width="49%">
              <Typography variant='h3'>Torch dType</Typography>
              <SelectDropdown
                value={formValues.torch_dtype}
                handleChange={e => setFormValues(prev => ({ ...prev, torch_dtype: e.target.value }))}
                options={TORCH_DTYPE_OPTIONS} placeholder="Select a option"
              />
            </Stack>
            <Stack spacing={1} width="49%">
              <Typography variant='h3'>Use Deepspeed</Typography>
              <SelectDropdown
                value={formValues.use_deepspeed}
                handleChange={e => setFormValues(prev => ({ ...prev, use_deepspeed: e.target.value }))}
                options={["stage_2", "stage_3"]} placeholder="Select a option"
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1} >
            <Stack spacing={1} width="49%">
              <Typography variant='h3'>Disable Gradient Checkpointing</Typography>
              <Select
                size='small'
                value={formValues.disable_gradient_checkpointing ? true : false}
                onChange={e =>
                  setFormValues(prev => ({ ...prev, disable_gradient_checkpointing: e.target.value }))
                }
                displayEmpty
                input={<OutlinedInput />}
                sx={{ bgcolor: color.white, borderRadius: "8px", fontSize: "14px" }}
              >
                <MenuItem
                  value={true}
                  sx={{ fontSize: "14px" }}
                >
                  Enabled
                </MenuItem>
                <MenuItem
                  value={false}
                  sx={{ fontSize: "14px" }}
                >
                  Disabled
                </MenuItem>
              </Select>
            </Stack>
            <Stack spacing={1} width="49%">
              <Typography variant='h3'>Use FlashAttention2</Typography>
              <Select
                size='small'
                value={formValues.use_flash_attention_2 ? true : false}
                onChange={e =>
                  setFormValues(prev => ({ ...prev, use_flash_attention_2: e.target.value }))
                }
                displayEmpty
                input={<OutlinedInput />}
                sx={{ bgcolor: color.white, borderRadius: "8px", fontSize: "14px" }}
              >
                <MenuItem
                  value={true}
                  sx={{ fontSize: "14px" }}
                >
                  Enabled
                </MenuItem>
                <MenuItem
                  value={false}
                  sx={{ fontSize: "14px" }}
                >
                  Disabled
                </MenuItem>
              </Select>
            </Stack>
          </Stack>
          <Stack spacing={1} >
            <Typography variant='h3'>LoRA</Typography>
            <Stack width="34%">
              <Select
                size='small'
                value={isLora}
                onChange={e =>
                  setIsLora(e.target.value)
                }
                displayEmpty
                input={<OutlinedInput />}
                sx={{ bgcolor: color.white, borderRadius: "8px", fontSize: "14px" }}
              >
                <MenuItem
                  value={true}
                  sx={{ fontSize: "14px" }}
                >
                  Enabled
                </MenuItem>
                <MenuItem
                  value={false}
                  sx={{ fontSize: "14px" }}
                  disabled={isSpot}
                >
                  Disabled
                </MenuItem>
              </Select>
            </Stack>
          </Stack>
          {
            isLora &&
            <Stack direction="row" alignItems="center" gap={1}>
              <Stack spacing={1} width="35%">
                <RequiredHeader>
                  <Typography variant='h3'>LoRA r</Typography>
                </RequiredHeader>
                <InputField
                  state={formValues.lora_r}
                  setState={e => setFormValues(prev => ({ ...prev, lora_r: e.target.value }))}
                  placeholder="Enter value" type="number"
                />
              </Stack>
              <Stack spacing={1} width="35%">
                <RequiredHeader>
                  <Typography variant='h3'>LoRA Alpha</Typography>
                </RequiredHeader>
                <InputField
                  state={formValues.lora_alpha}
                  setState={e => setFormValues(prev => ({ ...prev, lora_alpha: e.target.value }))}
                  placeholder="Enter value" type="number"
                />
              </Stack>
              <Stack spacing={1} width="30%">
                <RequiredHeader>
                  <Typography variant='h3'>LoRA Dropout</Typography>
                </RequiredHeader>
                <InputField
                  state={formValues.lora_dropout}
                  setState={e => setFormValues(prev => ({ ...prev, lora_dropout: e.target.value }))}
                  placeholder="Enter value" type="number"
                />
              </Stack>
            </Stack>
          }
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={() => window.open("https://docs.scalegen.ai/ft-guide#autotrain-parameters", "_blank")}
          >
            <MenuBookIcon fontSize='small' />
            <Typography variant='body1'>
              Docs
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <CustomButton
              onClick={handleUpdate}
              width="10%"
            >
              Update
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default EditAutoTrainModal