import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Chip, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloudProviders, setCloudRegions, setMinMemory, setMinVCPUs } from '../../../DataStore/newJobSlice';
import { useGetUserCloudsQuery } from '../../../Services/cloudProviderApi';
import { color } from "../../../Styles/Color";
import InputField from '../../UiComponents/InputField';

export const AdvancedSettings = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { cloudProviders, minVCPUs, minMemory } = useSelector(store => store.newJobData)
  const dispatch = useDispatch()

  const { data: cloudProviderOptions } = useGetUserCloudsQuery()
  const [regionOptions, setRegionOptions] = useState([])

  const [clouds, setClouds] = useState(cloudProviders.map(cp => cp.name) || [])
  const [regions, setRegions] = useState([])

  useEffect(() => {
    if (cloudProviderOptions && cloudProviderOptions.length > 0) {
      setClouds(
        [...cloudProviderOptions
          ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
          ?.map(cp => cp.cloud_provider), "SCALEGENAI"] || []
      )
      setRegionOptions(
        [...cloudProviderOptions
          ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
          .reduce(
            (accumulator, cp) => {
              return [...accumulator, ...cp.regions.map(region => `${cp.cloud_provider}:${region}`)]
            },
            [],
          ),
          "SCALEGENAI:US", "SCALEGENAI:ASIA", "SCALEGENAI:EU", "SCALEGENAI:CANADA"
        ]
      )
      setRegions(
        [...cloudProviderOptions
          ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
          .reduce(
            (accumulator, cp) => {
              return [...accumulator, ...cp.regions.map(region => `${cp.cloud_provider}:${region}`)]
            },
            [],
          ),
          "SCALEGENAI:US", "SCALEGENAI:ASIA", "SCALEGENAI:EU", "SCALEGENAI:CANADA"
        ]
      )
    }

  }, [cloudProviderOptions, dispatch])

  useEffect(() => {
    if (cloudProviderOptions && clouds.length > 0 && cloudProviderOptions.length !== clouds.length) {
      setRegions(prev => prev.filter(r => clouds.includes(r.split(":")[0])))
    }
  }, [clouds, cloudProviderOptions])

  useEffect(() => {
    dispatch(setCloudRegions(regions))
  }, [dispatch, regions])

  useEffect(() => {

    dispatch(
      setCloudProviders(
        clouds
          .map(
            cp => {
              return {
                name: cp,
                regions: regions?.filter(r => r.includes(cp)).map(r => r.split(":")[1])
              }
            }
          )
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clouds, regions])

  return (
    <Stack
      p={1}
      border={`1px solid ${color.borders}`}
      borderRadius="8px"
      bgcolor={!isOpen && color.primary
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      >
        <Stack
          fontWeight="600"
          color={isOpen ? color.primary : "#FFFFFF"}
          direction="row"
          alignItems="center"
          gap={1}
        >
          <Stack height="20px" width="5px" borderRadius="8px" bgcolor={color.primary}></Stack>
          <Typography fontSize="15px">Advanced</Typography>
        </Stack>
        {
          !isOpen ? <KeyboardArrowDownIcon sx={{ color: color.white }} />
            : <KeyboardArrowUpIcon sx={{ color: color.primary }} />
        }
      </Stack>
      {
        isOpen &&
        <Stack gap={1.5} p={2}>
          <Typography fontWeight="600" fontSize="15px">
            Cloud Providers (Specify which cloud providers do you want to run this experiment with.)
          </Typography>
          <Select
            size='small'
            multiple
            value={clouds}
            onChange={(e) => setClouds(e.target.value)}
            input={<OutlinedInput />}
            renderValue={(selected, idx) => (
              <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                  selected.length === 0 ?
                    <Typography fontSize="15px">Choose Cloud Providers</Typography>
                    : selected.map((value) => (
                      <Chip key={value} sx={{
                        bgcolor: color.lightBlue,
                        borderRadius: "6px",
                        fontSize: "12px",
                      }} label={value} />
                    ))}
              </Box>
            )}
            sx={{ bgcolor: color.white, borderRadius: "8px" }}
          >
            {
              (cloudProviderOptions
                ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
                || []).map((cloud, idx) => (
                  <MenuItem
                    key={idx}
                    value={cloud.cloud_provider}
                    sx={{ fontSize: "15px" }}
                  >
                    {cloud.cloud_provider}
                  </MenuItem>
                )
                )
            }
            <MenuItem
              value="SCALEGENAI"
              sx={{ fontSize: "15px" }}
            >
              SCALEGENAI
            </MenuItem>
          </Select>
          <Typography fontWeight="600" fontSize="15px">
            Cloud Regions (Specify which regions do you want to operate in.)
          </Typography>
          <Select
            size='small'
            multiple
            value={regions}
            onChange={(e) => setRegions(e.target.value)}
            input={<OutlinedInput />}
            renderValue={(selected, idx) => (
              <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                  selected.length === 0 ?
                    <Typography fontSize="15px">Choose the region where you want to train</Typography>
                    : selected.map((value) => (
                      <Chip key={value} sx={{
                        bgcolor: color.lightBlue,
                        borderRadius: "6px",
                        fontSize: "12px",
                      }} label={value} />
                    ))}
              </Box>
            )}
            sx={{ bgcolor: color.white, borderRadius: "8px" }}
          >
            {
              (regionOptions?.filter(region => clouds.includes(region.split(":")[0])) || [])
                .map((region, idx) => (
                  <MenuItem
                    key={idx}
                    value={region}
                    sx={{ fontSize: "15px" }}
                  >
                    {region}
                  </MenuItem>
                ))}
          </Select>
          <Typography fontWeight="600" fontSize="15px">
            Minimum vCPUs (Min. amount of cores/vCPUs required in the worker VMs.)
          </Typography>
          <InputField
            width="70%"
            placeholder="Enter value"
            state={minVCPUs}
            setState={(e) => dispatch(setMinVCPUs(e.target.value))}
          />
          <Typography fontWeight="600" fontSize="15px">
            Minimum Memory (Min. amount of memory/RAM required in the worker VMs.)
          </Typography>
          <InputField
            width="70%"
            placeholder="Enter value"
            state={minMemory}
            setState={(e) => dispatch(setMinMemory(e.target.value))}
          />
        </Stack>
      }
    </Stack >
  )
}
