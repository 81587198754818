import moment from "moment";

/**
 * Function to delete nested properties from an object
 * @param {object} obj
 * @param {string} path
 */
export const deletePropertyPath = (obj, path) => {
    if (!obj || !path) {
        return;
    }

    if (typeof path === "string") {
        path = path.split(".");
    }

    for (var i = 0; i < path.length - 1; i++) {
        obj = obj[path[i]];

        if (typeof obj === "undefined") {
            return;
        }
    }

    delete obj[path.pop()];
};


/**
 * Function to make an object immutable
 * @param {object} object
 * @returns {object}
 */
export const deepFreeze = (object) => {
    const propNames = Object.getOwnPropertyNames(object);
    for (const name of propNames) {
        const value = object[name];
        if (value && typeof value === "object") {
            deepFreeze(value);
        }
    }

    return Object.freeze(object);
};


/**
 * Function to retrive error message from error object
 * @param {object} error
 * @returns {string}
 */
export const getErrorMessage = (
    error,
    fallback = "An unknown error occured"
) => {
    if (Array.isArray(error?.data?.detail)) {
        return error?.data?.detail
            .map((obj) => {
                if (obj?.loc) {
                    return `${obj?.loc?.join(".")} ${obj?.msg}`;
                }

                return obj?.msg ?? "";
            })
            .join("\n");
    } else if (error?.message || error?.data?.message) {
        return error?.message || error?.data?.message;
    } else if (typeof error === "string") {
        return error;
    }

    return fallback;
};


/**
 * Function to retrive time elapsed from start and end time
 * @param {string} startTime
 * @param {string} endTime
 * @returns {string}
 */
export const getEllapsedTime = (startTime, endTime) => {
    if (startTime === "")
        return ""

    const st = moment.utc(startTime);
    const et = (endTime === 0 || endTime === "") ? moment.utc() : moment.utc(endTime);

    let days = et.diff(st, "days");

    let hours = et.diff(st, "hours");
    hours = days === 0 ? hours : hours % 24;

    let minutes = et.diff(st, "minutes");
    minutes = hours === 0 ? minutes : minutes % 60;

    let ellapsedTime;
    if (days === 0 && hours === 0) ellapsedTime = `${minutes} minutes`;
    else if (days === 0) ellapsedTime = `${hours} hours and ${minutes} minutes`;
    else ellapsedTime = `${days} days ${hours} hours and ${minutes} minutes`;

    return ellapsedTime;
};


/**
 * Function to list all the GPUs running in the cluster
 * @param {Object} computeUsed 
 * @return {string} 
 */
export const getGPUsRunning = computeUsed => {
    return (Object.keys(computeUsed)
        .map(k => `${k}(${computeUsed[k]})`))
        .join(", ");
}