import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSuccess: false,
  successMessage: "Added Successfully"
}

const successSlice = createSlice({
  name: 'successState',
  initialState,
  reducers: {
    setIsSuccess: (store, action) => {
      store.isSuccess = action.payload;
    },
    setSuccessMessage: (store, action) => {
      store.successMessage = action.payload;
    }
  }
}
)

export const {
  setIsSuccess,
  setSuccessMessage,
} = successSlice.actions

export default successSlice