import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { FINETUNE_TEMPLATES_OPTIONS } from '../../Configs/JobConstants';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { color } from '../../Styles/Color';

export const FinetuneTemplates = () => {

  const nav = useNavigate()

  return (
    <Stack spacing={3}>
      <Stack direction="row" gap={3}>
        {
          FINETUNE_TEMPLATES_OPTIONS.map((temp, idx) => (
            <Stack
              key={idx}
              minWidth="32%"
              borderRadius="12px"
              border={`1px solid ${color.borders}`}
              boxShadow="0px 1px 4px 0px #0000000A"
              overflow="hidden"
              height="15vh"
              position='relative'
              sx={{
                "&:hover": {
                  cursor: 'pointer'
                },
              }}
              onClick={() =>
                nav(PAGE_ROUTES.newFineTuning, { state: { model: temp.model } })
              }
            >
              <Stack
                position="absolute"
                top={0} left={0}
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="end"
              >
                {temp.icon}
              </Stack>
              <Stack
                position="absolute"
                top={0} left={0}
                zIndex="5"
                spacing={1}
                width="100%"
                p={2}
              >
                <Stack direction="row" justifyContent="end" alignItems="center">
                  <Chip
                    sx={{
                      background: "rgba(255, 255, 255, 0.55)",
                      textTransform: "uppercase",
                      px: 1,
                    }}
                    label={<Typography variant='h3' fontSize="12px">{temp.inf_type}</Typography>}
                  />
                </Stack>
                <Typography variant='h4'>FINE-TUNE</Typography>
                <Stack direction="row" gap={1} alignItems="end">
                  <Typography variant='body1' noWrap >{temp.model}</Typography>
                  <Typography variant='subtitle1' noWrap>by {temp.provider}</Typography>
                </Stack>
              </Stack>
            </Stack>
          ))
        }
      </Stack>
      <Stack
        direction="row"
        color={color.primary}
        gap={1}
        alignItems="center"
        width="25%"
        sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}
        onClick={() => window.open("https://docs.scalegen.ai/category/tutorials", "_blank")}
      >
        <Typography variant='h3'>Checkout More Templates</Typography>
        <ArrowForwardIcon sx={{ fontSize: "14px" }} />
      </Stack>
    </Stack>
  )
}
