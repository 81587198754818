import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  "name": "",
  "model": "",
  "hf_token": "",
  "allow_spot_instances": false,
  "max_price_per_hour": 0,
  "logs_bucket_s3": "",
  "artifactsDestination": {
    "name": "",
    "filter": ""
  },
  "cloud_providers": [],
  "initial_worker_config": {
    "min_workers": 0,
    "initial_workers_gpu": "",
    "initial_workers_gpu_num": 0,
    "use_same_gpus_when_scaling": false
  },
  "autoscaling_config": {
    "lower_allowed_latency_sec": 1,
    "scale_to_zero_timeout_min": 30,
    "scaling_timeout_min": 20,
    "time_window_minutes": 5,
    "upper_allowed_latency_sec": 4
  }
}

const newInferenceSlice = createSlice({
  name: 'newInference',
  initialState,
  reducers: {
    clearStore: (store, action) => {
      // store = initialState
      Object.assign(store, initialState)
    },
    setName: (store, action) => {
      store.name = action.payload
    },
    setModel: (store, action) => {
      store.model = action.payload
    },
    setHFToken: (store, action) => {
      store.hf_token = action.payload
    },
    setAllowSpotInstances: (store, action) => {
      store.allow_spot_instances = action.payload
    },
    setArtifactsDestination: (store, action) => {
      store.logs_bucket_s3 = action.payload
      store.artifactsDestination.name = action.payload
    },
    setCloudProviders: (store, action) => {
      store.cloud_providers = action.payload
    },
    setMinWorkes: (store, action) => {
      store.initial_worker_config.min_workers = action.payload
    },
    setLookBackTimeFrame: (store, action) => {
      store.autoscaling_config.time_window_minutes = action.payload
    }
  }
})

export const {
  clearStore,
  setName,
  setAllowSpotInstances,
  setArtifactsDestination,
  setCloudProviders,
  setHFToken,
  setLookBackTimeFrame,
  setMinWorkes,
  setModel
} = newInferenceSlice.actions;

export default newInferenceSlice;