import { ReactComponent as AWSLogo } from "../Assets/Images/Amazon-S3-Logo.svg"
import { ReactComponent as AzureLogo } from "../Assets/Images/azure.svg"
import { ReactComponent as GCPLogo } from "../Assets/Images/google-cloud.svg"
// import { ReactComponent as DriveLogo } from "../Assets/Images/google-drive-logo.svg"
import logo from "../Assets/Images/s3like.png"
import { ReactComponent as SFTPLogo } from "../Assets/Images/sftp-logo.svg"

export const ARTIFACT_STORAGE_TYPE_OPTIONS = Object.freeze([
  {
    name: "S3",
    icon: (width) => <AWSLogo width={width} />
  },
  {
    name: "S3 Like",
    icon: (width) => <img src={logo} width={width} alt="" />
  },
  {
    name: "Azure Blob Storage",
    icon: (width) => <AzureLogo width={width} />
  },
  {
    name: "Google Cloud",
    icon: (width) => <GCPLogo width={width} />
  },
  {
    name: "SFTP",
    icon: (width) => <SFTPLogo width={width} />
  },
  // {
  //   name: "Google Drive",
  //   icon: (width) => <DriveLogo width={width} />
  // },
])