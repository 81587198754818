import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { removeVirtualMounts } from '../../../DataStore/newJobSlice';
import { color } from '../../../Styles/Color';
import CustomButton from "../../UiComponents/CustomButton";
import InputField from '../../UiComponents/InputField';
import AddVMModal from './AddVMModal';

const VMData = ({ userVms, watch, setValue }) => {
  const [openVMModal, setOpenVMModal] = useState(false)

  // const { virtualMounts } = useSelector(store => store.newJobData)
  // const dispatch = useDispatch()

  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
        <Typography fontWeight="600" fontSize="15px">
          Virtual Mounts
        </Typography>
        <CustomButton width="30%" onClick={() => setOpenVMModal(true)}>
          <Stack direction="row" fontSize="15px" alignItems="center" gap={1}>
            <AddIcon sx={{ fontSize: "16px" }} />
            Add Virtual Mount
          </Stack>
        </CustomButton>
      </Stack>
      {
        watch("virtualMounts").length > 0 && watch("virtualMounts").map((vm, idx) => (
          <Stack key={idx} direction="row" gap={2}>
            <InputField
              disabled={true}
              state={vm.name}
              width="50%"
            />
            <Button
              onClick={() =>
                setValue("virtualMounts", watch("virtualMounts").filter((v) => v.name !== vm.name))
              }
              sx={{
                bgcolor: "#FFDBDB",
                p: 1,
                minWidth: '30px',
                '&:hover': {
                  bgcolor: "#FFDBDB",
                }
              }} >
              <DeleteIcon sx={{ color: "#FF6B6A", fontSize: "16px" }} />
            </Button >
          </Stack>
        ))
      }
      <AddVMModal isOpen={openVMModal} handleClose={() => setOpenVMModal(false)} userVMs={userVms}
        setValue={setValue} watch={watch} />
    </Stack >
  )
}

export default VMData