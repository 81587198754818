/* eslint-disable eqeqeq */
import { Chip, Stack, Typography } from "@mui/material"
// import { useState } from "react"
// import InputField from "../../Components/UiComponents/InputField"
// import NavigationTabs from "../../Components/UiComponents/NavigationTabs"
import { SelectDropdown } from "../../Components/UiComponents/SelectDropdown"
// import { GPU_CONFIG_TABS } from "../../Configs/ConfigureNewJobConstants"
import { useEffect } from "react"
import { useLocation } from "react-router"
import { RequiredHeader } from "../../Components/UiComponents/RequiredHeader"
import { PAGE_ROUTES } from "../../Configs/Routes"
import { useGetGpuTypesMutation } from "../../Services/cloudProviderApi"
import { color } from "../../Styles/Color"

export const OptimalWorkerConfig = ({ watch, setValue, errors }) => {

  // const [gpuType, setGpuType] = useState("0")
  const isInference = useLocation().pathname.includes(PAGE_ROUTES.inferences)

  const [getGPUTypes, { data: gpuTypeOptions, isLoading: isFetchingGPUs }] = useGetGpuTypesMutation()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (watch("cloud_providers").length > 0) {
        let cloudProvider = watch("cloud_providers").map(cp => cp.name)
        let region = watch("cloud_providers").map(cp => cp.regions)

        getGPUTypes({
          cloudProviders: cloudProvider,
          cloudRegions: region
        })
      }
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [getGPUTypes, setValue, watch])

  const GPU_CONFIG_PANELS = [
    <Stack spacing={3} key={0}>
      <Stack spacing={1} width="70%">
        <RequiredHeader >
          <Typography variant="h3">Preferred GPU Type</Typography>
        </RequiredHeader>
        <SelectDropdown
          value={
            watch(
              isInference ? 'initial_worker_config.initial_workers_gpu' : "gpu_type"
            )
          }
          handleChange={(e) => setValue(
            isInference ? 'initial_worker_config.initial_workers_gpu' : "gpu_type",
            e.target.value
          )}
          options={gpuTypeOptions}
          placeholder={isFetchingGPUs ? "Fetching GPU types" : "Auto-Select"}
        />
        {
          errors?.initial_worker_config?.initial_workers_gpu &&
          <Typography variant='subtitle1' color={color.error}>This field is required.</Typography>
        }
      </Stack>
      <Stack spacing={2} >
        <RequiredHeader>
          <Typography variant="h3">No. of GPUs per Worker</Typography>
        </RequiredHeader>
        <Stack direction="row" gap={2} flexWrap="wrap" >
          {
            ["1", "2", "4", "8"].map((count) => {
              const isSelected = watch(
                isInference ? "initial_worker_config.initial_workers_gpu_num" : "gpu_count"
              )
              return (
                <Chip
                  key={count}
                  sx={{
                    color: count == isSelected && color.white,
                    bgcolor: count == isSelected ? color.primary : color.secondaryBackground,
                    border: count != isSelected && `2px solid ${color.borders}`,
                    borderRadius: "6px",
                    fontSize: "12px",
                    minWidth: "12%",
                    "&:hover": {
                      bgcolor: count == isSelected ? color.primary : color.secondaryBackground,
                    }
                  }}
                  label={count}
                  clickable={true}
                  onClick={() => {
                    count != isSelected &&
                      setValue(
                        isInference ? "initial_worker_config.initial_workers_gpu_num" : "gpu_count",
                        count
                      )
                  }}
                />
              )
            })
          }
        </Stack>
        {
          errors?.initial_worker_config?.initial_workers_gpu_num &&
          <Typography variant='subtitle1' color={color.error}>This field is required.</Typography>
        }
      </Stack>
      {/* {
        isInference &&
        <Stack spacing={1}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                sx={{
                  pl: 0
                }}
                checked={watch("initial_worker_config.use_other_gpus")}
                onChange={(e) => setValue("initial_worker_config.use_other_gpus", e.target.checked)}
              />
            }
            label={<Typography variant="h3">Allow other GPU types</Typography>}
          />
          <Typography variant='subtitle1' color={color.secondaryText}>
            When enabled, if preferred GPU configuration is not available in the region of choice, an equivalent configuration will be selected.<br />
            Note: Might affect latency/throughput based on available config.
          </Typography>
        </Stack>
      } */}
    </Stack>,
    <Stack key={1} spacing={1}>
      <Typography variant="h3">Preferred Instance Type</Typography>
      <SelectDropdown multiple={true} options={["instance1", "instance2"]} placeholder="Auto-Select" />
    </Stack>
  ]



  return (
    <Stack spacing={3}>
      {/* <NavigationTabs
        tabs={GPU_CONFIG_TABS}
        panels={GPU_CONFIG_PANELS}
        value={gpuType}
        setValue={setGpuType}
        fontSize="14px"
      /> */}
      {
        GPU_CONFIG_PANELS[0]
      }
    </Stack>
  )
}
