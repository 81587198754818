import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as SlackIcon } from "../../Assets/Images/SlackIcon.svg"
import { color } from '../../Styles/Color'
import CustomButton from '../UiComponents/CustomButton'

const ConnectSlack = () => {
  const sgUser = useSelector((state) => state.userState.sgUser)

  return (
    <Stack
      borderRadius="12px"
      border={`1px solid ${color.borders}`}
      px={2}
      py={1.5}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <SlackIcon width="16px" height="16px" />
        <Typography variant='h3'>Connect To Slack</Typography>
      </Stack>
      {
        sgUser?.user_metadata?.sg_slack_webhook_url
          ?
          <CustomButton color="#FF6B6A">Disconnect</CustomButton>
          :
          <CustomButton
            height="24px"
            onClick={() =>
              window.open(process.env.REACT_APP_SLACK_APP_URL, "_blank")
            }
          >
            Connect
          </CustomButton>
      }
    </Stack>
  )
}

export default ConnectSlack