import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const accessKeysApi = createApi({
  reducerPath: 'accessKeysApi',
  ...platformAuthConfig(),
  tagTypes: ['UserKeys'],
  endpoints: builder => ({
    getAccessKeys: builder.query({
      query: () => '/access_keys',
      providesTags: ['UserKeys']
    }),
    addAccessKeys: builder.mutation({
      query: (config) => ({
        url: '/access_keys',
        method: 'POST',
        // body: config
      }),
      invalidatesTags: ['UserKeys']
    }),
    deleteAccessKeys: builder.mutation({
      query: (id) => ({
        url: `/access_keys/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserKeys']
    })
  })
})

export const {
  useGetAccessKeysQuery,
  useAddAccessKeysMutation,
  useDeleteAccessKeysMutation
} = accessKeysApi

export default accessKeysApi
