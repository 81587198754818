import { FormControlLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { PRIVATE_REPO_CREDS_FIELDS, REPO_HOSTS } from '../../../Configs/NewJobConstants'
// import { setBranchCode, setCodeDirectory, setIsRepoPrivate, setRepoCreds, setRepoHost, setRepoName } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'
import BoxSwitch from '../../UiComponents/BoxSwitch'
import InputField from '../../UiComponents/InputField'

const CodeFromRepository = ({ watch, register, setValue, errors }) => {

  // const { repoHost, repoName, branchCode, codeDirectory, isRepoPrivate, repoCreds } =
  //   useSelector((store) => store.newJobData)
  // const dispatch = useDispatch()

  return (
    <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Typography fontWeight="600" fontSize="15px">
        Code Repo Host (Choose a source where your code is stored, eg. "GitLab".)
      </Typography>
      <Select
        size='small'
        sx={{ fontSize: '15px', width: "30%", borderRadius: '8px', bgcolor: '#FFF' }}
        value={watch('codeTransfer.type')}
        onChange={(e) => setValue("codeTransfer.type", e.target.value)}
      // {...register('repoHost', { required: true })}
      >
        {
          REPO_HOSTS.map((option, idx) => (
            <MenuItem value={option.replace(" ", "_").toUpperCase()} key={idx} sx={{ fontSize: "15px" }}>
              {option.replace(" ", "_").toUpperCase()}
            </MenuItem>
          ))
        }
      </Select>
      <Typography fontWeight="600" fontSize="15px">
        Repository
      </Typography>
      <InputField
        width="70%"
        placeholder="Enter the name of your existing repository, where your code is located."
        // state={repoName}
        // setState={(e) => dispatch(setRepoName(e.target.value))}
        register={register} field="codeTransfer.repo" required={true} watch={watch}
        error={errors?.codeTransfer?.repo}
      />
      <Typography fontWeight="600" fontSize="15px">
        Commit hash or Branch name
      </Typography>
      <InputField
        width="70%"
        placeholder="Enter Specific version or branch of your code to use."
        // state={branchCode}
        // setState={(e) => dispatch(setBranchCode(e.target.value))}
        register={register} field="codeTransfer.ref" required={true} watch={watch}
        error={errors?.codeTransfer?.ref}
      />
      <Typography fontWeight="600" fontSize="15px">
        Code Directory
      </Typography>
      <InputField
        width="70%"
        placeholder="Enter Path to the directory containing the script relative to the repo."
        // state={codeDirectory}
        // setState={(e) => dispatch(setCodeDirectory(e.target.value))}
        register={register} field="codeTransfer.codeDir" required={true} watch={watch}
        error={errors?.codeTransfer?.codeDir}
      />
      {
        PRIVATE_REPO_CREDS_FIELDS[watch('codeTransfer.type')] &&
        <FormControlLabel
          value={watch('isPrivate')}
          sx={{
            p: 2
          }}
          control={
            <BoxSwitch onChange={(event) => setValue('isPrivate', event.target.checked)} />}
          label={
            <Typography fontSize="17px">Is Repo Private ?</Typography>
          }
        />
      }
      {
        watch('isPrivate') && (PRIVATE_REPO_CREDS_FIELDS[watch('codeTransfer.type')] || []).map((field, idx) =>
          <Stack
            key={idx}
            spacing={1}
          >
            <Typography fontSize="15px">{field.replace("_", " ")}</Typography>
            <InputField
              // state={repoCreds[field]}
              // setState={e => dispatch(setRepoCreds({ ...repoCreds, [field]: e.target.value }))}
              register={register} field={`codeTransfer.credentials.${field}`} watch={watch}
            />
          </Stack>
        )
      }
    </Stack >
  )
}

export default CodeFromRepository