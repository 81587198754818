import { ReactComponent as AWSLogo } from "../Assets/Images/Amazon-S3-Logo.svg"
import { ReactComponent as AzureLogo } from "../Assets/Images/azure.svg"
import { ReactComponent as GCPLogo } from "../Assets/Images/google-cloud.svg"
// import { ReactComponent as DriveLogo } from "../Assets/Images/google-drive-logo.svg"
import { ReactComponent as WebLogo } from "../Assets/Images/http-https-logo.svg"
import logo from "../Assets/Images/s3like.png"
import { ReactComponent as SFTPLogo } from "../Assets/Images/sftp-logo.svg"


export const VM_STORAGE_TYPE_OPTIONS = Object.freeze([
  {
    name: "S3",
    icon: (width) => <AWSLogo width={width} />
  },
  {
    name: "S3 Like",
    icon: (width) => <img src={logo} width={width} alt="" />
  },
  {
    name: "Azure Blob Storage",
    icon: (width) => <AzureLogo width={width} />
  },
  {
    name: "Google Cloud",
    icon: (width) => <GCPLogo width={width} />
  },
  {
    name: "SFTP",
    icon: (width) => <SFTPLogo width={width} />
  },
  {
    name: "HTTP / HTTPS",
    icon: (width) => <WebLogo width={width} />
  },
  // {
  //   name: "Google Drive",
  //   icon: (width) => <DriveLogo width={width} />
  // },
])

export const VM_STORAGE_TYPE_OPTIONS_CREDENTIALS = Object.freeze({
  "S3": [
    {
      label: "AWS Access Key ID",
      placeholder: "Enter value",
      formKey: "AWS_ACCESS_KEY_ID"
    },
    {
      label: "AWS Secret Access Key",
      placeholder: "Enter value",
      formKey: "AWS_SECRET_ACCESS_KEY"
    }
  ],
  "S3 Like": [
    {
      label: "Endpoint URL",
      placeholder: "Enter value",
      formKey: "ENDPOINT_URL"
    },
    {
      label: "AWS Access Key ID",
      placeholder: "Enter value",
      formKey: "AWS_ACCESS_KEY_ID"
    },
    {
      label: "AWS Secret Access Key",
      placeholder: "Enter value",
      formKey: "AWS_SECRET_ACCESS_KEY"
    }
  ],
  "Azure Blob Storage": [
    {
      label: "Azure Account Name",
      placeholder: "Enter value",
      formKey: "AZURE_ACCOUNT_NAME"
    },
    {
      label: "Azure Account Key",
      placeholder: "Enter value",
      formKey: "AZURE_ACCOUNT_KEY"
    }
  ],
  "Google Cloud": [
    {
      label: "GS Credentials",
      placeholder: "Enter value",
      formKey: "GS_CREDS"
    }
  ],
  "SFTP": [
    {
      label: "Username",
      placeholder: "Enter value",
      formKey: "SFTP_USERNAME"
    },
    {
      label: "Password / Private Key",
      placeholder: "Enter value",
      formKey: "SFTP_PASSWORD/SFTP_PRIVATE_KEY"
    },
    {
      label: "Port",
      placeholder: "Enter value",
      formKey: "SFTP_PORT"
    },
    {
      label: "Host",
      placeholder: "Enter value",
      formKey: "SFTP_HOST"
    }
  ]
})
