import { Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import DeleteConfirmationModal from "../Components/DeleteConfirmation/DeleteConfirmationModal"
import Loader from "../Components/UiComponents/Loader"
// import NavigationTabs from "../Components/UiComponents/NavigationTabs"
// import { InferenceCertificate } from "../NewComponents/Inference/InferenceCertificate"
import { useDeleteInferenceMutation, useGetInferenceQuery } from "../Services/inferenceApi"
import { NotFoundErrorPage } from "../Views/NotFoundErrorPage"
import { InferenceOverview } from "./InferenceOverview"

export const InferenceDetails = () => {

  const location = useLocation()

  // const [activeTab, setActiveTab] = useState('0')

  const { data: inferenceDetails, isLoading: isLoadingDetails } =
    useGetInferenceQuery(location.pathname.split('/').pop(), {
      skip: false,
    })

  const [deleteInference, { isLoading }] = useDeleteInferenceMutation()

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  useEffect(() => {
    if (isConfirmedDelete) {
      deleteInference(inferenceDetails?.id)
    }
  }, [deleteInference, inferenceDetails?.id, isConfirmedDelete])

  const handleDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  if (isLoadingDetails || isLoading) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return inferenceDetails ? (
    <Stack>
      {/* {
        (
          (
            inferenceDetails?.controller_cloud_config &&
            inferenceDetails?.controller_cloud_config.use_api_gateway === false &&
            inferenceDetails?.controller_cloud_config.use_ssl === true
          )
          ||
          (
            inferenceDetails?.controller_on_prem_config &&
            inferenceDetails?.controller_on_prem_config.use_ssl === true
          )

        ) &&
          !(
            inferenceDetails.status === "DELETED" ||
            inferenceDetails.status === "DELETING" ||
            inferenceDetails.status === "FAILED"
          ) ?
          <Stack mt={-2}>
            <NavigationTabs
              value={activeTab}
              setValue={setActiveTab}
              tabs={["Overview", "Certificate"]}
              panels={[
                <InferenceOverview
                  inferenceDetails={inferenceDetails} setIsDeleteModalOpen={setIsDeleteModalOpen}
                />,
                <InferenceCertificate inferenceDetails={inferenceDetails} />
              ]}
            />
          </Stack>
          :
          <InferenceOverview
            inferenceDetails={inferenceDetails}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
          />
      } */}
      <InferenceOverview
        inferenceDetails={inferenceDetails}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
      <DeleteConfirmationModal
        handleDelete={handleDelete} itemName="Inference Deployement"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </Stack>
  ) : <NotFoundErrorPage />
}
