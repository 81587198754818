import { createApi } from "@reduxjs/toolkit/query/react";
import { platformAuthConfig } from "./platformAuthConfig.js";

const virtualMountsApi = createApi({
  reducerPath: "virtualMountsApi",
  tagTypes: ["VirtualMounts"],
  ...platformAuthConfig(),
  endpoints: (builder) => ({
    getVirtualMounts: builder.query({
      query: () => ({
        url: "/virtual_mounts",
        method: "GET",
      }),
      providesTags: ["VirtualMounts"]
    }),
    createVirtualMount: builder.mutation({
      query: (config) => ({
        url: "/virtual_mounts",
        method: "POST",
        body: config,
      }),
      invalidatesTags: ["VirtualMounts"],
    }),
    deleteVirtualMount: builder.mutation({
      query: (id) => ({
        url: `/virtual_mount?virtual_mount_id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VirtualMounts"],
    }),
    editVirtualMount: builder.mutation({
      query: (config) => ({
        url: `/virtual_mounts`,
        method: "PUT",
        body: config,
      }),
    }),
  }),
});

export const {
  useGetVirtualMountsQuery,
  useCreateVirtualMountMutation,
  useDeleteVirtualMountMutation,
  useEditVirtualMountMutation,
} = virtualMountsApi;

export default virtualMountsApi;
