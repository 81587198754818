import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { CONFIGURATION_TYPE_OPTIONS } from '../../../Configs/ConfigureNewJobConstants';
// import { setSpotInstances } from '../../../DataStore/newJobSlice';
import { color } from '../../../Styles/Color';
import BoxSwitch from '../../UiComponents/BoxSwitch';
import CustomToggleButton from '../../UiComponents/CustomToggleButton';
import { ADTConfig } from './ADTConfig';
import { AdvancedSettings } from './AdvancedSettings';
import { CPUConfig } from './CPUConfig';
import { GPUConfig } from './GPUConfig';

export const NormalConfigurations = ({ register, watch, setValue, errors, activeTab, setActiveTab }) => {
  // const dispatch = useDispatch()

  useEffect(() => {
    if (activeTab === CONFIGURATION_TYPE_OPTIONS[0] || activeTab === CONFIGURATION_TYPE_OPTIONS[2]) {
      setValue("maxCPUWorkers", null)

    } else {
      setValue("gpusPerTrial", null)
      setValue("instanceTypes", null)
      setValue("gpuTypes", null)
      setValue("numNodes", null)
    }
  }, [activeTab, setValue])

  const renderTab = () => {
    switch (activeTab) {
      case CONFIGURATION_TYPE_OPTIONS[1]: return <CPUConfig register={register} watch={watch} />
      case CONFIGURATION_TYPE_OPTIONS[0]: return <GPUConfig register={register}
        watch={watch} setValue={setValue} />
      case CONFIGURATION_TYPE_OPTIONS[2]: return <ADTConfig register={register}
        watch={watch} setValue={setValue} />
      default: return <></>
    }
  }

  const renderError = () => {
    switch (activeTab) {
      case CONFIGURATION_TYPE_OPTIONS[1]:
        if (errors.maxCPUWorkers) {
          return <Typography color={color.error} fontSize="15px">This section is required</Typography>
        }
        break
      case CONFIGURATION_TYPE_OPTIONS[0]:
        if (errors.gpu) {
          return <Typography color={color.error} fontSize="15px">This section is required</Typography>
        }
        break
      case CONFIGURATION_TYPE_OPTIONS[2]:
        if (errors.gpu) {
          return <Typography color={color.error} fontSize="15px">This section is required</Typography>
        }
        break
      default: return <></>
    }
  }

  return (
    <Stack gap={2}
      overflow="auto"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        }
      }}
    >
      <FormControlLabel sx={{ p: 2 }}
        value={watch("useSpotInstances")}
        control={
          <BoxSwitch
            onChange={(e) => setValue("useSpotInstances", e.target.checked)}
          />
        }
        label={<Stack direction="row" alignItems="center">
          <Typography fontSize="17px" mx={1}>Spot Instances</Typography>
          <Tooltip title="Information">
            <InfoOutlinedIcon sx={{
              color: '#ABABAB',
              fontSize: '20px'
            }} />
          </Tooltip>
        </Stack >}
      />
      <CustomToggleButton
        options={CONFIGURATION_TYPE_OPTIONS} selected={activeTab} setSelected={setActiveTab}
      />
      {renderTab()}
      {renderError()}
      <AdvancedSettings />
    </Stack>
  )
}
