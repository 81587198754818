import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, Dialog, DialogContent, DialogTitle, Divider, IconButton, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as ArtifactsIcon } from "../../Assets/Images/artifactIcon.svg";
import { ReactComponent as PersonIcon } from "../../Assets/Images/person.svg";
import { ReactComponent as SecretsIcon } from "../../Assets/Images/secretsIcon.svg";
import { ReactComponent as VMIcon } from "../../Assets/Images/vmIcon.svg";
import { ReactComponent as CloudIcon } from "../../Assets/Images/wb_cloudy.svg";
import { useGetArtifactStoragePathsQuery } from '../../Services/artifactStorageApi';
import { useGetUserCloudsQuery } from '../../Services/cloudProviderApi';
import { useGetSecretsQuery } from '../../Services/secretsApi';
import { useGetUserPermissionsQuery, useUpdatePermissionMutation } from '../../Services/userApi';
import { useGetVirtualMountsQuery } from '../../Services/virtualMountsApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';
import SecondaryButton from '../UiComponents/SecondaryButton';

const TeamMember = ({ member, team, setModalIsOpen, setMemberData, modalIsOpen }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [permissions, setPermissions] = useState()

  const { data: permission, isLoading, isSuccess, isFetching } =
    useGetUserPermissionsQuery(member, { skip: member.length === 0 })
  const { data: cloudProviders, isLoading: fetchingClouds } = useGetUserCloudsQuery()
  const { data: storagePaths, isLoading: fetchingStoragePaths } = useGetArtifactStoragePathsQuery()
  const { data: virtualMounts, isLoading: fetchingVirtualMounts } = useGetVirtualMountsQuery()
  const { data: secrets, isLoading: fetchingSecrets } = useGetSecretsQuery()

  const [updatePermission, { isLoading: updating, isSuccess: isUpdated }] = useUpdatePermissionMutation()

  useEffect(() => {
    if (isSuccess) {
      setPermissions(permission)
    }
  }, [isSuccess, permission])


  useEffect(() => {
    if (isUpdated) setIsEditing(false)
  }, [isUpdated])

  const getIcon = (icon) => {
    switch (icon) {
      case "CLOUD_PROVIDER": return <CloudIcon width="15px" height="15px" />
      case "ARTIFACTS_STORAGE": return <ArtifactsIcon width="15px" height="15px" />
      case "SECRET": return <SecretsIcon width="15px" height="15px" />
      case "VIRTUAL_MOUNT": return <VMIcon width="15px" height="15px" />
      default: return
    }
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (e, permissionTitle) => {
    setPermissions({ ...permissions, [permissionTitle]: e.target.value })
  }

  const getOptions = (value) => {
    switch (value) {
      case "CLOUD_PROVIDER": return cloudProviders?.map(provider => provider.cloud_provider)
      case "ARTIFACTS_STORAGE": return storagePaths?.map(path => path.name)
      case "SECRET": return secrets
      case "VIRTUAL_MOUNT": return virtualMounts?.map(vm => vm.name)
      default: return []
    }
  }

  const handleSave = () => {

    if (JSON.stringify(permissions) === JSON.stringify(permission)) {
      setIsEditing(false)
      return
    }

    // API call to change permissions
    Object.entries(permissions).forEach(entry => {
      if (JSON.stringify(entry[1]) !== JSON.stringify(permission[entry[0]])) {
        updatePermission(
          {
            "member_email": member,
            "entity_type": entry[0],
            "entity_ids": entry[1]
          }
        )
      }
    })
  }

  return (
    <Dialog
      maxWidth="xl"
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false)
        setIsEditing(false)
      }}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}
    >
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack gap={1} direction="row" fontSize="1.25rem" alignItems="center" width="70%">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <PersonIcon width="20px" height="20px" />
            </Stack>
            <Select
              sx={{ bgcolor: color.white, fontSize: "15px", borderRadius: "8px" }}
              fullWidth
              value={member}
              onChange={(e) =>
                setMemberData(e.target.value)
              }
              size='small'
              input={<OutlinedInput placeholder="Choose the region where you want to train" />}
            >
              {
                team?.map((email, idx) => (
                  <MenuItem value={email} key={idx} sx={{ fontSize: "15px" }}>
                    {email}
                  </MenuItem>
                ))
              }
            </Select>
          </Stack>
          <IconButton onClick={() => {
            setModalIsOpen(false)
            setIsEditing(false)
          }
          }>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "55vh",
        width: "55vw", p: 2, py: 3,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
        <Stack alignItems="end" pb={2}>
          {
            isEditing ?
              <CustomButton onClick={handleSave} width="100px">Save</CustomButton>
              :
              <SecondaryButton onClick={() => { setIsEditing(!isEditing) }}>Edit</SecondaryButton>
          }
        </Stack>
        {
          isLoading || fetchingClouds || fetchingSecrets || isFetching
            || fetchingStoragePaths || fetchingVirtualMounts || updating ?
            <Stack height="40vh">
              <Loader />
            </Stack>
            :
            permissions &&
            <Stack gap={3}>
              {
                Object.entries(permissions).filter(e => e[0] !== "GT2")?.map((entry, idx) => (
                  <Stack gap={1} key={idx}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <Stack
                        border="2px solid #92CBFF"
                        boxShadow="0px 2px 4px 0px #0000001F"
                        padding={1}
                        borderRadius="10px"
                      >
                        {getIcon(entry[0])}
                      </Stack>
                      <Typography>{entry[0].replace("_", " ")}</Typography>
                    </Stack>
                    {
                      isEditing ?
                        <Select
                          size='small'
                          multiple
                          value={entry[1]}
                          onChange={(e) => handleChange(e, entry[0])}
                          input={<OutlinedInput placeholder="Choose one" />}
                          renderValue={(selected, idx) => (
                            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {
                                selected.length === 0 ?
                                  <Typography fontSize="15px">Choose one</Typography>
                                  :
                                  selected.map((value) => (
                                    <Chip key={value} sx={{
                                      bgcolor: color.lightBlue,
                                      borderRadius: "6px",
                                      fontSize: "12px",
                                    }} label={value} />
                                  ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                          sx={{ ml: 6.5, bgcolor: color.white, borderRadius: "8px" }}
                        >
                          {getOptions(entry[0]).map((name, idx) => (
                            <MenuItem
                              key={idx}
                              value={name}
                              sx={{ fontSize: "15px" }}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        :
                        <Stack direction="row" gap={0.5} ml={6.5} flexWrap="wrap">
                          {
                            entry[1].length > 0 ?
                              entry[1].map((item, idx) => (
                                <Chip
                                  key={idx}
                                  sx={{
                                    bgcolor: color.lightBlue,
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                  }}
                                  label={item}
                                />
                              ))
                              :
                              <Typography fontSize="15px" >No Permission</Typography>
                          }
                        </Stack>
                    }
                  </Stack>
                ))
              }
            </Stack>
        }
      </DialogContent>
    </Dialog>
  )
}

export default TeamMember