import { Stack, Typography } from '@mui/material'
import React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { setNumberOfGPUs } from '../../../DataStore/newJobSlice'
import { color } from '../../../Styles/Color'
import InputField from '../../UiComponents/InputField'
import { GPUConfig } from './GPUConfig'

export const ADTConfig = ({ register, watch, setValue }) => {
  // const dispatch = useDispatch()
  // const { numberOfNodes } = useSelector(store => store.newJobData)
  return (
    <Stack gap={2}>
      <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`}>
        <Typography fontWeight="600" fontSize="15px">
          Number of Nodes
        </Typography>
        <InputField
          width="70%"
          placeholder="Enter value"
          // state={numberOfNodes}
          // setState={(e) => dispatch(setNumberOfGPUs(e.target.value))}
          register={register} field="numNodes" required={true}
          watch={watch}
        />
      </Stack>
      <GPUConfig register={register} watch={watch} setValue={setValue} />
    </Stack >
  )
}
